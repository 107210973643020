import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Preferences, PreferencesFields } from 'src/types/questionnaire/preferences';

export const ShopSelector: view = ({
  favouriteMakeupShop = observe.profile.attributes.favouriteMakeupShop,
  updateFavouriteMakeupShop = update.ui.profile.attributes.favouriteMakeupShop,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'favouriteMakeupShop';
  const label = translate('profile.questionnaire.preferences.favouriteMakeupShop.label');
  const choices = Preferences[PreferencesFields.FAVOURITE_MAKEUP_SHOP].map((favouriteMakeupShop) => {
    return {
      label: translate(`profile.questionnaire.preferences.${PreferencesFields.FAVOURITE_MAKEUP_SHOP}.options.${favouriteMakeupShop}`),
      value: favouriteMakeupShop,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>, newChoices: string[]) => {
    updateFavouriteMakeupShop.set(newChoices);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={favouriteMakeupShop} onChange={handleChoice} />
    </div>
  );
};
ShopSelector.displayName = 'ShopSelector';
