import * as Yup from 'yup';
import { useFormik } from 'formik';

import { AuthActionType, AuthFields } from '../../../core/domain';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { AuthStateProps } from '../../../state';
import { Button, Input, Spinner } from '../../elements';

import { StyledAuthForm } from './styles';

type Props = AuthStateProps &
  LanguageProps & {
    updateVerificationCode: Update<string>;
  };

interface ConfirmAccountFormValues {
  verificationCode: string;
}

const initialValues: ConfirmAccountFormValues = {
  verificationCode: '',
};

const validationSchema: Yup.SchemaOf<ConfirmAccountFormValues> =
  Yup.object().shape({
    verificationCode: Yup.string()
      .required('Verification Code is required')
      .matches(/^\d{6}$/gm),
  });

export const ConfirmAccountForm: view<Props> = ({
  isLoading = observe.auth[AuthActionType.CONFIRM_ACCOUNT].isLoading,
  updateTrigger = update.auth[AuthActionType.CONFIRM_ACCOUNT].trigger,
  updateVerificationCode = update.auth[AuthActionType.CONFIRM_ACCOUNT].fields[
    AuthFields.VERIFICATION_CODE
  ],
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const onSubmit = ({ verificationCode }: ConfirmAccountFormValues) => {
    updateVerificationCode.set(verificationCode);

    updateTrigger.set(Date.now());
  };

  const { handleSubmit, values, handleChange, errors, isValid } =
    useFormik<ConfirmAccountFormValues>({
      validationSchema,
      initialValues,
      onSubmit,
    });

  return (
    <StyledAuthForm onSubmit={handleSubmit} lang={language}>
      <Input
        name="verificationCode"
        type="text"
        label={translate('login.verification_code')}
        placeholder=" "
        value={values.verificationCode}
        errorMessage={errors.verificationCode}
        onChange={handleChange}
      />
      <button className="primary rounded button button-primary" type="submit" disabled={!isValid}>
        {isLoading ? <Spinner /> : translate('login.submit')}
      </button>
    </StyledAuthForm>
  );
};
