import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Needs, NeedsFields } from 'src/types/questionnaire/needs';

export const GoodFeelingRaisingSelfEsteemSelector: view = ({
  goodFeelingRaisingSelfEsteem = observe.profile.attributes.goodFeelingRaisingSelfEsteem,
  updateGoodFeelingRaisingSelfEsteem = update.ui.profile.attributes.goodFeelingRaisingSelfEsteem,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'goodFeelingRaisingSelfEsteem';
  const label = translate('profile.questionnaire.needs.goodFeelingRaisingSelfEsteem.label');
  const choices = Needs[NeedsFields.GOOD_FEELING_RAISING_SELF_ESTEEM].map((answer) => {
    return {
      label: translate(`profile.questionnaire.needs.${NeedsFields.GOOD_FEELING_RAISING_SELF_ESTEEM}.options.${answer}`),
      value: answer,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateGoodFeelingRaisingSelfEsteem.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector
        items={choices}
        selections={goodFeelingRaisingSelfEsteem ? [goodFeelingRaisingSelfEsteem] : []}
        onChange={handleChoice}
      />
    </div>
  );
};
GoodFeelingRaisingSelfEsteemSelector.displayName = 'GoodFeelingRaisingSelfEsteemSelector';
