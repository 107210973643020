import {
  CircularProgressbar as CircularProgress,
  buildStyles,
} from 'react-circular-progressbar'

import { defaultStyles, StyledCircularProgressBar } from './styles'

interface Styles {
  rotation: number;
  strokeLinecap: string;
  pathTransitionDuration: number;
  textSize: string;
  pathColor: string;
  textColor: string;
  trailColor: string;
  backgroundColor: string;
}

type Props = {
  value: number;
  text?: string;
  className?: string;
  styles?: Styles;
};

export const CircularProgressBar = ({
  value,
  text,
  className,
  styles,
}: Props) => {
  const displayedText = text || `${value} %`
  const appliedStyles = styles
    ? buildStyles({ ...styles })
    : defaultStyles(value)

  return (
    <StyledCircularProgressBar>
      <CircularProgress
        value={value}
        text={displayedText}
        className={className || ''}
        styles={appliedStyles}
      />
    </StyledCircularProgressBar>
  )
}
