export enum AuthActionType {
  REFRESH_SESSION = 'REFRESH_SESSION',
  SIGN_UP = 'SIGN_UP',
  CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT',
  SIGN_IN = 'SIGN_IN',
  REQUEST_NEW_PASSWORD = 'REQUEST_NEW_PASSWORD',
  CONFIRM_NEW_PASSWORD = 'CONFIRM_NEW_PASSWORD',
  SIGN_OUT = 'SIGN_OUT',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  AUTHORIZE = 'AUTHORIZE',
  SOCIAL_SIGN_IN = 'SOCIAL_SIGN_IN',
}
