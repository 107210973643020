import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Preferences, PreferencesFields } from 'src/types/questionnaire/preferences';

export const TimeOnEverydayHairSelector: view = ({
  timeOnEverydayHair = observe.profile.attributes.timeOnEverydayHair,
  updateTimeOnEverydayHair = update.ui.profile.attributes.timeOnEverydayHair,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'timeOnEverydayHair';
  const label = translate('profile.questionnaire.preferences.timeOnEverydayHair.label');
  const choices = Preferences[PreferencesFields.TIME_ON_EVERYDAY_HAIR].map((answer) => {
    return {
      label: translate(`profile.questionnaire.preferences.${PreferencesFields.TIME_ON_EVERYDAY_HAIR}.options.${answer}`),
      value: answer,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateTimeOnEverydayHair.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={timeOnEverydayHair ? [timeOnEverydayHair] : []} onChange={handleChoice} />
    </div>
  );
};
TimeOnEverydayHairSelector.displayName = 'TimeOnEverydayHairSelector';
