import { RouteName } from '../../../core/types';

export const landingDidMount: producer = ({
  isLoading = observe.pages[RouteName.LANDING].isLoading,
  // videoState = observe.videoState,
  updateIsLoading = update.pages[RouteName.LANDING].isLoading,
}) => {
  if (!isLoading) return;

  updateIsLoading.set(false);
};
