import { Slide } from 'src/newDesign/Onboarding/Slide';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import { I18nTranslationsService } from 'src/services';
import bodyIcon from '../../../../assets/jtdiva/q-body.png';
import { HelpColorClothesSelector } from '../fields/HelpColorClothesSelector';
import { HelpFormalClothesSelector } from '../fields/HelpFormalClothesSelector';
import { HelpMakeupColorsSelector } from '../fields/HelpMakeupColorsSelector';
import { HelpMakeupApplicationSelector } from '../fields/HelpMakeupApplicationSelector';

export const StyleGuidance: view = ({ translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.needs.describe_needs')}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.needs.heading')}
        cardDescription={translate('profile.questionnaire.needs.description')}
      >
        <HelpColorClothesSelector />
        <HelpFormalClothesSelector />
        <HelpMakeupColorsSelector />
        <HelpMakeupApplicationSelector />
      </QuestionCard>
    </Slide>
  );
};
