import { AuthActionType, AuthEvents, AuthFields } from '../../../core/domain';
import { resultFormatter } from '../../../core/types';
import { SignUpResult } from '../../../core/services';
import { CognitoAuthService } from '../../../services';

export const signUpAction: producer = async ({
  trigger = observe.auth[AuthActionType.SIGN_UP].trigger,
  updateTrigger = update.auth[AuthActionType.SIGN_UP].trigger,
  updateIsLoading = update.auth[AuthActionType.SIGN_UP].isLoading,
  updateResult = update.auth[AuthActionType.SIGN_UP].result,
  firstName = get.auth[AuthActionType.SIGN_UP].fields[AuthFields.FIRST_NAME],
  email = get.auth[AuthActionType.SIGN_UP].fields[AuthFields.EMAIL],
  password = get.auth[AuthActionType.SIGN_UP].fields[AuthFields.PASSWORD],
  AuthService = CognitoAuthService,
}) => {
  if (!trigger) return;

  updateTrigger.remove();
  updateIsLoading.set(true);

  try {
    const response: SignUpResult = await AuthService.signUp({
      firstName: firstName.value(),
      username: email.value(),
      password: password.value(),
    });

    const {
      userConfirmed,
      codeDeliveryDetails: { AttributeName },
    } = response;
    const successMessage =
      userConfirmed && AttributeName !== 'email'
        ? ''
        : AuthEvents.ACCOUNT_CREATED_SUCCESS;

    updateResult.set(resultFormatter.ok(response, successMessage));
  } catch (e: unknown) {
    updateResult.set(resultFormatter.error((e as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
