import { ApiActionType } from '../../../core/types';
import { SweetAlertService } from '../../../services';
import { ErrorLoggerService } from '../../../services/ErrorLoggerService';

export const getModulesErrorAction: producer = async ({
  result = observe.api[ApiActionType.GET_MODULES].result,
  updateResult = update.api[ApiActionType.GET_MODULES].result,
  updateError = update.lifeCycle.error,
  alertService = SweetAlertService,
}) => {
  if (!(result && result.error)) return;
  ErrorLoggerService.logError(result.error);

  await alertService.error(result.error);

  updateError.set(result.error);
  updateResult.remove();
};
