import { authModalInitialState } from '../../../components';
import { AuthActionType } from '../../../core/domain';
import { Routes } from '../../../core/types';

export const signOutSuccessAction: producer = ({
  // history,
  // result = observe.auth[AuthActionType.SIGN_OUT].result,
  // updateResult = update.auth[AuthActionType.SIGN_OUT].result,
  // updateUser = update.user,
  // updateAuthModalState = update.utils.components.authModal.state,
}) => {
  // if (!(result && result.isOk)) return;

  // sessionStorage.clear();
  // localStorage.clear();
  // updateUser.remove();
  // updateAuthModalState.set(authModalInitialState);

  // history.replace(Routes.LANDING);

  // updateResult.remove();
};
