import { Module } from '../../../core/domain';
import { ApiActionType } from '../../../core/types';

export const getModulesSuccessAction: producer = ({
  result = observe.api[ApiActionType.GET_MODULES].result,
  updateResult = update.api[ApiActionType.GET_MODULES].result,
  updateModules = update.modules,
}) => {
  if (!(result && result.isOk)) return;

  const modules: Module[] = result.value;

  updateModules.set(modules);
  updateResult.remove();
};
