import { ChangeEvent, KeyboardEvent } from 'react';

export enum InputType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  DATE = 'date',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  RANGE = 'range',
  NUMBER = 'number',
  PASSWORD = 'password',
  EMAIL = 'email',
}

export enum SelectType {
  SIMPLE = 'simple',
  MULTISELECT = 'multi',
}

export type InputEventType =
  | ChangeEvent<HTMLInputElement>
  | KeyboardEvent<HTMLInputElement>
  | ChangeEvent<HTMLSelectElement>
  | ChangeEvent<HTMLTextAreaElement>;
