import { Slide } from 'src/newDesign/Onboarding/Slide';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import { I18nTranslationsService } from 'src/services';
import bodyIcon from '../../../../assets/jtdiva/q-body.png';
import { SignupReasonSelector } from '../fields/SignupReasonSelector';
import { WellDressedBetterDaySelector } from '../fields/WellDressedBetterDayS';
import { ClothesShowIdentitySelector } from '../fields/ClothesShowIdentitySelector';
import { GoodFeelingRaisingSelfEsteemSelector } from '../fields/GoodFeelingRaisingSelfEsteemSelector';

export const PersonalPresentation: view = ({ translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.needs.describe_needs')}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.needs.heading')}
        cardDescription={translate('profile.questionnaire.needs.description')}
      >
        <SignupReasonSelector />
        <WellDressedBetterDaySelector />
        <GoodFeelingRaisingSelfEsteemSelector />
        <ClothesShowIdentitySelector />
      </QuestionCard>
    </Slide>
  );
};
