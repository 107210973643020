import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const PremiumTag = styled.div`
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 12px;
  border-radius: 60px;
  background-color: #FFBE97;

  span {
    font-size: 12px;
    font-weight: 600;
    color: #897180;
  }

  
  @media ${theme.queries.tablet} {
    padding: 6px 8px;
    svg {
      height: 16px;
    }
    span {
      font-size: 10px;
    }
  }
`;

export const StyledSubscriptionBenefitsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;

  & > h5 {
    font: 500 24px/32px ${theme.font.secondary};
  }

  & > ul {
    font: 400 16px/22px ${theme.font.secondary};

    & li {
      text-align: center;
    }
  }

  & svg {
    font-size: 72px;
  }

  @media ${theme.queries.mobile} {
    svg {
      font-size: 60px;
    }
  }

  span {
    margin-right: 8px;
    background-image: url(https://fsh-assets.s3.eu-north-1.amazonaws.com/diva.svg);
    background-size: contain;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
  }
`;

export const StyledSubscriptionTypeAsset = styled.div`
  color: ${theme.color.red.somon};
`;
