import { Logo } from 'src/icons';
import styles from './styles.module.css';
import { FeedIcon } from 'src/icons';
import { ProfileIcon } from 'src/icons';
import { Link } from 'react-router-dom';
import { LanguageSelector } from 'src/components';
import { I18nTranslationsService } from 'src/services';

export const Navbar: view = ({ translate = I18nTranslationsService.translate }) => {
  const path = window.location.pathname;

  return (
    <header className={styles['header']}>
      <span className={styles['logo']}>
        <Logo></Logo>
      </span>
      <nav className={styles['navbar']}>
        <Link to="/feed" className={(path === '/feed') || (path.includes('videos')) ? styles['active'] : ''}>
          <FeedIcon />
          <span>{translate('navbar.feed')}</span>
        </Link>
        <Link to="/profile" className={(path === '/profile') || (path.includes('questionnaire')) ? styles['active'] : ''}>
          <ProfileIcon />
          <span>{translate('navbar.profile')}</span>
        </Link>
      </nav>
      <span>
        <LanguageSelector />
      </span>
    </header>
  );
};
