import { ApiActionType } from '../../../core/types';

export const createCustomerPortalSuccessAction: producer = ({
  result = observe.api[ApiActionType.CREATE_CUSTOMER_PORTAL].result,
  updateResult = update.api[ApiActionType.CREATE_CUSTOMER_PORTAL].result,
  updateCustomerPortalUrl = update.payment.customerPortalUrl,
}) => {
  if (!(result && result.isOk)) return;

  updateCustomerPortalUrl.set(result.value);
  updateResult.remove();
};
