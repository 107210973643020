import { ReactElement } from 'react';
import { TranslationsService } from '../../../core/services';
import { Language } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { EnglandFlag, NorwayFlag } from '../../Icons';
import { StyledLanguage, StyledLanguageSelector } from './styles';

const languageMap: Record<Language, ReactElement> = {
  [Language.ENGLISH]: EnglandFlag(),
  [Language.NORWEGIAN]: NorwayFlag(),
};

type Props = {
  readonly language: Language;
  updateLanguage: Update<Language>;
  translationsService: TranslationsService;
};

export const LanguageSelector: view<Props> = ({
  language = observe.language.value,
  updateLanguage = update.language.value,
  translationsService = I18nTranslationsService,
}: Props) => {
  const handleChange = (newLanguage: Language) => {
    translationsService.changeLanguage(newLanguage).then(() => {
      updateLanguage.set(newLanguage);
    });
  };

  return (
    <StyledLanguageSelector>
      {Object.entries(languageMap).map(([name, icon]) => (
        <StyledLanguage
          key={name}
          role="button"
          isActive={name === language}
          onClick={() => handleChange(name as Language)}
        >
          {name.includes('en') ? 'EN' : 'NO'}
        </StyledLanguage>
      ))}
    </StyledLanguageSelector>
  );
};
