import { Navbar } from '../Navbar';
import { StyledHeader } from './styles';

const Header: view = ({}) => (
  <StyledHeader>
    <Navbar />
  </StyledHeader>
);

export default Header;
