import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { BaseProfile, BaseProfileFields } from 'src/types/questionnaire/baseProfile';

export const HairDescriptionSelector: view = ({
  hairDescription = observe.profile.attributes.hairDescription,
  updateHairDescription = update.ui.profile.attributes.hairDescription,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'hairDescription';
  const label = translate('profile.questionnaire.base_profile.hairDescription.label');
  const choices = BaseProfile[BaseProfileFields.HAIR_DESCRIPTION].map((description) => {
    return {
      label: translate(`profile.questionnaire.base_profile.${BaseProfileFields.HAIR_DESCRIPTION}.options.${description}`),
      value: description,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>, newChoices: string[]) => {
    updateHairDescription.set(newChoices);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={hairDescription} onChange={handleChoice} />
    </div>
  );
};
HairDescriptionSelector.displayName = 'HairDescriptionSelector';
