import { Navigator } from 'src/generic_components/Navigator';
import { DescribeYourBody } from 'src/profileQuestionnaire/baseProfile/slides/Body';
import { DescribeFaceAndHair } from 'src/profileQuestionnaire/baseProfile/slides/FaceAndHair';
import { DescribeEyes } from 'src/profileQuestionnaire/baseProfile/slides/Eyes';
import { DescribeSkin } from 'src/profileQuestionnaire/baseProfile/slides/Skin';
import { onboardingFlows } from 'src/types/onboarding';
import { useHistory } from 'react-router-dom';
import { Routes } from 'src/core/types';
import { Loader } from 'src/components/Loader';
import { AreaOfInterest } from 'src/profileQuestionnaire/baseProfile/slides/AreaOfInterest';
import { I18nTranslationsService } from 'src/services';

export const BaseProfile: view = ({
  active,
  attributesLoaded = observe.ui.dataLoaded.attributes,
  currentSlide = observe.ui.navigator.baseProfile.currentSlide,
  updateStep = update.ui.onboarding.activeFlow,
  updateProgress = update.ui.progress,
  setCurrentSlide = update.ui.navigator.baseProfile.currentSlide,
  translate = I18nTranslationsService.translate,
  language = observe.language.value
}) => {
  const history = useHistory();

  if (!active) return null;
  if (!attributesLoaded) return <Loader />;

  const handleUpdateProgress = (value: number) => updateProgress.set(value);

  const slides = [
    <DescribeYourBody hideBackButton={true} />,
    <DescribeFaceAndHair hideBackButton={true} />,
    <DescribeEyes hideBackButton={true} />,
    <DescribeSkin hideBackButton={true} />,
    <AreaOfInterest hideBackButton={true} />,
  ];

  if (currentSlide === undefined) currentSlide = 0;

  updateProgress.set(15 + ((currentSlide + 1) / slides.length) * 85);

  return (
    <div className="modules" lang={language}>
      <Navigator
        slides={slides}
        nextStep={() => history.push(Routes.FEED)}
        previousStep={() => {
          updateProgress.set(15);
          updateStep.set(onboardingFlows.GREETING);
        }}
        updateProgress={handleUpdateProgress}
        updateCurrentSlide={(currentSlide) => setCurrentSlide.set(currentSlide)}
        lastSlideNextButtonText={translate('onboarding.base_profile.your_personalized_feed')}
        translate={translate}
      />
    </div>
  );
};
