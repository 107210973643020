import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  AuthActionType,
  AuthFields,
  PasswordConstraints,
} from '../../../core/domain';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { AuthStateProps } from '../../../state';
import { Button, Input, Spinner } from '../../elements';

import { StyledAuthForm } from './styles';

type Props = AuthStateProps &
  LanguageProps & {
    readonly email: Get<string>;
    updateVerificationCode: Update<string>;
    updatePassword: Update<string>;
    updateConfirmPassword: Update<string>;
  };

interface ConfirmNewPasswordFormValues {
  verificationCode: string;
  password: string;
  confirmPassword: string;
}

const initialValues: ConfirmNewPasswordFormValues = {
  verificationCode: '',
  password: '',
  confirmPassword: '',
};

const validationSchema: Yup.SchemaOf<ConfirmNewPasswordFormValues> =
  Yup.object().shape({
    verificationCode: Yup.string().required('Verification code is required'),
    password: Yup.string()
      .required('Password is required')
      .min(PasswordConstraints.min, 'Password must be at least 6 characters')
      .matches(
        PasswordConstraints.pattern,
        'Password must contain an uppercase, a lowercase and a digit'
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  });

export const ConfirmNewPasswordForm: view<Props> = ({
  isLoading = observe.auth[AuthActionType.CONFIRM_NEW_PASSWORD].isLoading,
  email = get.auth[AuthActionType.REQUEST_NEW_PASSWORD].fields[
    AuthFields.EMAIL
  ],
  updateTrigger = update.auth[AuthActionType.CONFIRM_NEW_PASSWORD].trigger,
  updateVerificationCode = update.auth[AuthActionType.CONFIRM_NEW_PASSWORD]
    .fields[AuthFields.VERIFICATION_CODE],
  updatePassword = update.auth[AuthActionType.CONFIRM_NEW_PASSWORD].fields[
    AuthFields.PASSWORD
  ],
  updateConfirmPassword = update.auth[AuthActionType.CONFIRM_NEW_PASSWORD]
    .fields[AuthFields.CONFIRM_PASSWORD],
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const onSubmit = ({
    verificationCode,
    password,
    confirmPassword,
  }: ConfirmNewPasswordFormValues) => {
    updateVerificationCode.set(verificationCode);
    updatePassword.set(password);
    updateConfirmPassword.set(confirmPassword);

    updateTrigger.set(Date.now());
  };

  const { handleSubmit, values, handleChange, errors, isValid } =
    useFormik<ConfirmNewPasswordFormValues>({
      validationSchema,
      initialValues,
      onSubmit,
    });

  return (
    <StyledAuthForm onSubmit={handleSubmit} lang={language}>
      <Input
        name="email"
        label={translate('global.email')}
        type="email"
        disabled
        value={email.value()}
        errorMessage={errors.verificationCode}
        onChange={handleChange}
      />
      <Input
        name="verificationCode"
        label={translate('login.verification_code')}
        type="text"
        value={values.verificationCode}
        errorMessage={errors.verificationCode}
        onChange={handleChange}
      />
      <Input
        name="password"
        label={translate('login.password')}
        type="password"
        value={values.password}
        errorMessage={errors.password}
        onChange={handleChange}
      />
      <Input
        name="confirmPassword"
        label={translate('login.confirm_password')}
        type="password"
        value={values.confirmPassword}
        errorMessage={errors.confirmPassword}
        onChange={handleChange}
      />
      <Button className="primary rounded button button-primary" type="submit" disabled={!isValid}>
        {isLoading ? <Spinner /> : translate('login.submit')}
      </Button>
    </StyledAuthForm>
  );
};
