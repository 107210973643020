import { RouteName } from '../../../core/types';

export const videoDetailsDidMount: producer = async ({
  isLoading = observe.pages[RouteName.VIDEO_DETAILS].isLoading,
  videoState = observe.videoState,
  updateIsLoading = update.pages[RouteName.VIDEO_DETAILS].isLoading,

  videos = observe.videos,
}) => {
  if (!(isLoading && videoState) || !videos) return;

  updateIsLoading.set(false);
};
