import React from 'react'
import { Heading } from 'src/generic_components/Heading'
import { Description } from 'src/generic_components/Description'

import { I18nTranslationsService } from 'src/services'
import styles from '../styles.module.css'
import signature from '../../../../assets/jtdiva/welcome-signature.svg'
import { OnboardingImageTransition } from 'src/generic_components/OnboardingImageTransition'

export const Welcome: view = ({
  // activeCategory = observe.ui.activeCategory,
  slides,
  className,
  translate = I18nTranslationsService.translate,
}) => {
  // if (activeCategory) return null;
  return (
    <div className={styles['welcome-section']}>
      <Heading className={styles['welcome-heading']} text={translate('onboarding_page.heading')}/>
      <img className={styles['welcome-signature']} src={signature}/>
      <OnboardingImageTransition/>
      <Description text={translate('onboarding.description')}/>
    </div>
  )
}
