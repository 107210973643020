import { ApiActionType } from '../../../core/types';
import { SweetAlertService } from '../../../services';
import { ErrorLoggerService } from '../../../services/ErrorLoggerService';

export const updateProfileModuleErrorAction: producer = async ({
  result = observe.api[ApiActionType.UPDATE_PROFILE_MODULE].result,
  updateResult = update.api[ApiActionType.UPDATE_PROFILE_MODULE].result,
  AlertService = SweetAlertService,
}) => {
  if (!(result && result.error)) return;

  ErrorLoggerService.logError(result.error);

  await AlertService.error(result.error);

  updateResult.remove();
};
