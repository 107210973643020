import { ContentList, Video } from '../../../core/domain';
import { ApiActionType } from '../../../core/types';

export const personalizedVideosSuccessAction: producer = ({
  result = observe.api[ApiActionType.GET_PERSONALIZED_CONTENT].result,
  updateResult = update.api[ApiActionType.GET_PERSONALIZED_CONTENT].result,
  updatePersonalizedVideos = update.personalizedVideos,
}) => {
  if (!(result && result.isOk)) return;

  const videos: ContentList<Video> = result.value;

  updatePersonalizedVideos.set(videos);
  updateResult.remove();
};
