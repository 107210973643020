import { AuthActionType, AuthEvents, AuthFields } from '../../../core/domain';
import { resultFormatter } from '../../../core/types';
import { AuthErrors } from '../../../core/errors';
import { CognitoAuthService } from '../../../services';

export const requestNewPasswordAction: producer = async ({
  trigger = observe.auth[AuthActionType.REQUEST_NEW_PASSWORD].trigger,
  updateTrigger = update.auth[AuthActionType.REQUEST_NEW_PASSWORD].trigger,
  updateResult = update.auth[AuthActionType.REQUEST_NEW_PASSWORD].result,
  updateIsLoading = update.auth[AuthActionType.REQUEST_NEW_PASSWORD].isLoading,
  email = get.auth[AuthActionType.REQUEST_NEW_PASSWORD].fields[
    AuthFields.EMAIL
  ],
  AuthService = CognitoAuthService,
}) => {
  if (!trigger) return;

  updateTrigger.remove();

  if (!email.value()) {
    updateResult.set(resultFormatter.error(AuthErrors.STATE_EMAIL_IS_EMPTY));
    return;
  }

  updateIsLoading.set(true);

  try {
    const response = await AuthService.requestNewPassword(email.value());

    const {
      CodeDeliveryDetails: { AttributeName },
    } = response;
    const successMessage =
      AttributeName === 'email'
        ? AuthEvents.ACCOUNT_REQUEST_NEW_PASSWORD_SUCCESS
        : '';

    updateResult.set(resultFormatter.ok(response, successMessage));
  } catch (error: unknown) {
    updateResult.set(resultFormatter.error((error as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
