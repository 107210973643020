import { CmsError } from '../../../errors';
import { CmsListMeta } from '../Content';
import { SearchContentRequest } from '../SearchContentRequest';
import { CreateVideoPayload } from '../Video';

export const SearchVideosQuery = (search: SearchContentRequest): string => {
  const query = SearchContentRequest.stringify(search);
  return `
    query {
      listVideos(${query}) {
        error {
          code,
          message,
          data,
        },
        data {
          premium,
          entryId,
          image,
          shortDescription,
          description,
          video,
          name,
          tags { name },
          author { name },
          categories { name },
          createdOn,
        },
        meta {
          cursor,
          hasMoreItems,
          totalCount
        }
      }
    }`;
};

export interface SearchVideosQueryResult {
  data: {
    listVideos: {
      error: CmsError;
      data: CreateVideoPayload[];
      meta: CmsListMeta;
    };
  };
}
