export enum BasicInformationFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  FULL_NAME = 'fullName',
  NAME = 'name',
  GENDER = 'gender',
  AGE = 'age',
  DATE_OF_BIRTH = 'dateOfBirth',
  PHONE = 'phone',
  ONBOARDING_COMPLETED = 'onboardingCompleted',
  PROFILE_PICTURE = 'profilePicture',
}

export const BasicInformation = {
  [BasicInformationFields.FIRST_NAME]: [],
  [BasicInformationFields.LAST_NAME]: [],
  [BasicInformationFields.FULL_NAME]: [],
  [BasicInformationFields.NAME]: [],
  [BasicInformationFields.GENDER]: ['female', 'male', 'trans', 'non_binary', 'others'],
  [BasicInformationFields.AGE]: [],
  [BasicInformationFields.DATE_OF_BIRTH]: [],
  [BasicInformationFields.PHONE]: [],
  [BasicInformationFields.ONBOARDING_COMPLETED]: [],
  [BasicInformationFields.PROFILE_PICTURE]: [],
}
