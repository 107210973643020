import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { SearchBar } from '../../SearchBar';
import { StyledContentHeaderWrapper } from './styles';

type Props = LanguageProps & {
  title: 'Articles' | 'Videos';
};

export const ContentHeader: view<Props> = ({
  title,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const placeholder = `${translate('feed_page.search')} ${translate(
    `feed_page.${title.toLowerCase()}`
  )}`;
  return (
    <>
    {/*<StyledContentHeaderWrapper lang={language}>
       <SearchBar placeholder={placeholder} /> 
    </StyledContentHeaderWrapper>*/}
    </>
  );
};
