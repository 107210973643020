import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Needs, NeedsFields } from 'src/types/questionnaire/needs';

export const HairShowsIdentitySelector: view = ({
  hairShowsIdentity = observe.profile.attributes.hairShowsIdentity,
  updateHairShowsIdentity = update.ui.profile.attributes.hairShowsIdentity,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'hairShowsIdentity';
  const label = translate('profile.questionnaire.needs.hairShowsIdentity.label');
  const choices = Needs[NeedsFields.HAIR_SHOWS_IDENTITY].map((answer) => {
    return {
      label: translate(`profile.questionnaire.needs.${NeedsFields.HAIR_SHOWS_IDENTITY}.options.${answer}`),
      value: answer,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateHairShowsIdentity.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={hairShowsIdentity ? [hairShowsIdentity] : []} onChange={handleChoice} />
    </div>
  );
};
HairShowsIdentitySelector.displayName = 'HairShowsIdentitySelector';
