import { TranslationsService } from '../../../core/services';
import { Language } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { AuthModalState } from '../../Auth';
import { Button } from '../../elements';
import { lazy } from 'react';
import { BsChevronRight } from 'react-icons/bs';

import {
  StyledHeroContainer,
  StyledHeroContent,
  StyledSignupButton,
} from './styles';

type StateProps = {
  readonly language: Language;
  updateAuthModalState: Update<AuthModalState>;
  videoIsStarted: boolean;
  showMobileVideoCover: boolean;
  hideMobileVideoCover: Update<boolean>;
};

type Deps = {
  translationsService: TranslationsService;
};

type Props = StateProps & Deps;

const VideoPlayer = lazy(() => import('../../VideoPlayer'));

export const Hero: view = ({
  language = observe.language.value,
  updateAuthModalState = update.utils.components.authModal.state,
  translationsService = I18nTranslationsService,
  videoIsStarted = observe.videoState.isStarted,
  showMobileVideoCover = observe.videoState.showMobileVideoCover,
  hideMobileVideoCover = update.videoState.showMobileVideoCover,
}: Props) => {
  const mountSignUpScreen = () => {
    updateAuthModalState.set({
      isOpen: true,
      isSignUpScreenMounted: true,
    });
  };

  return (
    <>
      <StyledHeroContainer lang={translationsService.toHTMLLang(language)}>
        {/* <VideoPlayer />
        {!videoIsStarted && showMobileVideoCover && (
          <StyledHeroContent onClick={() => hideMobileVideoCover.set(false)}>
            
            <button className="play">
              <svg
                width="141"
                height="141"
                viewBox="0 0 141 141"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M70.4005 11.7327C85.9597 11.7327 100.881 17.9133 111.884 28.9163C122.887 39.9183 129.067 54.8411 129.067 70.3995C129.067 85.9579 122.887 100.88 111.884 111.883C100.882 122.886 85.9589 129.066 70.4005 129.066C54.8421 129.066 39.9198 122.886 28.9173 111.883C17.9143 100.881 11.7337 85.9579 11.7337 70.3995C11.7513 54.8454 17.9379 39.9339 28.9359 28.9339C39.9349 17.9359 54.8456 11.7493 70.4015 11.7317L70.4005 11.7327ZM70.4005 0C51.7289 0 33.8227 7.41714 20.6202 20.6202C7.41714 33.8222 0 51.7294 0 70.4005C0 89.0716 7.41714 106.978 20.6202 120.181C33.8222 133.384 51.7294 140.801 70.4005 140.801C89.0716 140.801 106.978 133.384 120.181 120.181C133.384 106.979 140.801 89.0716 140.801 70.4005C140.801 51.7294 133.384 33.8227 120.181 20.6202C106.979 7.41714 89.0716 0 70.4005 0ZM52.8005 99.7317V41.0658L105.601 71.2549L52.8005 99.7317Z"
                  fill="white"
                />
              </svg>
            </button>
          </StyledHeroContent>
        )} */}
        <div className="hero-left">
          <div className="hero-image-background"></div>
          <div className="hero-image"></div>
        </div>
        <div className="hero-right">
          <h1 className="title">
            {translationsService.translate('home_page.title')}
          </h1>
          <p className="description">
            {translationsService.translate('home_page.description')}
          </p>
          <StyledSignupButton>
            <Button className="button" onClick={mountSignUpScreen}>
              {translationsService.translate('home_page.sign_up_now')}
              <BsChevronRight />
            </Button>
            {/* <Button
              className="button button-secondary"
              onClick={() => {
                window.location.href =
                  'https://buy.stripe.com/8wMaIEaqtfEP4IUcMM';
              }}
            >
              {translationsService.translate('home_page.buy_gift_card')}
            </Button> */}
            <Button
              className="button button-secondary"
              onClick={() => {
                window.location.href = '/concept';
              }}
            >
              {translationsService.translate('feed_page.read_more')}
            </Button>
          </StyledSignupButton>
        </div>
      </StyledHeroContainer>
    </>
  );
};
