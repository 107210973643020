import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledContentCardLink = styled.a<{ size: 'small' | 'large' }>`
  width: ${({ size }) =>
    size === 'small' ? 'calc(50% - 64px)' : 'calc(100% - 64px)'};
  margin: ${({ size }) => (size === 'small' ? '32px 32px 0' : '32px 32px 0')};
  text-decoration: none;
  color: ${theme.color.black};

  @media ${theme.queries.tablet} {
    margin: ${({ size }) => (size === 'small' ? '24px' : '24px')};
    width: ${({ size }) =>
      size === 'small' ? 'calc(50% - 48px)' : 'calc(100% - 48px)'};
  }

  @media ${theme.queries.mobile} {
    margin: 0 0 24px 0;
    width: 100%;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const StyledContentText = styled.h3`
  font: 500 28px/ 32px ${theme.font.secondary};
  width: 100%;
  grid-area: title;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  line-height: 1;
  margin-bottom: 190px;
`;
