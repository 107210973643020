import { createGlobalStyle } from 'styled-components';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '424px',
  tablet: '767px',
  laptop: '1023px',
  laptopM: '1280px',
  laptopL: '1439px',
  desktop: '1919px',
};

const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
};

const queries = {
  mobileS: `screen and (max-width: ${size.mobileM})`,
  mobileM: `screen and (max-width: ${size.mobileL})`,
  mobile: `screen and (max-width: ${size.tablet})`,
  tablet: `screen and (max-width: ${size.laptop})`,
  laptopS: `screen and (max-width: ${size.laptopM})`,
  laptop: `screen and (max-width: ${size.laptopL})`,
  desktop: `screen and (max-width: ${size.desktop})`,
};

const font = {
  primary: 'Inter, cursive',
  secondary: 'Inter, cursive',
  tertiary: 'Montserrat, sans-serif',
};

const color = {
  black: '#000000',
  dark: '#060606',
  white: '#FFFFFF',
  'hero-background': '#FDFAF9',
  pearl: '#FEFEFE',
  gray: {
    dark: '#404040',
    secondary: '#FDFAF9',
    'border-disabled': '#AAAAAA',
    medium: '#959595',
    light: '#E5E5E5',
    lighter: '#fbfbfb',
    disabled: '#707070',
    border: '#E6E6E6', // header border bottom
  },
  red: {
    primary: '#E77768', // text modal log in
    'primary-hover': '#D3887D', // hover text modal log in
    somon: '#f1aba3',
  },
  orange: {
    'primary-hover': '#fd7e69',
  },
  yellow: {
    primary: '#f7dfc2',
    'primary-hover': '#ebd5bb',
    input: '#f9f3e2ab',
    light: '#FFF6F4',
    "light-02": ' #FAF4EC',
    'light-hover': '#FEEBD6',
    background: '#FDFAF9',
  },
  green: {
    light: '#48BB78',
  },
  purple: {
    primary: '#735D67',
    secondary: '#281e2b',
    'light-shade': '#7c66710d',
    "primary-02": "#897180",
    "hover-02": "#4E3846",
    "secondary-02": "#695959",
  },
  pink: {
    light: '#EDE2DF',
    medium: '#D2877D'
  }
};


const fontSize = {
  base: '14px',   
  p: '16px',
  h1: '48px',
  h2: '38px',
  h3: '28px'            
};

const spacing = {
  xxs: '4px',
  xs: '8px',
  sm:'12px',
  s: '16px',
  m: '24px',
  l: '32px',
  xl: '48px',
  xxl: '64px',
};

const heading = {
  h1: `
    font: 600 3.125rem ${font.primary};
    letter-spacing: -0.125rem;
  `,
  h2: `
    font: 600 3.75rem ${font.primary};
    letter-spacing: -0.0625rem;
  `,
  h3: `
    font: 600 3rem ${font.primary};
  `,
  h4: `
    font: 600 2.125rem ${font.primary};
    letter-spacing: -0.094rem;
  `,
  h5: `
    font: 400 1.5rem/1.25rem ${font.primary};
  `,
  h6: `
    font: 400 1.25rem ${font.primary};
    letter-spacing: -0.009375rem;
  `,
};

const text = {
  big: `
   font: 400 1.125rem/1.575rem ${font.secondary};
   letter-spacing: 0.035rem;
  `,
  regular: `
    font: 400 1.0625rem/1.4875rem ${font.secondary};
    letter-spacing: 0.033125rem;
  `,
  small: `
    font: 400 0.875rem/1.3125rem ${font.secondary};
    letter-spacing: 0.015625rem;
  `,
};

const label = {
  l1: `
    font: 600 3.75rem ${font.secondary};
    letter-spacing: -0.031rem;
  `,
  l2: `
    font: 700 1.25rem/1.875rem ${font.secondary};
    letter-spacing: -0.0625rem;
  `,
  l3: `
    font: 500 1.25rem/1.875rem ${font.secondary};
    letter-spacing: -0.0625rem;
  `,
  l4: ``,
  l5: ``,
  l6: ``,
};

const width = {
  min: '320px',
  med: '1200px',
  max: '1500px',
};

export const GlobalReset = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  :root {
    --logo-color-primary: #fc8e7c;
    --logo-color-secondary: #fda88c;
    
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${font.primary};
    color: #897180;
  }

  body {
    line-height: 1;
    font-family: ${font.primary};

    @media ${queries.tablet} {
      margin-bottom: 48px;
    }
  }

  label, input {
    font-family: ${font.primary};
  }

  label {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    color: #897180;
    margin-bottom: 12px;
    font-size: 14px; 
  }

  input {
    background-color: #F4EEEB;
    color: #897180;
    border-color: transparent;
    border-radius: 60px;
    height: 37px;
    padding: 6px 16px;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
  }

  input:focus {
    outline: none;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  body.swal2-height-auto {
    height: 100%;
  }

  .swal2-title{
    padding-top:10px;
    font: 600 32px/44px ${font.secondary};
  }

  .swal2-html-container {
    margin-top:0;
    margin-bottom:20px;
    font: 400 16px/24px ${font.secondary};
  }

  .swal2-actions > .swal2-styled.swal2-confirm {
    background-color: ${color.purple["primary-02"]};
    color: ${color.yellow["light-02"]};
    border-color: ${color.purple["primary-02"]};

    &:hover:not(:disabled) {
      background-color: ${color.purple["hover-02"]};
      border-color: ${color.purple["hover-02"]};
    }

    &:focus{
      box-shadow: transprent;
    }
  }
  
  .swal2-actions > .swal2-loader {
    border: 1px solid ${color.purple["primary-02"]};
  }

  .swal2-styled.swal2-confirm:focus {
    box-shadow: none;
  }

  .swal2-close:focus{
    box-shadow: none;
  }

  .swal2-close:hover{
    color: ${color.red['primary-hover']};
  }

  button.swal2-styled.swal2-confirm{
    background-color: ${color.purple["primary-02"]};
    color: ${color.yellow["light-02"]};
    border-color: ${color.purple["primary-02"]};
  }

  .swal2-actions > .swal2-styled.swal2-confirm:active, .swal2-actions > .swal2-styled.swal2-confirm:focus {
    background-color: ${color.purple["primary-02"]};
    color: ${color.yellow["light-02"]};
    color: ${color.yellow["light-02"]};
    font: 600 14px/24px ${font.secondary};
    outline: 0;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    @media ${queries.mobile} {
      width: 90%;
    }
  }


  main{ background-color: #F4EEEB; min-height: 100%; display: flex;
  flex-direction: column; color: #897180}

  main > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  html { height: 100%}

  body{ height: 100%; background-color: ${color.yellow.background}}

  #app { height: 100%; } 

  p {
    font-size: 14px;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 7px 40px;
    border: 1px solid #897180;
    border-radius: 50px;
    background-color: #897180;
    color: #FAF4EC;
    text-transform: uppercase;
    font-family: ${font.primary};
    font-weight: 600;
    font-size: 14px; 
    line-height: 24px;

    &:disabled {
      background-color: #E1D5CE;
      border-color: #E1D5CE;
      color: #897180;
      cursor: default;
    }

    &:hover:not(:disabled) {
      background-color: #4e3846;
      border-color: #4e3846;
      cursor: pointer;
    }
  }

  button.ghost{
    background: transparent;
    border:none;
    padding: 0;

    &:hover {
      background: transparent;
    }
  }
  
  button.secondary {
  border: 1px solid #897180;
  background: transparent;
  color: #897180;

  &:disabled {
    opacity: 0.5;
  }

  :hover {
    border-color:  #897180;
    background-color: #897180;
    color: #FFFFFF;
  }
}

.icon-button {
  width: 40px;
  height: 40px;
  padding: 0;
  border: 1px solid #897180;
  background: transparent;
  color: #897180;

  &:disabled {
    opacity: 0.5;
  }

  :hover {
    background-color: transparent;
    border-color: #4e3846;
    color: #4e3846;
  }
}

body [role="dialog"] button{
  line-height: 24px;
}  

// body [role="dialog"] button:hover {
//   background-color: #4e3846;
//   border-color: #4e3846;
//   color: #FAF4EC;
// }

// body [role="dialog"] button.secondary:hover {
//   border-color:  #897180;
//   background-color: #897180;
//   color: #FFFFFF;
// }

// [role="dialog"] button {
//   line-height: 1;
// }

.MuiInputBase-input.MuiOutlinedInput-input  {
  color: #897180;
  font-weight: 500;
}

.MuiPickersArrowSwitcher-button:hover {
  background-color: #f4eeeb!important;
}

.MuiButtonBase-root.MuiPickersDay-root:hover, {
  color: #F4EEEB;
  background-color: #4e3846;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected, .MuiButtonBase-root.MuiPickersDay-root.Mui-selected:hover, {
  color: #F4EEEB;
 background-color: #4e3846;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:focus, .MuiButtonBase-root.MuiPickersDay-root:focus,MuiButtonBase-root MuiPickersDay-root Mui-selected  {
  color: #F4EEEB;
  background-color: #4e3846;
}

[role="dialog"] .MuiPickersYear-yearButton.Mui-selected, [role="dialog"] .MuiPickersYear-yearButton.Mui-selected:hover, .MuiPickersYear-yearButton.Mui-selected:hover,.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:focus,.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:hover {
 color: #F4EEEB;
 background-color: #4e3846!important;
 will-change: unset;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected  {
  color: #F4EEEB;
  background-color: #4e3846;
}

[role="dialog"] .MuiButtonBase-root-MuiPickersDay-root.Mui-selected,[role="dialog"]  .MuiPickersYear-yearButton.Mui-selected:focus, {
  color: #F4EEEB;
  background-color: #4e3846;
}

.MuiButtonBase-root.MuiPickersDay-root:hover, .MuiPickersYear-yearButton:hover:not(:disabled) {
  background-color: #F4EEEB;
  color: #4e3846;
}

.MuiPickersCalendarHeader-switchViewButton:hover {
  background-color: transparent!important;
}

.video-details-bar .saved-video {
  width: 48px!important;
  height: 48px!important;
  justify-content: center!important;
  margin-right: 16px!important;
}

.video-details-bar .save-video {
  display: flex;
  align-items: center;
  justify-content: center!important;
  width: 48px;
}

.video-details-bar .save-video svg{
  width: 32px!important;
  height: 32px!important;
}

.video-details-bar .saved-video svg{
  height: 16px;
}

.video-details-bar .liked-video  {
  display: flex;
  align-items: center;
  justify-content: center!important;
  width: 64px!important;
  height: 48px!important;
  padding: 0;
  margin-right: 0!important;
}

.video-details-bar .liked-video p {
  font-size: 14px;
}


.video-details-bar .like-button  {
  justify-content: center!important;
  width: 64px;
  height: 48px;
  padding: 0;
  border: 1px solid #897180!important;
  margin-right: 0!important;
}

.video-details-bar .liked-button p {
  font-size: 14px;
}

.video-details-bar .default-like-button {
  justify-content: center!important;
  width: 64px!important;
  height: 32px!important;
  align-items: center;
  display: flex;
  margin-right: 0!important; 
}

.video-details-bar .default-like-button svg{
  width: 28px!important;
  height: 28px!important;
}

.modules {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .question-card {
    max-width: 650px;
  }
}

.modules > div {
  padding: 20px 20px 40px;
}

.last-slider-button {
  background-color: #FFBE97;
 border-color: #FFBE97;
  color: #897180;
}

.last-slider-button:hover {
  background-color: #4e3845;
 border-color: #4e3845;
  color: #76606d;
}

@media only screen and (max-width: 1280px) { 
  .last-slider-button  {
    padding-inline: 16px;
  }
}

.modal-body {
  padding: 24px;
}

.modal-body p{
  font-size: 16px;
  color: #897180;
}

.spinner-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header {
  padding: 26px 24px;
  border-radius: 12px 12px 0 0;
  background-color: #FAF6F3;
}

.modal-header h5 {
  font-size: 24px;
  font-weight: 400;
}

  `;


export const theme = Object.freeze({
  size,
  device,
  queries,
  font,
  color,
  text,
  label,
  heading,
  spacing,
  width,
  fontSize,

});
