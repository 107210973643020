import { ApiActionType, RouteName } from '../../../core/types';

export const onboardingGetInitialProps: producer = ({
  history,
  trigger = observe.pages[RouteName.ONBOARDING].trigger,
  updateTrigger = update.pages[RouteName.ONBOARDING].trigger,
  updateIsLoading = update.pages[RouteName.ONBOARDING].isLoading,
  getProfileTrigger = update.api[ApiActionType.FIND_PROFILE].trigger,
}) => {
  if (!trigger) return;

  if (history.location) updateTrigger.remove();

  document.title = 'Diva - Onboarding';

  updateIsLoading.set(true);
  getProfileTrigger.set(Date.now());
};
