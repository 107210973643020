import styles from './styles.module.css';

export const QuestionCard: view = ({ cardHeading, cardDescription, img, extraClasses, children }) => {
  return (
    <div className={`${styles['question-card']} question-card`}>
      <div className={`${extraClasses} ${styles['question-card-header']}`}>
        <div>
          <h2>{cardHeading}</h2>
          <p>{cardDescription}</p>
        </div>
        <img src={img} />
      </div>
      <div className={styles['question-card-body']}>
        {Array.isArray(children) ? (
          children.map((child: any) => <div className={styles['question-card-section']} key={Math.random()}>{child}</div>)
        ) : (
          <div className={styles['question-card-section']} key={Math.random()}>{children}</div>
        )}
      </div>
    </div>
  );
};
