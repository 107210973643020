import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { AiOutlineMail } from 'react-icons/ai';
import { MdOutlineArrowBack } from 'react-icons/md';
import { FaFacebookSquare } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';

import { AuthActionType, AuthFields } from '../../../core/domain';
import { TranslationsService } from '../../../core/services';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';

import { Modal, ModalState } from '../../elements';
import {
  ConfirmAccountForm,
  ConfirmNewPasswordForm,
  RequestNewPasswordForm,
  SignInForm,
  SignUpForm,
} from '../AuthForms';

import {
  StyledAuthCtaOption,
  StyledAuthCtaWrapper,
  StyledAuthFooterInfo,
  StyledAuthModalBody,
  StyledAuthModalContainer,
  StyledAuthModalFooter,
  StyledAuthModalHeader,
} from './styles';

export interface AuthModalState extends ModalState {
  readonly isSignInScreenMounted?: boolean;
  readonly isSignUpScreenMounted?: boolean;
  readonly isSignInFormMounted?: boolean;
  readonly isSignUpFormMounted?: boolean;
  readonly isConfirmAccountFormMounted?: boolean;
  readonly isRequestNewPasswordFormMounted?: boolean;
  readonly isConfirmNewPasswordFormMounted?: boolean;
}

export const authModalInitialState: AuthModalState = {
  isOpen: false,
  isSignInScreenMounted: false,
  isSignUpScreenMounted: false,
  isSignInFormMounted: false,
  isSignUpFormMounted: false,
  isConfirmAccountFormMounted: false,
  isRequestNewPasswordFormMounted: false,
  isConfirmNewPasswordFormMounted: false,
};

type StateProps = LanguageProps & {
  authModalState: AuthModalState;
  updateAuthModalState: Update<AuthModalState>;
  updateProvider: Update<string>;
  updateSocialSignInTrigger: Update<number>;
};

type Deps = {
  translationsService: TranslationsService;
};

type Props = StateProps & Deps;

export const AuthModal: view = ({
  authModalState = observe.utils.components.authModal.state,
  updateAuthModalState = update.utils.components.authModal.state,
  updateProvider = update.auth[AuthActionType.SOCIAL_SIGN_IN].fields[AuthFields.PROVIDER],
  updateSocialSignInTrigger = update.auth[AuthActionType.SOCIAL_SIGN_IN].trigger,
  language = observe.language.value,
  translationsService = I18nTranslationsService,
}: Props) => {
  const {
    isOpen,
    isSignInScreenMounted,
    isSignUpScreenMounted,
    isSignInFormMounted,
    isSignUpFormMounted,
    isConfirmAccountFormMounted,
    isRequestNewPasswordFormMounted,
    isConfirmNewPasswordFormMounted,
  } = authModalState || authModalInitialState;

  const handleClose = () => {
    updateAuthModalState.set(authModalInitialState);
  };

  const action = () =>
    updateAuthModalState.set({
      ...authModalInitialState,
      isOpen: true,
      isSignInScreenMounted: !isSignInScreenMounted,
      isSignUpScreenMounted: !isSignUpScreenMounted,
    });

  const back = () =>
    updateAuthModalState.set({
      ...authModalInitialState,
      isOpen: true,
      isSignInScreenMounted: isSignInFormMounted,
      isSignUpScreenMounted: isSignUpFormMounted,
    });

  const trigger = () =>
    updateAuthModalState.set({
      ...authModalInitialState,
      isOpen: true,
      isSignInFormMounted: isSignInScreenMounted,
      isSignUpFormMounted: isSignUpScreenMounted,
    });

  const openGoogleConsent = () => {
    updateProvider.set(CognitoHostedUIIdentityProvider.Google);
    updateSocialSignInTrigger.set(Date.now());
  };

  const openFacebookConsent = () => {
    updateProvider.set(CognitoHostedUIIdentityProvider.Facebook);
    updateSocialSignInTrigger.set(Date.now());
  };

  const getTitle = () => {
    if (isSignInFormMounted || isSignInScreenMounted) {
      return translationsService.translate('login.welcome_back');
    }

    if (isSignUpFormMounted || isSignUpScreenMounted) {
      return translationsService.translate('login.join_diva');
    }

    if (isConfirmAccountFormMounted) {
      return translationsService.translate('login.confirm_account');
    }

    if (isRequestNewPasswordFormMounted) {
      return translationsService.translate('login.request_new_password');
    }

    if (isConfirmNewPasswordFormMounted) {
      return translationsService.translate('login.confirm_new_password');
    }

    return '';
  };

  const getText = () => {
    if (
      isRequestNewPasswordFormMounted ||
      isConfirmAccountFormMounted ||
      isConfirmNewPasswordFormMounted
    ) {
      return translationsService.translate('login.submit');
    }

    return isSignInScreenMounted || isSignInFormMounted
      ? translationsService.translate('login.sign_in')
      : translationsService.translate('login.sign_up');
  };

  const actionDescription = isSignInScreenMounted
    ? translationsService.translate('login.no_account')
    : translationsService.translate('login.already_member');
  const actionText = isSignInScreenMounted
    ? translationsService.translate('login.create_one')
    : translationsService.translate('login.sign_in');

  const text = getText();
  const footerText = `${translationsService.translate(
    'login.click'
  )} “${text}” ${translationsService.translate(
    'login.to_agree'
  )} <a href="./pdf/terms-of-service.pdf" target="_blank"> ${translationsService.translate(
    'login.terms_of_service'
  )} </a> 
  ${translationsService.translate(
    'login.acknowledge'
  )} <a href="./pdf/privacy-policy.pdf" target="_blank"> ${translationsService.translate(
    'login.privacy_policy'
  )} </a> ${translationsService.translate('login.applies_to_you')}`;

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      lang={translationsService.toHTMLLang(language)}
    >
      <StyledAuthModalContainer>
        <StyledAuthModalHeader>
          {(isSignInFormMounted || isSignUpFormMounted) && (
            <span
              tabIndex={0}
              className="auth-modal-back"
              role="button"
              onClick={back}
              onKeyDown={back}
            >
              <MdOutlineArrowBack />
            </span>
          )}
          <div className="auth-modal-title">{getTitle()}</div>
        </StyledAuthModalHeader>
        <StyledAuthModalBody>
          {isSignInFormMounted && <SignInForm />}
          {isSignUpFormMounted && <SignUpForm />}
          {isConfirmAccountFormMounted && <ConfirmAccountForm />}
          {isRequestNewPasswordFormMounted && <RequestNewPasswordForm />}
          {isConfirmNewPasswordFormMounted && <ConfirmNewPasswordForm />}
          {(isSignInScreenMounted || isSignUpScreenMounted) && (
            <StyledAuthCtaWrapper>
              <StyledAuthCtaOption
                className="trigger"
                onClick={openGoogleConsent}
              >
                <span className="icon">
                  <FcGoogle />
                </span>
                <span>{`${text} ${translationsService.translate(
                  'global.with'
                )} Google`}</span>
              </StyledAuthCtaOption>
              <StyledAuthCtaOption
                className="trigger"
                onClick={openFacebookConsent}
              >
                <span className="icon">
                  <FaFacebookSquare />
                </span>
                <span>{`${text} ${translationsService.translate(
                  'global.with'
                )} Facebook`}</span>
              </StyledAuthCtaOption>
              <StyledAuthCtaOption className="trigger" onClick={trigger}>
                <span className="icon">
                  <AiOutlineMail />
                </span>
                <span>{`${text} ${translationsService.translate(
                  'global.with'
                )} ${translationsService.translate('global.email')}`}</span>
              </StyledAuthCtaOption>
              <StyledAuthCtaOption>
                <span>{actionDescription}</span>
                <span
                  tabIndex={0}
                  className="action"
                  role="button"
                  onClick={action}
                  onKeyDown={action}
                >
                  {actionText}
                </span>
              </StyledAuthCtaOption>
            </StyledAuthCtaWrapper>
          )}
        </StyledAuthModalBody>
        <StyledAuthModalFooter>
          <StyledAuthFooterInfo
            dangerouslySetInnerHTML={{ __html: footerText }}
          />
        </StyledAuthModalFooter>
      </StyledAuthModalContainer>
    </Modal>
  );
};
