import { ContentList, CreateVideoPayload, SearchContentRequest, Video } from '../../../core/domain';
import { ApiActionType, resultFormatter } from '../../../core/types';
import { ContentHttpApiService } from '../../../services';

export const searchVideosAction: producer = async ({
  trigger = observe.api[ApiActionType.SEARCH_VIDEOS].trigger,
  searchRequest = observe.api[ApiActionType.SEARCH_VIDEOS].data.searchRequest,
  updateTrigger = update.api[ApiActionType.SEARCH_VIDEOS].trigger,
  updateIsLoading = update.api[ApiActionType.SEARCH_VIDEOS].isLoading,
  updateResult = update.api[ApiActionType.SEARCH_VIDEOS].result,
  ContentApiService = ContentHttpApiService,
}) => {
  if (!(trigger && searchRequest)) return;

  updateTrigger.remove();
  updateIsLoading.set(true);

  try {
    const result = await ContentApiService.searchVideos(searchRequest as SearchContentRequest);
    const { meta, data, error } = result.data.listVideos;

    if (error) {
      updateIsLoading.set(false);
      updateResult.set(resultFormatter.error(error.message));
      return;
    }

    const videos: Video[] = data.map((payload: CreateVideoPayload) => Video.create(payload));
    const videosList: ContentList<Video> = {
      data: videos,
      meta,
    };

    updateResult.set(resultFormatter.ok(videosList));
  } catch (e: unknown) {
    updateResult.set(resultFormatter.error((e as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
