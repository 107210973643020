import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledThumbnailPlaceholder = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  background-color: ${theme.color.gray.dark};
  border-radius: 12px;
  font: 600 46px ${theme.font.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
`;
