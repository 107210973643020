import { ApiActionType, resultFormatter } from '../../../core/types';
import { PaymentHttpApiService } from '../../../services';

export const createCustomerPortalAction: producer = async ({
  trigger = observe.api[ApiActionType.CREATE_CUSTOMER_PORTAL].trigger,
  updateTrigger = update.api[ApiActionType.CREATE_CUSTOMER_PORTAL].trigger,
  updateIsLoading = update.api[ApiActionType.CREATE_CUSTOMER_PORTAL].isLoading,
  updateResult = update.api[ApiActionType.CREATE_CUSTOMER_PORTAL].result,
  PaymentApiService = PaymentHttpApiService,
}) => {
  if (!trigger) return;

  updateTrigger.remove();
  updateIsLoading.set(true);

  try {
    const { customerPortalUrl } = await PaymentApiService.createCustomerPortal(
      window.location.href
    );

      window.location.href = customerPortalUrl;
  } catch (e: unknown) {
    updateResult.set(resultFormatter.error((e as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
