import { Alert, Snackbar } from '@mui/material';

export const ErrorNotificaton: view = ({
  vertical = 'bottom',
  horizontal = 'left',
  errorNotificationState = observe.errorNotificationState,
  updateErrorNotificationState = update.errorNotificationState,
}) => {
  const { notificationMessage, triggerNotification, severity } = errorNotificationState;

  return (
    <>
      <Snackbar
        open={triggerNotification}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={4000}
        onClose={() =>
          updateErrorNotificationState.set({
            triggerNotification: false,
            message: '',
            severity,
          })
        }
      >
        <Alert severity={severity} sx={{ width: '100%', m: '40px' }}>
          {notificationMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
