import { useFormik } from 'formik';
import { MouseEvent } from 'react';

import { Gender } from '../../../core/domain';
import {
  ApiActionType,
  InputType,
  LanguageProps,
  profileUpdateFormExtractUpdates,
  profileUpdateFormGetInitialValues,
  ProfileUpdateFormProps,
  profileUpdateFormValidationSchema,
  ProfileUpdateFormValues,
  ProfileUpdateStateProps,
} from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import {
  Button,
  CheckboxGroup,
  Heading,
  Input,
  Spinner,
  TextArea,
} from '../../elements';

import {
  StyledCondensedProfileInfo,
  StyledEditProfileInfoForm,
  StyledProfileInfo,
  StyledProfileInfoWrapper,
  StyledSubSectionWrapper,
} from './styles';

type OwnProps = ProfileUpdateFormProps;

type StateProps = ProfileUpdateStateProps & {
  isEditMode?: boolean;
};

type Props = OwnProps & StateProps & LanguageProps;

export const ProfileInfo: view = ({
  lastName = observe.profile.lastName,
  dateOfBirth = observe.profile.dateOfBirth,
  gender = observe.profile.gender,
  telephone = observe.profile.telephone,
  address = observe.profile.address,
  isLoading = observe.api[ApiActionType.UPDATE_PROFILE].isLoading,
  isEditMode = observe.utils.components.profileInfo.isEditMode,
  updateData = update.api[ApiActionType.UPDATE_PROFILE].data,
  updateTrigger = update.api[ApiActionType.UPDATE_PROFILE].trigger,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const initialValues: ProfileUpdateFormValues =
    profileUpdateFormGetInitialValues({
      lastName,
      gender,
      dateOfBirth,
      telephone,
      address,
    });

  const onSubmit = (answers: ProfileUpdateFormValues) => {
    const updates = profileUpdateFormExtractUpdates(answers);

    updateData.set({ updates });
    updateTrigger.set(Date.now());
  };

  const { handleSubmit, values, handleChange, errors, isValid, setFieldValue } =
    useFormik<ProfileUpdateFormValues>({
      validationSchema: profileUpdateFormValidationSchema,
      initialValues,
      onSubmit,
    });

  return (
    <StyledProfileInfoWrapper>
      <StyledEditProfileInfoForm onSubmit={handleSubmit} lang={language}>
        <StyledProfileInfo>
          <Input
            name="lastName"
            label={translate('onboarding_page.form.last_name')}
            type={InputType.TEXT}
            disabled={!isEditMode}
            errorMessage={errors.lastName}
            value={values.lastName}
            onChange={handleChange}
          />
        </StyledProfileInfo>
        {isEditMode ? (
          <StyledProfileInfo>
            <CheckboxGroup
              groupClassName="label-onboarding"
              name="gender"
              label={translate('onboarding_page.form.gender')}
              disabled={!isEditMode}
              defaultValue={gender}
              errorMessage={errors.gender}
              options={Object.values(Gender).map((value) => ({
                label: translate(`global.${value}`),
                value,
              }))}
              onClick={(e: MouseEvent<HTMLInputElement>) => {
                setFieldValue('gender', e.currentTarget.value);
              }}
            />
          </StyledProfileInfo>
        ) : (
          <StyledProfileInfo>
            <span>{translate('onboarding_page.form.gender')}:</span>
            <span>
              {(gender && translate(`global.${gender}`)) ||
                translate('onboarding_page.form.not_selected')}
            </span>
          </StyledProfileInfo>
        )}
        {dateOfBirth ? (
          <StyledProfileInfo>
            <span>{translate('onboarding_page.form.date_of_birth')}:</span>
            <span>{new Date(dateOfBirth).toDateString()}</span>
          </StyledProfileInfo>
        ) : (
          <StyledProfileInfo>
            <Input
              name="dateOfBirth"
              label={translate('onboarding_page.form.date_of_birth')}
              disabled={!isEditMode}
              value={values.dateOfBirth}
              type={InputType.DATE}
              errorMessage={errors.dateOfBirth}
              onChange={handleChange}
            />
          </StyledProfileInfo>
        )}
        <StyledProfileInfo>
          <Input
            name="telephone"
            label={translate('onboarding_page.form.telephone')}
            type={InputType.TEXT}
            disabled={!isEditMode}
            errorMessage={errors.telephone}
            value={values.telephone}
            onChange={handleChange}
          />
        </StyledProfileInfo>
        <StyledSubSectionWrapper>
          <Heading>
            {translate('onboarding_page.form.address.section_title')}
          </Heading>
        </StyledSubSectionWrapper>
        <StyledCondensedProfileInfo>
          <Input
            groupClassName="condensed"
            name="address.line1"
            label={translate('onboarding_page.form.address.line1')}
            type={InputType.TEXT}
            disabled={!isEditMode}
            errorMessage={errors.address?.line1}
            value={values.address?.line1}
            onChange={handleChange}
          />
        </StyledCondensedProfileInfo>
        <StyledCondensedProfileInfo>
          <Input
            groupClassName="condensed"
            name="address.line2"
            label={translate('onboarding_page.form.address.line2')}
            type={InputType.TEXT}
            disabled={!isEditMode}
            errorMessage={errors.address?.line2}
            value={values.address?.line2}
            onChange={handleChange}
          />
        </StyledCondensedProfileInfo>
        <StyledCondensedProfileInfo>
          <Input
            groupClassName="condensed"
            name="address.postalCode"
            label={translate('onboarding_page.form.address.postalCode')}
            type={InputType.TEXT}
            disabled={!isEditMode}
            errorMessage={errors.address?.postalCode}
            value={values.address?.postalCode}
            onChange={handleChange}
          />
        </StyledCondensedProfileInfo>
        <StyledCondensedProfileInfo>
          <Input
            groupClassName="condensed"
            name="address.city"
            label={translate('onboarding_page.form.address.city')}
            type={InputType.TEXT}
            disabled={!isEditMode}
            errorMessage={errors.address?.city}
            value={values.address?.city}
            onChange={handleChange}
          />
        </StyledCondensedProfileInfo>
        {isEditMode && (
          <Button className="small rounded button button-primary" type="submit" disabled={!isValid}>
            {isLoading ? <Spinner /> : translate('global.save')}
          </Button>
        )}
      </StyledEditProfileInfoForm>
    </StyledProfileInfoWrapper>
  );
};
