export const UploadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
  <g id="ic_upload" transform="translate(-1328 -669)">
    <rect id="Box" width="80" height="80" transform="translate(1328 669)" fill="none"/>
    <rect id="BG" width="80" height="80" rx="4" transform="translate(1328 669)" fill="#faf6f3"/>
    <g id="Icon_feather-upload" data-name="Icon feather-upload" transform="translate(1352 693)" opacity="0.495">
      <path id="Path_492" data-name="Path 492" d="M36.5,22.5v7.394a3.628,3.628,0,0,1-3.556,3.7H8.056a3.628,3.628,0,0,1-3.556-3.7V22.5" transform="translate(-4.5 -1.591)" fill="none" stroke="#4e3846" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Path_493" data-name="Path 493" d="M27.712,13.742,19.106,4.5,10.5,13.742" transform="translate(-3.106 -4.5)" fill="none" stroke="#4e3846" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Path_494" data-name="Path 494" d="M18,4.5V26.681" transform="translate(-2 -4.5)" fill="none" stroke="#4e3846" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </g>
</svg>

)