import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Needs, NeedsFields } from 'src/types/questionnaire/needs';

export const HelpMakeupQuickSelector: view = ({
  helpMakeupQuick = observe.profile.attributes.helpMakeupQuick,
  updateHelpMakeupQuick = update.ui.profile.attributes.helpMakeupQuick,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'helpMakeupQuick';
  const label = translate('profile.questionnaire.needs.helpMakeupQuick.label');
  const choices = Needs[NeedsFields.HELP_MAKEUP_QUICK].map((answer) => {
    return {
      label: translate(`profile.questionnaire.needs.${NeedsFields.HELP_MAKEUP_QUICK}.options.${answer}`),
      value: answer,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateHelpMakeupQuick.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={helpMakeupQuick ? [helpMakeupQuick] : []} onChange={handleChoice} />
    </div>
  );
};
HelpMakeupQuickSelector.displayName = 'HelpMakeupQuickSelector';
