import { ApiActionType, resultFormatter } from '../../../core/types';
import { ModulesHttpApiService } from '../../../services';

export const findModuleAction: producer = async ({
  trigger = observe.api[ApiActionType.FIND_MODULE].trigger,
  slug = observe.api[ApiActionType.FIND_MODULE].data.slug,
  updateTrigger = update.api[ApiActionType.FIND_MODULE].trigger,
  updateIsLoading = update.api[ApiActionType.FIND_MODULE].isLoading,
  updateResult = update.api[ApiActionType.FIND_MODULE].result,
  ModulesApiService = ModulesHttpApiService,
}) => {
  if (!(trigger && slug)) return;

  updateTrigger.remove();
  updateIsLoading.set(true);

  try {
    const module = await ModulesApiService.findModule(slug);

    updateResult.set(resultFormatter.ok(module));
  } catch (error: unknown) {
    updateResult.set(resultFormatter.error((error as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
