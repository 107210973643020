import { IconType } from 'react-icons';
import { MdRecommend } from 'react-icons/md';
import { SiAirplayvideo } from 'react-icons/si';
import { CgUserList } from 'react-icons/cg';
import { Button } from '../../elements';
import { BsChevronRight } from 'react-icons/bs';
import { TranslationsDeps } from '../../../core/services';
import { LanguageProps } from '../../../core/types';
import { AuthModalState } from '../../Auth';
import { I18nTranslationsService } from '../../../services';
import { Heading } from '../../elements';
import { Language } from '../../../core/types';

import {
  StyledFeaturesWrapper,
  StyledFeaturesContainer,
  StyledExperienceContainer,
  StyledScreensContainer,
} from './styles';

type Feature = {
  id: 'videos' | 'recommendations' | 'profile';
  asset: IconType;
};

type StateProps = {
  readonly language: Language;
  updateAuthModalState: Update<AuthModalState>;
  videoIsStarted: boolean;
  showMobileVideoCover: boolean;
  hideMobileVideoCover: Update<boolean>;
};

const features: Feature[] = [
  {
    id: 'videos',
    asset: SiAirplayvideo,
  },
  {
    id: 'recommendations',
    asset: MdRecommend,
  },
  {
    id: 'profile',
    asset: CgUserList,
  },
];

type Props = LanguageProps & TranslationsDeps;

export const Features: view = ({
  language = observe.language.value,
  translationsService = I18nTranslationsService,
  updateAuthModalState = update.utils.components.authModal.state,
}: Props) => {
  const mountSignUpScreen = () => {
    updateAuthModalState.set({
      isOpen: true,
      isSignUpScreenMounted: true,
    });
  };
  return (
    <>
      <StyledScreensContainer>
        <div className="screens-container-inner">
          <div className="screens"></div>
          <div>
            <h2>{translationsService.translate('brochure.title')}</h2>
            <h3>{translationsService.translate('brochure.subtitle')}</h3>
            <Button className="button" onClick={mountSignUpScreen}>
              {translationsService.translate('home_page.sign_up_now')}
            </Button>
          </div>
        </div>
      </StyledScreensContainer>
      <StyledExperienceContainer>
        {/* <StyledFeaturesContainer lang={translationsService.toHTMLLang(language)}>
      <Heading>
        {translationsService.translate('home_page.features_title')}
      </Heading>
      <StyledFeaturesWrapper>
        {features.map(({ id, asset }) => (
          <div className="feature" key={id}>
            <div className="asset">{asset({})}</div>
            <span className="label">
              {translationsService.translate(`home_page.features_${id}_label`)}
            </span>
            <p className="description">
              {translationsService.translate(
                `home_page.features_${id}_description`
              )}
            </p>
          </div>
        ))}
      </StyledFeaturesWrapper>
              </StyledFeaturesContainer>*/}
        <div className="experience-container-inner">
          <h2 className="title">
            {translationsService.translate('home_page.features_heading')}
          </h2>
          <div className="experience-box-container">
            <div className="experience-box hair">
              <span></span>
              <p style={{ whiteSpace: 'pre-line' }}>
                {translationsService.translate('home_page.box_1')}
              </p>
            </div>
            <div className="experience-box makeup">
              <span></span>
              <p style={{ whiteSpace: 'pre-line' }}>
                {translationsService.translate('home_page.box_2')}
              </p>
            </div>
            <div className="experience-box clothing">
              <span></span>
              <p style={{ whiteSpace: 'pre-line' }}>
                {translationsService.translate('home_page.box_3')}
              </p>
            </div>
          </div>
        </div>
      </StyledExperienceContainer>
    </>
  );
};
