import { LikesApiService, Post, PostUpdate } from '../core/services';
import { AxiosService } from './AxiosService';

const createService = (): LikesApiService => {
  const axiosInstance = AxiosService({});

  return <LikesApiService>{
    async getPostLikes(postId: string): Promise<Post> {
      return axiosInstance.get<{ postId: string }, Post>(
        `/api/post/${postId}/likes`
      );
    },

    likePost(postId: any): Promise<PostUpdate> {
      return axiosInstance.post<{ postId: string }, PostUpdate>(
        `/api/post/${postId}/like`,
        postId
      );
    },

    unlikePost(postId: any): Promise<PostUpdate> {
      return axiosInstance.delete<{ postId: string }, PostUpdate>(
        `/api/post/${postId}/like`,
        postId
      );
    },

    setAuthToken(authToken: string): void {
      axiosInstance.setAuthToken(authToken);
    },
  };
};

export const LikesHttpApiService = Object.freeze(createService());
