import React, { createRef, RefObject } from 'react';
import styles from "./styles.module.css"
import { UpdateProfileInfoRequest } from '../../../core/services';
import {
  ApiActionType,
  LanguageProps,
  UpdateAction,
} from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import {
  ErrorMessage,
  Modal,
  ModalState,
  Spinner,
} from '../../elements';

import {
  StyledAvatarProfileModal,
  StyledAvatarProfileModalBody,
  StyledAvatarProfileModalFooter,
  StyledAvatarProfileModalHeader,
} from './styles';
import { UploadIcon } from 'src/icons/UploadIcon';
import { Button } from 'src/generic_components/Button';

export type AvatarProfileModalState = ModalState & {
  readonly isDeleteModal?: boolean;
  readonly profilePicture?: string;
  readonly imageFile?: File;
};

export const avatarProfileModalInitialState: AvatarProfileModalState = {
  isOpen: false,
  isDeleteModal: false,
  profilePicture: '',
};

type Props = LanguageProps & {
  readonly avatarProfileModalState: AvatarProfileModalState;
  readonly hiddenFileInput?: RefObject<HTMLInputElement> | null;
  readonly errorMessage?: string;
  readonly isLoading?: boolean;
  updateAvatarProfileModalState: Update<AvatarProfileModalState>;
  updateErrorMessage?: Update<string>;
  updateRef: Update<RefObject<HTMLInputElement> | null>;
  updateData: Update<any>;
  updateTrigger: Update<number>;
};

const allowedExtensions = ['png', 'jpg', 'jpeg', 'JPEG', 'JPG', 'PNG'];
const allowedTypes = ['image/png', 'image/jpeg'];

const getExtension = (file: File): string => file.name.split('.').pop() || '';

const isImageValid = (file?: File): boolean => {
  if (file) {
    const extension = getExtension(file);
    return (
      allowedExtensions.indexOf(extension) > -1 &&
      allowedTypes.indexOf(file.type) > -1
    );
  }
  return true;
};

export const AvatarProfileModal: view<Props> = ({
  avatarProfileModalState = observe.utils.components.avatarProfileModal.state,
  hiddenFileInput = observe.utils.components.avatarProfileModal.ref,
  errorMessage = observe.utils.components.avatarProfileModal.errorMessage,
  updateErrorMessage = update.utils.components.avatarProfileModal.errorMessage,
  updateAvatarProfileModalState = update.utils.components.avatarProfileModal
    .state,
  updateRef = update.utils.components.avatarProfileModal.ref,
  updateData = update.api[ApiActionType.UPDATE_PROFILE].data,
  updateTrigger = update.api[ApiActionType.UPDATE_PROFILE].trigger,
  isLoading = observe.api[ApiActionType.UPDATE_PROFILE].isLoading,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  updateRef.set(createRef<HTMLInputElement>());

  const handleClose = () => {
    updateAvatarProfileModalState.set(avatarProfileModalInitialState);
  };

  const checkForErrors = (file: File): boolean => {
    updateErrorMessage.set('');
    if (isImageValid(file)) {
      if (file.size > 10000000) {
        updateErrorMessage.set('Image is bigger than 10mb.');
        return true;
      }
    } else {
      updateErrorMessage.set('Image type is not supported.');
      return true;
    }

    return false;
  };

  const { isOpen, isDeleteModal, profilePicture, imageFile } =
    avatarProfileModalState || avatarProfileModalInitialState;

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleSave = () => {
    const reader = new FileReader();

    if (imageFile) {
      reader.onload = () => {
        const data = reader.result;
        if (data) {
          const updates: UpdateProfileInfoRequest = {
            updates: {
              profilePicture: UpdateAction.set(data as string),
            },
          };
          updateData.set({ updates });
          updateTrigger.set(Date.now());
        }
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!e.target.files) {
      return;
    }

    const selectedFile: File = e.target.files[0];
    if (selectedFile) {
      if (!checkForErrors(selectedFile)) {
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;

          if (dataUrl) {
            updateAvatarProfileModalState.set({
              ...avatarProfileModalState,
              profilePicture: dataUrl.toString(),
              imageFile: selectedFile,
            });
          }
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  };

  const handleDelete = () => {
    const updates: UpdateProfileInfoRequest = {
      updates: {
        profilePicture: UpdateAction.remove(),
      },
    };
    updateData.set({ updates });
    updateTrigger.set(Date.now());
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} lang={language}>
      <StyledAvatarProfileModal>
        <div className={styles["modal-header"]}>
           <h5>{isDeleteModal
            ? translate('global.delete')
            : translate('global.change')}{' '}
          {translate('profile_page.profile_picture')}</h5>
        </div>
        <div className={styles["modal-body"]}>
          {isDeleteModal && (
              <p> {translate('profile_page.delete_confirmation')}</p>
            )}
            {profilePicture && <img src={profilePicture} className={styles["modal-profile-picture"]} alt="profilePicture" />}
            {!isDeleteModal && (
              <>
               <div className={styles["modal-upload-button"]}>
                <Button onClick={handleClick}>
                  <UploadIcon/>
                </Button>
               </div>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  multiple={false}
                  accept="image/*"
                />
              </>
            )}
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>

        <div className={styles["avatar-modal-footer"]}>
          <Button variant="secondary" onClose={handleClose}>
            cancel
          </Button>
          {isDeleteModal ? (
            <Button onClick={handleDelete}>
              {translate('global.delete')}
            </Button>
          ) : (
            <Button onClick={handleSave}>
              { translate('global.save')}
            </Button>
          )}
        </div>
      </StyledAvatarProfileModal>
    </Modal>
  );
};
