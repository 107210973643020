import styled from 'styled-components';
import { theme } from '../../../globalStyle';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import picture from '../../../../assets/jtdiva/subscription_picture.jpg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import background from '../../../../assets/theme/background.png';

export const StyledGiftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  max-width: 1400px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 100px 24px;
  background: ${theme.color.gray.secondary};

  @media ${theme.queries.tablet} { 
    padding: 80px 24px;
  }

  @media ${theme.queries.mobile} { 
    padding: 40px 24px;
  }
`;

export const StyledGiftMetadata = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  width: 100%;

  @media ${theme.queries.tablet} {
    flex-flow: column;
  }
`;

export const StyledGiftPicture = styled.div`
  background: url(${picture}) no-repeat center center;
  background-size: contain;
  width: 100%;
  min-height: 225px;
`;

export const StyledGiftContent = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .title {
    font: 700 48px / 1.2 ${theme.font.primary};
    text-transform: capitalize;

    @media ${theme.queries.tablet} {
      font-size: 40px;
      margin-bottom: 8px;
    }

    @media ${theme.queries.mobile} {
      font-size: 32px;
    }
  }

  .subtitle {
    font: 500 32px / 1.4 ${theme.font.primary};
    margin-bottom: 20px;

    @media ${theme.queries.tablet} {
      font-size: 22px;
    }
  }

  .description {
    font-family: ${theme.font.secondary};
    line-height: 1.4;
    text-align: center;
    margin-bottom: 60px;
    text-align: center;

    @media ${theme.queries.tablet} {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }

  .button {
    text-transform: uppercase;
    padding: 14px 24px;
  }
`;

export const StyledGiftInfo = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font: 400 16px ${theme.font.primary};

  @media ${theme.queries.tablet} {
    font-size: 14px;
  }
`;
