import { BsPlayCircle } from 'react-icons/bs';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';

import { ThumbnailPlaceholder } from '../../elements';
import { VideoActionBar } from '../VideoActionBar';
import { VideoPremium } from '../VideoPremium';

import {
  StyledContentCardImage,
  StyledContentCardCategory,
  StyledContentCardHero,
  StyledContentCardTitle,
  StyledContentCardWrapper,
  StyledContentCardCta,
  StyledMetaInfo,
  StyledMetaWrapper,
  SelectableArea,
} from './styles';
import { useHistory } from 'react-router-dom';

type Props = LanguageProps & {
  type: 'article' | 'video';
  size: 'small' | 'large';
  image?: string;
  categories: string[];
  name: string;
  createdOn: string;
  author?: string;
  id: string;
  premium: any;
  translate: any;
};

export const ContentCard: view<Props> = ({
  image,
  size,
  type,
  categories,
  name,
  createdOn,
  author,
  id,
  premium,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const history = useHistory();
  return (
    <>
      <StyledContentCardWrapper className={size} lang={language}>
        {/*<StyledContentCardHero onClick={() => (window.location.href = `/videos/${id}`)}>*/}
        <StyledContentCardHero onClick={() => history.push(`/videos/${id}`)}>
          {image ? <StyledContentCardImage imgSrc={image} role="img" /> : <ThumbnailPlaceholder />}
        </StyledContentCardHero>
        <div className="video-card-body">
          {/*<div onClick={() => (window.location.href = `/videos/${id}`)}>*/}
          <div onClick={() => history.push(`/videos/${id}`)}>
            <StyledContentCardTitle>{name}</StyledContentCardTitle>
            {author && (
              <StyledMetaInfo>
                <span>By</span>
                <span>{author}</span>
              </StyledMetaInfo>
            )}
          </div>
          <div className="video-card-extras">
            <div className="video-card-info">
              <VideoPremium premium={premium} />
              {categories.length > 0 && (
                <>
                  {categories.map((category: any) => (
                    <StyledContentCardCategory key={categories.indexOf(category)}>
                      {translate(`categories.${category}`)}
                    </StyledContentCardCategory>
                  ))}
                </>
              )}
            </div>
            {/* <StyledContentCardCta>
              {type === 'video' ? (
                <BsPlayCircle />
              ) : (
                <span>{translate('feed_page.read_more')}</span>
              )}
            </StyledContentCardCta> */}
            {type === 'video' && <VideoActionBar id={id} />}
          </div>
        </div>
      </StyledContentCardWrapper>
    </>
  );
};
