import { Module } from '../../core/domain';
import { InputType, SelectType } from '../../core/types';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import heroImage from '../../../assets/illustrations/preferences-hero.svg';

export const preferencesModule: Module = {
  name: 'Preferences',
  slug: 'preferences',
  description: `We need to know what your preferences are, help us steer Jan in the right direction.`,
  timeToComplete: '10 min',
  heroImage,
  questions: [
    {
      input: 'Style Preference',
      type: InputType.RADIO,
      required: true,
      description: 'What best describes your style preference?',
      answers: [
        'Chic',
        'Bohemian',
        'Classic',
        'Modern classic',
        'Rocked',
        'Sexy',
        'Eclectic',
        'Tomboy',
        'Minimalistic',
        'Urban',
        'Sporty',
        "I don't know what my style is",
      ].map((label) => ({ label })),
    },
    {
      input: 'Colors',
      type: SelectType.MULTISELECT,
      required: true,
      description: 'What colors do you like to dress in?',
      answers: [
        'Black',
        'Grey',
        'White',
        'Beige / nude',
        'Brown',
        'Green',
        'Blue',
        'Red',
        'Purple',
        'Pink',
        'Yellow',
      ].map((label) => ({ label })),
    },
    {
      input: 'Garments feel best in',
      type: InputType.TEXTAREA,
      required: true,
      description: 'What type of garments do you feel best in?',
      answers: [],
    },
    {
      input: 'Corfortable clothing',
      type: InputType.TEXTAREA,
      required: true,
      description:
        'Do you have a piece of clothing that makes you feel comfortable and pretty? Tell us what that is and why',
      answers: [],
    },
    {
      input: 'Foundation preference',
      type: InputType.RADIO,
      required: true,
      description: 'What kind of deck ability do you want from a foundation?',
      answers: [
        'Full coverage',
        'Medium coverage',
        'Light coverage',
        "I don't wear fondation",
      ].map((label) => ({ label })),
    },
    {
      input: 'Wanted look',
      type: InputType.RADIO,
      required: true,
      description: 'What do you prefer as a look for your skin?',
      answers: ['Glow', 'Mat', 'Natural', "I don't know"].map((label) => ({
        label,
      })),
    },
    {
      input: 'Everyday makeup',
      type: InputType.RADIO,
      required: true,
      description:
        'Do you prefer a lot of make-up, or a natural look in everyday life?',
      answers: [
        'A lot of make up',
        'Medium amount',
        'Naturlig look',
        'Depends on the day',
      ].map((label) => ({ label })),
    },
    {
      input: 'Party makeup',
      type: InputType.RADIO,
      required: true,
      description: 'Do you prefer a lot of makeup, or a natural look to party?',
      answers: [
        {
          label: 'Make up',
        },
        {
          label: 'Natural',
        },
      ],
    },
    {
      input: 'Time spent on makeup',
      type: InputType.RADIO,
      required: true,
      description: 'Do you like to spend time on makeup?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Make up buying location',
      type: SelectType.MULTISELECT,
      required: true,
      description: 'Choose all that applies for you',
      answers: [
        'Kicks',
        'Kicks nettbutikk',
        'Vita',
        'Vita nettbutikk',
        'Fredrik&Louisa',
        'F&L nettbutikk',
        'H&M',
        'H&M nettbutikk',
        'Cubus',
        'BliVakker',
        'Blush',
        'Lyko',
        'Lyko nettbutikk',
        'Other',
      ].map((label) => ({ label })),
    },
    {
      input: 'Hair length',
      type: InputType.RADIO,
      required: true,
      description: 'I would like to know the length of your hair',
      answers: [
        'Long',
        'Mid-lenght',
        'Shoulder lenght',
        'Bob-lenght',
        'Shortcut',
      ].map((label) => ({ label })),
    },
    {
      input: 'Hair style usually',
      type: InputType.RADIO,
      required: true,
      description: 'How do you usually have your hair?',
      answers: [
        'Down as it is',
        'I put it up',
        'I style it with straightening or curling iron',
      ].map((label) => ({ label })),
    },
    {
      input: 'Is it any styles you would want to learn about?',
      type: SelectType.MULTISELECT,
      required: true,
      description: 'How would you like to have your hair?',
      answers: ['Varies of curls', 'Waves', 'Ponytails', 'Hair setups'].map(
        (label) => ({ label })
      ),
    },
    {
      input: 'Spending time on hair style',
      type: InputType.RADIO,
      required: true,
      description:
        'Do you like to spend time creating hairstyles in everyday life?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Spending time on hair style for parties',
      type: InputType.RADIO,
      required: true,
      description:
        'Do you like to spend time creating hairstyles when you go to parties / on special occasions?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
  ],
};
