import { FieldLabel } from 'src/generic_components/FieldLabel';
import { InputField } from 'src/generic_components/InputField';
import { Slider } from 'src/generic_components/Slider';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { NeedsFields } from 'src/types/questionnaire/needs';

export const DressingSkillLevelSelector: view = ({
  dressingSkillLevel = observe.profile.attributes.dressingSkillLevel,
  updateDressingSkillLevel = update.ui.profile.attributes.dressingSkillLevel,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'dressingSkillLevel';
  const label = translate('profile.questionnaire.needs.dressingSkillLevel.question');
  const min = 1,
    max = 10;

  // if (!dressingSkillLevel) {
  //   updateDressingSkillLevel.set(min);
  // }

  const handleChangeCommitted = (event: any, value: number) => {
    updateDressingSkillLevel.set(value);
  };

  const valueVerification = (value: string) => {
    const numValue = parseInt(value);
    let newValue = numValue;

    if (numValue < min || Number.isNaN(numValue)) newValue = min;
    else if (numValue > max) newValue = max;

    return newValue;
  };

  const onSubmitInput = (value: any) => {
    updateDressingSkillLevel.set(value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      {/* <Slider
        attribute={NeedsFields.DRESSING_SKILL_LEVEL}
        onChangeCommitted={handleChangeCommitted}
        min={min}
        max={max}
        step={1}
        showValue={true}
        value={dressingSkillLevel}
        valueVerification={valueVerification}
        inputType={"number"}
      /> */}
      <InputField value={dressingSkillLevel} onSubmitInput={onSubmitInput} valueVerification={valueVerification} placeholder={min} type={'number'} />

    </div>
  );
};
DressingSkillLevelSelector.displayName = 'DressingSkillLevelSelector';
