import styles from './styles.module.css'

export const Button: view = ({
  children,
  variant,
  disabled,
  onClick,
  onTouchStart,
  type,
  extraClasses
  }) => {
    const _type = type ? type : "button"

    const className = `${styles["button"]} ${variant} ${extraClasses}`
    return (
        <button 
        className={className}
        disabled={disabled}
        onClick={onClick}
        type={_type}
        onTouchStart={onTouchStart}
        >
          {children}
        </button>
    )
 }