import styled from 'styled-components';
import logo from '../../../assets/jtdiva/logo-v2.svg';
import { theme } from '../../globalStyle';

export const StyledLogoWrapper = styled.div`
  svg {
    display: flex;
    width: auto;
    height: 40px;
    transition: height 0.3s ease-out;
    padding: 7px;
  }

  a {
    display: inline-block;
    background-image: url(${logo});
    background-size: contain;
    width: 80px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
  }

  @media ${theme.queries.tablet} { 
    a{
      width: 60px;
      height: 30px;
    }
  }
`;
