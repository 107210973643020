import styled from 'styled-components';
import { theme } from '../../globalStyle';

export const StyledNavbar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;
  left: 0;
  background: url(https://fsh-assets.s3.eu-north-1.amazonaws.com/header.png) center;
  background-size: cover;
  width: 100%;
  height: 70px;
  margin: 0;
  padding: 0 24px;
  transition: height 0.3s ease-out;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  
  @media ${theme.queries.mobile} {
    height: 60px;
    padding: 0 16px;
  }
`;

export const StyledNavbarHead = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
`;

export const StyledLogoContainer = styled.div`
  padding: 8px 0;
`;

export const StyledSignInCta = styled.span`
  color: ${theme.color.yellow.light};
  font: 600 14px/22px ${theme.font.secondary};
  text-align: right;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: ${theme.color.yellow['light-hover']};
  }

  @media ${theme.queries.tablet} {
    margin: 8px 0;
  }
`;

export const StyledNavbarOptionsWrapper = styled.div`
  display: flex;
  padding: 16px 0;
  align-items: center;
  top: 0;
  justify-content: center;
  margin: 0 auto;
  transition: height 0.3s ease-out;
  white-space: nowrap;

  a {
    font: 600 14px/22px ${theme.font.secondary};
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
    color: ${theme.color.yellow.light};
    text-decoration: none;
    margin: 0 30px;

    &:hover {
      color: ${theme.color.yellow['light-hover']};
    }

    @media ${theme.queries.tablet} {
      margin: 8px 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${theme.queries.tablet} {
    flex-flow: column;
    background-color: ${theme.color.white};
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    top: 70px;
    border-bottom: 2px solid ${theme.color.gray.border};
    box-shadow: 0.2px 0.5px 3px 0.5px rgb(0 0 0 / 20%);
  }
`;

export const StyledNavbarProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font: 600 18px/22px ${theme.font.secondary};
  text-transform: uppercase;
  white-space: nowrap;
  justify-content: flex-end;

  @media ${theme.queries.tablet} {
  }
`;

export const StyledProfileActions = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isActive }) =>
    isActive ? theme.color.yellow['light-hover'] : theme.color.yellow.light};

  &:hover {
    > div {
      color: ${theme.color.yellow['light-hover']};
    }
  }

  svg {
    margin-right: 4px;
    font-size: 24px;
  }
`;

export const StyledNavbarProfileDropdown = styled.div`
  max-height: 0;
  overflow: hidden;
  width: auto;
  position: absolute;
  z-index: 1000;
  top: 100%;
  padding: 12px 20px 4px;
  border-radius: 3px;
  background-color: ${theme.color.white};
  box-shadow: 0 3px 11px rgb(0 0 0 / 20%);
  font: 600 14px/22px ${theme.font.secondary}, sans-serif;

  &.toggled {
    max-height: 215px;
  }
`;

export const StyledNavbarDropdownIcon = styled.div`
  padding-top: 6px;
`;

export const StyledNavbarProfileName = styled.div`
  margin-right: 4px;
  font: 600 14px/22px ${theme.font.secondary}, sans-serif;
`;

export const StyledNavbarDropdownContent = styled.div`
  align-items: flex-start;
  text-align: center;
  justify-content: center;
  display: flex;
  font: 400 16px/22px ${theme.font.secondary};
  text-transform: capitalize;
  flex-flow: column;
`;

export const StyledNavbarDropdownItem = styled.div`
  margin-bottom: 8px;
  display: flex;
  width: 100%;
  align-items: flex-start;

  &:hover {
    color: ${theme.color.red['primary-hover']};

    a {
      color: ${theme.color.red['primary-hover']};
    }
  }

  & > a {
    text-decoration: none;
    color: ${theme.color.black};
  }
`;

export const StyledNavbarBottomTabs = styled.div`
  display: flex;
  gap: 8px;
  position: fixed;
  top: 60px;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 100;
  left: 0;
  background-color: ${theme.color.white};
  box-shadow: 0 0 4px rgb(0 0 0 / 15%);
  height: auto;
  margin: 0;
  padding: 4px 24px;
  transition: height 0.3s ease-out;

  a {
    text-decoration: none;
  }
`;

export const StyledNavbarBottomTab = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-flow: column wrap;
  width: 97px;
  border-radius: 8px;
  padding: ${theme.spacing.xs} 12px;
  align-items: center;
  color: ${theme.color.black};
  cursor: pointer;
  font-family: ${theme.font.secondary};
  text-align: center;
  text-transform: uppercase;
  background-color: ${({ isActive }) =>
    isActive ? theme.color.yellow.primary : theme.color.white};

  span:first-child {
    font-size: 22px;
  }

  span:last-child {
    font-size: 12px;
  }
`;

export const StyledActionsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
