import { ApiActionType } from '../../../core/types';

export const createCheckoutSessionSuccessAction: producer = ({
  result = observe.api[ApiActionType.CREATE_CHECKOUT_SESSION].result,
  updateResult = update.api[ApiActionType.CREATE_CHECKOUT_SESSION].result,
  updateCheckoutSessionUrl = update.payment.checkoutSessionUrl,
}) => {
  if (!(result && result.isOk)) return;

  updateCheckoutSessionUrl.set(result.value);
  updateResult.remove();
};
