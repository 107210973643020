import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Preferences, PreferencesFields } from 'src/types/questionnaire/preferences';

export const TimeOnOccasionsHairSelector: view = ({
  timeOnOccasionsHair = observe.profile.attributes.timeOnOccasionsHair,
  updateTimeOnOccasionsHair = update.ui.profile.attributes.timeOnOccasionsHair,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'timeOnOccasionsHair';
  const label = translate('profile.questionnaire.preferences.timeOnOccasionsHair.label');
  const choices = Preferences[PreferencesFields.TIME_ON_OCCASIONS_HAIR].map((answer) => {
    return {
      label: translate(`profile.questionnaire.preferences.${PreferencesFields.TIME_ON_OCCASIONS_HAIR}.options.${answer}`),
      value: answer,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateTimeOnOccasionsHair.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={timeOnOccasionsHair ? [timeOnOccasionsHair] : []} onChange={handleChoice} />
    </div>
  );
};
TimeOnOccasionsHairSelector.displayName = 'TimeOnOccasionsHairSelector';
