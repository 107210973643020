import { Module } from '../../core/domain';
import { InputType, SelectType } from '../../core/types';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import heroImage from '../../../assets/illustrations/needs-hero.svg';

export const needsModule: Module = {
  name: 'Needs',
  slug: 'needs',
  description:
    'Tell us about your needs. In that way we can tailor the advice in the best possible way to help out on exactly what you need.',
  timeToComplete: '12 min',
  heroImage,
  questions: [
    {
      input: 'Reason for Signing up',
      type: InputType.RADIO,
      required: true,
      description:
        'What is most appropriate to describe the reason why you sign up?',
      answers: [
        'I want inspiration about hair, make up and fashion',
        'I was curious to what this concept was about',
        'I find it hard to know what suits me, and want to learn',
        'I need to feel good with how I do my makeup and how I dress myself',
      ].map((label) => ({ label })),
    },
    {
      input: 'Well dressed good day',
      type: InputType.RADIO,
      required: true,
      description: 'Feeling well-dressed can lead to a better day?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Feeling good raises self esteem',
      type: InputType.RADIO,
      required: true,
      description: 'Feeling good in what you wear raises your self-esteem?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Clothes send signal about yourself',
      type: InputType.RADIO,
      required: true,
      description: 'Clothes send a signal about who you are?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Expensive vs Cheap bra',
      type: InputType.RADIO,
      required: true,
      description: 'Is it better with one expensive bra, than many cheap ones?',
      answers: [
        {
          label: 'One expensive bra',
        },
        {
          label: 'Many cheap bra',
        },
      ],
    },
    {
      input: 'Dress according to age',
      type: InputType.RADIO,
      required: true,
      description: 'Is it important to dress according to age?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Good at dressing',
      type: InputType.RANGE,
      minValue: 1,
      maxValue: 10,
      required: true,
      description: 'How good will you be at dressing?',
      answers: [],
    },
    {
      input: 'Hair about identity',
      type: InputType.RADIO,
      required: true,
      description: 'My hair has a lot to say for my identity',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Body shape challenge',
      type: InputType.RADIO,
      required: true,
      description:
        'Have you experienced that your body shape makes it challenging to find clothes?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Height challenge finding clothes',
      type: InputType.RADIO,
      required: true,
      description:
        'Have you experienced that height makes it challenging to find clothes?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Help for snug garments',
      type: InputType.RADIO,
      required: true,
      description:
        'Do you need advice when it comes to finding garments that fit snugly on your body?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Help for colors to wear',
      type: InputType.RADIO,
      required: true,
      description: 'Do you need tips on what colors to wear?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Help for clothes on formal',
      type: InputType.RADIO,
      required: true,
      description:
        'Do you need advice on when it comes to how to dress to highlight and formal features?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Make up color advice',
      type: InputType.RADIO,
      required: true,
      description:
        'Do you need advice when it comes to color choices and products?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Help up make up application',
      type: InputType.RADIO,
      required: true,
      description:
        'Do you need advice when it comes to how to apply the makeup you already have in the best possible way?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Help freshen up',
      type: InputType.RADIO,
      required: true,
      description:
        'Do you need advice when it comes to simple ways to freshen up (quick makeup)?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Help everyday hair style',
      type: InputType.RADIO,
      required: true,
      description:
        'Would you like to get tips on how to do simple everyday styling?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Help special occasions hair style',
      type: InputType.RADIO,
      required: true,
      description:
        'Would you like tips on how to set up more fancy hairstyles for special occasions?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Color for dying hair',
      type: InputType.RADIO,
      required: true,
      description:
        'Could you think of any color choices that would suit you if you wanted to dye your hair?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Advice on hair care',
      type: InputType.RADIO,
      required: true,
      description: 'Could you think of advice when it comes to hair care?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Help with using products',
      type: InputType.RADIO,
      required: true,
      description:
        'Would you like advice on how to use products to achieve volume / hold / styling?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
  ],
};
