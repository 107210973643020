import { AiOutlineInfoCircle } from 'react-icons/ai';

import { TranslationsDeps } from '../../../core/services';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { AuthModalState } from '../../Auth';
import { Button } from '../../elements';

import {
  StyledGiftContainer,
  StyledGiftContent,
  StyledGiftInfo,
  StyledGiftMetadata,
  StyledGiftPicture,
} from './styles';

type StateProps = {
  updateAuthModalState: Update<AuthModalState>;
};

type Props = LanguageProps & TranslationsDeps & StateProps;

export const Gift: view = ({
  language = observe.language.value,
  updateAuthModalState = update.utils.components.authModal.state,
  translationsService = I18nTranslationsService,
}: Props) => {
  const mountSignInScreen = () => {
    updateAuthModalState.set({
      isOpen: true,
      isSignInScreenMounted: true,
    });
  };

  return (
    <StyledGiftContainer lang={translationsService.toHTMLLang(language)}>
      <StyledGiftMetadata>
        <StyledGiftContent>
          <h4 className="title">
            {translationsService.translate('home_page.gift_title')}
          </h4>
          <h6 className="subtitle">
            {translationsService.translate('home_page.gift_subtitle')}
          </h6>
          <p className="description">
            {translationsService.translate('home_page.gift_description')}
          </p>
          <Button onClick={mountSignInScreen} className="button">
            {translationsService.translate('home_page.subscribe_now')}
          </Button>
          <StyledGiftInfo>
            <span>
              <AiOutlineInfoCircle />
            </span>
            <p className="info">
              {translationsService.translate('home_page.gift_info')}
            </p>
          </StyledGiftInfo>
        </StyledGiftContent>
        <StyledGiftPicture />
      </StyledGiftMetadata>
    </StyledGiftContainer>
  );
};
