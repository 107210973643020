import { ProfileModule, Question } from '../../../core/domain';
import { CreateProfileModuleRequest } from '../../../core/services';
import { ApiActionType, LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { Button, Spinner, ThumbnailPlaceholder } from '../../elements';
import { nameToTranslationKey } from '../../../utils';

import {
  StyledModuleAnswers,
  StyledModuleAnswersHeader,
  StyledModuleDescription,
  StyledModuleHero,
  StyledModuleHeroImage,
  StyledModuleMetadata,
  StyledModuleTimeToComplete,
  StyledModuleTitle,
  StyledCardContainer,
} from './styles';

type OwnProps = {
  name: string;
  heroImage?: string;
  description?: string;
  timeToComplete?: string;
  questions?: Question[];
  profileModules?: Record<string, ProfileModule>;
};

type StateProps = {
  isLoading?: boolean;
  updateIsEditMode: Update<boolean>;
  createProfileModuleTrigger: Update<number>;
  updateData: Update<unknown>;
  updateUtils: Update<unknown>;
};

type Props = OwnProps & StateProps & LanguageProps;

export const ModuleMetadata: view<Props> = ({
  name = observe.module.name,
  heroImage = observe.module.heroImage,
  questions = observe.module.questions,
  description = observe.module.description,
  timeToComplete = observe.module.timeToComplete,
  profileModules = observe.profile.modules,
  isLoading = observe.api[ApiActionType.CREATE_PROFILE_MODULE].isLoading,
  updateIsEditMode = update.utils.components.moduleDetails.isEditMode,
  createProfileModuleTrigger = update.api[ApiActionType.CREATE_PROFILE_MODULE]
    .trigger,
  updateData = update.api[ApiActionType.CREATE_PROFILE_MODULE].data,
  updateUtils = update.utils.components.moduleQuestion[param.moduleName]
    .answers,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const profileModule = profileModules && (profileModules[name] ?? undefined);
  const noOfQuestions = questions.length;

  const start = () => {
    if (!profileModule) {
      const request: CreateProfileModuleRequest = {
        name,
        noOfQuestions,
      };

      updateData.set({ request });
      createProfileModuleTrigger.set(Date.now());

      return;
    }
    updateUtils.set(profileModule.questions, { moduleName: name });
    updateIsEditMode.set(true);
  };

  const buttonText = profileModule
    ? translate(`modules.edit_module`)
    : translate(`modules.start_module`);

  return (
    <StyledModuleMetadata lang={language}>
      <StyledCardContainer>
        <StyledModuleTitle>
          {translate(`modules.${nameToTranslationKey(name)}.name`)}
        </StyledModuleTitle>
        <StyledModuleHero>
          {heroImage ? (
            <StyledModuleHeroImage src={heroImage} alt={name} />
          ) : (
            <ThumbnailPlaceholder />
          )}
        </StyledModuleHero>
      </StyledCardContainer>
      {description && (
        <StyledModuleDescription>
          {translate(`modules.${nameToTranslationKey(name)}.description`)}
        </StyledModuleDescription>
      )}
      {timeToComplete && (
        <StyledModuleTimeToComplete>
          <span>{translate('modules.time_to_complete')}</span>
          <span>{timeToComplete}</span>
        </StyledModuleTimeToComplete>
      )}
      {profileModule && profileModule.progress > 0 ? (
        <StyledModuleAnswers>
          <div>
            <StyledModuleAnswersHeader>
              {translate(`modules.your_answers`)}
            </StyledModuleAnswersHeader>
            <Button className="button button-primary" onClick={start}>
              {isLoading ? <Spinner /> : buttonText}
            </Button>
          </div>
          {/* <div> REDO MAPPING
            {Object.entries(profileModule.questions).map(
              ([key, value], index) => (
                <div key={key}>
                  <span>
                    {translate(
                      `modules.${nameToTranslationKey(
                        name
                      )}.questions.${index}.title`
                    )}
                    :
                  </span>
                  <span>{value.toString()}</span>
                </div>
              )
            )}
          </div> */}
        </StyledModuleAnswers>
      ) : (
        <Button className="rounded button button-primary" onClick={start}>
          {isLoading ? <Spinner /> : buttonText}
        </Button>
      )}
    </StyledModuleMetadata>
  );
};
