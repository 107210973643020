import { I18nTranslationsService } from '../../../services';
import { LikesHttpApiService } from '../../../services';

import { LikedIcon } from 'src/icons';

import { VideoActionButton } from 'src/generic_components/VideoActionButton';

export const Liked: view = ({
  id,
  translate = I18nTranslationsService.translate,
  likedVideos = observe.likedVideos,
  setLikedVideos = update.likedVideos,
  updateErrorNotificationState = update.errorNotificationState,
}) => {
  const handleClick = async () => {
    try {
      setLikedVideos.set({
        ...likedVideos,
        [id]: {
          likes: likedVideos[id] ? likedVideos[id].likes - 1 : 0,
          liked: false,
        },
      });

      const res = await LikesHttpApiService.unlikePost(id);

      if (Object.keys(res).length) throw new Error();

      updateErrorNotificationState.set({
        triggerNotification: true,
        notificationMessage: translate('notifications.UnlikeNotification'),
        severity: 'success',
      });
    } catch (err) {
      updateErrorNotificationState.set({
        triggerNotification: true,
        notificationMessage: translate('notifications.Error'),
        severity: 'error',
      });

      setLikedVideos.set({
        ...likedVideos,
        [id]: { likes: (likedVideos[id]?.likes || 0) + 1, liked: true },
      });
    }
  };

  return (
    <VideoActionButton key={'liked'} title={translate('media_library.UnlikeVideo')} onClick={handleClick}>
      <div className="liked-video">
        <LikedIcon />
        <p>{likedVideos[id]?.likes || 0}</p>
      </div>
    </VideoActionButton>
  );
};
