import { Styles } from 'react-modal';
import styled from 'styled-components';

import { theme } from '../../../globalStyle';

export const modalStyles: Styles = {
  content: {
    position: 'absolute',
    inset: '50% auto auto 50%',
    border: '1px solid #cccccc',
    background: theme.color.white,
    overflow: 'visible',
    borderRadius: '10px',
    outline: 'none',
    padding: '0',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
    margin: '0 auto',
    WebkitOverflowScrolling: 'touch',
  },
  overlay: { zIndex: 1000, backgroundColor: '#735d67a6' },
};

export const StyledModalCloseButton = styled.span`
  color: ${theme.color.black};
  font: 400 40px/36px ${theme.font.secondary};
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;

  &:active {
    background: transparent;
  }

  &:hover {
    color: #897180;
  }

  @media ${theme.queries.mobile} {
    top: 0;
    width: 16px;
  }
`;

export const StyledModalContent = styled.div`
  display: flex;
`;
