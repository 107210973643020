import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Preferences, PreferencesFields } from 'src/types/questionnaire/preferences';

export const UsualHairstyleSelector: view = ({
  usualHairstyle = observe.profile.attributes.usualHairstyle,
  updateUsualHairstyle = update.ui.profile.attributes.usualHairstyle,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'usualHairstyle';
  const label = translate('profile.questionnaire.preferences.usualHairstyle.label');
  const choices = Preferences[PreferencesFields.USUAL_HAIRSTYLE].map((hairstyle) => {
    return {
      label: translate(`profile.questionnaire.preferences.${PreferencesFields.USUAL_HAIRSTYLE}.options.${hairstyle}`),
      value: hairstyle,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateUsualHairstyle.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={usualHairstyle ? [usualHairstyle] : []} onChange={handleChoice} />
    </div>
  );
};
UsualHairstyleSelector.displayName = 'UsualHairstyleSelector';
