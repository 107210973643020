import { Category } from '../../core/types';
import { CgGirl } from 'react-icons/cg';
import { GiClothes } from 'react-icons/gi';
import { FaPaintBrush } from 'react-icons/fa';
import { GiStrong } from 'react-icons/gi';
import { HiCollection } from 'react-icons/hi';
import { ImMagicWand } from 'react-icons/im';
import { WiStars } from 'react-icons/wi';

export const Categories: Array<Category> = [
  {
    key: 'all',
    name: 'All',
    icon: <HiCollection size="24" />,
  },
  {
    key: 'confidence',
    name: 'Confidence',
    icon: <GiStrong size="24" />,
  },
  {
    key: 'clothes',
    name: 'Clothes',
    icon: <GiClothes size="24" />,
  },
  {
    key: 'makeup',
    name: 'Makeup',
    icon: <FaPaintBrush size="24" />,
  },
  {
    key: 'hair',
    name: 'Hair',
    icon: <CgGirl size="24" />,
  },
  {
    key: 'hacks',
    name: 'Hacks',
    icon: <ImMagicWand size="24" />,
  },
  {
    key: 'fashion',
    name: 'Fashion',
    icon: <WiStars size="24" />,
  },
];
