import { Slide } from 'src/newDesign/Onboarding/Slide';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import { I18nTranslationsService } from 'src/services';
import bodyIcon from '../../../../assets/jtdiva/q-body.png';
import { StylePreferenceSelector } from '../fields/StylePreferenceSelector';
import { ClothingColorSelector } from '../fields/ClothingColorSelector';
import { FavouriteGarmentsInput } from '../fields/FavouriteGarmentsInput';
import { ComfortableClothingInput } from '../fields/ComfortableClothingInput';

export const Clothing: view = ({ translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.preferences.describe_preferences')}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.preferences.heading')}
        cardDescription={translate('profile.questionnaire.preferences.description')}
      >
        <StylePreferenceSelector />
        <ClothingColorSelector />
        <FavouriteGarmentsInput />
        <ComfortableClothingInput />
      </QuestionCard>
    </Slide>
  );
};
