import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledModuleQuestion = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 514px;
`;

export const StyledQuestionInput = styled.div`
  font: 500 27px/28px ${theme.font.secondary};
  margin-bottom: 8px;
  text-align: center;
`;

export const StyledQuestionDescription = styled.div`
  font: 400 18px/24px ${theme.font.secondary};
  color: ${theme.color.black};
  margin-bottom: 10px;
  text-align: center;
`;

export const StyledQuestionAsset = styled.div<{ imgSrc: string }>``;

export const StyledQuestionsAnswers = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 8px;

  & > div {
    margin: 8px 0;
  }
`;
