import { Slide } from 'src/newDesign/Onboarding/Slide';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import { I18nTranslationsService } from 'src/services';
import bodyIcon from '../../../../assets/jtdiva/q-body.png';
import { IllnessSelector } from '../fields/IllnessSelector';
import { DiseasePerceptionSelector } from '../fields/DiseasePerceptionSelector';

export const Health: view = ({ translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.information.describe_information')}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.information.heading')}
        cardDescription={translate('profile.questionnaire.information.description')}
      >
        <IllnessSelector />
        <DiseasePerceptionSelector />
      </QuestionCard>
    </Slide>
  );
};
