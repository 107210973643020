import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { VipIcon } from 'src/icons';

import {
  StyledSubscriptionItem,
  StyledSubscriptionBenefitsWrapper,
  StyledSubscriptionTypeAsset,
  PremiumTag,
} from './styles';

type Props = LanguageProps & {
  premium?: boolean;
};

export const VideoPremium: view<Props> = ({
  premium,
  translate = I18nTranslationsService.translate,
}: Props) => {
  return (
    <>
      {premium && (
        <PremiumTag>
          <VipIcon />
          <span>{translate('subscription.vip')}</span>
        </PremiumTag>
      )}
    </>
  );
};
