import { I18nTranslationsService } from '../../../services'
import { ProfilesHttpApiService } from '../../../services'
import { FavouriteIcon, FavouriteIconActive } from 'src/icons'

import { VideoActionButton } from 'src/generic_components/VideoActionButton'
import { VisibleIf } from 'src/generic_components/VisibileIf'

export const Save: view = ({
  name,
  id,
  translate = I18nTranslationsService.translate,
  isSaved = observe.isSaved[arg.id],
  updateIsSaved = update.isSaved[arg.id],
  updateErrorNotificationState = update.errorNotificationState,
}) => {
  const removeFromFavourites = async () => {
    try {
      updateIsSaved.set(false)
      const res = await ProfilesHttpApiService.removeSavedVideo({ videoId: id })
      if (!Object.keys(res).length) throw new Error()
      updateErrorNotificationState.set({
        triggerNotification: true,
        notificationMessage: translate('notifications.RemovedVideo'),
        severity: 'success',
      })
    } catch (err) {
      updateErrorNotificationState.set({
        triggerNotification: true,
        notificationMessage: translate('notifications.Error'),
        severity: 'error',
      })
      updateIsSaved.set(true)
    }
  }
  const addToFavourites = async () => {
    try {
      updateIsSaved.set(true)
      const res = await ProfilesHttpApiService.saveVideo({
        videoDetails: { id, name },
      })
      if (!Object.keys(res).length) throw new Error()
      updateErrorNotificationState.set({
        triggerNotification: true,
        notificationMessage: translate('notifications.SavedVideo'),
        severity: 'success',
      })
    } catch (err) {
      updateErrorNotificationState.set({
        triggerNotification: true,
        notificationMessage: translate('notifications.Error'),
        severity: 'error',
      })
      updateIsSaved.set(false)
    }
  }
  
  return (
    <VideoActionButton
      key={'favouriteButton'}
      title={translate(`media_library.${isSaved ? 'Added' : 'Add'}`)}
      onClick={isSaved ? removeFromFavourites : addToFavourites}
    >
      <VisibleIf predicate={isSaved}>
        <div className="saved-video">
          <FavouriteIconActive/>
        </div>
      </VisibleIf>
      <VisibleIf predicate={!isSaved}>
        <div className="save-video">
          <FavouriteIcon/>
        </div>
      </VisibleIf>
    </VideoActionButton>
  )
}

// export const Save: view = ({
//   name,
//   id,
//   translate = I18nTranslationsService.translate,
//   isSaved = observe.isSaved,
//   updateIsSaved = update.isSaved,
//   updateErrorNotificationState = update.errorNotificationState,
// }) => {
//   const handleClick = async () => {
//     try {
//       updateIsSaved.set({ ...isSaved, [id]: true });
//
//       const res = await ProfilesHttpApiService.saveVideo({
//         videoDetails: { id, name },
//       });
//
//       if (!Object.keys(res).length) throw new Error();
//
//       updateErrorNotificationState.set({
//         triggerNotification: true,
//         notificationMessage: translate('notifications.SavedVideo'),
//         severity: 'success',
//       });
//     } catch (err) {
//       updateErrorNotificationState.set({
//         triggerNotification: true,
//         notificationMessage: translate('notifications.Error'),
//         severity: 'error',
//       });
//
//       updateIsSaved.set({ ...isSaved, [id]: false });
//     }
//   };
//
//   return (
//     <VideoActionButton key={'save'} title={translate('media_library.Add')} onClick={handleClick}>
//       <FavouriteIcon />
//     </VideoActionButton>
//   );
// };
