import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Needs, NeedsFields } from 'src/types/questionnaire/needs';

export const BraTypeSelector: view = ({
  expensiveVsCheapBra = observe.profile.attributes.expensiveVsCheapBra,
  updateExpensiveVsCheapBra = update.ui.profile.attributes.expensiveVsCheapBra,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'expensiveVsCheapBra';
  const label = translate('profile.questionnaire.needs.expensiveVsCheapBra.label');
  const choices = Needs[NeedsFields.EXPENSIVE_VS_CHEAP_BRA].map((answer) => {
    return {
      label: translate(`profile.questionnaire.needs.${NeedsFields.EXPENSIVE_VS_CHEAP_BRA}.options.${answer}`),
      value: answer,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateExpensiveVsCheapBra.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={expensiveVsCheapBra ? [expensiveVsCheapBra] : []} onChange={handleChoice} />
    </div>
  );
};
BraTypeSelector.displayName = 'BraTypeSelector';
