import { SearchContentRequest } from '../../../core/domain';
import { ApiActionType, RouteName } from '../../../core/types';

export const mediaLibraryGetInitialProps: producer = ({
  trigger = observe.pages[RouteName.MEDIA_LIBRARY].trigger,
  updateTrigger = update.pages[RouteName.MEDIA_LIBRARY].trigger,
  updateIsLoading = update.pages[RouteName.MEDIA_LIBRARY].isLoading,
  searchVideosTrigger = update.api[ApiActionType.SEARCH_VIDEOS].trigger,
  searchVideosData = update.api[ApiActionType.SEARCH_VIDEOS].data,
}) => {
  if (!trigger) return;

  updateTrigger.remove();

  document.title = 'Diva - Media Library';
  updateIsLoading.set(true);

  searchVideosData.set({ searchRequest: SearchContentRequest.create() });
  searchVideosTrigger.set(Date.now());
};
