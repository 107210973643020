import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Preferences, PreferencesFields } from 'src/types/questionnaire/preferences';

export const EverydayMakeupSelector: view = ({
  everydayMakeup = observe.profile.attributes.everydayMakeup,
  updateEveydayMakeup = update.ui.profile.attributes.everydayMakeup,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'everydayMakeup';
  const label = translate('profile.questionnaire.preferences.everydayMakeup.label');
  const choices = Preferences[PreferencesFields.EVERYDAY_MAKEUP].map((makeup) => {
    return {
      label: translate(`profile.questionnaire.preferences.${PreferencesFields.EVERYDAY_MAKEUP}.options.${makeup}`),
      value: makeup,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateEveydayMakeup.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={everydayMakeup ? [everydayMakeup] : []} onChange={handleChoice} />
    </div>
  );
};
EverydayMakeupSelector.displayName = 'EverydayMakeupSelector';
