import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledProfileInterestsContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 16px;
  border-radius: 12px;
  align-items: flex-start;
  margin: 24px 0;
  background: ${theme.color.gray.border};
`;

export const StyledProfileInterestsTitle = styled.h4`
  font: 600 24px/28px ${theme.font.secondary};
  color: ${theme.color.black};
  padding-bottom: 16px;
`;

export const StyledProfileInterestsWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  font: 400 14px/18px ${theme.font.secondary};

  span {
    padding: 4px 0;
  }
`;
