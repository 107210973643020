import { Video } from '../../../core/domain';
import { VideoCard } from '../VideoCard';
import { I18nTranslationsService } from 'src/services';

import { StyledRecommendedVideosContainer, StyledRecommendedVideosWrapper } from './styles';

type Props = {
  videos: Video[];
  currentVideo?: Video;
};

export const RecommendedVideosList: view = ({
  videos = observe.videos.data,
  currentVideo = observe.videoState.source,
  translate = I18nTranslationsService.translate,
}: Props) => {
  if (!(videos && currentVideo && videos.length)) return null;

  return (
    <StyledRecommendedVideosWrapper>
      <h4>{translate('video_details.related_videos')}</h4>
      <StyledRecommendedVideosContainer>
        {videos
          .filter((video: Video) => video.id !== currentVideo.id)
          .slice(0, 4)
          .map(({ id, name, createdOn, image, premium }: Video) => (
            <VideoCard key={id} name={name} createdOn={createdOn} id={id} image={image} premium={premium} />
          ))}
      </StyledRecommendedVideosContainer>
    </StyledRecommendedVideosWrapper>
  );
};
