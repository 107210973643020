enum UpdateActionType {
  SET = 'SET',
  REMOVE = 'REMOVE',
}

export class UpdateAction<T> {
  static set<T>(Value?: T): UpdateAction<T> {
    return new UpdateAction<T>(UpdateActionType.SET, Value);
  }

  static remove<T>(): UpdateAction<T> {
    return new UpdateAction<T>(UpdateActionType.REMOVE);
  }

  readonly Value?: T;

  readonly Action?: UpdateActionType;

  constructor(Action: UpdateActionType, Value?: T) {
    if (Value !== undefined && Action === UpdateActionType.REMOVE) {
      throw new Error(
        `A ${UpdateActionType.REMOVE} action cannot have a value`
      );
    }

    if (Value === undefined && !Action) {
      throw new Error('Please provide a Value and or an Action');
    }

    this.Value = Value;
    this.Action = Action;
  }
}
