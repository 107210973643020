import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Preferences, PreferencesFields } from 'src/types/questionnaire/preferences';

export const ClothingColorSelector: view = ({
  clothingColor = observe.profile.attributes.clothingColor,
  updateClothingColor = update.ui.profile.attributes.clothingColor,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'clothingColor';
  const label = translate('profile.questionnaire.preferences.clothingColor.label');
  const choices = Preferences[PreferencesFields.CLOTHING_COLOR].map((color) => {
    return {
      label: translate(`profile.questionnaire.preferences.${PreferencesFields.CLOTHING_COLOR}.options.${color}`),
      value: color,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>, newChoices: string[]) => {
    updateClothingColor.set(newChoices);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={clothingColor} onChange={handleChoice} />
    </div>
  );
};
ClothingColorSelector.displayName = 'ClothingColorSelector';
