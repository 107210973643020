import styled, { keyframes } from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledAnswerItemContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 8px;
  padding: 18px 10px;
  border-radius: 8px;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 20%);
  background-color: ${theme.color.gray.lighter};

  &:hover {
    background-color: ${theme.color.yellow.input};
    border-color: ${theme.color.yellow.input};
    cursor: pointer;
  }

  &:active {
    background-color: ${theme.color.yellow.input};
    border-color: ${theme.color.yellow['primary-hover']};
  }

  &.selected {
    background-color: ${theme.color.yellow.input};
    border-color: ${theme.color.yellow['primary-hover']};
    box-shadow: 0px 0px 4px 0px ${theme.color.yellow['primary-hover']};
  }
`;

export const StyledAnswerItemLabel = styled.label<{ disabled?: boolean }>`
  color: ${theme.color.black};
  font: 400 18px/18px ${theme.font.secondary};
  text-align: left;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: 2px 0 2px 28px;

  a {
    color: var(--logo-color-primary);
    text-deoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .bold {
    font-weight: 700;
  }
`;

export const StyledAnswerItem = styled.input`
  opacity: 0;
  z-index: 3;
  height: 22px;
  width: 22px;
  margin: 0;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  position: absolute;
`;

const popIn = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.5) ;
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1) ;
  }
`;

export const StyledIndicator = styled.div`
  width: 22px;
  height: 22px;
  z-index: -1;
  background: ${theme.color.white};
  position: absolute;
  cursor: pointer;
  top: 0;
  border: 1px solid ${theme.color.gray.dark};
  border-radius: 16px;
  display: none;

  ${StyledAnswerItem}:not(:disabled):checked & {
    background: ${theme.color.gray.border};
  }

  ${StyledAnswerItemLabel}:hover & {
    background: ${theme.color.gray.light};
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
  }

  ${StyledAnswerItem}:checked + &::after {
    display: block;
    border: solid ${theme.color.red.primary};
    border-radius: 16px;
    background-color: ${theme.color.red.primary};
    width: 6px;
    height: 6px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: ${popIn};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const AnswerItemWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const AnswerItemLabel = styled.label`
  font: 500 16px ${theme.font.secondary};
  text-align: left;
  margin-bottom: 8px;
`;
