import { AuthActionType } from '../../../core/domain';
import { SweetAlertService } from '../../../services';
import { ErrorLoggerService } from '../../../services/ErrorLoggerService';

export const signOutErrorAction: producer = async ({
  // result = observe.auth[AuthActionType.SIGN_OUT].result,
  // updateResult = update.auth[AuthActionType.SIGN_OUT].result,
  // AlertService = SweetAlertService,
}) => {
  // if (!(result && result.error)) return;

  // ErrorLoggerService.logError(result.error);

  // await AlertService.error(result.error);

  // updateResult.remove();
};
