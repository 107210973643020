import {
  StyledProfileAvatarContainer,
  StyledProfilePic,
  StyledProfilePicName,
} from './styles';

type Props = {
  firstName: string;
  profilePicture?: string;
};

export const ProfileAvatar: view<Props> = ({
  firstName = observe.profile.firstName,
  profilePicture,
}: Props) => (
  <StyledProfileAvatarContainer>
    <StyledProfilePic imgSrc={profilePicture}>
      {!profilePicture && (
        <StyledProfilePicName>
          <span>{firstName && (firstName.charAt(0).toUpperCase())}</span>
        </StyledProfilePicName>
      )}
    </StyledProfilePic>
  </StyledProfileAvatarContainer>
);
