import { ApiActionType, RouteName } from '../../../core/types';

export const moduleDetailsGetInitialProps: producer = ({
  trigger = observe.pages[RouteName.MODULE_DETAILS].trigger,
  params = observe.pages[RouteName.MODULE_DETAILS].params,
  updateTrigger = update.pages[RouteName.MODULE_DETAILS].trigger,
  updateIsLoading = update.pages[RouteName.MODULE_DETAILS].isLoading,
  getModuleTrigger = update.api[ApiActionType.FIND_MODULE].trigger,
  getModuleData = update.api[ApiActionType.FIND_MODULE].data,
  getProfileTrigger = update.api[ApiActionType.FIND_PROFILE].trigger,
}) => {
  if (!(trigger && params)) return;

  updateTrigger.remove();

  document.title = 'Diva - Module';
  updateIsLoading.set(true);

  getModuleData.set({ ...params });
  getModuleTrigger.set(Date.now());
  getProfileTrigger.set(Date.now());
};
