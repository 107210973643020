import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Needs, NeedsFields } from 'src/types/questionnaire/needs';

export const DressAccordingToAgeSelector: view = ({
  dressAccordingToAge = observe.profile.attributes.dressAccordingToAge,
  updateDressAccordingToAge = update.ui.profile.attributes.dressAccordingToAge,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'dressAccordingToAge';
  const label = translate('profile.questionnaire.needs.dressAccordingToAge.label');
  const choices = Needs[NeedsFields.DRESS_ACCORDING_TO_AGE].map((reason) => {
    return {
      label: translate(`profile.questionnaire.needs.${NeedsFields.DRESS_ACCORDING_TO_AGE}.options.${reason}`),
      value: reason,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateDressAccordingToAge.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={dressAccordingToAge ? [dressAccordingToAge] : []} onChange={handleChoice} />
    </div>
  );
};
DressAccordingToAgeSelector.displayName = 'DressAccordingToAgeSelector';
