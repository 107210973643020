import { ContentList, CreateVideoPayload, Video } from '../../../core/domain';
import { ApiActionType, resultFormatter } from '../../../core/types';
import { PersonalizedContentHttpApiService } from '../../../services';
const { enablePersonalizedContent } = window.env.flags;

export const personalizedVideosAction: producer = async ({
  trigger = observe.api[ApiActionType.GET_PERSONALIZED_CONTENT].trigger,
  updateTrigger = update.api[ApiActionType.GET_PERSONALIZED_CONTENT].trigger,
  updateIsLoading = update.api[ApiActionType.GET_PERSONALIZED_CONTENT]
    .isLoading,
  updateResult = update.api[ApiActionType.GET_PERSONALIZED_CONTENT].result,
  personalizedContentResult = update.api[ApiActionType.GET_PERSONALIZED_CONTENT]
    .result,
  PersonalizedContentApiService = PersonalizedContentHttpApiService,
}) => {
  if (!trigger || !enablePersonalizedContent) return;

  updateTrigger.remove();
  updateIsLoading.set(true);

  try {
    const personalContentData = (
      await PersonalizedContentApiService.getPersonalizedContent()
    ).map((payload: CreateVideoPayload) => Video.create(payload));
    const personalVideosList: ContentList<Video> = {
      data: personalContentData,
      meta: {
        totalCount: personalContentData.length,
        cursor: '',
        hasMoreItems: false,
      },
    };
    personalizedContentResult.set(resultFormatter.ok(personalVideosList));

    updateResult.set(resultFormatter.ok(personalVideosList));
  } catch (e: unknown) {
    updateResult.set(resultFormatter.error((e as Error).message));
    updateIsLoading.set(false);
  } finally {
    updateIsLoading.set(false);
  }
};
