export const GetSavedVideos: producer = async ({
  setIsSaved = update.isSaved,
  getProfile = observe.profile,
}) => {
  if (!getProfile) return;

  const savedVideos: any = getProfile.savedVideos;

  const formatSaved: { savedVideos: Object } = Object.keys(
    savedVideos || {}
  ).reduce((video: any, id: any) => ({ ...video, [id]: true }), {});

  setIsSaved.set(formatSaved);
};
