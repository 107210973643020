import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledSelectWrapper = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;

  .select-input {
    font: 400 16px/24px ${theme.font.secondary};

    > div {
      padding: 4px;
      border-color: ${theme.color.gray.light};
    }

    & > div > div > div {
      border-radius: 10px;
      padding: 2px 8px;
    }

    & div > div > div > div:first-child {
      padding: 0;
    }

    & div > div > div > div:last-child {
      padding-right: 0;
    }
  }

  .error {
    border: 1px solid ${theme.color.red.primary};

    & > div {
      border: 0;
    }
  }
`;

export const StyledSelectLabel = styled.label`
  color: ${theme.color.black};
  font: 500 18px ${theme.font.secondary};
  text-align: left;
  margin-bottom: 8px;
`;
