import { RouteName } from '../../../core/types';

export const moduleDetailsDidMount: producer = ({
  isLoading = observe.pages[RouteName.MODULE_DETAILS].isLoading,
  module = observe.module,
  profile = observe.profile,
  updateIsLoading = update.pages[RouteName.MODULE_DETAILS].isLoading,
  updateIsEditMode = update.utils.components.moduleDetails.isEditMode,
}) => {
  if (!(isLoading && module && profile)) return;

  updateIsEditMode.set(false);
  updateIsLoading.set(false);
};
