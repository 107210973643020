import React from 'react';

import {
  StyledErrorMessage,
  StyledHelperMessage,
  StyledSuccessMessage,
} from './styles';

type Props = {
  children: React.ReactNode;
};

export const ErrorMessage = ({ children }: Props) => (
  <StyledErrorMessage>{children}</StyledErrorMessage>
);

export const HelperMessage = ({ children }: Props) => (
  <StyledHelperMessage>{children}</StyledHelperMessage>
);

export const SuccessMessage = ({ children }: Props) => (
  <StyledSuccessMessage>{children}</StyledSuccessMessage>
);
