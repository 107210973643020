export const ProfileIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.22255 18.1493C4.83087 16.7161 6.25117 15.7109 7.90625 15.7109H13.9062C15.5613 15.7109 16.9816 16.7161 17.59 18.1493M14.9063 8.21094C14.9063 10.4201 13.1154 12.2109 10.9063 12.2109C8.69711 12.2109 6.90625 10.4201 6.90625 8.21094C6.90625 6.0018 8.69711 4.21094 10.9063 4.21094C13.1154 4.21094 14.9063 6.0018 14.9063 8.21094ZM20.9063 10.7109C20.9063 16.2338 16.4291 20.7109 10.9063 20.7109C5.3834 20.7109 0.90625 16.2338 0.90625 10.7109C0.90625 5.18809 5.3834 0.710938 10.9063 0.710938C16.4291 0.710938 20.9063 5.18809 20.9063 10.7109Z"
      stroke="#897180"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
