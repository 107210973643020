import { buildStyles } from 'react-circular-progressbar';
import styled from 'styled-components';

import { theme } from '../../../globalStyle';
import { GiTransparentSlime } from 'react-icons/gi';

export const defaultStyles = (percentage: number) =>
  buildStyles({
    strokeLinecap: 'round',
    pathColor: `#f08e7ceb`,
    textColor: "currentColor",
    textSize: '22px',
  });


export const StyledCircularProgressBar = styled.div`
  display: flex;
  font-family: ${theme.font.secondary};
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  background-color: #F4EEEB;
  border-radius: 60px;

  svg {
    width: 100%;
    text {
      text-anchor: middle;
      dominant-baseline: middle;
      font-weight: 700;
    }
  }
`;
