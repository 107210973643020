import { Slide } from 'src/newDesign/Onboarding/Slide';
import { I18nTranslationsService } from 'src/services';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import bodyIcon from '../../../../assets/jtdiva/q-body.png';
import { HeightSelector } from 'src/profileQuestionnaire/baseProfile/fields/HeightSelector';
import { BodyShapeSelector } from 'src/profileQuestionnaire/baseProfile/fields/BodyShapeSelector';
import { BraSizeChestSelector } from 'src/profileQuestionnaire/baseProfile/fields/BraSizeChestSelector';
import { BraSizeCupSelector } from 'src/profileQuestionnaire/baseProfile/fields/BraSizeCupSelector';
import styles from '../styles.module.css';
import { useState } from 'react';

export const DescribeYourBody: view = ({ hideBackButton, translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.base_profile.describe_body')} hideBackButton={hideBackButton}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.base_profile.body')}
        cardDescription={translate('profile.questionnaire.base_profile.body_description')}
        img={bodyIcon}
      >
        <HeightSelector />
        <BodyShapeSelector />
        <BraSizeCupSelector />
        <BraSizeChestSelector />
      </QuestionCard>
    </Slide>
  );
};
