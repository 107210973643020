import styled from 'styled-components';
import { theme } from '../../globalStyle';

export const SpeedDialIcon = styled.div`
  background: #d3887d;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  align-content: center;
  justify-content: center;
`;
