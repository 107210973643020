import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { BaseProfile, BaseProfileFields } from 'src/types/questionnaire/baseProfile';

export const EyesColorSelector: view = ({
  eyesColor = observe.profile.attributes.eyesColor,
  updateEyesColor = update.ui.profile.attributes.eyesColor,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'eyesColor';
  const label = translate('profile.questionnaire.base_profile.eyesColor.label');
  const choices = BaseProfile[BaseProfileFields.EYES_COLOR].map((color) => {
    return {
      label: translate(`profile.questionnaire.base_profile.${BaseProfileFields.EYES_COLOR}.options.${color}`),
      value: color,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateEyesColor.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={eyesColor ? [eyesColor] : []} onChange={handleChoice} />
    </div>
  );
};
EyesColorSelector.displayName = 'EyesColorSelector';
