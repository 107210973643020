export enum BaseProfileFields {
  HEIGHT = 'height',
  BODY_SHAPE = 'bodyShape',
  BRA_SIZE_CUP = 'braSizeCup',
  BRA_SIZE_CHEST = 'braSizeChest',
  FACE_SHAPE = 'faceShape',
  HAIR_DESCRIPTION = 'hairDescription',
  EYES_COLOR = 'eyesColor',
  EYES_SHAPE = 'eyesShape',
  SKIN_TYPE = 'skinType',
  SKIN_CHARACTERISTICS = 'skinCharacteristics',
  AREA_OF_INTEREST = 'areaOfInterest'
}

export const BaseProfile = {
  [BaseProfileFields.AREA_OF_INTEREST]: ['hair', 'makeup', 'clothing'],
  [BaseProfileFields.HEIGHT]: [],
  [BaseProfileFields.BODY_SHAPE]: [
    'athletic',
    'not-fit',
    'petite',
    'slim',
    'full',
    'powerful',
    'hourglass-shape',
    'more-square-in-shape',
    // 'pear-shape',
  ],
  [BaseProfileFields.BRA_SIZE_CUP]: [],
  [BaseProfileFields.BRA_SIZE_CHEST]: [],
  [BaseProfileFields.FACE_SHAPE]: ['round', 'square', 'pyramid', 'oval', 'drop-shaped', 'heart-shaped', 'narrow', 'other'],
  [BaseProfileFields.HAIR_DESCRIPTION]: ['curly', 'worn-over-treated', 'colored', 'coarse-frizzy', 'nordic', 'healthy', 'undyed'],
  [BaseProfileFields.EYES_SHAPE]: ['big', 'narrower', 'heavy-eyelids', 'close-together', 'far-apart', 'no-specific-characteristics'],
  [BaseProfileFields.EYES_COLOR]: [
    'blue',
    'dark-brown',
    'lighter-brown',
    'brown-green',
    'green',
    'teal',
    'gray',
    'gray-blue',
    'gray-green',
    'black',
  ],
  [BaseProfileFields.SKIN_TYPE]: ['dry', 'normal', 'oily', 'combination', 'unsure'],
  [BaseProfileFields.SKIN_CHARACTERISTICS]: [
    'smooth',
    'characterized-by-scars',
    'large-pores',
    'impurities',
    'acne',
    'soft',
    'wrinkles-or-lines',
    // 'a-little-pores',
  ],
}

// export interface BaseProfile {
//   [BaseProfileFields.HEIGHT]: number
// }
