import { AuthActionType, AuthFields, User } from '../../../core/domain';
import { resultFormatter } from '../../../core/types';
import { CognitoAuthService } from '../../../services';

export const signInAction: producer = async ({
  trigger = observe.auth[AuthActionType.SIGN_IN].trigger,
  updateTrigger = update.auth[AuthActionType.SIGN_IN].trigger,
  updateIsLoading = update.auth[AuthActionType.SIGN_IN].isLoading,
  updateResult = update.auth[AuthActionType.SIGN_IN].result,
  username = get.auth[AuthActionType.SIGN_IN].fields[AuthFields.EMAIL],
  password = get.auth[AuthActionType.SIGN_IN].fields[AuthFields.PASSWORD],
  AuthService = CognitoAuthService,
}) => {
  if (!trigger) return;

  updateTrigger.remove();
  updateIsLoading.set(true);

  try {
    const user: User = await AuthService.signIn({
      username: username.value(),
      password: password.value(),
    });

    updateResult.set(resultFormatter.ok(user));
  } catch (e: unknown) {
    updateResult.set(resultFormatter.error((e as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
