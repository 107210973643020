import { AuthActionType, AuthEvents, AuthFields } from '../../../core/domain';
import { resultFormatter } from '../../../core/types';
import { AuthErrors } from '../../../core/errors';
import { CognitoAuthService } from '../../../services';

export const confirmAccountAction: producer = async ({
  trigger = observe.auth[AuthActionType.CONFIRM_ACCOUNT].trigger,
  updateTrigger = update.auth[AuthActionType.CONFIRM_ACCOUNT].trigger,
  updateIsLoading = update.auth[AuthActionType.CONFIRM_ACCOUNT].isLoading,
  updateResult = update.auth[AuthActionType.CONFIRM_ACCOUNT].result,
  verificationCode = get.auth[AuthActionType.CONFIRM_ACCOUNT].fields[
    AuthFields.VERIFICATION_CODE
  ],
  username = get.auth[AuthActionType.SIGN_UP].fields[AuthFields.EMAIL],
  AuthService = CognitoAuthService,
}) => {
  if (!trigger) return;

  updateTrigger.remove();

  if (!verificationCode.value()) {
    updateResult.set(
      resultFormatter.error(AuthErrors.VERIFICATION_CODE_IS_EMPTY)
    );
    return;
  }

  updateIsLoading.set(true);

  try {
    const usernameValue = username.value() || localStorage.getItem('email');

    const response: string = await AuthService.confirmAccount({
      username: usernameValue,
      verificationCode: verificationCode.value(),
    });

    if (response === 'SUCCESS') {
      updateResult.set(
        resultFormatter.ok(response, AuthEvents.ACCOUNT_CONFIRMED_SUCCESS)
      );
    } else {
      updateResult.set(
        resultFormatter.error(AuthEvents.ACCOUNT_CONFIRMED_ERROR)
      );
    }
  } catch (error: unknown) {
    updateResult.set(resultFormatter.error((error as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
