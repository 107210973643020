import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';

import {
  StyledOnboardingScreenAsset,
  StyledOnboardingScreenContainer,
  StyledOnboardingScreenDescription,
  StyledOnboardingScreenTitle,
} from './styles';

type Props = LanguageProps & {
  translationSlug: string;
  asset?: string;
  description?: string;
};

export const OnboardingScreen: view<Props> = ({
  asset,
  description,
  translationSlug,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => (
  <StyledOnboardingScreenContainer lang={language}>
    {asset && <StyledOnboardingScreenAsset imgSrc={asset} />}
    <StyledOnboardingScreenTitle>
      {translate(`onboarding_page.${translationSlug}.title`)}
    </StyledOnboardingScreenTitle>
    {description && (
      <StyledOnboardingScreenDescription>
        {translate(`onboarding_page.${translationSlug}.description`)}
      </StyledOnboardingScreenDescription>
    )}
  </StyledOnboardingScreenContainer>
);
