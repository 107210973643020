import { FieldLabel } from 'src/generic_components/FieldLabel';
import { InputField } from 'src/generic_components/InputField';
import { Slider } from 'src/generic_components/Slider';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { BaseProfile, BaseProfileFields } from 'src/types/questionnaire/baseProfile';

export const BraSizeCupSelector: view = ({
  braSizeCup = observe.profile.attributes.braSizeCup,
  updateBraSizeCup = update.ui.profile.attributes.braSizeCup,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'braSizeCup';
  const label = translate('profile.questionnaire.base_profile.braSizeCup');
  const cupSize = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'];
  const min = 1,
    max = 14;

  const formatBraSizeCup = (value: number) => {
    return cupSize[value - 1];
  };

  const unformatBraSizeCup = (cup: string) => {
    return cupSize.indexOf(cup) + 1;
  };

  const handleChangeCommitted = (event: any, value: number) => {
    updateBraSizeCup.set(formatBraSizeCup(value));
  };

  // if (!braSizeCup) {
  //   updateBraSizeCup.set(formatBraSizeCup(min));
  //   braSizeCup = formatBraSizeCup(min);
  // }

  // const valueVerification = (value: string) => {
  //   let newValue = value;

  //   if (!cupSize.includes(value)) newValue = cupSize[min - 1]

  //   return unformatBraSizeCup(newValue);
  // };


  const valueVerification = (value: string) => {
    const valueUpperCase = value.toUpperCase();
    let newValue = valueUpperCase

    if (!cupSize.includes(valueUpperCase)) newValue = cupSize[min - 1]

    return newValue;
  };

  const onSubmitInput = (value: any) => {
    updateBraSizeCup.set(value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      {/* <Slider
        attribute={BaseProfileFields.BRA_SIZE_CUP}
        onChangeCommitted={handleChangeCommitted}
        min={min}
        max={max}
        step={1}
        showValue={true}
        value={braSizeCup}
        formatValue={formatBraSizeCup}
        unformatValue={unformatBraSizeCup}
        valueVerification={valueVerification}
      /> */}
      <InputField value={braSizeCup} onSubmitInput={onSubmitInput} valueVerification={valueVerification} placeholder={cupSize[0]}/>
    </div>
  );
};
BraSizeCupSelector.displayName = 'BraSizeCupSelector';
