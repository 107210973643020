import { AuthActionType, AuthFields } from '../../../core/domain';
import { Routes } from '../../../core/types';
import { CognitoAuthService, SweetAlertService } from '../../../services';
import { ErrorLoggerService } from '../../../services/ErrorLoggerService';

export const confirmAccountSuccessAction: producer = async ({
  history,
  result = observe.auth[AuthActionType.CONFIRM_ACCOUNT].result,
  updateResult = update.auth[AuthActionType.CONFIRM_ACCOUNT].result,
  username = get.auth[AuthActionType.SIGN_UP].fields[AuthFields.EMAIL],
  password = get.auth[AuthActionType.SIGN_UP].fields[AuthFields.PASSWORD],
  authModalState = update.utils.components.authModal.state,
  AlertService = SweetAlertService,
  AuthService = CognitoAuthService,
}) => {
  if (!(result && result.isOk)) return;

  await AlertService.success('Account confirmed successfully');

  const usernameValue = username.value();
  const passwordValue = password.value();

  if (!usernameValue || !passwordValue) {
    authModalState.set({
      isOpen: true,
      isSignInMounted: true,
    });
    return;
  }

  try {
    await AuthService.signIn({
      username: usernameValue,
      password: passwordValue,
    });

    history.replace(Routes.ONBOARDING);
    updateResult.remove();
  } catch (e: unknown) {
    ErrorLoggerService.logError(result.error);

    await AlertService.error((e as Error).message);
  }
};
