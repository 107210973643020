import styled from 'styled-components';
import { theme } from '../../../globalStyle';
import { StyledButton } from '../../elements/Button/styles';

export const StyledModuleWizard = styled.div`
  display: flex;
  flex-flow: column;
`;

export const StyledModuleWizardNavigationItem = styled.div<{
  disabled?: boolean;
}>`
  font: 400 22px ${theme.font.secondary};
  display: flex;
  align-items: center;
  color: ${theme.color.dark};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: ${theme.color.gray.disabled};
  }

  .nav-icon {
    font-size: 42px;

    @media ${theme.queries.tablet} {
      font-size: 28px;
    }
  }

  span:not(.nav-icon) {
    margin-top: -12px;
  }
`;

export const StyledModuleWizardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 48px;
  margin: 0 auto;
  width: 100%;

  ${StyledModuleWizardNavigationItem}:last-child > ${StyledButton} {
    padding: 8px 16px;
    background-color: ${theme.color.black};
    border: 1px solid ${theme.color.black};
    color: ${theme.color.white};

    &:hover {
      color: ${theme.color.black};
      background-color: transparent;
    }
  }
`;

export const StyledModuleProgress = styled.div`
  max-width: 525px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledModuleName = styled.h3`
  font: 500 36px ${theme.font.secondary};
  color: ${theme.color.black};
  align-self: center;
  white-space: nowrap;

  @media ${theme.queries.tablet} {
    font-size: 28px;
  }
`;

export const StyledModuleWizardBody = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 48px 0 24px;
  width: 100%;
`;

export const StyledModuleWizardFooter = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  ${StyledModuleWizardNavigationItem} > span:last-child {
    font: 300 18px/22px ${theme.font.secondary};
    color: ${theme.color.gray.dark};
    border-bottom: 1px solid ${theme.color.black};
    margin-top: 16px;
  }
`;

export const StyledModuleWizardFeedback = styled.div``;
