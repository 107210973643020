import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledOnboardingScreenContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  max-width: 720px;
  align-self: center;
`;

export const StyledOnboardingScreenAsset = styled.div<{ imgSrc: string }>`
  background: url(${(props) => props.imgSrc}) no-repeat center center;
  background-size: contain;
  width: 100%;
  aspect-ratio: 16 / 9;
  max-width: 620px;
  align-self: center;

  @media ${theme.queries.mobile} {
    aspect-ratio: 4 / 3;
  }
`;

export const StyledOnboardingScreenTitle = styled.h4`
  ${theme.label.l3};
  margin: 40px auto 10px;
`;

export const StyledOnboardingScreenDescription = styled.p`
  font: 400 16px ${theme.font.secondary};
  text-align: center;
`;
