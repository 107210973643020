import styled from 'styled-components';

export const StyledOnboardingForm = styled.form`
  display: flex;
  width: 100%;
  flex-flow: column;
  max-width: 768px;

  button {
    margin: 16px auto;
  }
`;

export const OnboardingFormField = styled.div`
  padding: 8px 0;

  .label-onboarding {
    label {
      font-size: 16px;
    }
  }
`;
