import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledVideoDetailsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  .video-details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 6px 0 24px;
  }

  .video-category {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  @media ${theme.queries.tablet} {
    .video-category {
      justify-content: space-between;
    }
  }
`;

export const StyledVideoPlayerContainer = styled.div`
 
`;

export const StyledVideoMetadataContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media ${theme.queries.tablet} {
    flex-flow: column;
    align-items: flex-start;
  }
`;

const MetadataWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const StyledVideoInfoWrapper = styled(MetadataWrapper)`
  align-items: flex-start;
`;

export const StyledVideoTagsWrapper = styled(MetadataWrapper)`
  align-items: flex-end;
  h6 {
    font: 400 16px/24px ${theme.font.secondary};
    margin-bottom: 8px;
  }

  div {
    display: flex;
    flex-flow: row wrap;
  }

  div > span {
    font: 400 14px/16px ${theme.font.secondary};
    background: ${theme.color.gray.light};
    color: ${theme.color.black};
    border-radius: 12px;
    padding: 6px;
  }

  div > span:not(:last-child) {
    margin-right: 8px;
  }

  @media ${theme.queries.tablet} {
    align-items: flex-start;
  }
`;

export const StyledVideoInfo = styled.div`
  display: flex;
  font: 400 13px/24px ${theme.font.secondary};
  color: ${theme.color.gray.medium};
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledVideoCategoriesContainer = styled.div`
  display: flex;
  align-items: center;
  font: 400 14px/24px ${theme.font.secondary};
  color: ${theme.color.red['primary-hover']};
  text-transform: uppercase;
  margin-right: 16px;

  span:not(:last-child) {
    margin-right: 8px;
  }
`;

export const StyledVideoDescription = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
`;
