import {
  FindArticleQuery,
  FindArticleQueryResult,
  FindVideoQuery,
  FindVideoQueryResult,
  SearchArticlesQuery,
  SearchArticlesQueryResult,
  SearchContentRequest,
  SearchVideosQuery,
  SearchVideosQueryResult,
} from '../core/domain';

import { ContentApiService } from '../core/services';
import { AxiosService } from './AxiosService';

const { cmsApiKey, cmsBaseUrl } = window.env.cms;

const createService = (): ContentApiService => {
  const axiosInstance = AxiosService({
    baseURL: `https://${cmsBaseUrl}/cms`,
    headers: {
      Authorization: `Bearer ${cmsApiKey}`,
    },
  });

  return {
    async findArticle(id: string): Promise<FindArticleQueryResult> {
      return axiosInstance.post<{ query: string }, FindArticleQueryResult>(
        '/read/nb-NO',
        { query: FindArticleQuery(id) }
      );
    },

    async findVideo(id: string): Promise<FindVideoQueryResult> {
      return axiosInstance.post<{ query: string }, FindVideoQueryResult>(
        '/read/nb-NO',
        {
          query: FindVideoQuery(id),
        }
      );
    },

    async searchArticles(
      request: SearchContentRequest
    ): Promise<SearchArticlesQueryResult> {
      return axiosInstance.post<{ query: string }, SearchArticlesQueryResult>(
        '/read/nb-NO',
        {
          query: SearchArticlesQuery(request),
        }
      );
    },

    async searchVideos(
      request: SearchContentRequest
    ): Promise<SearchVideosQueryResult> {
      return axiosInstance.post<{ query: string }, SearchVideosQueryResult>(
        '/read/nb-NO',
        {
          query: SearchVideosQuery(request),
        }
      );
    },
  };
};

export const ContentHttpApiService = Object.freeze(createService());
