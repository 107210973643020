export enum RouteName {
  LANDING = 'LANDING',
  PROFILE = 'PROFILE',
  NEW_PROFILE = 'NEW_PROFILE',
  FEED = 'FEED',
  VIDEO_DETAILS = 'VIDEO_DETAILS',
  ONBOARDING = 'ONBOARDING',
  NEW_ONBOARDING = 'NEW_ONBOARDING',
  DASHBOARD = 'DASHBOARD',
  MODULE_DETAILS = 'MODULE_DETAILS',
  MEDIA_LIBRARY = 'MEDIA_LIBRARY',
  CONCEPT = 'CONCEPT',
  POST_SIGN_IN = 'POST_SIGN_IN',
  NEW_FEED = "NEW_FEED"
}

export const Routes: Record<RouteName, string> = {
  [RouteName.LANDING]: '/',
  [RouteName.POST_SIGN_IN]: '/postSignIn',
  [RouteName.PROFILE]: '/profile',
  [RouteName.FEED]: '/feed',
  [RouteName.NEW_FEED]: '/newFeed',
  [RouteName.NEW_PROFILE]: '/newProfile',
  [RouteName.VIDEO_DETAILS]: '/videos/:id',
  [RouteName.ONBOARDING]: '/onboarding',
  [RouteName.NEW_ONBOARDING]: '/newOnboarding',
  [RouteName.DASHBOARD]: '/dashboard',
  [RouteName.MODULE_DETAILS]: '/modules/:slug',
  [RouteName.MEDIA_LIBRARY]: '/media-library',
  [RouteName.CONCEPT]: '/concept',
};
