import { Module } from '../core/domain';
import { ModulesApiService } from '../core/services';
import { modules } from '../mocks/modules';

export const createService = (): ModulesApiService => ({
  findModule(slug: string): Promise<Module> {
    const foundModule = modules.find((module) => module.slug === slug);

    if (!foundModule) {
      throw new Error('Module not found');
    }

    return Promise.resolve(foundModule);
  },

  getModules(): Promise<Module[]> {
    return Promise.resolve(modules);
  },
});

export const ModulesHttpApiService = Object.freeze(createService());
