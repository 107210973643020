import { I18nTranslationsService } from '../../../services';
import { Profile } from '../../../core/domain';
import { ApiActionType, Routes } from '../../../core/types';
import { SweetAlertService } from '../../../services';

export const updateProfileModuleSuccessAction: producer = async ({
  translate = I18nTranslationsService.translate,
  history,
  request = get.api[ApiActionType.UPDATE_PROFILE_MODULE].data.request,
  profile = get.profile,
  result = observe.api[ApiActionType.UPDATE_PROFILE_MODULE].result,
  updateResult = update.api[ApiActionType.UPDATE_PROFILE_MODULE].result,
  updateProfile = update.profile,
  AlertService = SweetAlertService,
}) => {
  if (!(result && result.isOk)) return;

  const moduleName = request.value().name;
  const profileUpdated: Profile = result.value;

  if (
    profileUpdated.modules &&
    profileUpdated.modules[moduleName] &&
    profileUpdated.modules[moduleName].progress === 100 &&
    profile.value().modules[moduleName].progress !== 100
  ) {
    await AlertService.fire({
      icon: 'success',
      title: 'Congratulations',
      text: `You successfully completed ${moduleName} module`,
    });
  } else {
    await AlertService.success(translate('confirmationModal.message'));
  }

  // updateProfile.set(profile);
  updateResult.remove();

  history.push(Routes.DASHBOARD);
};
