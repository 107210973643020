import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { AuthService } from '../../../core/services';
import { RouteName, Routes } from '../../../core/types';
import { CognitoAuthService } from '../../../services';

export const onboardingDidMount: producer = async ({
  history,
  isLoading = observe.pages[RouteName.ONBOARDING].isLoading,
  profile = observe.profile,
  updateIsLoading = update.pages[RouteName.ONBOARDING].isLoading,
  authService = CognitoAuthService,
}) => {
  // if (
  //   /already.found.an.entry.for.username.google/gi.test(history.location.search)
  // ) {
  //   await authService.socialSignIn({
  //     provider: CognitoHostedUIIdentityProvider.Google,
  //   });
  //   return;
  // }
  // if (
  //   /already.found.an.entry.for.username.facebook/gi.test(
  //     history.location.search
  //   )
  // ) {
  //   await authService.socialSignIn({
  //     provider: CognitoHostedUIIdentityProvider.Facebook,
  //   });
  //   return;
  // }
  if (/already.found.an.entry.for.username/gi.test(history.location.search)) {
    const provider = localStorage.getItem('lastSignedUpProvider');
    await authService.socialSignIn({
      provider: provider,
    });
  }

  if (!(isLoading && profile)) return;

  updateIsLoading.set(false);

  const { lastName, dateOfBirth, gender, description } = profile;

  if ([lastName, dateOfBirth, gender, description].every(Boolean)) {
    history.replace(Routes.PROFILE);
  }
};
