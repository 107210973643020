import { Video } from '../../../core/domain';
import { ApiActionType, RouteName } from '../../../core/types';
import { landingPageMock } from '../../../mocks';

export const landingGetInitialProps: producer = ({
  trigger = observe.pages[RouteName.LANDING].trigger,
  updateTrigger = update.pages[RouteName.LANDING].trigger,
  updateIsLoading = update.pages[RouteName.LANDING].isLoading,
  // updateVideoState = update.videoState,
  // updateVideoId = update.api[ApiActionType.FIND_VIDEO].data.id,
  // findVideoTrigger = update.api[ApiActionType.FIND_VIDEO].trigger,
}) => {
  if (!trigger) return;

  updateTrigger.remove();

  document.title = 'Diva';
  updateIsLoading.set(true);

  // updateVideoState.set({
  //   isLoading: false,
  //   isLoaded: true,
  //   isStarted: false,
  //   showMobileVideoCover: true,
  //   source: Video.create({
  //     video: landingPageMock.conceptVideoUrl,
  //     categories: [{ name: 'Landing Page Video' }],
  //     createdOn: '',
  //     entryId: '',
  //     name: '',
  //     tags: [{ name: 'Landing Page Video' }],
  //     image:
  //       'https://fsh-assets.s3.eu-north-1.amazonaws.com/conceptVideoPoster.jpeg',
  //   }),
  // });

  // updateVideoId.set(landingPageMock.conceptVideoId);
  // findVideoTrigger.set(Date.now());
};
