import styled from 'styled-components';
import { theme } from '../../globalStyle';

export const StyledIcon = styled.span`
  width: 30px;
  height: 30px;

  @media ${theme.queries.tablet} {
    width: 25px;
    height: 25px;
  }

  svg {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }

  .st0 {
    fill: #fefefe;
  }
  .st1 {
    fill: #c8102e;
  }
  .st2 {
    fill: #012169;
  }
`;
