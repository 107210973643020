import { ContentList, Video } from '../../../core/domain';
import { ApiActionType } from '../../../core/types';

export const searchVideosSuccessAction: producer = ({
  result = observe.api[ApiActionType.SEARCH_VIDEOS].result,
  updateResult = update.api[ApiActionType.SEARCH_VIDEOS].result,
  updateVideos = update.videos,
}) => {
  if (!(result && result.isOk)) return;

  const videos: ContentList<Video> = result.value;

  updateVideos.set(videos);
  updateResult.remove();
};
