import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledProfileModule = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${theme.queries.mobile} {
    width: 40px;
    justify-content: space-between;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 30px;
    width: 85%;

      
    @media ${theme.queries.mobile} {
      margin-left: 20px;
    }
  }

  & > div span div {
    width: 50px;
    margin-right: 30px;

    @media ${theme.queries.mobile} {
      width: 40px;
    }
  }

  & > div span {
    font: 500 16px/24px ${theme.font.secondary};
    white-space: nowrap;

    @media ${theme.queries.mobile} {
      font-size: 14px;
      font-weight: 600;
    }
  }

  a:last-child {
    font: 500 16px/24px ${theme.font.secondary};
    color: ${theme.color.black};
    cursor: pointer;

    &:hover {
      color: ${theme.color.red['primary-hover']};
    }

    @media ${theme.queries.mobile} {
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

export const StyledProfileModulesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;

  ${StyledProfileModule} {
    padding: 14px 30px;
    border: 1px solid ${theme.color.gray.light};
    background-color: ${theme.color.gray.lighter};
    border-radius: 100px;
    width: 100%;

    &:hover {
      background-color: ${theme.color.gray.lighter};
      border-color: ${theme.color.gray.light};
      box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    @media ${theme.queries.mobile} {
      padding: 14px 20px;
    }
  }
`;

export const StyledProfileModuleNotStarted = styled.p`
  font: 400 18px/24px ${theme.font.secondary};
  color: ${theme.color.gray.dark};

  & > a {
    text-decoration: underline ${theme.color.black};
    cursor: pointer;
    color: ${theme.color.gray.dark};
    font-style: italic;
    font-weight: 500;

    &:hover {
      color: ${theme.color.red.primary};
      text-decoration: underline ${theme.color.red.primary};
    }
  }
`;
