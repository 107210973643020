import styles from './styles.module.css'
import { Button } from './Button'
import logo from '../../assets/jtdiva/logo-v3.svg'
import { ProgressBar } from './ProgressBar'
import { VisibleIf } from 'src/generic_components/VisibileIf'
import { onboardingFlows } from 'src/types/onboarding'
import { useHistory } from 'react-router-dom'
import { Routes } from 'src/core/types'
import { LanguageSelector } from 'src/components'
import { I18nTranslationsService } from 'src/services'

export const OnboardingHeader: view = ({
  activeFlow = observe.ui.onboarding.activeFlow,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}) => {
  const history = useHistory()
  return (
    <div className={styles['onboarding-header'] + ' ' + styles['welcome']} lang={language}>
      <img src={logo}/>
      <ProgressBar className={styles['progress']}/>
      <VisibleIf predicate={activeFlow && activeFlow !== onboardingFlows.GREETING}>
        <div className={styles['skip-container']}>
          <Button
            variant="ghost"
            onClick={() => {
              history.push(Routes.FEED)
            }}
          >
            {translate("onboarding.skip_profile")}
          </Button>
        </div>
      </VisibleIf>
      <LanguageSelector />
    </div>
  )
}