import { SearchVideosQueryResult } from '../core/domain';

import { PersonalizedContentApiService } from '../core/services';
import { AxiosService } from './AxiosService';

const createService = (): PersonalizedContentApiService => {
  const axiosInstance = AxiosService({});

  return {
    async getPersonalizedContent(): Promise<SearchVideosQueryResult> {
      return axiosInstance.get<{}, SearchVideosQueryResult>(
        '/api/feed/personalized'
      );
    },

    setAuthToken(authToken: string): void {
      axiosInstance.setAuthToken(authToken);
    },
  };
};

export const PersonalizedContentHttpApiService = Object.freeze(createService());
