import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  AuthActionType,
  AuthFields,
  PasswordConstraints,
} from '../../../core/domain';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { AuthStateProps } from '../../../state';
import { Input, Spinner } from '../../elements';

import { StyledAuthForm } from './styles';
import { Button } from 'src/generic_components/Button';

type Props = AuthStateProps &
  LanguageProps & {
    updateFirstName: Update<string>;
    updateEmail: Update<string>;
    updatePassword: Update<string>;
  };

interface SignUpFormValues {
  firstName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const initialValues: SignUpFormValues = {
  firstName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const validationSchema: Yup.SchemaOf<SignUpFormValues> = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  email: Yup.string().email().required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(PasswordConstraints.min, 'Password must be at least 6 characters')
    .matches(
      PasswordConstraints.pattern,
      'Password must contain an uppercase, a lowercase and a digit'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required'),
});

export const SignUpForm: view<Props> = ({
  isLoading = observe.auth[AuthActionType.SIGN_UP].isLoading,
  updateTrigger = update.auth[AuthActionType.SIGN_UP].trigger,
  updateFirstName = update.auth[AuthActionType.SIGN_UP].fields[
    AuthFields.FIRST_NAME
  ],
  updateEmail = update.auth[AuthActionType.SIGN_UP].fields[AuthFields.EMAIL],
  updatePassword = update.auth[AuthActionType.SIGN_UP].fields[
    AuthFields.PASSWORD
  ],
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const onSubmit = ({ firstName, email, password }: SignUpFormValues) => {
    updateFirstName.set(firstName);
    updateEmail.set(email);
    updatePassword.set(password);

    updateTrigger.set(Date.now());
  };

  const { handleSubmit, values, handleChange, errors, isValid } =
    useFormik<SignUpFormValues>({
      validationSchema,
      initialValues,
      onSubmit,
    });

  return (
    <StyledAuthForm onSubmit={handleSubmit} lang={language}>
      <Input
        name="firstName"
        label={translate('login.first_name')}
        type="text"
        value={values.firstName}
        errorMessage={errors.firstName}
        onChange={handleChange}
      />
      <Input
        name="email"
        label={translate('global.email')}
        type="email"
        value={values.email}
        errorMessage={errors.email}
        onChange={handleChange}
      />
      <Input
        name="password"
        label={translate('login.password')}
        type="password"
        value={values.password}
        errorMessage={errors.password}
        onChange={handleChange}
      />
      <Input
        name="confirmPassword"
        label={translate('login.confirm_password')}
        type="password"
        value={values.confirmPassword}
        errorMessage={errors.confirmPassword}
        onChange={handleChange}
      />
      <Button type="submit" disabled={!isValid}>
        {isLoading ? <Spinner /> : translate('login.sign_up')}
      </Button>
    </StyledAuthForm>
  );
};
