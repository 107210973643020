import React from 'react';
import ReactSelect, { components } from 'react-select';
import { FaAngleDown } from 'react-icons/fa';

import { ErrorMessage, HelperMessage } from '../Messages';

import { StyledSelectLabel, StyledSelectWrapper } from './styles';
import { theme } from '../../../globalStyle';

export type SelectOption = {
  value: string;
  label: string;
};

type SelectProps = {
  name: string;
  label: string;
  options: string[];
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  initialValue?: any;
  groupClassName?: string;
  selectClassName?: string;
  errorMessage?: string;
  helperMessage?: string;
  onChange?: (option: SelectOption) => void;
};

export const Select: view<SelectProps> = ({
  name,
  label,
  multiple,
  options,
  disabled,
  groupClassName,
  initialValue,
  selectClassName,
  placeholder,
  onChange,
  errorMessage,
  helperMessage,
}: SelectProps) => {
  const isMobile = window.innerWidth < 768;

  // eslint-disable-next-line react/no-unstable-nested-components
  const DropdownIndicator = (providedProps: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...providedProps}>
      <span>
        <FaAngleDown />
      </span>
    </components.DropdownIndicator>
  );

  const customStyles = {
    singleValue: (provided: any) => ({
      ...provided,
      maxWidth: `calc(100% - 24px)`,
      lineHeight: '24px',
    }),

    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? theme.color.yellow.input
        : theme.color.white,
      color: theme.color.black,
    }),

    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused
        ? theme.color.black
        : theme.color.gray.disabled,
      borderRadius: '12px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: state.isFocused && theme.color.black,
      },
      padding: '0 4px',
    }),

    menu: (provided: any) => ({ ...provided, zIndex: 3 }),

    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: isMobile ? '8px 12px' : '12px 20px 6px',
    }),

    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
  };

  const toOption = (element: any) => ({
    value: element,
    label: element,
  });

  const defaultValue =
    initialValue &&
    (typeof initialValue === 'string'
      ? toOption(initialValue)
      : [...initialValue.map(toOption)]);

  return (
    <StyledSelectWrapper className={`${groupClassName || ''}`}>
      {label && <StyledSelectLabel htmlFor={name}>{label}</StyledSelectLabel>}

      <ReactSelect
        classNamePrefix={selectClassName}
        styles={customStyles}
        components={{ DropdownIndicator }}
        id={name}
        isMulti={multiple}
        className={errorMessage ? 'select-input error' : 'select-input'}
        placeholder={placeholder}
        isDisabled={disabled}
        options={options.map(toOption)}
        onChange={onChange}
        defaultValue={defaultValue}
      />

      {helperMessage && !errorMessage && (
        <HelperMessage>{helperMessage}</HelperMessage>
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </StyledSelectWrapper>
  );
};
