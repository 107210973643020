import { ApiActionType } from '../../../core/types';
import { SweetAlertService } from '../../../services';
import { ErrorLoggerService } from '../../../services/ErrorLoggerService';

export const createProfileModuleErrorAction: producer = async ({
  result = observe.api[ApiActionType.CREATE_PROFILE_MODULE].result,
  updateResult = update.api[ApiActionType.CREATE_PROFILE_MODULE].result,
  updateIsEditMode = update.utils.components.moduleDetails.isEditMode,
  AlertService = SweetAlertService,
}) => {
  if (!(result && result.error)) return;

  ErrorLoggerService.logError(result.error);

  await AlertService.error(
    `There was an error starting the module. Please try again.`
  );

  updateIsEditMode.set(false);
  updateResult.remove();
};
