import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { Video } from '../../../core/domain';
import { Loader } from '../../Loader';
import { ContentCard } from '../ContentCard';
import {
  StyledContentCardLink,
  StyledContentContainer,
  StyledContentText,
} from './styles';

type Props = LanguageProps & {
  translate: any;
  items?: Video[];
  isSaved: any;
};

export const LibraryContainer: view<Props> = ({
  translate = I18nTranslationsService.translate,
  items = observe.videos.data,
  isSaved = observe.isSaved,
}: Props) => {
  if (!items || !isSaved) {
    return <Loader />;
  }

  const savedVideosCount: any = items.filter(
    ({ id }: { id: any }) => isSaved[id]
  ).length;

  return (
    <StyledContentContainer>
      {savedVideosCount ? (
        items
          .filter(({ id }: { id: any }) => isSaved[id])
          .map((item) => (
            <StyledContentCardLink key={item.id} size={'small'}>
              <ContentCard
                image={item.image}
                categories={item.categories}
                name={item.name}
                size={'small'}
                createdOn={item.createdOn}
                author={item.author}
                type="video"
                id={item.id}
                premium={item.premium}
              />
            </StyledContentCardLink>
          ))
      ) : (
        <StyledContentText>
          {translate('media_library.EmptyListMessage')}
        </StyledContentText>
      )}
    </StyledContentContainer>
  );
};
