import { Module } from '../../core/domain';
import { InputType, SelectType } from '../../core/types';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import heroImage from '../../../assets/illustrations/information-hero.svg';

export const informationModule: Module = {
  name: 'Information',
  slug: 'information',
  description:
    'The extra sprinkle on the top to improve the advice. Let Jan really understand you.',
  timeToComplete: '14 min',
  heroImage,
  questions: [
    {
      input: 'About you',
      type: SelectType.MULTISELECT,
      required: true,
      description: 'What words best describe who you are today?',
      answers: [
        {
          label: 'Happy',
        },
        {
          label: 'Stressed',
        },
        {
          label: 'Thoughtful',
        },
        {
          label: 'Kind',
        },
        {
          label: 'Caring',
        },
        {
          label: 'Downhearted',
        },
        {
          label: 'Stubborn',
        },
        {
          label: 'Derement',
        },
        {
          label: 'Shy & Outgoing',
        },
        {
          label: 'Lazy',
        },
        {
          label: 'Active',
        },
        {
          label: 'Dutiful',
        },
        {
          label: 'Irresponsabile',
        },
        {
          label: 'Insecure',
        },
        {
          label: 'Confident',
        },
      ],
    },
    {
      input: 'Affected by illness',
      type: InputType.RADIO,
      required: true,
      description:
        'Has your life been affected by illness (physical or mental)?',
      answers: [
        {
          label: 'Yes',
        },
        {
          label: 'No',
        },
      ],
    },
    {
      input: 'Stand out or dazzle',
      type: InputType.RADIO,
      required: true,
      description: 'Did you like to stand out or dazzle?',
      answers: [
        {
          label: 'Stand out',
        },
        {
          label: 'Dazzle',
        },
        {
          label: 'Depends on the setting',
        },
      ],
    },
    {
      input: 'Disease changed your perception',
      type: InputType.RADIO,
      required: true,
      description: 'Has the disease changed the way you look at yourself?',
      answers: [
        {
          label: 'Yes, in a positive way',
        },
        {
          label: 'No, in a positive way',
        },
        {
          label: 'Yes, in a negative way',
        },
        {
          label: 'No, in a negative way',
        },
        {
          label: 'I am not sure',
        },
      ],
    },
    {
      input: 'Most happy about appearance',
      type: SelectType.MULTISELECT,
      required: true,
      description: 'What are you most happy with about your own appearance?',
      answers: [
        {
          label: 'Face',
        },
        {
          label: 'Hair',
        },
        {
          label: 'Breasts',
        },
        {
          label: 'Booty',
        },
        {
          label: 'Hips',
        },
        {
          label: 'Thighs',
        },
        {
          label: 'Calves',
        },
        {
          label: 'Arms',
        },
        {
          label: 'Shoulders',
        },
        {
          label: 'Back',
        },
        {
          label: 'Waist',
        },
      ],
    },
  ],
};
