import { LanguageProps } from '../../../core/types'

import { SelectableArea } from './styles'

import { Save, Saved, Unsave, Like, Liked } from './actionButtons'

type Props = LanguageProps & {
  name: string;
  id: string;
  translate: any;
  isSaved: any;
  likedVideos: any;
};

export const VideoActionBar: view<Props> = ({
  name,
  id,
  isSaved = observe.isSaved[arg.id],
  likedVideos = observe.likedVideos
}: Props) => {
  const pages = ['feed', 'media-library', 'details']
  const currentLocation: string = window.location.href.split('/').pop() || ''
  const location: any = pages.includes(currentLocation) ? window.location.href.split('/').pop() : 'details'

  const actionType: any = {
    feed: {
      component: (isSaved: boolean, isLiked: boolean = false) => (
        <>
          {/*<div className="save-comp">{isSaved ? <Saved/> : <Save name={name} id={id}/>}</div>*/}
          <div className="save-comp"><Save name={name} id={id}/></div>
          {/*{isLiked ? <Liked id={id}/> : <Like id={id}/>}*/}
          <Like id={id}/>
        </>
      ),
    },
    'media-library': {
      component: () => <Unsave id={id}/>,
    },
    details: {
      component: (isSaved: boolean = false, isLiked: boolean = false) => (
        <>
          {/*<div>{isSaved ? <Saved id={id}/> : <Save name={name} id={id}/>}</div>*/}
          <div><Save name={name} id={id}/></div>
          {/*{isLiked ? <Liked id={id}/> : <Like id={id}/>}*/}
          <Like id={id}/>
        </>
      ),
    },
  }

  return (
    <>
      <SelectableArea>{actionType[location].component(!!isSaved, likedVideos[id]?.liked)}</SelectableArea>
    </>
  )
}
