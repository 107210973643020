import { Module } from '../../../core/domain';
import { ApiActionType } from '../../../core/types';

export const findModuleSuccessAction: producer = ({
  result = observe.api[ApiActionType.FIND_MODULE].result,
  updateResult = update.api[ApiActionType.FIND_MODULE].result,
  updateModule = update.module,
}) => {
  if (!(result && result.isOk)) return;

  const module: Module = result.value;

  updateModule.set(module);
  updateResult.remove();
};
