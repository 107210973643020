import styled from 'styled-components';
import { theme } from '../../globalStyle';

export const StyledLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledLoaderTransition = styled.div`
  position: absolute;
  width: 180px;
  height: 100px;

  &:nth-of-type(2) {
    transform: rotate(60deg);
  }

  &:nth-of-type(3) {
    transform: rotate(-60deg);
  }
`;

export const StyledLoaderInnerTransition = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  & > span {
    position: absolute;
    width: 4px;
    height: 0;
    background: ${theme.color.red.somon};
    z-index: 1000;
  }

  .h1 {
    left: 0;
    animation: loadLeft 7.2s ease infinite;
  }

  .h2 {
    right: 0;
    animation: loadRight 7.2s ease 0.6s infinite;
  }

  .h3 {
    right: 0;
    animation: loadRight 7.2s ease 1.2s infinite;
  }

  .h4 {
    right: 0;
    animation: loadRight 7.2s ease 1.8s infinite;
  }

  .h5 {
    left: 0;
    animation: loadLeft 7.2s ease 2.4s infinite;
  }

  .h6 {
    left: 0;
    animation: loadLeft 7.2s ease 3s infinite;
  }

  @keyframes loadLeft {
    0% {
      bottom: 0;
      height: 0;
    }

    7% {
      bottom: 0;
      height: 100%;
    }

    50% {
      top: 0;
      height: 100%;
    }

    60% {
      top: 0;
      height: 0;
    }
  }

  @keyframes loadRight {
    0% {
      bottom: 0;
      height: 0;
    }

    7% {
      bottom: 0;
      height: 100%;
    }

    50% {
      top: 0;
      height: 100%;
    }

    60% {
      bottom: 0;
      height: 0;
    }
  }
`;
