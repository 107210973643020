import { Profile } from '../core/domain';
import { Video } from '../core/domain/content/Video';
import { CreateProfileModuleRequest, ProfilesApiService, UpdateProfileInfoRequest, UpdateProfileModuleRequest } from '../core/services';
import { AxiosService } from './AxiosService';

const createService = (): ProfilesApiService => {
  const axiosInstance = AxiosService({});

  return <ProfilesApiService>{
    async createProfileModule(updates: CreateProfileModuleRequest): Promise<Profile> {
      return axiosInstance.post<{ updates: CreateProfileModuleRequest; type: 'CREATE_MODULE' }, Profile>('/api/profiles/modules', {
        type: 'CREATE_MODULE',
        updates,
      });
    },

    async deleteProfile(): Promise<void> {
      return axiosInstance.delete<string, void>('/api/profiles');
    },

    async findProfile(): Promise<Profile> {
      return axiosInstance.get<string, Profile>('/api/profiles');
    },

    async getAttributes(): Promise<any> {
      return axiosInstance.get<string, any>(`/api/profiles/attributes`);
    },

    async createAttribute(attribute: any): Promise<any> {
      return axiosInstance.post<any, any>('/api/profiles/attributes', attribute);
    },

    async updateBasicInformation(field: any): Promise<any> {
      return axiosInstance.put<any, any>('/api/profiles/basicInformation', field);
    },

    async updateProfileModule(updates: UpdateProfileModuleRequest): Promise<Profile> {
      return axiosInstance.patch<{ updates: UpdateProfileModuleRequest; type: 'UPDATE_MODULE' }, Profile>('/api/profiles/modules', {
        type: 'UPDATE_MODULE',
        updates,
      });
    },

    async updateProfileInfo(updates: UpdateProfileInfoRequest): Promise<Profile> {
      return axiosInstance.patch<UpdateProfileInfoRequest & { type: 'UPDATE_INFO' }, Profile>('/api/profiles', {
        type: 'UPDATE_INFO',
        ...updates,
      });
    },

    async saveVideo(updates: any): Promise<Video> {
      return await axiosInstance.patch<UpdateProfileInfoRequest & { type: 'SAVE_VIDEO' }, Video>('/api/profiles', {
        type: 'SAVE_VIDEO',
        updates,
      });
    },

    async removeSavedVideo(updates: any): Promise<Video> {
      return await axiosInstance.patch<UpdateProfileInfoRequest & { type: 'REMOVE_SAVED_VIDEO' }, Video>('/api/profiles', {
        type: 'REMOVE_SAVED_VIDEO',
        updates,
      });
    },

    async deleteProfilePicture(): Promise<any> {
      return axiosInstance.delete<any, any>('/api/profiles/picture');
    },

    async updateProfilePicture(avatar: any): Promise<any> {
      return axiosInstance.put<any, any>('/api/profiles/picture', avatar);
    },

    setAuthToken(authToken: string): void {
      axiosInstance.setAuthToken(authToken);
    },
  };
};

export const ProfilesHttpApiService = Object.freeze(createService());
