import { I18nTranslationsService } from '../../../services';
import { LanguageProps } from '../../../core/types';

import { PageHeading } from 'src/generic_components/PageHeading';

import { PageContainer, ContentContainer } from '../../../components';

import { StyledFeedHeader } from './styles';
import { FeedMenu } from './feed.menu';
import { FilterButton } from './feed.filterButon';
import { Filters } from './feed.filters';
import styles from './styles.module.css';
import { ErrorNotificaton } from 'src/generic_components/ErrorNotification';
import { Loader } from '../../../components/Loader';

type Props = LanguageProps & {
  translate: any;
  key: String;
  personalizedContent: boolean;
  setPersonalizedContent: any;
};

export const FeedPageContainer: view<Props> = ({
  videosLoaded = observe.ui.dataLoaded.videos,
  videoLikesLoaded = observe.ui.dataLoaded.videoLikes,
  filterMenu = observe.filterMenu,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}) => {
  return (
    <PageContainer >
      <div className={styles['feed-page']} lang={language}>
        <StyledFeedHeader>
          <PageHeading text={translate('feed_page.header')} />
          <div className={styles['feed-filters']}>
            <FeedMenu />
            <FilterButton />
          </div>
        </StyledFeedHeader>
        {filterMenu && <Filters />}
        {videosLoaded && videoLikesLoaded ? <ContentContainer /> : <Loader />}
        <ErrorNotificaton />
      </div>
    </PageContainer>
  );
};
