import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { BaseProfile, BaseProfileFields } from 'src/types/questionnaire/baseProfile';

export const AreaOfInterestSelector: view = ({
  areaOfInterest = observe.profile.attributes.areaOfInterest,
  updateAreaOfInterest = update.ui.profile.attributes.areaOfInterest,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'areaOfInterest';
  const label = translate('profile.questionnaire.base_profile.areaOfInterest.label');
  const choices = BaseProfile[BaseProfileFields.AREA_OF_INTEREST].map((area) => {
    return {
      label: translate(`profile.questionnaire.base_profile.${BaseProfileFields.AREA_OF_INTEREST}.options.${area}`),
      value: area,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>, newChoices: string[]) => {
    updateAreaOfInterest.set(newChoices);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={areaOfInterest} onChange={handleChoice} />
    </div>
  );
};
AreaOfInterestSelector.displayName = 'AreaOfInterestSelector';
