import React from 'react';
import { Footer } from '../../Footer';
import { StyledFlexContainer, StyledPageContainer } from './styles';

type Props = {
  children?: React.ReactNode;
};

export const FlexContainer = ({ children }: Props) => (
  <StyledFlexContainer>{children}</StyledFlexContainer>
);

export const PageContainer = ({ children }: Props) => (
  <>
    <StyledPageContainer>{children}</StyledPageContainer>
    {/* <Footer /> */}
  </>
);
