import styled from 'styled-components';
import { theme } from '../../globalStyle';

export const StyledSliderContainer = styled.div`
  width: 100%;

  .swiper {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 24px 0 48px;

    @media ${theme.queries.tablet} {
      margin: 16px 0 24px;
    }
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
  }

  .swiper-pagination {
    bottom: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: ${theme.color.red.somon};
    padding: 100px 100px;
  }

  .swiper-pagination-bullet-active {
    background: ${theme.color.red.somon};
  }

  .diva-slider {
    max-width: 90vw;
    @media ${theme.queries.mobile} {
      max-width: 320px;
    }
  }
`;
