import * as Yup from 'yup';
import { useFormik } from 'formik';

import { AuthActionType, AuthFields } from '../../../core/domain';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';

import { AuthStateProps } from '../../../state';
import { Button, Input, Spinner } from '../../elements';

import { StyledAuthForm } from './styles';

type Props = AuthStateProps &
  LanguageProps & {
    updateEmail: Update<string>;
  };

interface RequestNewPasswordFormValues {
  email: string;
}

const initialValues: RequestNewPasswordFormValues = {
  email: '',
};

const validationSchema: Yup.SchemaOf<RequestNewPasswordFormValues> =
  Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Email is required'),
  });

export const RequestNewPasswordForm: view<Props> = ({
  isLoading = observe.auth[AuthActionType.REQUEST_NEW_PASSWORD].isLoading,
  updateTrigger = update.auth[AuthActionType.REQUEST_NEW_PASSWORD].trigger,
  updateEmail = update.auth[AuthActionType.REQUEST_NEW_PASSWORD].fields[
    AuthFields.EMAIL
  ],
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const onSubmit = ({ email }: RequestNewPasswordFormValues) => {
    updateEmail.set(email);

    updateTrigger.set(Date.now());
  };

  const { handleSubmit, values, handleChange, errors, isValid } =
    useFormik<RequestNewPasswordFormValues>({
      validationSchema,
      initialValues,
      onSubmit,
    });

  return (
    <StyledAuthForm onSubmit={handleSubmit} lang={language}>
      <Input
        name="email"
        type="email"
        label={translate('global.email')}
        value={values.email}
        errorMessage={errors.email}
        onChange={handleChange}
      />
      <Button className="primary rounded button button-primary" type="submit" disabled={!isValid}>
        {isLoading ? <Spinner /> : translate('login.submit')}
      </Button>
    </StyledAuthForm>
  );
};
