import { FieldLabel } from 'src/generic_components/FieldLabel';
import { InputField } from 'src/generic_components/InputField';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';

export const ComfortableClothingInput: view = ({
  comfortableClothing = observe.profile.attributes.comfortableClothing,
  updateComfortableClothing = update.ui.profile.attributes.comfortableClothing,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'comfortableClothing';
  const label = translate('profile.questionnaire.preferences.comfortableClothing.question');

  const onSubmitInput = (value: any) => {
    updateComfortableClothing.set(value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} required={true} />
      <InputField value={comfortableClothing} onSubmitInput={onSubmitInput}/>
    </div>
  );
};
ComfortableClothingInput.displayName = 'ComfortableClothingInput';
