import { ToggleButtonGroup } from '@mui/material';
import MuiToggleButton from '@mui/material/ToggleButton';
import styles from './styles.module.css';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

const ToggleButton = styled(MuiToggleButton)(({}) => ({
  padding: '12px 14px 11px',
  '&:hover:not(:disabled)': {
    color: 'white',
    backgroundColor: '#897180',
  },
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#897180',
  },
}));

export const ChipSelector: view = ({ name, label, selections, items, onChange }) => {
  return (
    <>
      {/*<FieldLabel htmlFor={name} label={label}/>*/}
      <ToggleButtonGroup
        id={name}
        value={selections}
        onChange={onChange}
        aria-label="text alignment"
        className={styles['question-option']}
      >
        {items.map((choice: any) => (
          <ToggleButton key={Math.random()} value={choice.value} aria-label={choice.label}>
            {choice.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};
