import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';

import {
  StyledSubscriptionBenefitsWrapper,
  StyledSubscriptionDatesWrapper,
  StyledSubscriptionItem,
  StyledSubscriptionTypeAsset,
  StyledSubscriptionWrapper,
  StyledSubscriptionTypeWrapper,
} from './styles';

type Props = LanguageProps & {
  subscriptionPlan: string;
};

export const ProfileSubscription: view<Props> = ({
  subscriptionPlan,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const subscriptionBenefitsMapper: Record<string, string[]> = {
    Diva: [
      translate('profile_page.benefits_types.access_to_user_profile'),
      translate('profile_page.benefits_types.access_to_generic_content'),
      translate('profile_page.benefits_types.tailored_video_on_demand'),
    ],
    'Diva VIP': [
      'Full access to all content in Diva universe',
      'Exclusive content',
      'Tailored advices from Jan Thomas',
      'Exclusive events only for Diva VIP',
      'Exclusive VIP offers and discount codes',
      'Diva cashpoint',
    ],
  };
  return (
    <StyledSubscriptionWrapper lang={language}>
      <StyledSubscriptionTypeWrapper>
        <StyledSubscriptionItem>
          <span>{translate('profile_page.subscription_type')}</span>
          <span>{subscriptionPlan || 'Diva'}</span>
        </StyledSubscriptionItem>
        {/* <StyledSubscriptionDatesWrapper>
          <StyledSubscriptionItem>
            <span>{translate('profile_page.expires_at')}</span>
            <span>
              {subscriptionPlan
                ? translate('profile_page.one_month')
                : translate('global.never')}
            </span>
          </StyledSubscriptionItem>
        </StyledSubscriptionDatesWrapper> */}
      </StyledSubscriptionTypeWrapper>
      <StyledSubscriptionBenefitsWrapper>
        <StyledSubscriptionTypeAsset>
          <span />
        </StyledSubscriptionTypeAsset>
        <h5>{translate('profile_page.benefits')}</h5>
        <ul>
          {subscriptionBenefitsMapper[subscriptionPlan || 'Diva'].map(
            (item) => (
              <li key={item}>{item}</li>
            )
          )}
        </ul>
      </StyledSubscriptionBenefitsWrapper>
    </StyledSubscriptionWrapper>
  );
};
