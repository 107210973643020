import { AuthActionType } from '../../../core/domain';
import { Routes } from '../../../core/types';

export const authorizeErrorAction: producer = ({
  history,
  result = observe.auth[AuthActionType.AUTHORIZE].result,
  updateResult = update.auth[AuthActionType.AUTHORIZE].result,
}) => {
  if (!(result && result.error)) return;

  history.replace(Routes.LANDING);
  updateResult.remove();
};
