import { Question } from '../../../core/domain';
import {
  InputEventType,
  InputType,
  LanguageProps,
  SelectType,
} from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { nameToTranslationKey } from '../../../utils';
import {
  CheckboxGroup,
  Select,
  SelectOption,
  TextArea,
  Range,
  Input,
} from '../../elements';
import { AnswerItemGroup } from '../AnswerSelector';

import {
  StyledModuleQuestion,
  StyledQuestionAsset,
  StyledQuestionDescription,
  StyledQuestionInput,
  StyledQuestionsAnswers,
} from './styles';

type OwnProps = {
  moduleName: string;
  currentIndex: number;
  question: Question;
};

type StateProps = {
  answer?: string;
  updateAnswer: Update<string | string[]>;
};

type Props = OwnProps & StateProps & LanguageProps;

export const ModuleQuestion: view<Props> = ({
  moduleName,
  question,
  currentIndex,
  answer = observe.utils.components.moduleQuestion[prop.moduleName].answers[
    prop.question.input
  ],
  updateAnswer = update.utils.components.moduleQuestion[prop.moduleName]
    .answers[prop.question.input],
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const {
    input,
    answers,
    description,
    asset,
    type,
    required,
    maxValue,
    minValue,
  } = question;

  const name = nameToTranslationKey(moduleName);

  const renderQuestionByType = () => {
    switch (type) {
      case InputType.TEXT:
      case InputType.DATE:
      case InputType.NUMBER:
        return (
          <Input
            name={`question_${input}`}
            key={`input_type_${type}_$`}
            type={type}
            value={answer ? answer[0] : ''}
            onChange={(e: InputEventType) =>
              updateAnswer.set([e.currentTarget.value])
            }
          />
        );
      case InputType.RANGE:
        return (
          <Range
            name={`question_${input}`}
            key={`input_type_${type}`}
            type={type}
            min={minValue}
            value={answer ? answer[0] : minValue}
            max={maxValue}
            required={required}
            onChange={(e: InputEventType) =>
              updateAnswer.set([e.currentTarget.value])
            }
          />
        );
      case InputType.RADIO:
        return (
          <AnswerItemGroup
            name={`question_${input}`}
            defaultValue={answer ? answer[0] : ''}
            options={answers.map((ans, index) => {
              const label = translate(
                `modules.${name}.questions.${currentIndex}.answers.${index}`
              );
              return { label, value: ans.label };
            })}
            onClick={(_e: InputEventType, value: string) =>
              updateAnswer.set([value])
            }
          />
        );
      case InputType.CHECKBOX:
        return (
          <CheckboxGroup
            name={`question_${input}`}
            options={answers.map((ans, index) => {
              const label = translate(
                `modules.${name}.questions.${currentIndex}.answers.${index}`
              );
              return { label, value: ans.label };
            })}
            onClick={(_e: InputEventType, data: string[]) =>
              updateAnswer.set(data)
            }
          />
        );
      case InputType.TEXTAREA:
        return (
          <TextArea
            name={`question_${input}`}
            placeholder={translate('global.textarea_placeholder')}
            value={answer}
            onChange={(e: InputEventType) =>
              updateAnswer.set([e.currentTarget.value])
            }
          />
        );
      case SelectType.SIMPLE:
        return (
          <Select
            initialValue={answer}
            name={`question_${input}`}
            key={`input_type_${type}`}
            options={answers.map((_, index) =>
              translate(
                `modules.${name}.questions.${currentIndex}.answers.${index}`
              )
            )}
            required={required}
            onChange={(value: SelectOption) => updateAnswer.set([value.label])}
          />
        );
      case SelectType.MULTISELECT:
        return (
          <Select
            name={`question_${input}`}
            initialValue={answer}
            options={answers.map((_, index) =>
              translate(
                `modules.${name}.questions.${currentIndex}.answers.${index}`
              )
            )}
            multiple
            required={required}
            onChange={(values: SelectOption[]) =>
              updateAnswer.set(values.map((value) => value.label))
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <StyledModuleQuestion lang={language}>
      <StyledQuestionInput>
        {translate(`modules.${name}.questions.${currentIndex}.title`)}
      </StyledQuestionInput>
      {description && (
        <StyledQuestionDescription>
          {translate(`modules.${name}.questions.${currentIndex}.description`)}
        </StyledQuestionDescription>
      )}
      {asset && <StyledQuestionAsset imgSrc={asset} />}
      <StyledQuestionsAnswers>{renderQuestionByType()}</StyledQuestionsAnswers>
    </StyledModuleQuestion>
  );
};
