export enum NeedsFields {
  SIGNUP_REASON = 'signupReason',
  WELL_DRESSED_BETTER_DAY = 'wellDressedBetterDay',
  GOOD_FEELING_RAISING_SELF_ESTEEM = 'goodFeelingRaisingSelfEsteem',
  CLOTHES_SHOW_IDENTITY = 'clothesShowIdentity',
  EXPENSIVE_VS_CHEAP_BRA = 'expensiveVsCheapBra',
  DRESS_ACCORDING_TO_AGE = 'dressAccordingToAge',
  DRESSING_SKILL_LEVEL = 'dressingSkillLevel',
  HAIR_SHOWS_IDENTITY = 'hairShowsIdentity',
  BODY_SHAPE_CHALLENGE = 'bodyShapeChallenge',
  HEIGHT_CHALLENGE = 'heightChallenge',
  HELP_SNUGLY_GARMENTS = 'helpSnuglyGarments',
  HELP_COLOR = 'helpColor',
  HELP_FORMAL_CLOTHES = 'helpFormalClothes',
  HELP_MAKEUP_COLORS = 'helpMakeupColors',
  HELP_MAKEUP_APPLICATION = 'helpMakeupApplication',
  HELP_MAKEUP_QUICK = 'helpMakeupQuick',
  HELP_HAIRSTYLE_CASUAL = 'helpHairstyleCasual',
  HELP_HAIRSTYLE_OCCASIONS = 'helpHairstyleOccasions',
  PREFERRED_HAIR_COLORS = 'preferredHairColors',
  HAIR_CARE_ADVICE = 'hairCareAdvice',
  HELP_PRODUCTS_USAGE = 'helpProductsUsage',
}

export const Needs = {
  [NeedsFields.SIGNUP_REASON]: ['inspiration', 'curiousity', 'learning', 'feeling-better'],
  [NeedsFields.WELL_DRESSED_BETTER_DAY]: ['yes', 'no'],
  [NeedsFields.GOOD_FEELING_RAISING_SELF_ESTEEM]: ['yes', 'no'],
  [NeedsFields.CLOTHES_SHOW_IDENTITY]: ['yes', 'no'],
  [NeedsFields.EXPENSIVE_VS_CHEAP_BRA]: ['one-expensive-bra', 'many-cheap-bras'],
  [NeedsFields.DRESS_ACCORDING_TO_AGE]: ['yes', 'no'],
  [NeedsFields.DRESSING_SKILL_LEVEL]: [],
  [NeedsFields.HAIR_SHOWS_IDENTITY]: ['yes', 'no'],
  [NeedsFields.BODY_SHAPE_CHALLENGE]: ['yes', 'no'],
  [NeedsFields.HEIGHT_CHALLENGE]: ['yes', 'no'],
  [NeedsFields.HELP_SNUGLY_GARMENTS]: ['yes', 'no'],
  [NeedsFields.HELP_COLOR]: ['yes', 'no'],
  [NeedsFields.HELP_FORMAL_CLOTHES]: ['yes', 'no'],
  [NeedsFields.HELP_MAKEUP_COLORS]: ['yes', 'no'],
  [NeedsFields.HELP_MAKEUP_APPLICATION]: ['yes', 'no'],
  [NeedsFields.HELP_MAKEUP_QUICK]: ['yes', 'no'],
  [NeedsFields.HELP_HAIRSTYLE_CASUAL]: ['yes', 'no'],
  [NeedsFields.HELP_HAIRSTYLE_OCCASIONS]: ['yes', 'no'],
  [NeedsFields.PREFERRED_HAIR_COLORS]: ['yes', 'no'],
  [NeedsFields.HAIR_CARE_ADVICE]: ['yes', 'no'],
  [NeedsFields.HELP_PRODUCTS_USAGE]: ['yes', 'no'],
};
