import { matchPath } from 'react-router-dom';

import { AuthActionType } from '../../core/domain';
import { Routes } from '../../core/types';

export const mount: producer = ({
  history,
  user = observe.user,
  location = observe.lifeCycle.location,
  updateAuthorizeTrigger = update.auth[AuthActionType.AUTHORIZE].trigger,
  updatePageTrigger = update.pages[param.id].trigger,
  updatePageParams = update.pages[param.id].params,
  updateLocation = update.lifeCycle.location,
}) => {
  if (location === history.location.pathname) return;

  if (history.location.pathname !== Routes.LANDING && !user) {
    updateAuthorizeTrigger.set(Date.now());
    return;
  }

  const routes = Object.entries(Routes);
  routes.forEach(([id, path]) => {
    const mounted = matchPath(history.location.pathname, {
      path,
      exact: true,
    });

    if (mounted) {
      updateLocation.set(mounted.url);
      updatePageParams.set(mounted.params, { id });
      updatePageTrigger.set(Date.now(), { id });
    }
  });
};
