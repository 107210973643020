import React, { createRef, RefObject } from 'react';

import { ErrorMessage, HelperMessage } from '../Messages';
import { Label } from '../Label';
import { InputEventType } from '../../../core/types';

import {
  StyledTextArea,
  StyledTextAreaPlaceholder,
  StyledTextAreaWrapper,
} from './styles';

type OwnPops = {
  name: string;
  label?: string;
  groupClassName?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  helperMessage?: string;
  value?: string;
  maxLength?: number;
  onChange?: (event: InputEventType) => void;
};

type StateProps = {
  readonly showPlaceholder?: boolean;
  readonly currentValue?: string;
  readonly ref?: RefObject<HTMLTextAreaElement> | null;
  updateShowPlaceholder: Update<boolean>;
  updateCurrentValue: Update<string>;
  updateRef: Update<RefObject<HTMLTextAreaElement> | null>;
};

type Props = OwnPops & StateProps;

export const TextArea: view = ({
  name,
  label,
  groupClassName,
  className,
  placeholder,
  errorMessage,
  helperMessage,
  value,
  maxLength,
  disabled,
  onChange,
  showPlaceholder = observe.utils.components.textArea[prop.name]
    .showPlaceholder,
  currentValue = observe.utils.components.textArea[prop.name].currentValue,
  ref = observe.utils.components.textArea[prop.name].ref,
  updateShowPlaceholder = update.utils.components.textArea[prop.name]
    .showPlaceholder,
  updateRef = update.utils.components.textArea[prop.name].ref,
  updateCurrentValue = update.utils.components.textArea[prop.name].currentValue,
}: Props) => {
  updateCurrentValue.set(value || '');
  updateRef.set(createRef());

  if (currentValue) {
    updateShowPlaceholder.set(false);
  } else updateShowPlaceholder.set(true);

  const handleChange = (e: InputEventType) => {
    if (ref?.current && ref?.current?.scrollHeight > 80) {
      // eslint-disable-next-line no-param-reassign
      ref.current.style.minHeight = `${ref.current.scrollHeight + 2}px`;
    }

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <StyledTextAreaWrapper className={`${groupClassName || ''}`}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <StyledTextArea
        id={name}
        className={`${className || ''}`}
        value={currentValue}
        onChange={handleChange}
        disabled={disabled}
        ref={ref}
        maxLength={maxLength}
      />

      {placeholder && showPlaceholder && !currentValue && (
        <StyledTextAreaPlaceholder>{placeholder}</StyledTextAreaPlaceholder>
      )}

      {helperMessage && !errorMessage && (
        <HelperMessage>{helperMessage}</HelperMessage>
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </StyledTextAreaWrapper>
  );
};
