import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Preferences, PreferencesFields } from 'src/types/questionnaire/preferences';

export const PreferredHairstylesSelector: view = ({
  preferredHairstyles = observe.profile.attributes.preferredHairstyles,
  updatePreferredHairstyles = update.ui.profile.attributes.preferredHairstyles,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'preferredHairstyles';
  const label = translate('profile.questionnaire.preferences.preferredHairstyles.label');
  const choices = Preferences[PreferencesFields.PREFERRED_HAIRSTYLES].map((hairstyle) => {
    return {
      label: translate(`profile.questionnaire.preferences.${PreferencesFields.PREFERRED_HAIRSTYLES}.options.${hairstyle}`),
      value: hairstyle,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>, newChoices: string[]) => {
    updatePreferredHairstyles.set(newChoices);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={preferredHairstyles} onChange={handleChoice} />
    </div>
  );
};
PreferredHairstylesSelector.displayName = 'PreferredHairstylesSelector';
