import { ApiActionType, RouteName } from '../../../core/types';
import { I18nTranslationsService, SweetAlertService } from '../../../services';

export const profileGetInitialProps: producer = async ({
  profile = observe.profile,
  trigger = observe.pages[RouteName.PROFILE].trigger,
  updateTrigger = update.pages[RouteName.PROFILE].trigger,
  updateIsLoading = update.pages[RouteName.PROFILE].isLoading,
  getProfileTrigger = update.api[ApiActionType.FIND_PROFILE].trigger,
  AlertService = SweetAlertService,
  translate = I18nTranslationsService.translate,
}) => {
  if (!trigger) return;

  updateTrigger.remove();

  if (profile) return;

  document.title = 'Diva - Profile';
  updateIsLoading.set(true);

  getProfileTrigger.set(Date.now());
};
