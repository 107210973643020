import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Needs, NeedsFields } from 'src/types/questionnaire/needs';

export const PreferredHairColorsSelector: view = ({
  preferredHairColors = observe.profile.attributes.preferredHairColors,
  updatePreferredHairColors = update.ui.profile.attributes.preferredHairColors,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'preferredHairColors';
  const label = translate('profile.questionnaire.needs.preferredHairColors.label');
  const choices = Needs[NeedsFields.PREFERRED_HAIR_COLORS].map((answer) => {
    return {
      label: translate(`profile.questionnaire.needs.${NeedsFields.PREFERRED_HAIR_COLORS}.options.${answer}`),
      value: answer,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updatePreferredHairColors.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={preferredHairColors ? [preferredHairColors] : []} onChange={handleChoice} />
    </div>
  );
};
PreferredHairColorsSelector.displayName = 'PreferredHairColorsSelector';
