import { Link } from 'react-router-dom';
import { ThumbnailPlaceholder } from '../../elements';

import styles from '../VideoDetails/styles.module.css';

import { StyledVideoCard } from './styles';
import { VideoPremiumSmall } from '../VideoDetails/VideoPremiumSmall';

export const VideoCard: view = ({ name, createdOn, id, image, premium }) => {
  return (
    <Link to={`/videos/${id}`}>
      <StyledVideoCard>
        <div className={styles['related-video-priview']}>{image ? <img src={image} alt={name} /> : <ThumbnailPlaceholder />}</div>
        <div className={styles['related-video-info']}>
          <h4>{name}</h4>
          <div className={styles['related-video-info-bottom']}>
            <span>{new Date(createdOn).toDateString()}</span>
            {premium && <VideoPremiumSmall />}
          </div>
        </div>
      </StyledVideoCard>
    </Link>
  );
};
