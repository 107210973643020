import { I18nTranslationsService, SweetAlertService } from '../../../services';

export const afterPaymentAlert: producer = async ({
  language = observe.language.value,
  profile = observe.profile,
  AlertService = SweetAlertService,
  translate = I18nTranslationsService.translate,
}) => {
  if (!language || !profile) {
    return;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const successParam = urlParams.get('success');

  if (successParam !== 'true') {
    return;
  }
  const response = await (AlertService as typeof SweetAlertService).fire({
    icon: 'success',
    title: translate('profile_page.after_payment.success.title'),
    text: translate('profile_page.after_payment.success.description'),
  });
};
