import { lazy, Suspense } from 'react';
import { Route, Switch, useParams, withRouter } from 'react-router-dom';

import { Layout, Loader } from './components';
import { AppProps, Routes } from './core/types';
import * as producers from './producers';
import { OnboardingView } from './newDesign/Onboarding';
import { FeedPage as NewFeedPage } from './newDesign/Feed/index';
import { PostSignIn } from './pages/postSignIn';
import { Navbar } from './newDesign/Navbar/Navbar';
import { questionnaireFlows } from 'src/types/questionnaire';
import { BaseProfile } from 'src/profileQuestionnaire/baseProfile';
import { Needs } from 'src/profileQuestionnaire/needs';
import { Information } from 'src/profileQuestionnaire/information';
import { Preferences } from 'src/profileQuestionnaire/preferences';
import { comments } from 'src/components/Comments/mock';
import { VideoDetailsPageContainer } from 'src/containers';

const ErrorPage = lazy(() => import('./pages/error'));
const FeedPage = lazy(() => import('./pages/feed'));
const LandingPage = lazy(() => import('./pages/landing'));
const ModuleDetailsPage = lazy(() => import('./pages/moduleDetails'));
const DashboardPage = lazy(() => import('./pages/dashboard'));
const OnboardingPage = lazy(() => import('./pages/onboarding'));
const ProfilePage = lazy(() => import('./pages/profile'));
const NewProfilePage = lazy(() => import('./newDesign/Profile'));
const VideoDetailsPage = lazy(() => import('./pages/videoDetails/index'));
const MediaLibrary = lazy(() => import('./pages/mediaLibrary'));
const Concept = lazy(() => import('./pages/concept'));
const Header = lazy(() => import('./components/Header'));

const Wrapper: view = ({ children, language = observe.language.value }) => {
  if (window.location.pathname === '/onboarding') return <>{children}</>;

  return !['/', '/concept'].includes(window.location.pathname) ? (
    <div lang={language}>
      <Navbar />
      {children}
    </div>
  ) : (
    <></>
  );
};

// const getFlow = () => {
//   const { flow } = useParams()
//   switch (flow) {
//     case questionnaireFlows.BASE_PROFILE:
//       return <BaseProfile/>
//     case questionnaireFlows.NEEDS:
//       return <Needs/>
//     case questionnaireFlows.INFORMATION:
//       return <Information/>
//     case questionnaireFlows.PREFERENCES:
//       return <Preferences/>
//     default:
//       return <div>Not found</div>
//   }
// }

const App: view = ({ history, language = observe.language.value }: AppProps) => {
  return (
    <Layout lang={language}>
      <Route exact path={Routes.LANDING}>
        <LandingPage />
      </Route>
      <Route path={Routes.CONCEPT}>
        <Concept />
      </Route>
      <Wrapper>
        <Switch location={history.location} key={history.location.pathname}>
          <Route path="/postSignIn">
            <PostSignIn />
          </Route>
          <Route path={Routes.VIDEO_DETAILS}>
            <VideoDetailsPage />
          </Route>
          <Route path={Routes.MODULE_DETAILS}>
            <ModuleDetailsPage />
          </Route>
          <Route path={Routes.DASHBOARD}>
            <DashboardPage />
          </Route>
          <Route path={Routes.MEDIA_LIBRARY}>
            <MediaLibrary />
          </Route>
          <Route path="/onboarding">
            <OnboardingView />
          </Route>
          <Route path="/feed">
            <NewFeedPage />
          </Route>
          <Route path="/profile">
            <NewProfilePage />
          </Route>
          <Route path={`/questionnaire/${questionnaireFlows.BASE_PROFILE}`}>
            <BaseProfile />
            {/*{getFlow()}*/}
          </Route>
          <Route path={`/questionnaire/${questionnaireFlows.PREFERENCES}`}>
            <Preferences />
          </Route>
          <Route path={`/questionnaire/${questionnaireFlows.NEEDS}`}>
            <Needs />
          </Route>
          <Route path={`/questionnaire/${questionnaireFlows.INFORMATION}`}>
            <Information />
          </Route>
          <Route path="*">
            <ErrorPage />
          </Route>
        </Switch>
      </Wrapper>
    </Layout>
  );
};

App.producers(producers);

export default withRouter(App);
