export const FeedIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.12847 7.33909C8.12847 6.80877 8.12847 6.54361 8.2393 6.39558C8.33588 6.26658 8.48371 6.18587 8.64445 6.17439C8.8289 6.16121 9.05194 6.3046 9.49803 6.59137L14.7431 9.96322C15.1302 10.2121 15.3238 10.3365 15.3906 10.4947C15.449 10.6329 15.449 10.7889 15.3906 10.9272C15.3238 11.0854 15.1302 11.2098 14.7431 11.4587L9.49804 14.8305C9.05194 15.1173 8.8289 15.2607 8.64445 15.2475C8.48371 15.236 8.33588 15.1553 8.2393 15.0263C8.12847 14.8783 8.12847 14.6131 8.12847 14.0828V7.33909Z"
      stroke="#897180"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.90625 6.04427C0.90625 4.17743 0.90625 3.24401 1.26956 2.53097C1.58914 1.90376 2.09907 1.39383 2.72628 1.07425C3.43932 0.710938 4.37274 0.710938 6.23958 0.710938H15.5729C17.4398 0.710938 18.3732 0.710938 19.0862 1.07425C19.7134 1.39383 20.2234 1.90376 20.5429 2.53097C20.9063 3.24401 20.9063 4.17743 20.9063 6.04427V15.3776C20.9063 17.2444 20.9063 18.1779 20.5429 18.8909C20.2234 19.5181 19.7134 20.028 19.0862 20.3476C18.3732 20.7109 17.4398 20.7109 15.5729 20.7109H6.23958C4.37274 20.7109 3.43932 20.7109 2.72628 20.3476C2.09907 20.028 1.58914 19.5181 1.26956 18.8909C0.90625 18.1779 0.90625 17.2444 0.90625 15.3776V6.04427Z"
      stroke="#897180"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
