import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledTextAreaWrapper = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;

  textarea:active:not(:disabled) + div {
    display: none;
  }

  textarea:focus:not(:disabled) + div {
    display: none;
  }

  &.bigger {
    textarea {
      min-height: 160px;
    }
  }
`;

export const StyledTextArea = styled.textarea<{ disabled?: boolean }>`
  color: ${theme.color.black};
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.gray.border};
  flex-basis: 100%;
  resize: none;
  text-align: left;
  padding: 12px 16px;
  width: 100%;
  font: 500 16px/22px ${theme.font.secondary};
  border-radius: 10px;
  min-height: 80px;

  ${({ disabled }) =>
    disabled &&
    `
    color: ${theme.color.gray.disabled};
    border: 1px solid ${theme.color.gray['border-disabled']};
    background-color: hsl(0, 0%, 95%);
    cursor: not-allowed;
    `}
  ${theme.queries.mobile} {
    padding: 8px 16px;
    font-weight: 600;
  }

  &::placeholder {
    font-family: ${theme.font.secondary};
  }

  &:focus,
  &:active {
    border: 1px solid ${theme.color.black};
    outline: none;
  }

  &:disabled {
    &:focus,
    &:active {
      border: 1px solid ${theme.color.gray.dark};
      background-color: hsl(0, 0%, 95%);
    }
  }

  &.error {
    border: 1px solid ${theme.color.red.primary};

    &:focus {
      border: 1px solid ${theme.color.red.primary};
    }
  }
`;

export const StyledTextAreaPlaceholder = styled.div`
  font: 400 16px/24px ${theme.font.secondary};
  position: absolute;
  color: ${theme.color.black};
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  position: absolute;
  left: 16px;
  pointer-events: none;
`;
