import { AuthActionType } from '../../../core/domain';

export const socialSignInSuccessAction: producer = async ({
  result = observe.auth[AuthActionType.SOCIAL_SIGN_IN].result,
  updateResult = update.auth[AuthActionType.SOCIAL_SIGN_IN].result,
}) => {
  if (!(result && result.isOk)) return;

  updateResult.remove();
};
