import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledInputWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  &.condensed {
    & label {
      font-size: 12px;
    }
    & input {
      font-size: 16px;
      padding: 4px 16px;
    }
  }
`;

export const StyledInput = styled.input<{ isInvalid?: boolean }>`
  padding: 12px 16px;
  border-radius: 12px;
  outline: none;
  width: 100%;
  font: 500 16px/22px ${theme.font.secondary};
  border: ${({ isInvalid }) =>
    `1px solid ${isInvalid ? theme.color.red.primary : theme.color.gray.dark}`};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${theme.color.white} inset;
  }

  &:focus,
  &:active,
  &:not(:placeholder-shown) {
    border: 1px solid ${theme.color.gray.dark};
  }

  &:disabled {
    color: ${theme.color.gray.disabled};
    font-weight: 400;
    border: 1px solid ${theme.color.gray['border-disabled']};
    background-color: hsl(0, 0%, 95%);
    cursor: not-allowed;
  }
`;
