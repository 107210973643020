import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Information, InformationFields } from 'src/types/questionnaire/information';

export const StandOutSelector: view = ({
  standOut = observe.profile.attributes.standOut,
  updateStandOut = update.ui.profile.attributes.standOut,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'standOut';
  const label = translate('profile.questionnaire.information.standOut.label');
  const choices = Information[InformationFields.STAND_OUT].map((answer) => {
    return {
      label: translate(`profile.questionnaire.information.${InformationFields.STAND_OUT}.options.${answer}`),
      value: answer,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateStandOut.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={standOut ? [standOut] : []} onChange={handleChoice} />
    </div>
  );
};
StandOutSelector.displayName = 'StandOutSelector';
