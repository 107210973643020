import styled from 'styled-components';
import { theme } from '../../../globalStyle';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import picture from '../../../../assets/jtdiva/celina-cut-eyes.jpg';

export const StyledConceptContainer = styled.div`
  display: flex;
  width: 100%;
  color: ${theme.color.white};
  gap: 32px;
  min-height: calc(100vh - 97px);
  justify-content: center;
  align-items: stretch;
  background-color: ${theme.color.white};
  color: ${theme.color.dark};

  @media ${theme.queries.tablet} {
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 48px;
    justify-content: flex-start;
  }

  @media ${theme.queries.mobile} {
    margin: 0;
  }
`;

export const StyledConceptPicture = styled.div`
  width: 100%;

  .picture {
    margin: auto 0;
    background: url(${picture}) no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
  }
`;

export const StyledConceptText = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .text {
    padding: 0 16px;
    font: 400 18px/24px ${theme.font.secondary};
    text-align: center;
  }
`;
