import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import { LanguageProps } from '../../core/types';
import { I18nTranslationsService } from '../../services';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { ImFilter } from 'react-icons/im';

import { Categories } from './Categories';
import { Category } from '../../core/types';

import { SpeedDialIcon } from './styles';

type Props = LanguageProps & {
  selectedCategory: any;
  filterMenu: boolean;
  setFilterMenu: boolean;
  videos: Array<Object>;
};

export const SpeedDialComponent: view<Props> = ({
  translate = I18nTranslationsService.translate,
  filterMenu = observe.filterMenu,
  setFilterMenu = update.filterMenu,
  selectedCategory = update.selectedCategory,
  videos = get.videos.data,
}) => {
  const count: any = {};
  Categories.forEach((category: Category) => {
    count[category.key] =
      category.key === 'all'
        ? videos.value().length
        : videos
            .value()
            .filter((video: any) => video.categories.includes(category.key))
            .length;
  });

  const selectFilter = (_key: string) => {
    const _selectedCategory = Categories.filter(
      ({ key }: { key: string }) => key === _key
    ).shift();

    selectedCategory.set(_selectedCategory);
    setFilterMenu.set(false);
  };

  return (
    <>
      <Backdrop open={filterMenu} />
      <SpeedDial
        ariaLabel="Filter"
        sx={{
          alignItems: 'end',
          height: 0,
        }}
        icon={
          <div>
            <SpeedDialIcon style={{ display: 'flex', padding: '20px' }}>
              <ImFilter size={'30'} />
            </SpeedDialIcon>
          </div>
        }
        onClose={() => setFilterMenu.set(false)}
        onOpen={() => setFilterMenu.set(true)}
        open={filterMenu}
      >
        {Categories.map(({ key, icon }: Category) => (
          <SpeedDialAction
            key={key}
            icon={icon}
            tooltipTitle={
              <div style={{ width: 'max-content' }}>{`${translate(
                `categories.${key}`
              )} (${count[key]})`}</div>
            }
            tooltipOpen={true}
            onClick={() => selectFilter(key)}
          />
        ))}
      </SpeedDial>
    </>
  );
};
