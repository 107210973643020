import { FeedNavbar } from 'src/state/FeedNavbar';
import { comments } from '../mock';
import { AppState } from './state';
import { FeedFilter } from 'src/state/FeedFilter';

export const state: AppState = {
  // comments: comments,
  comments: [],
  showReply: {},
  newComment: {
    label: 'Write a new comment',
    message: '',
  },
  newReply: {
    label: '',
    replyTo: '',
    message: '',
  },
  idReplyEditor: '',
  // editComment: {
  //   label: 'Edit your comment',
  //   commentId: '',
  //   message: '',
  // },
  // idEditComment: '',
  idDeleteComment: '',
  commentLikes: {},
  idLikeComment: '',
  idFlagComment: '',
  commentTriggers: {
    sendNewComment: false,
    sendEditComment: false,
    sendReply: false,
    deleteComment: false,
    likeComment: false,
    unlikeComment: false,
    flagComment: false,
    deleteDialog: false,
  },
  feed: {
    activeTab: FeedNavbar['MAIN_FEED']
  },
  ui: {
    profileNavigation: {section: "", scroll: false},
    editSliderValue: {}
  },
  selectedCategory: FeedFilter.ALL
};
