import { useFormik } from 'formik';
import { MouseEvent } from 'react';
import { withRouter } from 'react-router-dom';

import { Gender } from '../../../core/domain';
import {
  ProfileUpdateFormProps,
  ProfileUpdateStateProps,
  ApiActionType,
  AppProps,
  InputType,
  ProfileUpdateFormValues,
  profileUpdateFormGetInitialValues,
  profileUpdateFormExtractUpdates,
  profileUpdateFormValidationSchema,
  LanguageProps,
} from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import {
  Button,
  CheckboxGroup,
  Input,
  Spinner,
  TextArea,
} from '../../elements';

import { OnboardingFormField, StyledOnboardingForm } from './styles';

type OwnProps = ProfileUpdateFormProps;
type StateProps = ProfileUpdateStateProps;
type Props = OwnProps & StateProps & AppProps & LanguageProps;

const Form: view<Props> = ({
  lastName = observe.profile.lastName,
  gender = observe.profile.gender,
  dateOfBirth = observe.profile.dateOfBirth,
  description = observe.profile.description,
  updateData = update.api[ApiActionType.UPDATE_PROFILE].data,
  updateTrigger = update.api[ApiActionType.UPDATE_PROFILE].trigger,
  isLoading = observe.api[ApiActionType.UPDATE_PROFILE].isLoading,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const initialValues: ProfileUpdateFormValues =
    profileUpdateFormGetInitialValues({
      lastName,
      gender,
      dateOfBirth,
      description,
    });

  const onSubmit = (answers: ProfileUpdateFormValues) => {
    const updates = profileUpdateFormExtractUpdates(answers);
    updateData.set({ updates });
    updateTrigger.set(Date.now());
  };

  const { handleSubmit, values, handleChange, errors, isValid, setFieldValue } =
    useFormik<ProfileUpdateFormValues>({
      validationSchema: profileUpdateFormValidationSchema,
      initialValues,
      onSubmit,
      initialErrors: {
        lastName: '',
        gender: '',
        dateOfBirth: '',
        description: '',
      },
    });

  return (
    <StyledOnboardingForm onSubmit={handleSubmit} lang={language}>
      <OnboardingFormField>
        <Input
          name="lastName"
          label={translate('onboarding_page.form.last_name')}
          errorMessage={errors.lastName}
          value={values.lastName}
          onChange={handleChange}
        />
      </OnboardingFormField>

      <OnboardingFormField>
        <CheckboxGroup
          groupClassName="label-onboarding"
          name="gender"
          label={translate('onboarding_page.form.gender')}
          defaultValue={gender}
          errorMessage={errors.gender}
          options={Object.values(Gender).map((value) => ({
            label: translate(`global.${value}`),
            value,
          }))}
          onClick={(e: MouseEvent<HTMLInputElement>) => {
            setFieldValue('gender', e.currentTarget.value);
          }}
        />
      </OnboardingFormField>

      <OnboardingFormField>
        <Input
          name="dateOfBirth"
          label={translate(`onboarding_page.form.date_of_birth`)}
          value={values.dateOfBirth}
          type={InputType.DATE}
          errorMessage={errors.dateOfBirth}
          onChange={handleChange}
        />
      </OnboardingFormField>

      <Button className="primary rounded button button-primary" type="submit" disabled={!isValid}>
        {isLoading ? <Spinner /> : translate('global.save')}
      </Button>
    </StyledOnboardingForm>
  );
};

export const OnboardingForm = withRouter(Form);
