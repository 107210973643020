import { RouteName } from '../../../core/types';

export const profileDidMount: producer = ({
  isLoading = observe.pages[RouteName.PROFILE].isLoading,
  profile = observe.profile,
  // customerPortalUrl = observe.payment.customerPortalUrl,
  // checkoutSessionUrl = observe.payment.checkoutSessionUrl,
  updateIsLoading = update.pages[RouteName.PROFILE].isLoading,
}) => {
  if (!(isLoading && /* customerPortalUrl && checkoutSessionUrl && */ profile))
    return;

  updateIsLoading.set(false);
};
