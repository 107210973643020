import { ChangeEvent } from 'react';

import { InputEventType, InputType } from '../../../core/types';

import {
  StyledSwitchButton,
  StyledSwitchInput,
  StyledSwitchLabel,
} from './styles';

type StateProps = {
  readonly isToggled?: boolean;
  updateIsToggled: Update<boolean>;
};

type OwnProps = {
  name: string;
  onChange: (e: InputEventType) => void;
};

type Props = OwnProps & StateProps;

export const ToggleSwitch: view<Props> = ({
  name,
  onChange,
  isToggled = observe.utils.components.toggleSwitch.isToggled[prop.name],
  updateIsToggled = update.utils.components.toggleSwitch.isToggled[prop.name],
}: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateIsToggled.set(e.target.checked);

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <>
      <StyledSwitchInput
        className="switch-checkbox"
        id={name}
        type={InputType.CHECKBOX}
        checked={isToggled}
        onChange={handleChange}
      />
      <StyledSwitchLabel className="switch-label" htmlFor={name}>
        <StyledSwitchButton className="switch-button" />
      </StyledSwitchLabel>
    </>
  );
};
