import { RouteName } from '../../../core/types';

export const conceptGetInitialProps: producer = ({
  trigger = observe.pages[RouteName.MEDIA_LIBRARY].trigger,
  updateTrigger = update.pages[RouteName.MEDIA_LIBRARY].trigger,
  updateIsLoading = update.pages[RouteName.MEDIA_LIBRARY].isLoading,
}) => {
  if (!trigger) return;

  updateTrigger.remove();

  document.title = 'Diva - Concept';
  updateIsLoading.set(true);
};
