import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledAuthModalContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 24px;
  width: 100%;
`;

export const StyledAuthModalHeader = styled.div`
  display: flex;
  font: 500 28px/32px ${theme.font.secondary};
  padding: 24px 0;
  width: 100%;
  align-items: center;

  .auth-modal-back {
    cursor: pointer;
  }

  .auth-modal-back:hover {
    color: ${theme.color.orange['primary-hover']};
  }

  .auth-modal-title {
    margin: auto;
    font: 600 28px/28px ${theme.font.secondary};
  }
`;

export const StyledAuthModalBody = styled.div`
  padding: 24px 0;
  width: 100%;
`;

export const StyledAuthCtaWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  font: 400 16px ${theme.font.secondary};

  // @media ${theme.queries.mobile} {
  //   max-width: 300px;
  // }
`;

export const StyledAuthCtaOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font: 400 16px ${theme.font.secondary};
  padding: 12px 20px;
  width: 100%;
  margin: 16px 0;

  & > span {
    margin: 0 4px;
    font: 500 16px ${theme.font.secondary};
  }

  & > span.icon {
    margin-top: 4px;
    font-size: 22px;
    margin-right: 30px;
  }

  & > span.action {
    cursor: pointer;
    font-weight: 600;
    color: ${theme.color.red.primary};
  }

  & > span.action:hover {
    color: ${theme.color.red['primary-hover']};
  }

  &.trigger {
    border: 1px solid ${theme.color.gray.dark};
    border-radius: 18px;
    cursor: pointer;

    &:hover {
      border: 1px solid ${theme.color.black};
      background-color: ${theme.color.yellow.background};
    }
  }

  @media ${theme.queries.mobile} {
    margin: 8px 0;
  }
`;

export const StyledAuthModalFooter = styled.div`
  max-width: 450px;
  padding: 24px 0;
  text-align: center;
  color: ${theme.color.black};

  @media ${theme.queries.mobile} {
    max-width: 295px;
    padding: 12px 0;
  }
`;

export const StyledAuthFooterInfo = styled.div`
  font: 300 14px/18px ${theme.font.secondary};
  color: ${theme.color.black};

  & > a {
    color: ${theme.color.black};
  }
`;
