import React, { useState } from 'react'
import { Button } from './Button'
import { RightArrow } from 'src/icons'
import styles from './styles.module.css'
import { VisibleIf } from 'src/generic_components/VisibileIf'
import { Translation } from 'react-i18next'

export const Slide = ({ content }: any) => {
  return <div className={styles['slide-content']}>{content}</div>
}

export const Navigator = ({
  name, slides, previousStep, nextStep, updateCurrentSlide, lastSlideNextButtonText, translate
}: any) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const isLastSlide = currentSlide === slides.length - 1
  const isFirstSlide = currentSlide === 0
  const goBack = () => {
    if (currentSlide > 0) setCurrentSlide((prevSlide) => {
      updateCurrentSlide(prevSlide - 1)
      return prevSlide - 1
    })
    else if (previousStep) previousStep()
  }
  const goNext = () => {
    if (currentSlide < slides.length - 1) setCurrentSlide((prevSlide) => {
      updateCurrentSlide(prevSlide + 1)
      return prevSlide + 1
    })
    else if (nextStep) nextStep()
  }

  // const totalSlides = slides.length + 1;

  // updateProgress(((currentSlide + 1) / totalSlides) * 85 + 15);

  return (
    <div className={styles['profile-questions-container']}>
      <Slide content={slides[currentSlide]}/>
      {/*<Slide>{slides[currentSlide]}</Slide>*/}
      <div className={styles['onboarding-navigator']}>
        <Button onClick={goBack} variant="icon-button">
          <RightArrow/>
        </Button>
        <VisibleIf predicate={!isLastSlide}>
          <Button onClick={goNext}>
            {translate("global.next")} <RightArrow/>
          </Button>
        </VisibleIf>
        <VisibleIf predicate={isLastSlide}>
          <Button onClick={goNext} variant="last-slider-button">
            {lastSlideNextButtonText} <RightArrow/>
          </Button>
        </VisibleIf>
      </div>
    </div>
  )
}
