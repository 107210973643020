import {
  StyledProgressBarContainer,
  StyledProgressBarLabel,
  StyledProgressBarProgress,
  StyledProgressBarWrapper,
} from './styles';

type ProgressBarProps = {
  percentage: number;
  label?: string;
  className?: string;
};

export const ProgressBar = ({
  percentage,
  label,
  className,
}: ProgressBarProps) => (
  <StyledProgressBarWrapper>
    {label && <StyledProgressBarLabel>{label}</StyledProgressBarLabel>}
    <StyledProgressBarContainer className={className || ''}>
      <StyledProgressBarProgress percentage={percentage} />
    </StyledProgressBarContainer>
  </StyledProgressBarWrapper>
);
