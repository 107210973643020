import styled from 'styled-components';
import { theme } from '../../../globalStyle';
import Switch from '@mui/material/Switch';
import toggleIconOff from '../../../../assets/jtdiva/toggleIconOff.svg';
import toggleIconOn from '../../../../assets/jtdiva/toggleIconOn.svg';

export const MUISwitch = styled(Switch)(({}) => ({
  '& .MuiSwitch-switchBase': {
    margin: '3px 2px',
    padding: 0,
    transform: 'translateX(2px)',
    '&.Mui-checked': {
      color: '#D2877D',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('${toggleIconOn}')`,
      },
      transform: 'translateX(20px)',
      
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#D2877D',
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#FFFFFF',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 26,
    height: 26,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('${toggleIconOff}')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    borderRadius: 50,
    backgroundColor: '`${theme.color.black}`',
  },
}));

export const StyledFeedHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;

  .personalized-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:10px;
    width: 380px;
    border: 1px solid #EBEBEB;
    padding: 12px;
    border-radius: 12px;
  }

  .personalized-text {
    span {
      display: block;
      line-height: 16px;
      font-size: 14px;
      margin-top: 4px;
    }

    b {
      line-height: 19px;
    }
  }

  .personalized-text span {
    display: block;
    line-height: 16px;
    font-size: 14px;
  }

  .muiSwitch {
    padding: 0;
    width: 52px;
    height: 32px;
    margin: 0;
  }

  h1 {
    margin-bottom: 0;
  }

  @media ${theme.queries.tablet} {
    flex-direction: column;
    align-items: normal;
    gap: 20px;
    margin-top: 8px;
    padding: 0 20px;

    h1 {
      padding-block: 8px;
    }

    .personalized-content {
      width: 100%
    }
  }

  
  @media ${theme.queries.mobile} {
    padding: 0;
    .personalized-content {
       margin-bottom: 16px;
      }
  }
`

export const StyledSpeedDial = styled.div`
  position: sticky;
  bottom: 40px;
  right: 0;
  top: 0;
  left: 0;
  align-items: end;
  margin-left: 60px;
  width: 100%;

  @media ${theme.queries.tablet} {
    margin-left: 0;
  }
`