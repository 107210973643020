import styled from 'styled-components';
import { theme } from '../../globalStyle';

export const StyledSearchBar = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid ${theme.color.gray.border};
  border-radius: 12px;
  padding: 10px 20px;
  background-color: ${theme.color.purple['light-shade']};

  &:focus-within {
    border-color: ${theme.color.gray.disabled};
  }
`;

export const StyledSearchBarIcon = styled.span`
  font-size: 24px;
  cursor: pointer;

  &:hover {
    color: ${theme.color.gray.dark};
  }
`;

export const StyledSearchBarInput = styled.input`
  padding: 8px 8px 8px 0;
  outline: none;
  border: none;
  width: 100%;
  font: 500 16px/22px ${theme.font.secondary};
  background-color: transparent;
`;
