import { InputEventType, InputType } from '../../../core/types';
import { Label } from '../Label';
import { ErrorMessage, HelperMessage } from '../Messages';

import { StyledInput, StyledInputWrapper } from './styles';

type Props = {
  name: string;
  type: InputType;
  label?: string;
  required?: boolean;
  groupClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string | number;
  errorMessage?: string;
  helperMessage?: string;
  onChange?: (event: InputEventType) => void;
  onKeyDown?: (event: InputEventType) => void;
};

export const Input: view = ({
  name,
  label,
  required,
  type,
  groupClassName,
  inputClassName,
  placeholder,
  disabled,
  value,
  onChange,
  onKeyDown,
  errorMessage,
  helperMessage,
}: Props) => {
  const handleChange = (e: InputEventType) => {
    if (onChange) {
      onChange(e);
    }
  };

  const handleKeyDown = (e: InputEventType) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <StyledInputWrapper className={`${groupClassName || ''}`}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <StyledInput
        id={name}
        isInvalid={!!errorMessage}
        name={name}
        required={required}
        className={`${inputClassName || ''}`}
        type={type}
        defaultValue={value}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />

      {helperMessage && !errorMessage && (
        <HelperMessage>{helperMessage}</HelperMessage>
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </StyledInputWrapper>
  );
};
