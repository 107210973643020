import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledContentCardWrapper = styled.div`
  border-radius: 12px;
  box-shadow: 0 4px 27.93px 14.52px rgba(225, 225, 225, 0.5);
  position: relative;
  background-color: ${theme.color.white};
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .video-card-body {
    display: flex;
    flex-direction: column;
    padding: 9px 20px 15px;
    flex-grow: 1;

    > div:first-child {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .video-card-extras {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 24px;
  }

  .video-card-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
  }

  .video-title-section {
    display: flex; 
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;

    > div:first-child {
      width: 88%;
    }

    @media ${theme.queries.mobile} {
      margin-bottom: 0;

      .video-card-extras { 
        margin-top: 20px;
        flex-wrap: wrap;
      }
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-actions {
    display: flex;
    align-items: center;
  }

  
  @media ${theme.queries.tablet} {
    .video-card-body {
      padding: 16px;
    }

    .video-card-extras {
      margin-top: 20px;
      flex-wrap: wrap;
      gap: 12px;
    }
  }
`;

export const SelectableArea = styled.div`
  cursor: pointer;
`;

export const StyledContentCardHero = styled.div`
  width: 100%;
  height: auto;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledContentCardImage = styled.div<{ imgSrc: string }>`
  background: url(${(props) => props.imgSrc}) no-repeat center center;
  background-size: cover;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 12px 12px 0 0;
`;

export const StyledContentCardTitle = styled.h3`
  margin-bottom: auto;
  font-size: 24px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #897180;
  line-height: 1.3;

  @media ${theme.queries.tablet} { 
    font-size: 19px;
  }
`;

export const StyledMetaInfo = styled.div`
  display: inline-block;
  margin-top: 4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #897180;

  @media ${theme.queries.tablet} { 
    font-size: 10px;
  }
`;

export const StyledMetaWrapper = styled.div`
  font-size: 18px;
  margin-top: 4px;
`;

export const StyledContentCardCategory = styled.div`
  padding: 8px 12px;
 border-radius: 60px;
 background-color: #897180;
 font-size: 12px;
 font-weight: 600;
 color: #FFFFFF;
 text-transform: uppercase;

  @media ${theme.queries.tablet} {
    padding: 8px 8px;
    font-size: 10px;
  }
`;

export const StyledContentCardCta = styled.div`
  font-size: 28px;
  height: 28px;
`;

export const StyledContentCardSave = styled.div`
  font-size: 20px;
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
  margin-top: auto;
`;
