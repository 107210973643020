import * as Yup from 'yup';
import { Gender } from '../domain';
import { UpdateProfileInfoRequest } from '../services/ProfilesApiService';
import { UpdateAction } from './Updates';

export type ProfileUpdateFormProps = {
  readonly lastName?: string;
  readonly gender?: Gender;
  readonly dateOfBirth?: string;
  readonly telephone?: string;
  readonly address?: {
    readonly line1?: string;
    readonly line2?: string;
    readonly postalCode?: string;
    readonly city?: string;
  };
};

export interface ProfileUpdateStateProps {
  isLoading?: boolean;
  updateData: Update<any>;
  updateTrigger: Update<number>;
}

export interface ProfileUpdateFormValues {
  lastName?: string;
  gender?: string;
  dateOfBirth?: string;
  telephone?: string;
  address?: {
    line1?: string;
    line2?: string;
    postalCode?: string;
    city?: string;
  };
}

export const profileUpdateFormValidationSchema: Yup.SchemaOf<ProfileUpdateFormValues> =
  Yup.object().shape({
    lastName: Yup.string(),
    gender: Yup.string(),
    dateOfBirth: Yup.string(),
    telephone: Yup.string(),
    address: Yup.object().shape({
      line1: Yup.string(),
      line2: Yup.string(),
      postalCode: Yup.string(),
      city: Yup.string(),
    }),
  });

export const profileUpdateFormGetInitialValues = ({
  lastName,
  gender,
  dateOfBirth,
  telephone,
  address = {},
}: ProfileUpdateFormProps): ProfileUpdateFormValues => ({
  lastName: lastName || '',
  gender: gender || '',
  dateOfBirth: dateOfBirth || '',
  telephone: telephone || '',
  address: {
    line1: address.line1 || '',
    line2: address.line2 || '',
    postalCode: address.postalCode || '',
    city: address.city || '',
  },
});

export const profileUpdateFormExtractUpdates = (
  answers: ProfileUpdateFormValues
): UpdateProfileInfoRequest => ({
  updates: {
    lastName: answers.lastName
      ? UpdateAction.set(answers.lastName)
      : UpdateAction.remove(),
    gender: answers.gender
      ? UpdateAction.set(answers.gender as Gender)
      : UpdateAction.remove(),
    dateOfBirth: answers.dateOfBirth
      ? UpdateAction.set(answers.dateOfBirth)
      : UpdateAction.remove(),
    telephone: answers.telephone
      ? UpdateAction.set(answers.telephone)
      : UpdateAction.remove(),
    address: answers.address
      ? UpdateAction.set(answers.address)
      : UpdateAction.remove(),
  },
});
