import { OnboardingHeader } from 'src/generic_components/OnboardingHeader';
import styles from './styles.module.css';
import { GreetingView } from 'src/newDesign/Onboarding/greeting';
import { Loader } from 'src/components';
import { onboardingFlows } from 'src/types/onboarding';
import { BaseProfile } from 'src/newDesign/Onboarding/baseProfile';

export const OnboardingView: view = ({
  basicInfoLoaded = observe.ui.dataLoaded.basicInformation,
  activeFlow = observe.ui.onboarding.activeFlow,
}) => {
  if (!basicInfoLoaded) return <Loader />;
  if (!activeFlow) activeFlow = onboardingFlows.GREETING;

  return (
    <div>
      <OnboardingHeader />
      <div className={styles['onboarding']}>
        <GreetingView active={activeFlow === onboardingFlows.GREETING} />
        <BaseProfile active={activeFlow === onboardingFlows.BASE_PROFILE} />
      </div>
    </div>
  );
};
