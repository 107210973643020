import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledRangeWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const StyledRangeLabel = styled.label`
  color: ${theme.color.black};
  font: 400 16px/18px ${theme.font.primary};
  letter-spacing: 1px;
  text-align: left;
`;
export const StyledRangeInput = styled.input`
  width: 100%;
  height: 15px;
  -webkit-appearance: none;
  background: ${theme.color.black};
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: inset 0 0 5px ${theme.color.white};

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${theme.color.white};
    cursor: pointer;
    border: 4px solid ${theme.color.gray.light};
    box-shadow: -407px 0 0 400px ${theme.color.gray.light};
`;
export const StyledRangeOutput = styled.output`
  margin: 16px 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const StyledRangeValue = styled.span`
  color: ${theme.color.black};
  font: 300 60px/62px ${theme.font.secondary};
`;
