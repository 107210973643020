export enum AuthChallengeName {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  PASSWORD_VERIFIER = 'PASSWORD_VERIFIER ',
  DEVICE_PASSWORD_VERIFIER = 'DEVICE_PASSWORD_VERIFIER',
  CUSTOM_CHALLENGE = 'CUSTOM_CHALLENGE',
}

export type AuthActionStep = {
  readonly challengeName?: AuthChallengeName;
  readonly challengeParam: {
    requiredAttributes: string[];
  };
};
