import styled from 'styled-components';
import { theme } from '../../../globalStyle';
import story from '../../../../assets/jtdiva/enkel-festsminke.jpg';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import background from '../../../../assets/theme/background.png';
import hero from '../../../../assets/jtdiva/enkel-festsminke.jpg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import downArrow from '../../../../assets/theme/down_arrow.svg';

export const StyledHeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 70px );
  width: 100%;
  max-width: 1300px;
  padding: 24px;
  margin: 0 auto;

  .hero-left {
    position: relative;
  }

  .hero-image-background {
    display: inline-block;
    width: 350px;
    height: 320px;
    position: absolute;
    background-color: #7C595C;
    bottom: -30px;
    right: -30px;
  }

  .hero-image {
    background: url(${story}) no-repeat center center;;
    background-size: cover;
    width: 450px;
    height: 450px;
    display: inline-block;
    position: relative;
    z-index: 2;
  }

  .hero-right {
    max-width: 600px;
  }

  .title {
    text-align: center;
    font: 700 70px ${theme.font.primary};
    margin-bottom: 20px;

    @media ${theme.queries.laptop} {
      font-size: 48px;
    }
      @media ${theme.queries.mobile} {
      font-size: 40px;
      margin-bottom: 20px;
    }
  }
  
  .description {
    font: 500 20px Inter,cursive;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 60px;

    @media ${theme.queries.laptop} {
      font-size: 18px;
    }
    @media ${theme.queries.mobile} {
      font-size: 18px;
      margin-bottom: 40px;
    }
  }

  .button {
    display: flex;
    padding: 14px 24px;
    align-items: center;
    border-width: 2px;
    text-align: center;
    justify-content: center;

    svg {
      margin-left: 10px;
      width: 18px;
      height: 15px;
      strokeWidth: 0.8;
    }
  }

  .button-secondary {
    background-color: transparent;
    color: #644252;
    border-color: ${theme.color.purple.primary};

    &:hover {
      background-color: #f1eceb;
    }
  }

  @media ${theme.queries.laptop} {
    justify-content: center;
    height: auto;
    padding: 100px 24px ;

    .hero-left {
      margin-right: 80px;
      margin-bottom: 50px;
    }

    .hero-image {
      width: 280px;
      height: 280px;
    }

    .hero-image-background {
      width: 250px;
      height: 220px;
      bottom: -20px;
      right: -20px;
    }
  }

  @media ${theme.queries.tablet} {
    flex-direction: column;
    
    .hero-left {
      margin-right: 0;
    }
    padding: 80px 24px;
    button {
      width: 100%;
    }
   }

`;


export const StyledHeroContent = styled.div`
  position:absolute;
  top:0; 
  right:0; 
  bottom:0; 
  left:0;
  background: url(${hero}) no-repeat center top;
  background-size: cover;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 64px;
  z-index: 555;
  pointer-events: none;

  * { pointer-events: none; }


  @media ${theme.queries.mobile} {
    padding: 0 24px;
    pointer-events: all;
    * { pointer-events: all; }
  }

  .play {
    background: transparent;
    border: 0;
    padding: 32px;
    margin: 16px 0;
    cursor:pointer;

    @media ${theme.queries.mobile} {
      padding: 24px;
      margin: 0; 
      svg { 
        width: 80px;
        height: 80px;
      }
    }

  }

`;


export const StyledSignupButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media ${theme.queries.mobile} { 
    flex-direction: column;
  }

`