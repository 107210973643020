import { VideoActionBar } from '../../VideoActionBar';
import { VideoPremium } from '../../VideoPremium';
import styles from '../styles.module.css';
import { I18nTranslationsService } from '../../../../services';
import { AuthorIcon } from 'src/icons';

export const VideoDetailsBar: view = ({ translate = I18nTranslationsService.translate, video = observe.videoState.source, categories }) => {
  if (!video || !categories) return null;
  const { tags, author, createdOn, id } = video;

  return (
    <div className={styles['video-details-bar'] + ' ' + 'video-details-bar'}>
      <div className={styles['author']}>
        <AuthorIcon />
        <span>by Jan Thomas</span>
        {/* <span>{`By ${author}`}</span> */}
      </div>
      <div className={styles['categories']}>
        {categories.map((category: any) => (
          <span key={category}>{translate(`categories.${category}`)}</span>
        ))}
      </div>
      <div className={styles['video-actions']}>
        <VideoActionBar id={id} />
      </div>
    </div>
  );
};
