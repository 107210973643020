import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledLanguageSelector = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 6px;
  line-height: 22px;
  font-weight: 600;
  width: 99px;
  justify-content: flex-end;
  cursor: pointer;

  @media ${theme.queries.tablet} {
    width: auto;
  }
`;

export const StyledLanguage = styled.div<{ isActive?: boolean }>`
  display: flex;
  ${({ isActive }) =>
    isActive &&
    `border-bottom: 3px solid ${theme.color.red.somon}; transform: scale(1.2);`}

  &:hover {
    ${({ isActive }) => `opacity: ${isActive ? '1' : '0.7'};`}
`;
