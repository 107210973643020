import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { BaseProfile, BaseProfileFields } from 'src/types/questionnaire/baseProfile';

export const SkinCharacteristicsSelector: view = ({
  skinCharacteristics = observe.profile.attributes.skinCharacteristics,
  updateSkinCharacteristics = update.ui.profile.attributes.skinCharacteristics,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'skinCharacteristics';
  const label = translate('profile.questionnaire.base_profile.skinCharacteristics.label');
  const choices = BaseProfile[BaseProfileFields.SKIN_CHARACTERISTICS].map((characteristic) => {
    return {
      label: translate(`profile.questionnaire.base_profile.${BaseProfileFields.SKIN_CHARACTERISTICS}.options.${characteristic}`),
      value: characteristic,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>, newChoices: string[]) => {
    updateSkinCharacteristics.set(newChoices);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={skinCharacteristics} onChange={handleChoice} />
    </div>
  );
};
SkinCharacteristicsSelector.displayName = 'SkinCharacteristicsSelector';
