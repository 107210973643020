import { FieldLabel } from 'src/generic_components/FieldLabel';
import { Slider } from 'src/generic_components/Slider';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { BaseProfileFields } from 'src/types/questionnaire/baseProfile';
import styles from './styles.module.css';
import { InputField } from 'src/generic_components/InputField';

export const HeightSelector: view = ({
  height = observe.profile.attributes.height,
  updateHeight = update.ui.profile.attributes.height,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'height';
  const label = translate('profile.questionnaire.base_profile.height');
  const min = 100,
    max = 250;

  // if (!height) {
  //   updateHeight.set(min);
  //   height = min;
  // }

  // const handleChangeCommitted = (event: any, value: number) => {
  //   updateHeight.set(value);
  // };

  const valueVerification = (value: string) => {
    const numValue = parseInt(value);
    let newValue = numValue;

    if (numValue < min || Number.isNaN(numValue)) newValue = min;
    else if (numValue > max) newValue = max;

    return newValue;
  };

  const onSubmitInput = (value: any) => {
    updateHeight.set(value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      {/* <Slider
        attribute={BaseProfileFields.HEIGHT}
        onChangeCommitted={handleChangeCommitted}
        min={min}
        max={max}
        step={1}
        showValue={true}
        value={height}
        measureUnit={translate('measure_unit.centimeters')}
        valueVerification={valueVerification}
        extraClasses={styles['height']}
      /> */}
      <InputField value={height} onSubmitInput={onSubmitInput} valueVerification={valueVerification} placeholder={min} type={'number'} />
    </div>
  );
};
HeightSelector.displayName = 'HeightSelector';
