import styled from 'styled-components';
import { theme } from '../../../globalStyle';

import mirror from '../../../../assets/jtdiva/mirror.svg';
import video from '../../../../assets/jtdiva/video.svg';
import screen from '../../../../assets/jtdiva/app-screen.png';

export const StyledFeaturesSecondary = styled.div`
  position: relative;
  max-width: 1400px;
  width: 100%;
  margin: 210px auto 140px;
  display: flex;
  background-color: ${theme.color.yellow["light-02"]};
  .features-info {
    padding: 83px 64px;
    text-align:center;
    > div {
      display: flex;
      justify-content:center;
    }
  }

  h2 {
    margin: 0 auto ${theme.spacing.xxl};
    max-width: 500px;
  }

  p {
    line-height: 26px;
    max-width: 240px;
  }

  .feature-type span{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 40px;
    height: 64px;
    display: inline-block;
  }

  .feature-type:first-child {
    padding-right: 40px;
    margin-right: 40px;
    border-right: 1px solid rgb(137 113 128 / 30%);
    span{
      background-image: url(${mirror});
      width: 53px;
    }
  }

  .feature-type:last-child span{
    background-image: url(${video});
    width: 60px;
  }

  .app-screen {
    background-image: url(${screen});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    height: 560px;
    width: 100%;
    display: inline-block;
    position: absolute;
    bottom: 0px;
  }

  @media ${theme.queries.laptopS} {
    width: auto;
    margin: ${theme.spacing.xl}  ${theme.spacing.l} 96px;
    .app-screen { 
      height: 380px;
    }
  }

  @media ${theme.queries.tablet} {
    margin-bottom: 96px;
    flex-direction: column;
    .app-screen {
      position: static;
      height: 330px;
    }
    .feature-type:first-child {
      padding-right:  ${theme.spacing.s};
      margin-right:  ${theme.spacing.s};
    }

    .features-info {
      padding: ${theme.spacing.l} ${theme.spacing.s};

      h2 {
        font-size: 24px;
        margin-bottom: 47px;
      }
    }
  }

`;

