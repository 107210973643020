import { ContentList, ContentSorter, CreateVideoPayload, SearchContentRequest, Video } from '../../core/domain'
import { resultFormatter } from '../../core/types'
import { ContentHttpApiService, LikesHttpApiService, PersonalizedContentHttpApiService } from '../../services'
import { FeedNavbar } from 'src/state/FeedNavbar'

//TODO optimize this so it's not reloading the data everytime and blocking the view
export const LoadVideos: producer = async ({
  ContentApiService = ContentHttpApiService,
  setVideos = update.videos,
  activeTab = observe.feed.activeTab,
  updateVideosLoaded = update.ui.dataLoaded.videos,
  //   updateIsLoading = update.api[ApiActionType.SEARCH_VIDEOS].isLoading,
}) => {
  if (activeTab !== FeedNavbar.MAIN_FEED) return
  updateVideosLoaded.set(false)

  const filter = SearchContentRequest.create({
    sort: [ContentSorter.createdOn_DESC],
  })

  const result = await ContentApiService.searchVideos(filter)
  const { meta, data, error } = result.data.listVideos

  if (error) {
    // updateIsLoading.set(false);
    // updateResult.set(resultFormatter.error(error.message));
    return
  }

  const videos: Video[] = data.map((payload: CreateVideoPayload) => Video.create(payload))
  const videosList: ContentList<Video> = {
    data: videos,
    meta,
  }

  const { value: formatedResult }: any = resultFormatter.ok(videosList)

  setVideos.set(formatedResult)
  updateVideosLoaded.set(true)
}

export const LoadPersonalizedContent: producer = async ({
  PersonalizedContentApiService = PersonalizedContentHttpApiService,
  activeTab = observe.feed.activeTab,
  setVideos = update.videos,
  updateVideosLoaded = update.ui.dataLoaded.videos,
}) => {
  if (activeTab !== FeedNavbar.PERSONALIZED) return

  updateVideosLoaded.set(false)

  try {
    const personalContentData = (await PersonalizedContentApiService.getPersonalizedContent()).map((payload: CreateVideoPayload) =>
      Video.create(payload)
    )

    const personalVideosList: ContentList<Video> = {
      data: personalContentData || [],
      meta: {
        totalCount: personalContentData?.length || 0,
        cursor: '',
        hasMoreItems: false,
      },
    }

    setVideos.set(personalVideosList)
  } catch (e) {
    setVideos.set({
      data: [],
      meta: {
        totalCount: 0,
        cursor: '',
        hasMoreItems: false,
      },
    })
  }

  updateVideosLoaded.set(true)
}

export const LoadFavouritedVideos: producer = async ({
  ContentApiService = ContentHttpApiService,
  isSaved = observe.isSaved,
  setVideos = update.videos,
  activeTab = observe.feed.activeTab,
  updateVideosLoaded = update.ui.dataLoaded.videos,
}) => {
  if (activeTab !== FeedNavbar.FAVOURITES) return

  updateVideosLoaded.set(false)

  const filter = SearchContentRequest.create({
    sort: [ContentSorter.createdOn_DESC],
  })

  const result = await ContentApiService.searchVideos(filter)
  const { meta, data, error } = result.data.listVideos

  if (error) {
    // updateIsLoading.set(false);
    // updateResult.set(resultFormatter.error(error.message));
    return
  }

  const videos: Video[] = data.map((payload: CreateVideoPayload) => Video.create(payload))
  const videosList: ContentList<Video> = {
    data: videos,
    meta,
  }

  const { value: formatedResult }: any = resultFormatter.ok(videosList)

  const savedVideos = { data: formatedResult.data.filter((video: any) => isSaved[video.id]), meta }

  !Object.keys(savedVideos)
    ? setVideos.set({
      data: [],
      meta: {
        totalCount: 0,
        cursor: '',
        hasMoreItems: false,
      },
    })
    : setVideos.set(savedVideos)

  updateVideosLoaded.set(true)
}

export const LoadLikesOnVideos: producer = async ({
  likedVideos = update.likedVideos,
  profile = observe.profile,
  videos = observe.videos.data,
  videoLikesLoaded = get.ui.dataLoaded.videoLikes.value(),
  updateVideoLikesLoaded = update.ui.dataLoaded.videoLikes,
}) => {
  if (!profile || !videos?.length || videoLikesLoaded) return

  updateVideoLikesLoaded.set(false)

  const getPostLikesData = async (videoId: string) => {
    const { postId, likes, likesByProfileId } = await LikesHttpApiService.getPostLikes(videoId)
    return {
      postId: postId,
      data: {
        likes: likes,
        liked: likesByProfileId.includes(profile.id),
      },
    }
  }

  const promises = videos.map((video: any) => getPostLikesData(video.id))
  const results = await Promise.all(promises)

  const _likedVideos = results.reduce((acc, { postId, data }) => {
    acc[postId] = data
    return acc
  }, {})

  likedVideos.set(_likedVideos)
  updateVideoLikesLoaded.set(true)
}
