import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledRecommendedVideosWrapper = styled.div`
  margin-top: 48px;

  & > h4 {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 400;
  }

  @media ${theme.queries.tablet} {
    margin-top: 32px;

  }
`;

export const StyledRecommendedVideosContainer = styled.div`
  display: flex;
  gap: 32px;
  overflow-y:auto;

  a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    margin-bottom: 2px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  }

`;
