import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { BaseProfile, BaseProfileFields } from 'src/types/questionnaire/baseProfile';

export const EyesShapeSelector: view = ({
  eyesShape = observe.profile.attributes.eyesShape,
  updateEyesShape = update.ui.profile.attributes.eyesShape,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'eyesShape';
  const label = translate('profile.questionnaire.base_profile.eyesShape.label');
  const choices = BaseProfile[BaseProfileFields.EYES_SHAPE].map((shape) => {
    return {
      label: translate(`profile.questionnaire.base_profile.${BaseProfileFields.EYES_SHAPE}.options.${shape}`),
      value: shape,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>, newChoices: string[]) => {
    updateEyesShape.set(newChoices); 
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={eyesShape} onChange={handleChoice} />
    </div>
  );
};
EyesShapeSelector.displayName = 'EyesShapeSelector';
