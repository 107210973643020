// import { StyledEditProfileInfoForm } from '../../../components/Profile/ProfileInfo/styles';
import { RouteName } from '../../../core/types';

export const feedDidMount: producer = async ({
  isLoading = observe.pages[RouteName.FEED].isLoading,
  videos = observe.videos,
  updateIsLoading = update.pages[RouteName.FEED].isLoading,
}) => {
  if (!isLoading || !videos) return;

  updateIsLoading.set(false);
};
