import { CmsError } from '../../../errors';
import { CreateVideoPayload } from '../Video';

export const FindVideoQuery = (id: string) => `
{
  getVideo(where: {entryId: "${id}"}) {
    error {
      code,
      message,
      data,
    },
    data {
      entryId,
      image,
      shortDescription,
      description,
      video,
      name,
      premium,
      tags { name },
      author { name },
      categories { name },
      createdOn,
    }
  }
}
`;

export interface FindVideoQueryResult {
  data: {
    getVideo: {
      error: CmsError;
      data: CreateVideoPayload;
    };
  };
}
