export enum PreferencesFields {
  STYLE_PREFERENCE = 'stylePreference',
  CLOTHING_COLOR = 'clothingColor',
  FAVOURITE_GARMENTS = 'favouriteGarments',
  COMFORTABLE_CLOTHING = 'comfortableClothing',
  FOUNDATION_PREFERENCE = 'foundationPreference',
  SKIN_LOOK_PREFERENCE = 'skinLookPreference',
  EVERYDAY_MAKEUP = 'everydayMakeup',
  PARTY_MAKEUP = 'partyMakeup',
  TIME_ON_MAKEUP = 'timeOnMakeup',
  FAVOURITE_MAKEUP_SHOP = 'favouriteMakeupShop',
  HAIR_LENGTH = 'hairLength',
  USUAL_HAIRSTYLE = 'usualHairstyle',
  PREFERRED_HAIRSTYLES = 'preferredHairstyles',
  TIME_ON_EVERYDAY_HAIR = 'timeOnEverydayHair',
  TIME_ON_OCCASIONS_HAIR = 'timeOnOccasionsHair',
}

export const Preferences = {
  [PreferencesFields.STYLE_PREFERENCE]: [
    'chic',
    'bohemian',
    'classic',
    'modern-classic',
    'rocked',
    'sexy',
    'eclectic',
    'tomboy',
    'minimalistic',
    'urban',
    'sporty',
    'not-sure',
  ],
  [PreferencesFields.CLOTHING_COLOR]: ['black', 'white', 'grey', 'blue', 'green', 'yellow', 'red', 'brown', 'purple', 'pink', 'beige'],
  [PreferencesFields.FAVOURITE_GARMENTS]: [],
  [PreferencesFields.COMFORTABLE_CLOTHING]: [],
  [PreferencesFields.FOUNDATION_PREFERENCE]: ['full-coverage', 'medium-coverage', 'light-coverage', 'no-foundation'],
  [PreferencesFields.SKIN_LOOK_PREFERENCE]: ['mat', 'glow', 'natural', 'not-sure'],
  [PreferencesFields.EVERYDAY_MAKEUP]: ['a-lot', 'medium', 'natural', 'depends'],
  [PreferencesFields.PARTY_MAKEUP]: ['makeup', 'natural'],
  [PreferencesFields.TIME_ON_MAKEUP]: ['yes', 'no'],
  [PreferencesFields.FAVOURITE_MAKEUP_SHOP]: [
    'Kicks',
    'Kicks nettbutikk',
    'Vita',
    'Vita nettbutikk',
    'Fredrik&Louisa',
    'F&L nettbutikk',
    'H&M',
    'H&M nettbutikk',
    'Cubus',
    'BliVakker',
    'Blush',
    'Lyko',
    'Lyko nettbutikk',
    'Other',
  ],
  [PreferencesFields.HAIR_LENGTH]: ['long', 'mid-length', 'shoulder-length', 'bob-length', 'shortcut'],
  [PreferencesFields.USUAL_HAIRSTYLE]: ['down', 'up', 'straightening-or-curling-iron'],
  [PreferencesFields.PREFERRED_HAIRSTYLES]: ['varies-of-curls', 'waves', 'ponytails', 'hair-setups'],
  [PreferencesFields.TIME_ON_EVERYDAY_HAIR]: ['yes', 'no'],
  [PreferencesFields.TIME_ON_OCCASIONS_HAIR]: ['yes', 'no'],
};
