import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Information, InformationFields } from 'src/types/questionnaire/information';

export const DiseasePerceptionSelector: view = ({
  diseasePerception = observe.profile.attributes.diseasePerception,
  updateDiseasePerception = update.ui.profile.attributes.diseasePerception,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'diseasePerception';
  const label = translate('profile.questionnaire.information.diseasePerception.label');
  const choices = Information[InformationFields.DISEASE_PERCEPTION].map((perception) => {
    return {
      label: translate(`profile.questionnaire.information.${InformationFields.DISEASE_PERCEPTION}.options.${perception}`),
      value: perception,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateDiseasePerception.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={diseasePerception} onChange={handleChoice} />
    </div>
  );
};
DiseasePerceptionSelector.displayName = 'DiseasePerceptionSelector';
