import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Information, InformationFields } from 'src/types/questionnaire/information';

export const IllnessSelector: view = ({
  illness = observe.profile.attributes.illness,
  updateIllness = update.ui.profile.attributes.illness,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'illness';
  const label = translate('profile.questionnaire.information.illness.label');
  const choices = Information[InformationFields.ILLNESS].map((_illness) => {
    return {
      label: translate(`profile.questionnaire.information.${InformationFields.ILLNESS}.options.${_illness}`),
      value: _illness,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateIllness.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={illness ? [illness] : []} onChange={handleChoice} />
    </div>
  );
};
IllnessSelector.displayName = 'IllnessSelector';
