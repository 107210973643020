import { Tooltip } from '@mui/material';
import { Action } from 'src/components/Content/VideoActionBar/styles';
import { LikedIcon } from 'src/icons';

export const VideoActionButton: view = ({ key, title, onClick, children }) => {
  return (
    <Tooltip key={key} title={title} arrow>
      <Action onClick={onClick}>{children}</Action>
    </Tooltip>
  );
};
