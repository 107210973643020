import React from 'react';

import { StyledLabel } from './styles';

type Props = {
  htmlFor: string;
  children: React.ReactNode;
};

export const Label = ({ htmlFor, children }: Props) => (
  <StyledLabel htmlFor={htmlFor}>{children}</StyledLabel>
);
