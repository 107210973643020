import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Preferences, PreferencesFields } from 'src/types/questionnaire/preferences';

export const SkinLookPreferenceSelector: view = ({
  skinLookPreference = observe.profile.attributes.skinLookPreference,
  updateSkinLookPreference = update.ui.profile.attributes.skinLookPreference,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'skinLookPreference';
  const label = translate('profile.questionnaire.preferences.skinLookPreference.label');
  const choices = Preferences[PreferencesFields.SKIN_LOOK_PREFERENCE].map((skinLook) => {
    return {
      label: translate(`profile.questionnaire.preferences.${PreferencesFields.SKIN_LOOK_PREFERENCE}.options.${skinLook}`),
      value: skinLook,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateSkinLookPreference.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={skinLookPreference ? [skinLookPreference] : []} onChange={handleChoice} />
    </div>
  );
};
SkinLookPreferenceSelector.displayName = 'SkinLookPreferenceSelector';
