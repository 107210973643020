import { authModalInitialState } from '../../../components';
import { AuthActionType, AuthFields } from '../../../core/domain';
import { SweetAlertService } from '../../../services';

export const requestNewPasswordSuccessAction: producer = async ({
  result = observe.auth[AuthActionType.REQUEST_NEW_PASSWORD].result,
  updateResult = update.auth[AuthActionType.REQUEST_NEW_PASSWORD].result,
  email = get.auth[AuthActionType.REQUEST_NEW_PASSWORD].fields[
    AuthFields.EMAIL
  ],
  updateAuthModalState = update.utils.components.authModal.state,
  AlertService = SweetAlertService,
}) => {
  if (!(result && result.isOk)) return;

  localStorage.setItem('email', email.value());

  await AlertService.success(result.successMessage);

  updateAuthModalState.set({
    ...authModalInitialState,
    isOpen: true,
    isConfirmNewPasswordFormMounted: true,
  });

  updateResult.remove();
};
