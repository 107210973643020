import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { Video } from '../../../core/domain';
import { Category } from '../../../core/types';
import { Loader } from '../../Loader';
import { ContentCard } from '../ContentCard';
import { StyledContentCardLink, StyledContentContainer, StyledContentText } from './styles';
const { enablePersonalizedContent } = window.env.flags;
import styles from "./styles.module.css"
import { FeedFilter } from 'src/state/FeedFilter';

export const ContentContainer: view = ({
  videos = observe.videos.data,
  personalizedVideos = observe.personalizedVideos.data,
  selectedCategory = observe.selectedCategory,
  personalizedContent = observe.personalizedContent,
  translate = I18nTranslationsService.translate,
}) => {
  if (!videos?.length) {
    return <p className={styles['empty-video-list-message']}>{translate('feed_page.no_videos')}</p>;
  }

  const items = personalizedContent ? personalizedVideos : videos;

  const filteredItems =
    !selectedCategory || selectedCategory === FeedFilter.ALL
      ? items
      : items.filter((item: any) => item.categories.includes(selectedCategory.toLowerCase()));

  return (
    <StyledContentContainer>
      {filteredItems.length ? (
        filteredItems.map((item, index) => (
          <StyledContentCardLink key={item.id} size={index % 3 === 0 ? 'large' : 'small'}>
            <ContentCard
              image={item.image}
              categories={item.categories}
              name={item.name}
              size={index % 3 === 0 ? 'large' : 'small'}
              createdOn={item.createdOn}
              author={item.author}
              type="video"
              id={item.id}
              premium={item.premium}
            />
          </StyledContentCardLink>
        ))
      ) : (
        <StyledContentText>{translate('feed_page.EmptyFilterMessage')}</StyledContentText>
      )}
    </StyledContentContainer>
  );
};
