import { AuthActionType } from '../../../core/domain';
import { authModalInitialState } from '../../../components';
import { SweetAlertService } from '../../../services';
import { ErrorLoggerService } from '../../../services/ErrorLoggerService';

export const signUpErrorAction: producer = async ({
  result = observe.auth[AuthActionType.SIGN_UP].result,
  updateResult = update.auth[AuthActionType.SIGN_UP].result,
  updateAuthModalState = update.utils.components.authModal.state,
  AlertService = SweetAlertService,
}) => {
  if (!(result && result.error)) return;

  ErrorLoggerService.logError(result.error);

  await AlertService.error(result.error);

  updateAuthModalState.set({
    ...authModalInitialState,
    isOpen: true,
    isSignUpFormMounted: true,
  });

  updateResult.remove();
};
