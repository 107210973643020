import { wildcard } from '@c11/engine.runtime'
import { ProfilesHttpApiService } from 'src/services'

export const saveSelections: producer = async ({
  field = wildcard,
  selection = observe.ui.profile.attributes[arg.field],
  updateProfile = update.profile.attributes[arg.field],
}) => {
  if (!field || selection === undefined) return

  const body = {
    question: field,
    attribute: field,
    answer: selection,
  }

  updateProfile.set(selection);

  try {
    await ProfilesHttpApiService.createAttribute(JSON.stringify(body))
    console.log('Profile updated')
  } catch (err) {
    console.error('Error saving selections: ', err)
  }
}
