import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Preferences, PreferencesFields } from 'src/types/questionnaire/preferences';

export const FoundationPreferenceSelector: view = ({
  foundationPreference = observe.profile.attributes.foundationPreference,
  updateFoundationPreference = update.ui.profile.attributes.foundationPreference,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'foundationPreference';
  const label = translate('profile.questionnaire.preferences.foundationPreference.label');
  const choices = Preferences[PreferencesFields.FOUNDATION_PREFERENCE].map((foundation) => {
    return {
      label: translate(`profile.questionnaire.preferences.${PreferencesFields.FOUNDATION_PREFERENCE}.options.${foundation}`),
      value: foundation,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateFoundationPreference.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={foundationPreference ? [foundationPreference] : []} onChange={handleChoice} />
    </div>
  );
};
FoundationPreferenceSelector.displayName = 'FoundationPreferenceSelector';
