import { FieldLabel } from 'src/generic_components/FieldLabel';
import { InputField } from 'src/generic_components/InputField';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';

export const FavouriteGarmentsInput: view = ({
  favouriteGarments = observe.profile.attributes.favouriteGarments,
  updateFavouriteGarments = update.ui.profile.attributes.favouriteGarments,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'favouriteGarments';
  const label = translate('profile.questionnaire.preferences.favouriteGarments.question');

  const onSubmitInput = (value: any) => {
    updateFavouriteGarments.set(value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} required={true} />
      <InputField value={favouriteGarments} onSubmitInput={onSubmitInput}/>
    </div>
  );
};
FavouriteGarmentsInput.displayName = 'FavouriteGarmentsInput';
