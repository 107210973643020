import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledAvatarProfileModal = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  font-family: ${theme.font.secondary};
  min-width: 660px;
  max-width: 50vw;
  border-radius: 12px;

  @media ${theme.queries.laptop} {
    min-width: 65vw;
    max-width: 100%;
    width: 100%;
  }

  @media ${theme.queries.tablet} {
    min-width: 75vw;
  }
`;

export const StyledAvatarProfileModalBody = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;

  span {
    padding: 8px 0 24px;
    word-break: break-all;
  }

  p {
    font: 500 18px ${theme.font.secondary};
    align-self: baseline;
    margin-bottom: 20px;
  }

  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 24px;
  }

  @media ${theme.queries.mobile} {
    margin: 16px 16px 32px;
  }

  button{
    text-transform: capitalize;
    border-radius: 8px;
    background-color: ${theme.color.black};
    border: 1px solid ${theme.color.black};
    color:${theme.color.white};

    &:hover {
      background-color: ${theme.color.white};
      border: 1px solid ${theme.color.black};
      color: ${theme.color.black};
    }

    &:disabled,
    &:disabled:hover {
    background-color:  ${theme.color.black};
    border: 1px solid  ${theme.color.black};
    color: ${theme.color.black};
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const StyledAvatarProfileModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px;

  > button {
    margin-left: 32px;
    &.small {
      padding: 15px 32px;
    }
  }

  @media ${theme.queries.mobile} {
    flex-direction: column;
    padding: 16px;
    > button {
      margin: 0;
      &:nth-child(2) {
        margin-top: 16px;
      }
    }
  }
`;
