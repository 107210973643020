import { lazy } from 'react'

import { LanguageProps, Routes } from '../../../core/types'
import { I18nTranslationsService } from '../../../services'

import { Video } from '../../../core/domain'
import {
  StyledVideoDescription,
  StyledVideoDetailsWrapper,
} from './styles'

import styles from './styles.module.css'
import { VideoDetailsBar } from './VideoDetailsBar'
import { Button } from 'src/generic_components/Button'
import { RightArrow } from 'src/icons'
import { useHistory } from 'react-router-dom'

const VideoPlayer = lazy(() => import('../../VideoPlayer'))

type Props = LanguageProps & {
  translate: any;
  video?: Video;
};

export const VideoDetails: view<Props> = ({
  translate = I18nTranslationsService.translate,
  video = observe.videoState.source,
}: Props) => {
  const history = useHistory()

  if (!video) return null

  const {
    name,
    id,
    shortDescription,
    description,
    categories
  } = video

  const handleClick = () => {
    history.push(Routes.FEED)
  }

  return (
    <StyledVideoDetailsWrapper>
      <div className={styles['video-page-header']}>
        <Button variant="icon-button" onClick={handleClick}><RightArrow/></Button>
        <h1>{name}</h1>
      </div>
      {<VideoPlayer id={id}/>}
      <VideoDetailsBar categories={categories}/>
      {(shortDescription || description) && (
        <>
          {shortDescription && (
            <StyledVideoDescription>{shortDescription}</StyledVideoDescription>
          )}
          {description && (
            <>
              <StyledVideoDescription>{description}</StyledVideoDescription>
            </>
          )}
        </>
      )}
    </StyledVideoDetailsWrapper>
  )
}
