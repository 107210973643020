import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledPageContainer = styled.div`
  padding: 64px ${theme.spacing.m};
  margin: 0 auto;
  flex-grow: 1;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    background-color: #FEEBD6;
    width: 588px;
    height: 900px;
    border-radius: 300px;
    filter: blur(70px);
    position: absolute;
    z-index: 0;
    bottom: -20%;
    opacity: 1;
    left: -25%;
  }

  
  &:before {
    content: "";
    background-color: #FEAC8C;
    width: 588px;
    height: 900px;
    border-radius: 300px;
    filter: blur(70px);
    position: absolute;
    z-index: 0;
    opacity: 0.3;
    right: -25%;
  }


  @media ${theme.queries.tablet} {
    padding: 30px 20px;
    &:before {
      content: none
    }
    &:after {
      content: none
    }
  }
`;

export const StyledFlexContainer = styled.div`
  display: flex;
`;
