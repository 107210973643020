import { Profile } from '../../../core/domain'
import { ApiActionType } from '../../../core/types'

export const createProfileModuleSuccessAction: producer = async ({
  result = observe.api[ApiActionType.CREATE_PROFILE_MODULE].result,
  updateResult = update.api[ApiActionType.CREATE_PROFILE_MODULE].result,
  updateProfile = update.profile,
  updateIsEditMode = update.utils.components.moduleDetails.isEditMode,
}) => {
  if (!(result && result.isOk)) return

  updateIsEditMode.set(true)

  const profile: Profile = result.value

  updateProfile.merge(profile)
  updateResult.remove()
}
