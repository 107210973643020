import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledProgressBarWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  width: 100%;
`;

export const StyledProgressBarLabel = styled.div`
  text-align: center;
  font: 500 14px/16px ${theme.font.secondary};
  color: ${theme.color.orange['primary-hover']};
  padding: 4px 0;
`;

export const StyledProgressBarContainer = styled.div`
  position: relative;
  border-radius: 25px;
  padding: 8px;
  background: ${theme.color.white};
  border: 2px solid ${theme.color.orange['primary-hover']};
`;

export const StyledProgressBarProgress = styled.span<{ percentage: number }>`
  position: absolute;
  top: 0;
  left: 0;
  background: ${theme.color.orange['primary-hover']};
  height: 100%;
  border-radius: 25px;
  width: ${(props) => `${props.percentage || 0}`}%;
`;
