import { Video } from '../../../core/domain';
import { ApiActionType } from '../../../core/types';

export const findVideoSuccessAction: producer = ({
  result = observe.api[ApiActionType.FIND_VIDEO].result,
  updateResult = update.api[ApiActionType.FIND_VIDEO].result,
  updateVideoState = update.videoState,
}) => {
  if (!(result && result.isOk)) return;

  const video: Video = result.value;
  updateVideoState.set({
    isLoading: false,
    isLoaded: true,
    source: video,
  });

  updateResult.remove();
};
