import { Navigator } from 'src/generic_components/Navigator'
import { HairGuidance } from './slides/HairGuidance'
import { Identity } from './slides/Identity'
import { PersonalPresentation } from './slides/PersonalPresentation'
import { StyleGuidance } from './slides/StyleGuidance'
import { StylingPreferences } from './slides/StylingPreferences'
import { useHistory } from 'react-router-dom'
import { onboardingFlows } from 'src/types/onboarding'
import { Loader } from 'src/components'
import { Routes } from 'src/core/types'
import { I18nTranslationsService } from 'src/services'

export const Needs: view = ({
  attributesLoaded = observe.ui.dataLoaded.attributes,
  updateCurrentSlide = update.ui.navigator.baseProfile.currentSlide,
  language = observe.language.value,
  translate = I18nTranslationsService.translate
}) => {
  const history = useHistory()
  if (!attributesLoaded) return <Loader/>

  const slides = [<HairGuidance/>, <Identity/>, <PersonalPresentation/>, <StyleGuidance/>, <StylingPreferences/>]
  return (
    <div className="modules" lang={language}>
      <Navigator
        slides={slides}
        nextStep={() => history.push(Routes.PROFILE)}
        previousStep={() => history.push(Routes.PROFILE)}
        updateCurrentSlide={(currentSlide) => updateCurrentSlide.set(currentSlide)}
        lastSlideNextButtonText={translate('onboarding.base_profile.your_personalized_feed')}
        translate={translate}
      />
    </div>
  )
}
