import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { BasicInformation, BasicInformationFields } from 'src/types/profile';
import { ChipSelector } from 'src/generic_components/ChipSelector';

export const GenderSelector: view = ({
  gender = observe.profile.gender,
  updateGender = update.ui.profile.gender,
  translate = I18nTranslationsService.translate,
  className,
}) => {
  const name = 'gender';
  const label = translate('profile.gender.label');
  const choices = BasicInformation[BasicInformationFields.GENDER].map((gender) => {
    return {
      label: translate(`profile.${BasicInformationFields.GENDER}.options.${gender}`),
      value: gender,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateGender.set(event.target.value);
  };

  return (
    <div className={`${className}`}>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={gender ? [gender] : []} onChange={handleChoice} />
    </div>
  );
};
GenderSelector.displayName = 'GenderSelector';
