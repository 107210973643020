import styles from './styles.module.css'
import { FeedIcon } from 'src/icons'
import { ProfileIcon } from 'src/icons'
import { I18nTranslationsService } from 'src/services'
import { FeedNavbar } from 'src/state/FeedNavbar'

export const FeedMenu: view = ({
  activeTab = observe.feed.activeTab,
  setActiveTab = update.feed.activeTab,
  translate = I18nTranslationsService.translate,
  language = observe.language.value,
}) => {
  const feedNav = [
    {
      name: translate(`navbar.${FeedNavbar.MAIN_FEED}`),
      active: FeedNavbar.MAIN_FEED === activeTab ? true : false,
      handler: () => setActiveTab.set(FeedNavbar.MAIN_FEED),
    },
    {
      name: translate(`navbar.${FeedNavbar.PERSONALIZED}`),
      active: FeedNavbar.PERSONALIZED === activeTab ? true : false,
      handler: () => setActiveTab.set(FeedNavbar.PERSONALIZED),
    },
    {
      name: translate(`navbar.${FeedNavbar.FAVOURITES}`),
      active: FeedNavbar.FAVOURITES === activeTab ? true : false,
      handler: () => setActiveTab.set(FeedNavbar.FAVOURITES),
    },
  ]

  return (
    <>
      <div className={styles['feed-categories-selector']} lang={language}>
        {feedNav.map(({ name, active, handler }) => (
          <a key={Math.random()} className={active ? styles.active : ''} onClick={handler}>
            {name}
          </a>
        ))}
      </div>
    </>
  )
}
