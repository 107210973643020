import { I18nTranslationsService } from '../../services';

export const setupLanguage: producer = async ({
  updateIsReady = update.language.isReady,
  updateLanguage = update.language.value,
  isReady = observe.language.isReady,
  TranslationsService = I18nTranslationsService,
}) => {
  if (isReady) return;

  try {
    await TranslationsService.initialize();

    updateLanguage.set(TranslationsService.getLanguage());
    updateIsReady.set(true);
  } catch (e) {
    console.error(e);
  }
};
