import { TranslationsDeps } from '../../../core/services';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { Button } from '../../elements';

import {
  StyledConceptSectionSecondary
} from './styles';

type Props = LanguageProps & TranslationsDeps;

export const ConceptSectionSecondary: view = ({
  language = observe.language.value,
  translationsService = I18nTranslationsService,
}: Props) => (
  <StyledConceptSectionSecondary lang={translationsService.toHTMLLang(language)}>
    <div className="concept-img">
      <span></span>
    </div>
    <div className="concept">
      <h2>{translationsService.translate('landing_page.concept_title')}</h2>
      <p>{translationsService.translate('landing_page.concept_text')}</p>
      <Button
        className="button button-secondary"
        onClick={() => {
          window.location.href = '/concept';
        }}
      >
        {translationsService.translate('landing_page.read_more')}
      </Button>
    </div>
  </StyledConceptSectionSecondary>
);
