import styled from 'styled-components';
import { theme } from '../../../globalStyle';
import conceptImg from '../../../../assets/jtdiva/enkel-festsminke.jpg';

export const StyledConceptSectionSecondary = styled.div`
  display: flex;
  height: 676px;

  >div {
    width: 50%;
  }

  
  .concept {
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    padding: 0 120px;
  }

  h2 {
    max-width: 250px;
    margin-bottom: ${theme.spacing.xl};
    text-align: center;
    line-height: 1.3;
  }

  p {
    max-width: 500px;
    margin-bottom: ${theme.spacing.xl};
    text-align: center;
    line-height: 24px;
  }

  .concept-img span {
    background-image: url(${conceptImg});;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    height: 100%;
    width: 100%;
    display: inline-block;
  }

  @media ${theme.queries.tablet} {
    flex-direction: column;
    height: auto;

    > div {
      width: 100%;
    }

    .concept-img span {
      height: 420px;
      backgorund-position: top center;
    }

    .concept {
      padding: 48px 20px 96px;
    }
  }

  @media ${theme.queries.mobile} {
    
    .concept {
      h2 {
        font-size: 32px;
        margin-bottom: ${theme.spacing.l};
      }

      p {
        margin-bottom: ${theme.spacing.l};
      }
    }

    .concept-img span {
      height: 320px;
      backgorund-position: top center;
    }
  }
`;

