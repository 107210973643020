import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Needs, NeedsFields } from 'src/types/questionnaire/needs';

export const HelpFormalClothesSelector: view = ({
  helpFormalClothes = observe.profile.attributes.helpFormalClothes,
  updateHelpFormalClothes = update.ui.profile.attributes.helpFormalClothes,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'helpFormalClothes';
  const label = translate('profile.questionnaire.needs.helpFormalClothes.label');
  const choices = Needs[NeedsFields.HELP_FORMAL_CLOTHES].map((answer) => {
    return {
      label: translate(`profile.questionnaire.needs.${NeedsFields.HELP_FORMAL_CLOTHES}.options.${answer}`),
      value: answer,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateHelpFormalClothes.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={helpFormalClothes ? [helpFormalClothes] : []} onChange={handleChoice} />
    </div>
  );
};
HelpFormalClothesSelector.displayName = 'HelpFormalClothesSelector';
