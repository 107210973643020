import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Needs, NeedsFields } from 'src/types/questionnaire/needs';

export const HeightChallengeSelector: view = ({
  heightChallenge = observe.profile.attributes.heightChallenge,
  updateHeightChallenge = update.ui.profile.attributes.heightChallenge,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'heightChallenge';
  const label = translate('profile.questionnaire.needs.heightChallenge.label');
  const choices = Needs[NeedsFields.HEIGHT_CHALLENGE].map((reason) => {
    return {
      label: translate(`profile.questionnaire.needs.${NeedsFields.HEIGHT_CHALLENGE}.options.${reason}`),
      value: reason,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateHeightChallenge.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={heightChallenge ? [heightChallenge] : []} onChange={handleChoice} />
    </div>
  );
};
HeightChallengeSelector.displayName = 'HeightChallengeSelector';
