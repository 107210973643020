import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { BiUserCircle } from 'react-icons/bi';
import { MdPermMedia, MdViewModule, MdDynamicFeed } from 'react-icons/md';
import { Link, withRouter } from 'react-router-dom';
import { AuthActionType, User } from '../../core/domain';
import { TranslationsDeps } from '../../core/services';

import { AppProps, Language, LanguageProps, Routes } from '../../core/types';
import { theme } from '../../globalStyle';
import { I18nTranslationsService } from '../../services';

import { AuthModalState } from '../Auth';
import { FlexContainer } from '../elements';
import { LanguageSelector } from '../Language';
import { Logo } from '../Logo';

import {
  StyledActionsWrapper,
  StyledLogoContainer,
  StyledNavbar,
  StyledNavbarBottomTab,
  StyledNavbarBottomTabs,
  StyledNavbarDropdownContent,
  StyledNavbarDropdownIcon,
  StyledNavbarDropdownItem,
  StyledNavbarHead,
  StyledNavbarOptionsWrapper,
  StyledNavbarProfileDropdown,
  StyledNavbarProfileIcon,
  StyledNavbarProfileName,
  StyledNavbarProfileWrapper,
  StyledProfileActions,
  StyledSignInCta,
} from './styles';

const options = [
  {
    label: 'dashboard',
    href: '/dashboard',
    icon: MdViewModule,
  },
  {
    label: 'feed',
    href: '/feed',
    icon: MdDynamicFeed,
  },
  {
    label: 'media_library',
    href: '/media-library',
    icon: MdPermMedia,
  },
];

type StateProps = {
  readonly isDropdownToggled?: boolean;
  readonly language: Language;
  readonly isMobileOrTablet?: boolean;
  readonly user?: User;
  updateMediaQuery: Update<string>;
  updateIsDropdownToggled: Update<boolean>;
  updateAuthModalState: Update<AuthModalState>;
  updateSignOutTrigger: Update<number>;
};

type Props = AppProps & StateProps & LanguageProps & TranslationsDeps;

const Component: view<Props> = ({
  history,
  isDropdownToggled = observe.utils.components.navbar.isDropdownToggled,
  isMobileOrTablet = observe.utils.hooks.useMediaQuery.matches,
  user = observe.user,
  updateMediaQuery = update.utils.hooks.useMediaQuery.query,
  updateIsDropdownToggled = update.utils.components.navbar.isDropdownToggled,
  updateAuthModalState = update.utils.components.authModal.state,
  updateSignOutTrigger = update.auth[AuthActionType.SIGN_OUT].trigger,
  language = observe.language.value,
  translationsService = I18nTranslationsService,
}: Props) => {
  updateMediaQuery.set(theme.queries.tablet);

  const activeTab = options.find(
    (option) => option.href === history.location.pathname
  );

  const showDropdown = () => {
    updateIsDropdownToggled.set(true);
  };
  const hideDropdown = () => {
    updateIsDropdownToggled.set(false);
  };

  const mountSignIn = () =>
    updateAuthModalState.set({
      isOpen: true,
      isSignInScreenMounted: true,
    });

  const signOut = () => {
    updateSignOutTrigger.set(Date.now());
  };

  return (
    <StyledNavbar
      role="navigation"
      lang={translationsService.toHTMLLang(language)}
    >
      <StyledNavbarHead>
        <StyledLogoContainer>
          <Logo />
        </StyledLogoContainer>
      </StyledNavbarHead>
      {!isMobileOrTablet && user && (
        <StyledNavbarOptionsWrapper>
          {options.map(({ href, label }) => (
            <Link to={href} key={`navbar_item_${label}`}>
              {translationsService.translate(`navbar.${label}`)}
            </Link>
          ))}
        </StyledNavbarOptionsWrapper>
      )}
      {isMobileOrTablet && user && (
        <StyledNavbarBottomTabs>
          {options.map(({ href, label, icon }) => (
            <Link to={href} key={`bottom_tab_${label}`}>
              <StyledNavbarBottomTab isActive={activeTab?.label === label}>
                <span>{icon({})}</span>
                <span>{translationsService.translate(`navbar.${label}`)}</span>
              </StyledNavbarBottomTab>
            </Link>
          ))}
        </StyledNavbarBottomTabs>
      )}
      <FlexContainer>
        <StyledNavbarProfileWrapper>
          {user ? (
            <StyledProfileActions
              className="profile-actions"
              isActive={isDropdownToggled}
              onMouseEnter={showDropdown}
              onTouchStart={showDropdown}
              onTouchEnd={hideDropdown}
            >
              <BiUserCircle />
              <StyledNavbarProfileName>
                {user?.attributes.given_name || 'User'}
              </StyledNavbarProfileName>
              <StyledNavbarDropdownIcon>
                {isDropdownToggled ? <FaAngleUp /> : <FaAngleDown />}
              </StyledNavbarDropdownIcon>
            </StyledProfileActions>
          ) : (
            <StyledActionsWrapper>
              <LanguageSelector />
              <StyledSignInCta onClick={mountSignIn}>
                {translationsService.translate('login.sign_in')}
              </StyledSignInCta>
            </StyledActionsWrapper>
          )}
          {isDropdownToggled && (
            <StyledNavbarProfileDropdown
              className={isDropdownToggled ? 'toggled' : ''}
            >
              <StyledNavbarDropdownContent
                onMouseLeave={hideDropdown}
                onTouchEnd={hideDropdown}
              >
                <StyledNavbarDropdownItem>
                  <Link to={Routes.PROFILE}>
                    {translationsService.translate('login.profile')}
                  </Link>
                </StyledNavbarDropdownItem>
                <StyledNavbarDropdownItem onClick={signOut}>
                  <span>{translationsService.translate('login.sign_out')}</span>
                </StyledNavbarDropdownItem>
                <StyledNavbarDropdownItem>
                  <LanguageSelector />
                </StyledNavbarDropdownItem>
              </StyledNavbarDropdownContent>
            </StyledNavbarProfileDropdown>
          )}
        </StyledNavbarProfileWrapper>
      </FlexContainer>
    </StyledNavbar>
  );
};

export const Navbar = withRouter(Component);
