import { authModalInitialState } from '../../../components';
import { AuthActionType, AuthFields } from '../../../core/domain';
import { SweetAlertService } from '../../../services';

export const signUpSuccessAction: producer = async ({
  result = observe.auth[AuthActionType.SIGN_UP].result,
  updateResult = update.auth[AuthActionType.SIGN_UP].result,
  email = get.auth[AuthActionType.SIGN_UP].fields[AuthFields.EMAIL],
  updateAuthModalState = update.utils.components.authModal.state,
  AlertService = SweetAlertService,
}) => {
  if (!(result && result.isOk)) return;

  localStorage.setItem('email', email.value());

  await AlertService.success(
    result.successMessage || 'Account created successfully'
  );

  updateAuthModalState.set({
    ...authModalInitialState,
    isOpen: true,
    isConfirmAccountFormMounted: true,
  });

  updateResult.remove();
};
