export enum InformationFields {
  PERSONALITY_TRAIT = 'personalityTrait', //
  ILLNESS = 'illness', //
  STAND_OUT = 'standOut',
  DISEASE_PERCEPTION = 'diseasePerception', //
  APPEARANCE_TRAITS = 'appearanceTraits', //
}

export const Information = {
  [InformationFields.PERSONALITY_TRAIT]: [
    'happy',
    'stressed',
    'thoughtful',
    'kind',
    'caring',
    'downhearted',
    'stubborn',
    'shy',
    'outgoing',
    'lazy',
    'active',
    'dutiful',
    'irresponsible',
    'insecure',
    'confident',
  ],
  [InformationFields.ILLNESS]: ['yes', 'no'],
  [InformationFields.STAND_OUT]: ['stand-out', 'dazzle', 'depends-on-the-setting'],
  [InformationFields.DISEASE_PERCEPTION]: [
    'yes-in-a-positive-way',
    'yes-in-a-negative-way',
    'no-in-a-positive-way',
    'no-in-a-negative-way',
    'not-sure',
  ],
  [InformationFields.APPEARANCE_TRAITS]: [
    'face',
    'hair',
    'breasts',
    'booty',
    'hips',
    'thighs',
    'calves',
    'arms',
    'shoulders',
    'back',
    'waist',
  ],
}
