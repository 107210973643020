import { I18nTranslationsService, ProfilesHttpApiService } from '../../../services';

import { FavouriteIcon, FavouriteIconActive } from 'src/icons';

import { VideoActionButton } from 'src/generic_components/VideoActionButton';

export const Saved: view = ({
  id,
  isSaved = observe.isSaved,
  updateIsSaved = update.isSaved,
  updateErrorNotificationState = update.errorNotificationState,
  translate = I18nTranslationsService.translate,
}) => {
  const handleClick = async () => {
    try {
      updateIsSaved.set({ ...isSaved, [id]: false });

      const res = await ProfilesHttpApiService.removeSavedVideo({ videoId: id });

      if (!Object.keys(res).length) throw new Error();

      updateErrorNotificationState.set({
        triggerNotification: true,
        notificationMessage: translate('notifications.RemovedVideo'),
        severity: 'success',
      });
    } catch (err) {
      updateErrorNotificationState.set({
        triggerNotification: true,
        notificationMessage: translate('notifications.Error'),
        severity: 'error',
      });

      updateIsSaved.set({ ...isSaved, [id]: true });
    }
  };

  return (
    <VideoActionButton key={'saved'} title={translate('media_library.Added')} onClick={handleClick}>
      <div className="saved-video">
        <FavouriteIconActive />
      </div>
      {/* <p>{translate('media_library.Saved')}</p> */}
    </VideoActionButton>
  );
};
