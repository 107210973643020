import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledHeading = styled.h1`
  ${theme.heading.h1}
  margin: ${theme.spacing.m} 0;
  text-align: center;

  @media ${theme.queries.tablet} {
    ${theme.heading.h3}
    margin: 0 0 ${theme.spacing.l};
  }

  @media ${theme.queries.mobile} {
    ${theme.heading.h4}
  }
`;
