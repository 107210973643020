export enum ApiActionType {
  FIND_PROFILE = 'FIND_PROFILE',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  CREATE_PROFILE_MODULE = 'CREATE_PROFILE_MODULE',
  UPDATE_PROFILE_MODULE = 'UPDATE_PROFILE_MODULE',
  DELETE_PROFILE = 'DELETE_PROFILE',

  FIND_VIDEO = 'FIND_VIDEO',
  SEARCH_VIDEOS = 'SEARCH_VIDEOS',
  GET_PERSONALIZED_CONTENT = 'GET_PERSONALIZED_CONTENT',
  FIND_ARTICLE = 'FIND_ARTICLE',
  SEARCH_ARTICLES = 'SEARCH_ARTICLES',

  FIND_MODULE = 'FIND_MODULE',
  GET_MODULES = 'GET_MODULES',

  CREATE_CUSTOMER_PORTAL = 'CREATE_CUSTOMER_PORTAL',
  CREATE_CHECKOUT_SESSION = 'CREATE_CHECKOUT_SESSION',

  UPDATE_PLAYER_OPTIONS = 'UPDATE_PLAYER_OPTIONS',
}
