import { RouteName } from '../../../core/types';

export const mediaLibraryDidMount: producer = async ({
  isLoading = observe.pages[RouteName.MEDIA_LIBRARY].isLoading,
  videos = observe.videos,
  updateIsLoading = update.pages[RouteName.MEDIA_LIBRARY].isLoading,
}) => {
  if (!isLoading || !videos) return;

  updateIsLoading.set(false);
};
