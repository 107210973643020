import { Slide } from 'src/newDesign/Onboarding/Slide';
import { I18nTranslationsService } from 'src/services';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import hairIcon from '../../../../assets/jtdiva/q-hair.png';
import { FaceShapeSelector } from 'src/profileQuestionnaire/baseProfile/fields/FaceShapeSelector';
import { HairDescriptionSelector } from 'src/profileQuestionnaire/baseProfile/fields/HairDescriptionSelector';

export const DescribeFaceAndHair: view = ({ hideBackButton, translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.base_profile.describe_body')} hideBackButton={hideBackButton}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.base_profile.face_and_hair')}
        cardDescription={translate('profile.questionnaire.base_profile.face_and_hair_description')}
        img={hairIcon}
      >
        <FaceShapeSelector />
        <HairDescriptionSelector />
      </QuestionCard>
    </Slide>
  );
};
