import { I18nTranslationsService } from '../../../services'
import { LikesHttpApiService } from '../../../services'
import { LikedIcon, LikeIcon } from 'src/icons'

import { VideoActionButton } from 'src/generic_components/VideoActionButton'
import { VisibleIf } from 'src/generic_components/VisibileIf'

export const Like: view = ({
  id,
  translate = I18nTranslationsService.translate,
  videoLikes = observe.likedVideos[arg.id],
  updateVideoLike = update.likedVideos[arg.id],
  updateErrorNotificationState = update.errorNotificationState,
}) => {

  const unlike = async () => {
    try {
      updateVideoLike.set({
        likes: videoLikes ? videoLikes.likes - 1 : 0,
        liked: false,
      })

      const res = await LikesHttpApiService.unlikePost(id)

      if (Object.keys(res).length) throw new Error()

      updateErrorNotificationState.set({
        triggerNotification: true,
        notificationMessage: translate('notifications.UnlikeNotification'),
        severity: 'success',
      })
    } catch (err) {
      updateErrorNotificationState.set({
        triggerNotification: true,
        notificationMessage: translate('notifications.Error'),
        severity: 'error',
      })

      updateVideoLike.set({ likes: (videoLikes?.likes || 0) + 1, liked: true })
    }
  }
  const like = async () => {
    try {
      updateVideoLike.set({ likes: (videoLikes?.likes || 0) + 1, liked: true })

      const res = await LikesHttpApiService.likePost(id)

      if (!Object.keys(res).length) throw new Error()

      updateErrorNotificationState.set({
        triggerNotification: true,
        notificationMessage: translate('notifications.LikeNotification'),
        severity: 'success',
      })
    } catch (err) {
      updateErrorNotificationState.set({
        triggerNotification: true,
        notificationMessage: translate('notifications.Error'),
        severity: 'error',
      })

      updateVideoLike.set({
        likes: videoLikes ? videoLikes.likes - 1 : 0,
        liked: false,
      })
    }
  }

  return (
    <VideoActionButton key={'like'} title={translate('media_library.LikeVideo')} onClick={videoLikes?.liked ? unlike : like}>
      <VisibleIf predicate={videoLikes?.liked}>
        <LikedVideo likes={videoLikes.likes}/>
      </VisibleIf>
      <VisibleIf predicate={!videoLikes || videoLikes.likes < 1}>
        <NoLikesVideo/>
      </VisibleIf>
      <VisibleIf predicate={videoLikes?.likes > 0 && !videoLikes?.liked}>
        <LikedByOthersVideo likes={videoLikes.likes}/>
      </VisibleIf>
      {/*{!videoLikes ? (*/}
      {/*  <div className="like-button">*/}
      {/*    <LikedIcon/>*/}
      {/*    <span>{videoLikes?.likes || 0}</span>*/}
      {/*  </div>*/}
      {/*) : (*/}
      {/*  <div className="default-like-button">*/}
      {/*    <LikeIcon/>*/}
      {/*  </div>*/}
      {/*)}*/}
    </VideoActionButton>
  )
}

const LikedVideo = ({ likes }) => (
  <div className="liked-video">
    <LikedIcon/>
    <p>{likes}</p>
  </div>
)

// const NoLikesVideo = () => (
//   <div className="default-like-button">
//     <LikeIcon/>
//   </div>
// )
const NoLikesVideo = () => (
  <div className="default-like-button">
    <LikeIcon/>
  </div>
)

const LikedByOthersVideo = ({ likes }) => (
  <div className="like-button">
    <LikedIcon/>
    <span>{likes}</span>
  </div>
)

