import { ApiActionType, RouteName } from '../../../core/types';

export const dashboardGetInitialProps: producer = ({
  trigger = observe.pages[RouteName.DASHBOARD].trigger,
  updateTrigger = update.pages[RouteName.DASHBOARD].trigger,
  updateIsLoading = update.pages[RouteName.DASHBOARD].isLoading,
  getModulesTrigger = update.api[ApiActionType.GET_MODULES].trigger,
  getProfileTrigger = update.api[ApiActionType.FIND_PROFILE].trigger,
}) => {
  if (!trigger) return;

  updateTrigger.remove();

  document.title = 'Diva - Modules';
  updateIsLoading.set(true);

  getModulesTrigger.set(Date.now());
  getProfileTrigger.set(Date.now());
};
