import { Slide } from 'src/newDesign/Onboarding/Slide';
import { I18nTranslationsService } from 'src/services';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import eyesIcon from '../../../../assets/jtdiva/q-eyes.png';
import { EyesShapeSelector } from 'src/profileQuestionnaire/baseProfile/fields/EyesShapeSelector';
import { EyesColorSelector } from 'src/profileQuestionnaire/baseProfile/fields/EyesColorSelector';

export const DescribeEyes: view = ({ hideBackButton, translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.base_profile.describe_body')} hideBackButton={hideBackButton}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.base_profile.eyes')}
        cardDescription={translate('profile.questionnaire.base_profile.eyes_description')}
        img={eyesIcon}
      >
        <EyesShapeSelector />
        <EyesColorSelector />
      </QuestionCard>
    </Slide>
  );
};
