import { modalInitialState } from '../../../components';
import { Profile } from '../../../core/domain';
import { ApiActionType, Routes } from '../../../core/types';
import { SweetAlertService } from '../../../services';

export const updateProfileSuccessAction: producer = async ({
  history,
  result = observe.api[ApiActionType.UPDATE_PROFILE].result,
  updateResult = update.api[ApiActionType.UPDATE_PROFILE].result,
  isEditMode = get.utils.components.profileInfo.isEditMode,
  updateIsEditMode = update.utils.components.profileInfo.isEditMode,
  updateProfile = update.profile,
  updateAvatarProfileModalState = update.utils.components.avatarProfileModal.state,
  AlertService = SweetAlertService,
}) => {
  if (!(result && result.isOk)) return;

  const profile: Profile = result.value;
  // updateProfile.set(profile);

  await AlertService.success('Profile updated successfully');

  if (isEditMode) {
    updateIsEditMode.set(false);
  }

  if (history.location.pathname === Routes.ONBOARDING) {
    history.push(Routes.FEED);
  }
  updateAvatarProfileModalState.set(modalInitialState);
  updateResult.remove();
};
