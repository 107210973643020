import { path } from '@c11/engine.runtime';

export const debug: producer = ({
  getValue = get[param.path],
  setValue = update[param.path],
}) => {
  const makePath = (str = '') => {
    const parts = str.split('.');
    return parts.reduce((acc, x) => {
      // eslint-disable-next-line no-param-reassign
      acc = acc[x];
      return acc;
    }, path);
  };

  (window as any).get = (str: string) =>
    getValue.value({ path: makePath(str) });
  (window as any).set = (str: string, value: any) =>
    setValue.set(value, { path: makePath(str) });
};
