import { ApiActionType, resultFormatter } from '../../../core/types';
import { ProfilesHttpApiService } from '../../../services';

export const createProfileModuleAction: producer = async ({
  trigger = observe.api[ApiActionType.CREATE_PROFILE_MODULE].trigger,
  request = observe.api[ApiActionType.CREATE_PROFILE_MODULE].data.request,
  updateTrigger = update.api[ApiActionType.CREATE_PROFILE_MODULE].trigger,
  updateIsLoading = update.api[ApiActionType.CREATE_PROFILE_MODULE].isLoading,
  updateResult = update.api[ApiActionType.CREATE_PROFILE_MODULE].result,
  ProfilesApiService = ProfilesHttpApiService,
}) => {
  if (!(trigger && request)) return;

  updateTrigger.remove();
  updateIsLoading.set(true);

  try {
    const profile = await ProfilesApiService.createProfileModule(request);

    updateResult.set(resultFormatter.ok(profile));
  } catch (e: unknown) {
    updateResult.set(resultFormatter.error((e as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
