import { TranslationsDeps, TranslationsService } from '../../../core/services';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { nameToTranslationKey } from '../../../utils';
import { Heading } from '../../elements';

import {
  StyledSubscriptionContainer,
  StyledSubscriptionInfo,
  StyledSubscriptionTiers,
} from './styles';

type TierType = {
  label: string;
  benefits: string[];
  prices: string[];
  translationsService?: TranslationsService;
};

const tiers: TierType[] = [
  {
    label: 'Diva',
    benefits: [
      'Access to user profile',
      'Comprehensive content',
      'Tailored videos on demand',
    ],
    prices: ['Free'],
  },
  {
    label: 'Diva VIP',
    benefits: [
      'Unlimited access to all content in Diva Universe',
      'Exclusive content',
      'Tailored advices from Jan Thomas',
      'Exclusive events only for Diva VIP',
      'Exclusive offers and discount codes',
    ],
    prices: ['69 NOK / month', '700 NOK / year'],
  },
];

const Tier = ({
  translationsService = I18nTranslationsService,
  label,
  benefits,
  prices,
}: TierType) => (
  <div className="tier">
    <h6 className="label">
      {translationsService.translate(
        `home_page.subscription.tiers.${nameToTranslationKey(label)}.label`
      )}
    </h6>
    <ul className="benefits">
      {benefits.map((benefit, index) => (
        <li key={benefit}>
          {translationsService.translate(
            `home_page.subscription.tiers.${nameToTranslationKey(
              label
            )}.benefits.${index}`
          )}
        </li>
      ))}
    </ul>
    <ul className="prices">
      <span>
        {translationsService.translate('home_page.subscription.price')}:
      </span>
      {prices.map((price, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <li key={Math.random().toLocaleString()}>
              {translationsService.translate('global.or')}
            </li>
          )}
          <li key={price}>
            {translationsService.translate(
              `home_page.subscription.tiers.${nameToTranslationKey(
                label
              )}.prices.${index}`
            )}
          </li>
        </React.Fragment>
      ))}
    </ul>
  </div>
);

type Props = LanguageProps & TranslationsDeps;

export const Subscription: view = ({
  language = observe.language.value,
  translationsService = I18nTranslationsService,
}: Props) => (
  <StyledSubscriptionContainer lang={translationsService.toHTMLLang(language)}>
    <StyledSubscriptionInfo>
      <Heading>
        {translationsService.translate('home_page.subscription.title')}
      </Heading>
      <p className="info">
        {translationsService.translate('home_page.subscription.info')}
      </p>
    </StyledSubscriptionInfo>
    <StyledSubscriptionTiers>
      {tiers.map(({ label, benefits, prices }) => (
        <Tier key={label} label={label} benefits={benefits} prices={prices} />
      ))}
    </StyledSubscriptionTiers>
  </StyledSubscriptionContainer>
);
