import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { BaseProfile, BaseProfileFields } from 'src/types/questionnaire/baseProfile';

export const SkinTypeSelector: view = ({
  skinType = observe.profile.attributes.skinType,
  updateSkinType = update.ui.profile.attributes.skinType,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'skinType';
  const label = translate('profile.questionnaire.base_profile.skinType.label');
  const choices = BaseProfile[BaseProfileFields.SKIN_TYPE].map((type) => {
    return {
      label: translate(`profile.questionnaire.base_profile.${BaseProfileFields.SKIN_TYPE}.options.${type}`),
      value: type,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateSkinType.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={skinType ? [skinType] : []} onChange={handleChoice} />
    </div>
  );
};
SkinTypeSelector.displayName = 'SkinTypeSelector';
