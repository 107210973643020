import { ApiActionType, resultFormatter } from '../../../core/types';
import { PaymentHttpApiService } from '../../../services';

export const createCheckoutSessionAction: producer = async ({
  trigger = observe.api[ApiActionType.CREATE_CHECKOUT_SESSION].trigger,
  checkoutPlan = get.api[ApiActionType.CREATE_CHECKOUT_SESSION].checkoutPlan,
  updateTrigger = update.api[ApiActionType.CREATE_CHECKOUT_SESSION].trigger,
  updateIsLoading = update.api[ApiActionType.CREATE_CHECKOUT_SESSION].isLoading,
  updateResult = update.api[ApiActionType.CREATE_CHECKOUT_SESSION].result,
  PaymentApiService = PaymentHttpApiService,
}) => {
  if (!trigger) return;

  const plan = checkoutPlan.value();
  console.log('Creating checkout session for plan: ', plan);
  const req = {
    redirectUrl: window.location.href,
    subscriptionPlan: plan,
  };

  updateTrigger.remove();
  updateIsLoading.set(true);

  try {
    const { checkoutSessionUrl } =
      await PaymentApiService.createCheckoutSession(req);
    window.location.href = checkoutSessionUrl;
  } catch (e: unknown) {
    updateResult.set(resultFormatter.error((e as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
