import { Heading } from 'src/generic_components/Heading';
import { I18nTranslationsService } from 'src/services';
import { Description } from 'src/generic_components/Description';
import { VisibleIf } from 'src/generic_components/VisibileIf';
import styles from './styles.module.css';
import { Button } from 'src/generic_components/Button';
import { RightArrow } from 'src/icons';
import { useHistory } from 'react-router-dom';
import { Routes } from 'src/core/types';

export const Slide: view = ({ mainHeading, mainDescription, hideBackButton, children }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(Routes.PROFILE);
  };

  return (
    <div className={styles['onboarding-step']}>
      <VisibleIf predicate={mainHeading}>
          <div className={styles['module-description']}>
          {!hideBackButton && (
              <Button variant="icon-button" onClick={handleClick}>
                <RightArrow />
              </Button>
            )}
            <Heading text={mainHeading} />
        </div>
      </VisibleIf>
      {/* <VisibleIf predicate={mainDescription}>
        <Description text={mainDescription}/>
      </VisibleIf> */}
      <div className={styles['onboarding-form']}>{children}</div>
    </div>
  );
};
