import { Slide } from 'src/newDesign/Onboarding/Slide';
import { I18nTranslationsService } from 'src/services';
import { Button } from '../../../generic_components/Button';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import { RightArrow } from 'src/icons';
import styles from '../styles.module.css';
import { NameInput } from 'src/components/Profile/BasicInformation/fields/NameInput';
import { GenderSelector } from 'src/components/Profile/BasicInformation/fields/GenderSelector';
import { AgeSelector } from 'src/components/Profile/BasicInformation/fields/AgeSelector';
import { onboardingFlows } from 'src/types/onboarding';

export const BasicInformation: view = ({ hideBackButton, translate = I18nTranslationsService.translate }) => {
  return (
    <Slide hideBackButton={hideBackButton}>
      <QuestionCard
        cardHeading={translate('onboarding.basicInformation.cardHeading')}
        cardDescription={translate('onboarding.basicInformation.cardDescription')}
        extraClasses={styles['welcome-card-header']}
      >
        <NameInput />
        <GenderSelector />
        <AgeSelector />
      </QuestionCard>
      {/*
        <InputField
          name="phone"
          label={translate('onboarding.basicInformation.phone')}
          required={true}
          placeholder={translate('onboarding.basicInformation.phonePlaceholder')}
          classNames="w-full"
          inputExtraClassNames="w-full"
          onChange={(value) => {updateInfo.set(value, { field: 'phone' })}}
        /> */}

      <StartBaseProfile />
    </Slide>
  );
};

export const StartBaseProfile: view = ({
  fullName = observe.profile.fullName,
  gender = observe.profile.gender,
  age = observe.profile.age,
  updateOnboardingCompleted = update.ui.profile.onboardingCompleted,
  nextStep = update.ui.onboarding.activeFlow,
  translate = I18nTranslationsService.translate,
}) => {
  const validForm = fullName && age && gender;
  return (
    <Button
      disabled={!validForm}
      onClick={() => {
        if (!validForm) return;
        nextStep.set(onboardingFlows.BASE_PROFILE);
        updateOnboardingCompleted.set(true);
      }}
    >
      {translate('generic.start')}
      <RightArrow />
    </Button>
  );
};
