import { authModalInitialState } from '../../../components';
import { AuthActionType } from '../../../core/domain';
import { SweetAlertService } from '../../../services';
import { ErrorLoggerService } from '../../../services/ErrorLoggerService';

export const socialSignInErrorAction: producer = async ({
  result = observe.auth[AuthActionType.SOCIAL_SIGN_IN].result,
  updateAuthModalState = update.utils.components.authModal.state,
  AlertService = SweetAlertService,
}) => {
  if (!(result && result.error)) return;

  ErrorLoggerService.logError(result.error);

  await AlertService.error(result.error);

  updateAuthModalState.set({
    ...authModalInitialState,
    isOpen: true,
    isSignInFormMounted: true,
  });
};
