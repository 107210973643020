import { Slide } from 'src/newDesign/Onboarding/Slide';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import { I18nTranslationsService } from 'src/services';
import bodyIcon from '../../../../assets/jtdiva/q-body.png';
import { HelpMakeupQuickSelector } from '../fields/HelpMakeupQuickSelector';
import { HelpHairstyleCasualSelector } from '../fields/HelpHairstyleCasualSelector';
import { HelpHairstyleOccasionsSelector } from '../fields/HelpHairstyleOccasionsSelector';
import { PreferredHairColorsSelector } from '../fields/PreferredHairColorsSelector';
import { HairCareAdviceSelector } from '../fields/HairCareAdviceSelector';
import { HelpProductsUsageSelector } from '../fields/HelpProductsUsageSelector';

export const HairGuidance: view = ({ translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.needs.describe_needs')}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.needs.heading')}
        cardDescription={translate('profile.questionnaire.needs.description')}
      >
        <HelpMakeupQuickSelector />
        <HelpHairstyleCasualSelector />
        <HelpHairstyleOccasionsSelector />
        <PreferredHairColorsSelector />
        <HairCareAdviceSelector />
        <HelpProductsUsageSelector />
      </QuestionCard>
    </Slide>
  );
};
