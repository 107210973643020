import styled from 'styled-components';
import { theme } from '../../../globalStyle';

import vipIcon from '../../../../assets/jtdiva/vip-icon.svg';
import arrow from '../../../../assets/jtdiva/arrow-right.svg';
import button from '../../../../assets/jtdiva/faq-button.svg';
import buttonHover from '../../../../assets/jtdiva/faq-button-hover.svg';

export const StyledSubscriptionSecondary = styled.div`
  padding: 128px 20px 112px;
  background-color: ${theme.color.yellow["light-02"]};

  .subscription {
    max-width: 1208px;
    margin: 0 auto;

    .button {
      margin-top: 70px;
      display: flex;
      align-items: center;
      margin: auto auto 0;
    }

    .button-primary {
      padding: 16px 30px;
      line-height: 1.1;
    }
  }
  
  .subscriptionTypes {
    display: flex;
  }

  h2 {
    margin-bottom:${theme.spacing.xxl};
    text-align: center;
  }

  h3 {
    margin-bottom: ${theme.spacing.s};
    margin-top: auto;
  }

  .vip-icon {
    background-image: url(${vipIcon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    height: 30px;
    width: 40px;
    display: inline-block;
    margin-bottom: ${theme.spacing.s};
  }

  .arrow-icon {
    background-image: url(${arrow});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    height: 16px;
    width: 18px;
    display: inline-block;
    margin-left: 12px;
  }

  button {
    .vip-icon { 
      margin: 0 14px 0 0;
      width: 18px;
      height: 14px;
      flex-shrink: 0;
    }
  }

  .subscriptionType {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    padding: ${theme.spacing.xl};
    border: 1px solid #D3CBC0;
    text-align: center;

    p {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: ${theme.spacing.xl};
    }

  }

  .subscriptionType:first-child {
   padding-top: 84px;
  }

  .subscriptionType:last-child {
    border-color: transparent;
    background-color: ${theme.color.white};
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }

  .benefits {
    display: block;
    width: 80%;
    padding: 24px 0;
    border-top: 1px solid #F1E8DC;
    font-size: 18px;
    color: ${theme.color.purple["primary-02"]};

    &:last-of-type {
      margin-bottom: 50px
    }
  }

  .faq {
    max-width: 1208px;
    margin: 112px auto 0;
  }

  .question {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: ${theme.spacing.m};
    padding: ${theme.spacing.m} 0; 
    border-top: 1px solid #F1E8DC;

    > div {
      align-self: center;
    }
    
    .question-title {
      font-size: 18px;
    }

    .question-title + .response {
      margin-top: ${theme.spacing.s};
      margin-botton: ${theme.spacing.s};
      line-height: 1.2;
    }

    .faq-button {
      padding: 0;
      background-color: transparent;
      border: none;
      height: 48px;
      width: 44px;
      flex-shrink: 0;
      .button-icon-hover {
        display: none;
      }
      &:hover {
        .button-icon {
          display: none;
        }
        .button-icon-hover {
          display: inline-block;
        }
      }
    }

    .button-icon {
      background-image: url(${button});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right center;
      height: 100%;
      width: 100%;
      display: inline-block;
    }

    
    .button-icon-hover {
      background-image: url(${buttonHover});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right center;
      height: 100%;
      width: 100%;
      display: inline-block;
    }
  }
 
  @media ${theme.queries.tablet} { 
    padding: 48px 20px 96px;

    .subscription h2 {
      margin-bottom: ${theme.spacing.xl};
      font-size: 32px;
    }

    .subscriptionTypes {
      flex-direction: column
    }

    .subscriptionType {
      width: 100%;
      padding: ${theme.spacing.l};

      &:first-child {
        padding-top: ${theme.spacing.l};
      }

      p {
        margin-bottom: ${theme.spacing.l};
      }
    }

    .benefits {
      padding: ${theme.spacing.s} ${theme.spacing.l} ; 
      width: 100%;

      &:last-of-type {
        margin-bottom:  ${theme.spacing.l} 
     }
    }

    .faq {
      margin: 96px auto 0px;
    }

    .question .button-icon { 
      width: 32px;
      height: 32px;
    }

    .question .button-icon-hover {
      width: 32px;
      height: 32px;
    }

  }

`;

