import { CmsError } from '../../../errors';
import { CreateArticlePayload } from '../Article';

export const FindArticleQuery = (id: string) => `
{
  getArticle(where: {entryId: "${id}") {
    error {
      code,
      message,
      data,
    },
    data {
      entryId,
      image,
      content,
      name,
      tags { name },
      author { name },
      categories { name },
      createdOn,
    }
  }
}
`;

export interface FindArticleQueryResult {
  data: {
    getArticle: {
      error: CmsError;
      data: CreateArticlePayload;
    };
  };
}
