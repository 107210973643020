import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledProfileMetaWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${theme.queries.mobile} {
    align-items: center;
    justify-content: center;
  }
`;

export const StyledProfileMetaDatesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;

  & > span:first-child {
    margin-bottom: 4px;
  }
`;

export const StyledProfileMetaDate = styled.span`
  display: flex;
  margin-top: 4px;
  font: 500 16px ${theme.font.secondary};

  & > span:first-child {
    margin-right: 8px;
    font-weight: 400;
    color: ${theme.color.black};
  }

  & > span:last-child {
    font-weight: 500;
  }
`;

export const StyledProfileMetaName = styled.span`
  font: 600 30px ${theme.font.secondary};

  @media ${theme.queries.mobile} {
    font-size: 20px;
  }
`;

export const StyledProfileMetaEmail = styled.span`
  font: 500 24px ${theme.font.secondary};

  @media ${theme.queries.mobile} {
    font-size: 18px;
  }
`;
