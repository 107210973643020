import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Needs, NeedsFields } from 'src/types/questionnaire/needs';

export const WellDressedBetterDaySelector: view = ({
  wellDressedBetterDay = observe.profile.attributes.wellDressedBetterDay,
  updateWellDressedBetterDay = update.ui.profile.attributes.wellDressedBetterDay,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'wellDressedBetterDay';
  const label = translate('profile.questionnaire.needs.wellDressedBetterDay.label');
  const choices = Needs[NeedsFields.WELL_DRESSED_BETTER_DAY].map((answer) => {
    return {
      label: translate(`profile.questionnaire.needs.${NeedsFields.WELL_DRESSED_BETTER_DAY}.options.${answer}`),
      value: answer,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateWellDressedBetterDay.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={wellDressedBetterDay ? [wellDressedBetterDay] : []} onChange={handleChoice} />
    </div>
  );
};
WellDressedBetterDaySelector.displayName = 'WellDressedBetterDaySelector';
