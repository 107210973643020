import { TranslationsDeps } from '../../../core/services';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { Language } from '../../../core/types';
import { Button } from '../../elements';
import { Routes } from '../../../core/types';

import { StyledSubscriptionSecondary } from './styles';

type Props = LanguageProps &
  TranslationsDeps & {
    showQuestions: any;
    setShowQuestions: any;
    updateAuthModalState: any;
    subscribeRedirect: any;
  } & {
    translate: any;
  };

export const SubscriptionSecondary: view = ({
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
  updateAuthModalState = update.utils.components.authModal.state,
  showQuestions = observe.showQuestions,
  setShowQuestions = update.showQuestions,
}: Props) => {
  const questions: Array<Object> = [
    {
      id: 0,
      title: translate('landing_page.question_1'),
      response: translate('landing_page.response_1'),
    },
    {
      id: 1,
      title: translate('landing_page.question_2'),
      response: translate('landing_page.response_2'),
    },
    {
      id: 2,
      title: translate('landing_page.question_3'),
      response: translate('landing_page.response_3'),
    },
  ];

  return (
    <StyledSubscriptionSecondary>
      <section className="subscription">
        <h2> {translate('landing_page.subscription_h2_1')}</h2>
        <div className="subscriptionTypes">
          <div className="subscriptionType">
            <h3> {translate('landing_page.subscription_h3_1')} </h3>
            <p>{translate('landing_page.subscription_p_1')}</p>
            <span className="benefits">
              {translate('landing_page.subscription_benefits_1')}
            </span>
            <span className="benefits">
              {translate('landing_page.subscription_benefits_2')}
            </span>
            <span className="benefits">
              {translate('landing_page.subscription_benefits_3')}{' '}
            </span>
            <Button
              className="button button-secondary"
              onClick={() =>
                updateAuthModalState.set({
                  isOpen: true,
                  isSignUpScreenMounted: true,
                })
              }
            >
              {translate('landing_page.joinButton')}
            </Button>
          </div>
          <div className="subscriptionType">
            <span className="vip-icon"></span>
            <h3> {translate('landing_page.subscription_h3_2')} </h3>
            <p>{translate('landing_page.subscription_p_2')}</p>
            <span className="benefits">
              {translate('landing_page.subscription_benefits_4')}
            </span>
            <span className="benefits">
              {translate('landing_page.subscription_benefits_5')}
            </span>
            <span className="benefits">
              {translate('landing_page.subscription_benefits_6')}{' '}
            </span>
            <Button
              className="button button-primary"
              onClick={() => {
                updateAuthModalState.set({
                  isOpen: true,
                  isSignUpScreenMounted: true,
                });
                localStorage.setItem('subscribeRedirect', 'true');
              }}
            >
              <span className="vip-icon"></span>
              {translate('landing_page.joinButton')}
              <span className="arrow-icon"></span>
            </Button>
          </div>
        </div>
      </section>
      <section className="faq">
        {questions.map((question: any) => (
          <div key={Math.random()} className="question">
            <div>
              <p className="question-title">{question.title}</p>
              <p
                className="response"
                hidden={!showQuestions[question.id]}
                style={{ whiteSpace: 'pre-line' }}
              >
                {question.response}
              </p>
            </div>
            <Button
              className="faq-button"
              onClick={() =>
                setShowQuestions.set({
                  ...showQuestions,
                  [question.id]: !!!showQuestions[question.id],
                })
              }
            >
              <span className="button-icon"></span>
              <span className="button-icon-hover"></span>
            </Button>
          </div>
        ))}
      </section>
    </StyledSubscriptionSecondary>
  );
};
