import { Logo } from '../Logo';
import {
  StyledLoaderInnerTransition,
  StyledLoaderTransition,
  StyledLoaderWrapper,
} from './styles';

export const Loader: view = () => (
  <StyledLoaderWrapper>
    <Logo />
    <StyledLoaderTransition>
      <StyledLoaderInnerTransition>
        <span className="from h6" />
        <span className="to h3" />
      </StyledLoaderInnerTransition>
    </StyledLoaderTransition>
    <StyledLoaderTransition>
      <StyledLoaderInnerTransition>
        <span className="from h1" />
        <span className="to h4" />
      </StyledLoaderInnerTransition>
    </StyledLoaderTransition>
    <StyledLoaderTransition>
      <StyledLoaderInnerTransition>
        <span className="from h5" />
        <span className="to h2" />
      </StyledLoaderInnerTransition>
    </StyledLoaderTransition>
  </StyledLoaderWrapper>
);
