import {
  CreateCheckoutSessionRequest,
  PaymentApiService,
} from '../core/services';
import { AxiosService } from './AxiosService';

const createService = (): PaymentApiService => {
  const axiosInstance = AxiosService({
    baseURL: `/api/payment/subscription`,
  });

  return {
    async createCheckoutSession(
      req: CreateCheckoutSessionRequest
    ): Promise<{ checkoutSessionUrl: string }> {
      return axiosInstance.post<
        CreateCheckoutSessionRequest,
        { checkoutSessionUrl: string }
      >('/checkout/session', req);
    },

    async createCustomerPortal(
      redirectUrl: string
    ): Promise<{ customerPortalUrl: string }> {
      return axiosInstance.post<
        { redirectUrl: string },
        { customerPortalUrl: string }
      >('/customer-portal/session', { redirectUrl });
    },

    setAuthToken(authToken: string): void {
      axiosInstance.setAuthToken(authToken);
    },
  };
};

export const PaymentHttpApiService = Object.freeze(createService());
