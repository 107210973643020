import { Slide } from 'src/newDesign/Onboarding/Slide';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import { I18nTranslationsService } from 'src/services';
import bodyIcon from '../../../../assets/jtdiva/q-body.png';
import { FoundationPreferenceSelector } from '../fields/FoundationPreferenceSelector';
import { SkinLookPreferenceSelector } from '../fields/SkinLookPreferenceSelector';
import { EverydayMakeupSelector } from '../fields/EverydayMakeupSelector';
import { ShopSelector } from '../fields/ShopSelector';
import { PartyMakeupSelector } from '../fields/PartyMakeupSelector';
import { TimeOnMakeupSelector } from '../fields/TimeOnMakeupSelector';

export const Makeup: view = ({ translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.preferences.describe_preferences')}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.preferences.heading')}
        cardDescription={translate('profile.questionnaire.preferences.description')}
      >
        <FoundationPreferenceSelector />
        <SkinLookPreferenceSelector />
        <EverydayMakeupSelector />
        <PartyMakeupSelector />
        <TimeOnMakeupSelector />
        <ShopSelector />
      </QuestionCard>
    </Slide>
  );
};
