import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { ProfilesHttpApiService } from '../../../services';

import { StyledButton } from './styles';

import { TbTrash } from 'react-icons/tb';

type Props = LanguageProps & {
  id: string;
  translate: any;
  isSaved: any;
  setSaved: any;
  videoActionState: any;
};

export const Unsave: view<Props> = ({
  id,
  translate = I18nTranslationsService.translate,
  isSaved = observe.isSaved,
  setSaved = update.isSaved,
  videoActionState = update.videoActionState,
}: Props) => (
  <StyledButton
    onClick={async () => {
      const res = await ProfilesHttpApiService.removeSavedVideo({
        videoId: id,
      });

      Object.keys(res).length
        ? setSaved.set({ ...isSaved, [id]: false })
        : console.log('>>>>> error');

      videoActionState.set({
        notificationMessage: translate('notifications.RemovedVideo'),
        triggerNotification: true,
      });
    }}
  >
    <TbTrash size={20} />
    {/* {translate('media_library.Remove')} */}
  </StyledButton>
);
