export enum AuthFields {
  FIRST_NAME = 'firstName',
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  VERIFICATION_CODE = 'verificationCode',
  PROVIDER = 'provider',
}

export const PasswordConstraints = {
  min: 6,
  max: 18,
  pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
};
