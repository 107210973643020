import { Navigator } from 'src/generic_components/Navigator'
import { DescribeYourBody } from 'src/profileQuestionnaire/baseProfile/slides/Body'
import { DescribeFaceAndHair } from './slides/FaceAndHair'
import { DescribeEyes } from './slides/Eyes'
import { DescribeSkin } from './slides/Skin'
import { useHistory } from 'react-router-dom'
import { Routes } from 'src/core/types'
import { Loader } from 'src/components/Loader'
import { AreaOfInterest } from './slides/AreaOfInterest'
import { I18nTranslationsService } from 'src/services'

export const BaseProfile: view = ({
  attributesLoaded = observe.ui.dataLoaded.attributes,
  updateCurrentSlide = update.ui.navigator.baseProfile.currentSlide,
  language = observe.language.value,
  translate = I18nTranslationsService.translate
}) => {
  const history = useHistory()
  if (!attributesLoaded) return <Loader/>

  const slides = [<DescribeYourBody/>, <DescribeFaceAndHair/>, <DescribeEyes/>, <DescribeSkin/>, <AreaOfInterest/>]
  return (
    <div className="modules" lang={language}>
      <Navigator
        slides={slides}
        nextStep={() => history.push(Routes.PROFILE)}
        previousStep={() => history.push(Routes.PROFILE)}
        updateCurrentSlide={(currentSlide) => updateCurrentSlide.set(currentSlide)}
        lastSlideNextButtonText={translate('onboarding.base_profile.your_personalized_feed')}
        translate={translate}
      />
    </div>
  )
}
