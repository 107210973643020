import { AuthActionType } from '../../../core/domain';
import { resultFormatter, Routes } from '../../../core/types';
import { CognitoAuthService } from '../../../services';

export const authorizeAction: producer = async ({
  history,
  user = observe.user,
  trigger = observe.auth[AuthActionType.AUTHORIZE].trigger,
  updateTrigger = update.auth[AuthActionType.AUTHORIZE].trigger,
  updateIsLoading = update.auth[AuthActionType.AUTHORIZE].isLoading,
  updateResult = update.auth[AuthActionType.AUTHORIZE].result,
  AuthService = CognitoAuthService,
}) => {
  if (
    !trigger ||
    user ||
    history.location.pathname === Routes.LANDING ||
    history.location.pathname === Routes.CONCEPT
  )
    return;

  updateTrigger.remove();
  updateIsLoading.set(true);

  try {
    const currentSession = await AuthService.getCurrentUser();
    updateResult.set(resultFormatter.ok(currentSession));
  } catch (error: unknown) {
    updateResult.set(resultFormatter.error(error));
  } finally {
    updateIsLoading.set(false);
  }
};
