import { Slide } from 'src/newDesign/Onboarding/Slide';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import { I18nTranslationsService } from 'src/services';
import bodyIcon from '../../../../assets/jtdiva/q-body.png';
import { PersonalityTraitSelector } from '../fields/PersonalityTraitSelector';
import { AppearanceTraitsSelector } from '../fields/AppearanceTraitsSelector';
import { StandOutSelector } from '../fields/StandOutSelector';

export const Traits: view = ({ translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.information.describe_information')}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.information.heading')}
        cardDescription={translate('profile.questionnaire.information.description')}
      >
        <PersonalityTraitSelector />
        <StandOutSelector />
        <AppearanceTraitsSelector />
      </QuestionCard>
    </Slide>
  );
};
