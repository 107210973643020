import { TranslationsDeps } from '../../../core/services';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { Language } from '../../../core/types';

import { StyledFeaturesSecondary } from './styles';

type Props = LanguageProps &
  TranslationsDeps & {
    translate: any;
  };

export const FeaturesSecondary: view = ({
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  return (
    <StyledFeaturesSecondary>
      <div className="features-info">
        <h2>{translate('landing_page.feature_text_1')}</h2>
        <div>
          <div className="feature-type">
            <span></span>
            <p>{translate('landing_page.feature_text_2')}</p>
          </div>
          <div className="feature-type">
            <span></span>
            <p>{translate('landing_page.feature_text_3')}</p>
          </div>
        </div>
      </div>
      <span className="app-screen"></span>
    </StyledFeaturesSecondary>
  );
};
