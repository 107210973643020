import { CommentsApiService, Response } from '../types/CommentsApiService';
import { AxiosService } from '../../../services/AxiosService';
import { Comment } from '../types/Comment';

const createService = (): CommentsApiService => {
  const axiosInstance = AxiosService({});

  return <CommentsApiService>{
    async getCommentsByPostId(postId: string): Promise<Comment[]> {
      return axiosInstance.get<{ postId: string }, Comment[]>(
        `/api/post/${postId}/comments`
      );
    },

    async addComment(postId: string, payload: any): Promise<Response> {
      return axiosInstance.post<{ message: string }, Response>(
        `/api/post/${postId}/comment`,
        { message: payload.message }
      );
    },

    async editComment(postId: string, commentId: string, payload: any): Promise<Response> {
      return axiosInstance.put<{ message: string }, Response>(
        `/api/post/${postId}/comment/${commentId}`,
        { message: payload.message }
      );
    },

    async removeComment(postId: string, commentId: string): Promise<Object> {
      return axiosInstance.delete<{ message: string }, Object>(
        `/api/post/${postId}/comment/${commentId}`
      );
    },

    async likeComment(postId: string, commentId: string): Promise<Response> {
      return axiosInstance.post<{}, Response>(
        `/api/post/${postId}/comment/${commentId}/like`,
        {}
      );
    },

    async unlikeComment(postId: string, commentId: string): Promise<{}> {
      return axiosInstance.delete<{}, {}>(
        `/api/post/${postId}/comment/${commentId}/like`,
        {}
      );
    },

    async addReply(postId: string, payload: any): Promise<Response> {
      return axiosInstance.post<{ message: string; parent: string }, Response>(
        `/api/post/${postId}/comment`,
        { message: payload.message, parent: payload.parent }
      );
    },

    async flagComment(postId: string, commentId: string): Promise<Response> {
      return axiosInstance.post<{}, Response>(
        `/api/post/${postId}/comment/${commentId}/flag`,
        {}
      );
    },

    setAuthToken(authToken: string): void {
      axiosInstance.setAuthToken(authToken);
    },
  };
};

export const CommentsHttpApiService = Object.freeze(createService());
