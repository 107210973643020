import { FieldLabel } from 'src/generic_components/FieldLabel'
import { InputField } from 'src/generic_components/InputField'
import { I18nTranslationsService } from 'src/services/I18nTranslationsService'

export const NameInput: view = ({
  name = observe.profile.fullName,
  updateName = update.ui.profile.fullName,
  translate = I18nTranslationsService.translate,
}) => {
  const _name = 'name'
  const label = translate('profile.name.label')

  const onSubmitInput = (value: any) => {
    updateName.set(value);
  };

  return (
    <div>
      <FieldLabel htmlFor={_name} label={label} required={true}/>
      <InputField value={name} placeholder={translate('profile.name.placeholder')} onSubmitInput={onSubmitInput}/>
    </div>
  )
}
NameInput.displayName = 'NameInput'
