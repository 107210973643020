import { useHistory } from 'react-router-dom';
import { AuthActionType } from '../../../core/domain';
import { Routes, resultFormatter } from '../../../core/types';
import { CognitoAuthService, SweetAlertService } from '../../../services';
import { ErrorLoggerService } from '../../../services/ErrorLoggerService';
import { authModalInitialState } from 'src/components';

export const signOutAction: producer = async ({
  history,
  trigger = observe.auth[AuthActionType.SIGN_OUT].trigger,
  updateTrigger = update.auth[AuthActionType.SIGN_OUT].trigger,
  updateIsLoading = update.auth[AuthActionType.SIGN_OUT].isLoading,
  updateAuthModalState = update.utils.components.authModal.state,
  updateUser = update.user,
  AuthService = CognitoAuthService,
  AlertService = SweetAlertService,
}) => {
  if (!trigger) return;

  updateTrigger.remove();
  updateIsLoading.set(true);

  try {
    await AuthService.signOut();

    history.replace(Routes.LANDING);
    sessionStorage.clear();
    localStorage.clear();
    updateUser.remove();
    updateAuthModalState.set(authModalInitialState);
    console.log("heresfads")

  } catch (e: unknown) {
    const _result = resultFormatter.error((e as Error).message);

    ErrorLoggerService.logError(_result.error);

    await AlertService.error(_result.error);
  } finally {
    updateIsLoading.set(false);
  }
};
