import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledVideoCard = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 240px;
  background-color: #FFFFFF;
  border-radius: 8px;
  overflow: hidden;
  flex-grow: 1;
`;

export const StyledVideoCardCapture = styled.div`
  position: relative;
  width: 168px;
  aspect-ratio: 16 / 9;
  max-height: 94px;
  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
  }

  .time {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 2px 4px;
    color: ${theme.color.white};
    background-color: ${theme.color.black};
    opacity: 0.8;
  }
`;

export const StyledVideoCardPremiumBadge = styled.div`
  background-color: ${theme.color.yellow.primary};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: 12px;
  }
`;
