import { ProfilesHttpApiService } from '../../services/ProfilesHttpApiService';

export const initUserData: producer = async ({
  user = observe.user,
  basicInfoLoaded = get.ui.dataLoaded.basicInformation.value(),
  updateBasicInfoLoaded = update.ui.dataLoaded.basicInformation,
  setProfile = update.profile,
}) => {
  if (!user || basicInfoLoaded) return;
  updateBasicInfoLoaded.set(false);

  let basicInfoData: any;
  try {
    basicInfoData = await ProfilesHttpApiService.findProfile();
  } catch (err) {
    return;
  }

  setProfile.merge({ ...basicInfoData });
  updateBasicInfoLoaded.set(true);
};
