import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const SelectableArea = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;

  > div:first-child {
    margin-right: 16px;
    padding-right: 16px;
    border-right: 1px solid #EADEDA;
    width: 46px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
    
  svg {
    height: 24px;
    width: 24px;
  }
  
  p {
    font-size: 14px;
    font-family: ${theme.font.secondary};
    font-size: 12px;
    font-weight: 500;
  }

  .like-button {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 6px;
    border-radius: 60px;
    border: 1px solid #897180;

    svg {
      height: 16px;
      width: 16px;
    }

    span {
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .save-comp {
    width: 32px;
    height: 32px;
  }

  .default-like-button { 
    width: 24px;
    height: 24px;
    transition: width 20s, height 40s;
  }

  .liked-video {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 6px;
    border-radius: 60px;
    border: 1px solid #897180;
    background-color: #897180;
    color: #FFFFFF;
    width: 49px;
    height: 32px;

    svg {
      height: 16px;
      width: 16px;
    }

    span {
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .saved-video {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #897180;
    border-radius: 30px;
    height: 30px;
    width: 30px;
  }

  .saved-video svg{
    height: 14px;
  }

  @media ${theme.queries.tablet} { 
    margin-left: auto;
    > div:first-child {
      margin-right: 20px;
      padding-right: 0;
      border-right: 0;
    }
  }
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: center;
`;

export const StyledButton = styled.button`
  display: flex;
  box-sizing: border-box;
  color: ${theme.color.black};
  cursor: pointer;
  text-align: center;
  margin-left: 20px;
  border: none;
  background-color: transparent;
  padding: 0;
  font: 600 12px/22px ${theme.font.secondary};
  text-transform: uppercase;

  &:hover {
    background-color: #fda88c;
    border: 1px solid #fda88c;
    color: ${theme.color.black};
  }

  &:disabled,
  &:disabled:hover {
    background-color: ${theme.color.yellow.primary};
    border: 1px solid ${theme.color.yellow.primary};
    color: ${theme.color.black};
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.accent {
    background-color: ${theme.color.red.primary};
    border: 1px solid ${theme.color.red.primary};

    &:hover {
      background-color: ${theme.color.red['primary-hover']};
      border: 1px solid ${theme.color.red['primary-hover']};
    }
  }

  &.small {
    padding: 8px 16px;
  }

  svg {
    width: 26px;
    display: inline-block;
    height: 26px;
  }
`;
