import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { TranslationsService } from '../core/services';
import { Language } from '../core/types';

const createService = (): TranslationsService => {
  const path = '/locales/{{lng}}/{{ns}}.json';
  const existingLanguage = localStorage.getItem('i18nextLng');
  const config = {
    lng: existingLanguage || Language.NORWEGIAN,
    ns: ['common'],
    defaultNS: 'common',
    debug: false,
    backend: {
      loadPath: path,
    },
    fallbackLng: Language.ENGLISH,
  };

  i18n.use(Backend).use(LanguageDetector).use(initReactI18next);

  return {
    async initialize(): Promise<void> {
      await i18n.init(config);
    },

    async changeLanguage(newLanguage: Language): Promise<void> {
      await i18n.changeLanguage(newLanguage).then(() => {
        i18n.options.lng = newLanguage;
      });
    },

    translate(key: string): string {
      return i18n.t(key);
    },

    getLanguage(): Language {
      return i18n.language as Language;
    },

    toHTMLLang(language: Language): string {
      return <string>language.split(/[_-]+/)[0];
    },
  };
};

export const I18nTranslationsService = Object.freeze(createService());
