import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Information, InformationFields } from 'src/types/questionnaire/information';

export const PersonalityTraitSelector: view = ({
  personalityTrait = observe.profile.attributes.personalityTrait,
  updatePersonalityTrait = update.ui.profile.attributes.personalityTrait,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'personalityTrait';
  const label = translate('profile.questionnaire.information.personalityTrait.label');
  const choices = Information[InformationFields.PERSONALITY_TRAIT].map((trait) => {
    return {
      label: translate(`profile.questionnaire.information.${InformationFields.PERSONALITY_TRAIT}.options.${trait}`),
      value: trait,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>, newChoices: string[]) => {
    updatePersonalityTrait.set(newChoices);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={personalityTrait} onChange={handleChoice} />
    </div>
  );
};
PersonalityTraitSelector.displayName = 'PersonalityTraitSelector';
