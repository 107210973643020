interface ContentListWhereInput {
  premium_not?: boolean;
}

export enum ContentSorter {
  name_ASC = 'name_ASC',
  name_DESC = 'name_DESC',
  createdOn_ASC = 'createdOn_ASC',
  createdOn_DESC = 'createdOn_DESC',
}

interface Payload {
  readonly where?: ContentListWhereInput;
  readonly sort?: ContentSorter[];
  readonly limit?: number;
  readonly after?: string;
}

export class SearchContentRequest {
  static defaultLimit = 100;

  static defaultSort = [ContentSorter.createdOn_DESC];

  static create(request?: Payload): SearchContentRequest {
    return new SearchContentRequest(request || {});
  }

  static stringify(search: SearchContentRequest): string {
    const fields = Object.entries(search).map(
      ([key, value]) =>
        (key === 'sort'
          ? `${key}: [${value}]`
          : value && `${key}: ${JSON.stringify(value).replaceAll(`"`, ``)}`) ||
        ''
    );

    return fields.reduce(
      (query, field, index) =>
        query + (query && field && index < fields.length ? ', ' : '') + field
    );
  }

  readonly where?: ContentListWhereInput;

  readonly sort: ContentSorter[];

  readonly limit: number;

  readonly after?: string;

  private constructor({ where, sort, limit, after }: Payload) {
    this.limit = limit || SearchContentRequest.defaultLimit;
    this.sort = sort || SearchContentRequest.defaultSort;

    if (where) {
      this.where = where;
    }

    if (after) {
      this.after = after;
    }
  }
}
