import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledModuleMetadata = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

export const StyledCardContainer = styled.div`
  position: relative;
  margin-bottom: 24px;

  svg {
    margin-bottom: 30px;

    @media ${theme.queries.mobile} {
      height: 35px;
    }
  }
`;

export const StyledModuleTitle = styled.h3`
  ${theme.heading.h2}
  margin-bottom: 40px;
  text-align: center;
  color: ${theme.color.black};

  @media ${theme.queries.tablet} {
    ${theme.heading.h3}
  }

  @media ${theme.queries.mobile} {
    ${theme.heading.h4}
  }
`;

export const StyledModuleHero = styled.div`
  height: 250px;
  margin-bottom: 30px;

  @media ${theme.queries.mobile} {
    height: auto;
    margin-bottom: 10px;
  }

  > div > div {
    @media ${theme.queries.tablet} {
      height: 50px;
    }
  }
`;

export const StyledModuleHeroImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px;
`;

export const StyledModuleDescription = styled.p`
  font: 500 18px/22px ${theme.font.secondary};
  margin-bottom: 10px;
  text-align: center;
`;

export const StyledModuleTimeToComplete = styled.div`
  font: 500 16px/24px ${theme.font.secondary};
  margin-bottom: 24px;

  & > span:first-child {
    margin-right: 4px;
  }
`;

export const StyledModuleAnswers = styled.div`
  display: flex;
  flex-flow: column;
  padding: 24px 0;
  width: 100%;
  max-width: 900px;

  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
    padding-bottom: 20px;
    flex-wrap: wrap;
    border-bottom: 1px solid ${theme.color.gray.dark};
  }

  & > div:last-child {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }

  & > div div {
    margin-bottom: 10px;
    width: calc(50% - 20px);
    padding: 0 10px;

    @media ${theme.queries.mobile} {
      width: calc(100% - 20px);
    }
  }

  button {
    align-self: center;

    @media ${theme.queries.mobile} {
      margin-top: 10px;
    }
  }

  span {
    font: 400 16px/18px ${theme.font.secondary};
    margin-right: 4px;
    line-height: 1.3;
  }

  span:first-child {
    font-weight: 600;
  }
`;

export const StyledModuleAnswersHeader = styled.h4`
  margin-right: 50px;
  font: 600 24px/28px ${theme.font.secondary};
  line-height: 1;
`;
