import { DeepPartial, Language } from '../core/types'
import { AppState } from './AppState'
import { FeedNavbar } from './FeedNavbar'

export const state: DeepPartial<AppState> = {
  language: {
    value: Language.NORWEGIAN,
  },
  isSaved: {},
  likedVideos: {},
  subscriptionState: {
    subscriptionModal: false,
    checkSubscriptionFlag: false,
    isAllowed: false,
  },
  errorNotificationState: {
    notificationMessage: '',
    triggerNotification: false,
    severity: 'error',
  },
  selectedCategory: {
    key: 'all',
  },
  filterMenu: false,
  showQuestions: {},
  personalizedContent: JSON.parse(localStorage.getItem('personalizedContentUserDecision') || 'false'),
}
