import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Needs, NeedsFields } from 'src/types/questionnaire/needs';

export const HelpProductsUsageSelector: view = ({
  helpProductsUsage = observe.profile.attributes.helpProductsUsage,
  updateHelpProductsUsage = update.ui.profile.attributes.helpProductsUsage,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'helpProductsUsage';
  const label = translate('profile.questionnaire.needs.helpProductsUsage.label');
  const choices = Needs[NeedsFields.HELP_PRODUCTS_USAGE].map((answer) => {
    return {
      label: translate(`profile.questionnaire.needs.${NeedsFields.HELP_PRODUCTS_USAGE}.options.${answer}`),
      value: answer,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateHelpProductsUsage.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={helpProductsUsage ? [helpProductsUsage] : []} onChange={handleChoice} />
    </div>
  );
};
HelpProductsUsageSelector.displayName = 'HelpProductsUsageSelector';
