import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Preferences, PreferencesFields } from 'src/types/questionnaire/preferences';

export const PartyMakeupSelector: view = ({
  partyMakeup = observe.profile.attributes.partyMakeup,
  updatePartyMakeup = update.ui.profile.attributes.partyMakeup,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'partyMakeup';
  const label = translate('profile.questionnaire.preferences.partyMakeup.label');
  const choices = Preferences[PreferencesFields.PARTY_MAKEUP].map((color) => {
    return {
      label: translate(`profile.questionnaire.preferences.${PreferencesFields.PARTY_MAKEUP}.options.${color}`),
      value: color,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updatePartyMakeup.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={partyMakeup ? [partyMakeup] : []} onChange={handleChoice} />
    </div>
  );
};
PartyMakeupSelector.displayName = 'PartyMakeupSelector';
