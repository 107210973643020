import { AuthActionType } from '../../core/domain';
import { RouteName } from '../../core/types';

export const checkIsLoading: producer = ({
  isAuthorizeLoading = observe.auth[AuthActionType.AUTHORIZE].isLoading,
  isLandingLoading = observe.pages[RouteName.LANDING].isLoading,
  isProfileLoading = observe.pages[RouteName.PROFILE].isLoading,
  isOnboardingLoading = observe.pages[RouteName.ONBOARDING].isLoading,
  isFeedLoading = observe.pages[RouteName.FEED].isLoading,
  isModuleLoading = observe.pages[RouteName.MODULE_DETAILS].isLoading,
  isModulesLoading = observe.pages[RouteName.DASHBOARD].isLoading,
  isVideoDetailsLoading = observe.pages[RouteName.VIDEO_DETAILS].isLoading,
  updateIsLoading = update.lifeCycle.isLoading,
  isLanguageLoading = observe.language.isReady,
}) => {
  const isAppLoading = [
    isAuthorizeLoading,
    isLandingLoading,
    isProfileLoading,
    isOnboardingLoading,
    isFeedLoading,
    isModuleLoading,
    isModulesLoading,
    isVideoDetailsLoading,
    !isLanguageLoading,
  ].some(Boolean);

  updateIsLoading.set(isAppLoading);
};
