export const Subscription: producer = async ({
  getSubscriptionFlag = observe.subscriptionState.checkSubscriptionFlag,
  checkSubscriptionFlag = update.subscriptionState.checkSubscriptionFlag,
  setSubscriptionModal = update.subscriptionState.subscriptionModal,
  isAllowed = update.subscriptionState.isAllowed,
  user = get.user,
}) => {
  if (!getSubscriptionFlag) return;

  const subscriptionPlan =
    user.value()?.attributes['custom:subscriptionPlan'] ?? '';

  const greenLight: boolean = subscriptionPlan ? true : false;
  !greenLight && setSubscriptionModal.set(true);

  isAllowed.set(greenLight);

  checkSubscriptionFlag.set(false);
};
