import { Profile } from '../../../core/domain'
import { ApiActionType } from '../../../core/types'

export const findProfileSuccessAction: producer = ({
  result = observe.api[ApiActionType.FIND_PROFILE].result,
  updateResult = update.api[ApiActionType.FIND_PROFILE].result,
  updateProfile = update.profile,
}) => {
  if (!(result && result.isOk)) return

  const profile: Profile = result.value

  updateProfile.merge(profile)
  updateResult.remove()
}
