import styled from 'styled-components';
import { theme } from '../../../globalStyle';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import signature from '../../../../assets/jtdiva/signature.svg';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import story from '../../../../assets/jtdiva/story.png';

export const StyledStoryContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
  justify-content: center;
  align-items: stretch;
  background-color: ${theme.color.purple.secondary};

  @media ${theme.queries.tablet} {
    flex-flow: column;
    align-items: flex-start;
    padding-bottom: 48px;
    justify-content: flex-start;
  }
`;

export const StyledStoryText = styled.div`
  display: flex;
  flex-flow: column;
  padding: 48px 0;
  color: ${theme.color.white};
  align-items: center;
  @media ${theme.queries.tablet} {
    flex-basis: 100%;
    order: 2;
  }

  .text {
    ${theme.text.regular}
    text-align: center;
    display: flex;
    padding: 0 24px;
  }

  .signature {
    width: 100%;
    background: url(${signature}) no-repeat center center;
    background-size: contain;
    height: 100px;
    margin-top: ${theme.spacing.l};
  }
`;

export const StyledStoryPicture = styled.div`
  width: 100%;
  .picture {
    margin: auto 0;
    background: url(${story}) no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
  }
`;
