import { useState } from 'react';

export const InputField: view = ({
  value,
  name,
  placeholder,
  onSubmitInput,
  valueVerification,
  type
}: any) => {
  const [_value, setValue] = useState(value);
  let _type = type ? type : "text"

  const submitInput = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.trim();

    const newValue = valueVerification ? valueVerification(inputValue) : inputValue;

    setValue(newValue)
    onSubmitInput(newValue);
  };

  return (
    <>
      <input
        name={name}
        id={name}
        type={_type}
        autoComplete="off"
        value={_value}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => submitInput(event)}
        placeholder={`${placeholder || ''}`}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event?.target.value)}
      />
    </>
  );
};