import { TranslationsDeps } from '../../../core/services';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { Heading } from '../../elements';

import {
  StyledConceptContainer,
  StyledConceptPicture,
  StyledConceptText,
} from './styles';

type Props = LanguageProps & TranslationsDeps;

export const Concept: view = ({
  language = observe.language.value,
  translationsService = I18nTranslationsService,
}: Props) => (
  <StyledConceptContainer lang={translationsService.toHTMLLang(language)}>
    <StyledConceptPicture>
      <div className="picture" />
    </StyledConceptPicture>
    <StyledConceptText>
      <Heading>
        {translationsService.translate('home_page.concept_title')}
      </Heading>
      <p className="text">
        {translationsService.translate('home_page.concept_text')}
      </p>
    </StyledConceptText>
  </StyledConceptContainer>
);
