import styled, { keyframes } from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledCheckboxContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin: 0 16px 4px 0;
`;

export const StyledCheckboxLabel = styled.label<{ disabled?: boolean }>`
  color: ${theme.color.black};
  font: 500 16px/22px ${theme.font.secondary};
  text-align: left;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-left: 26px;

  a {
    color: var(--logo-color-primary);
    text-deoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledCheckbox = styled.input`
  opacity: 0;
  z-index: 3;
  height: 18px;
  width: 18px;
  margin: 0;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  position: absolute;
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

export const StyledIndicator = styled.div`
  width: 18px;
  height: 18px;
  z-index: 0;
  background: ${theme.color.white};
  position: absolute;
  cursor: pointer;
  top: 0;
  border: 1px solid ${theme.color.gray.dark};
  border-radius: 4px;

  ${StyledCheckbox}:not(:disabled):checked & {
    background: ${theme.color.gray.border};
  }

  ${StyledCheckboxLabel}:hover & {
    background: ${theme.color.gray.light};
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
  }

  ${StyledCheckbox}:checked + &::after {
    display: block;
    top: 2px;
    left: 6px;
    width: 4px;
    height: 8px;
    border: solid ${theme.color.gray.dark};
    border-width: 0 2px 2px 0;
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const StyledCheckboxGroup = styled.div`
  display: flex;
  align-items: baseline;
`;
export const StyledCheckboxGroupLabel = styled.label`
  color: ${theme.color.black};
  font: 500 16px ${theme.font.secondary};
  text-align: left;
  margin-right: 16px;
`;
