import { AuthActionType, AuthEvents, AuthFields } from '../../../core/domain';
import { resultFormatter } from '../../../core/types';
import { AuthErrors } from '../../../core/errors';
import { CognitoAuthService } from '../../../services';

export const confirmNewPasswordAction: producer = async ({
  trigger = observe.auth[AuthActionType.CONFIRM_NEW_PASSWORD].trigger,
  updateTrigger = update.auth[AuthActionType.CONFIRM_NEW_PASSWORD].trigger,
  updateIsLoading = update.auth[AuthActionType.CONFIRM_NEW_PASSWORD].isLoading,
  updateResult = update.auth[AuthActionType.CONFIRM_NEW_PASSWORD].result,
  verificationCode = get.auth[AuthActionType.CONFIRM_NEW_PASSWORD].fields[
    AuthFields.VERIFICATION_CODE
  ],
  password = get.auth[AuthActionType.CONFIRM_NEW_PASSWORD].fields[
    AuthFields.PASSWORD
  ],
  email = get.auth[AuthActionType.REQUEST_NEW_PASSWORD].fields[
    AuthFields.EMAIL
  ],
  AuthService = CognitoAuthService,
}) => {
  if (!trigger) return;

  updateTrigger.remove();

  if (!verificationCode.value()) {
    updateResult.set(
      resultFormatter.error(AuthErrors.VERIFICATION_CODE_IS_EMPTY)
    );
    return;
  }

  if (!password.value()) {
    updateResult.set(resultFormatter.error(AuthErrors.STATE_PASSWORD_IS_EMPTY));
    return;
  }

  updateIsLoading.set(true);

  try {
    const emailValue = email.value() || localStorage.getItem('email');

    const response = await AuthService.confirmNewPassword({
      username: emailValue,
      password: password.value(),
      verificationCode: verificationCode.value(),
    });

    updateResult.set(
      resultFormatter.ok(response, AuthEvents.ACCOUNT_PASSWORD_RESET_SUCCESS)
    );
  } catch (error: unknown) {
    updateResult.set(resultFormatter.error((error as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
