import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledSubscriptionWrapper = styled.div`
  margin-top: 10px;
  padding: 0 16px;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 40px;

    @media ${theme.queries.mobile} {
      margin-bottom: 25px;
    }
  }
`;

export const StyledSubscriptionItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-wrap: no-wrap;
  text-transform: capitalize;
  font: 500 18px ${theme.font.secondary};

  & > span:last-child {
    background-color: ${theme.color.red['primary-hover']};
    padding: 5px 16px;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.color.white};

    @media ${theme.queries.mobile} {
      font-size: 11px;
    }
  }

  & > span:first-child {
    margin-right: 10px;
    padding: 3px 0;
  }

  @media ${theme.queries.mobile} {
    // font-size: 15px;
    // margin-bottom: 10px;
  }
`;

export const StyledSubscriptionTypeAsset = styled.div`
  color: ${theme.color.red.somon};
`;

export const StyledSubscriptionDatesWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const StyledSubscriptionBenefitsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-bottom: 30px;

  & > h5 {
    font: 500 24px/32px ${theme.font.secondary};
  }

  & > ul {
    font: 400 16px/22px ${theme.font.secondary};

    & li {
      text-align: center;
    }
  }

  & svg {
    font-size: 72px;
  }

  @media ${theme.queries.mobile} {
    svg {
      font-size: 60px;
    }
  }

  span {
    margin-left: 12px;
    background-image: url(https://fsh-assets.s3.eu-north-1.amazonaws.com/diva.svg);
    background-size: contain;
    width: 70px;
    height: 70px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
  }
`;

export const StyledSubscriptionTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 15px;

  @media ${theme.queries.mobile} {
    justify-content: center;
    margin-top: 0;
  }

  div {
    margin-right: 20px;
  }
`;
