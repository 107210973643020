import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { Preferences, PreferencesFields } from 'src/types/questionnaire/preferences';

export const TimeOnMakeupSelector: view = ({
  timeOnMakeup = observe.profile.attributes.timeOnMakeup,
  updateTimeOnMakeup = update.ui.profile.attributes.timeOnMakeup,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'timeOnMakeup';
  const label = translate('profile.questionnaire.preferences.timeOnMakeup.label');
  const choices = Preferences[PreferencesFields.TIME_ON_MAKEUP].map((answer) => {
    return {
      label: translate(`profile.questionnaire.preferences.${PreferencesFields.TIME_ON_MAKEUP}.options.${answer}`),
      value: answer,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateTimeOnMakeup.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={timeOnMakeup ? [timeOnMakeup] : []} onChange={handleChoice} />
    </div>
  );
};
TimeOnMakeupSelector.displayName = 'TimeOnMakeupSelector';
