import sweetalert2, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { AlertService } from '../core/services';

const createService = (): AlertService => {
  const config: SweetAlertOptions = {
    showCloseButton: true,
    showConfirmButton: true,
  };

  return {
    async fire(content: SweetAlertOptions): Promise<SweetAlertResult> {
      return sweetalert2.fire({ ...content, ...config });
    },

    async success(message: string): Promise<SweetAlertResult> {
      return sweetalert2.fire({
        ...config,
        icon: 'success',
        title: 'Success',
        text: message,
      });
    },

    async error(message: string): Promise<SweetAlertResult> {
      return sweetalert2.fire({
        ...config,
        icon: 'error',
        title: 'Oops! Something went wrong',
        text: message,
      });
    },
  };
};

export const SweetAlertService = Object.freeze(createService());
