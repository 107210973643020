import { IoChevronBack, IoSave } from 'react-icons/io5';

import { Question } from '../../../core/domain';
import { UpdateProfileModuleRequest } from '../../../core/services';
import { ApiActionType, LanguageProps } from '../../../core/types';
import { theme } from '../../../globalStyle';
import { I18nTranslationsService } from '../../../services';
import { nameToTranslationKey } from '../../../utils';
import { Button, ProgressBar } from '../../elements';
import { ModuleQuestion } from '../ModuleQuestion';

import {
  StyledModuleName,
  StyledModuleProgress,
  StyledModuleWizard,
  StyledModuleWizardBody,
  StyledModuleWizardFooter,
  StyledModuleWizardHeader,
  StyledModuleWizardNavigationItem,
} from './styles';

type OwnProps = {
  name: string;
};

type StateProps = {
  readonly questions: Question[];
  readonly currentIndex?: number;
  readonly isMobileOrTablet?: boolean;
  updateCurrentIndex: Update<number>;
  updateMediaQuery: Update<string>;
};

type Props = OwnProps & StateProps & LanguageProps;

export const ModuleWizard: view<Props> = ({
  name,
  questions = observe.module.questions,
  currentIndex = observe.utils.components.moduleWizard[prop.name].currentIndex,
  updateCurrentIndex = update.utils.components.moduleWizard[prop.name]
    .currentIndex,
  moduleQuestion = observe.utils.components.moduleQuestion,
  isMobileOrTablet = observe.utils.hooks.useMediaQuery.matches,
  updateTrigger = update.api[ApiActionType.UPDATE_PROFILE_MODULE].trigger,
  updateData = update.api[ApiActionType.UPDATE_PROFILE_MODULE].data,
  updateIsEditMode = update.utils.components.moduleDetails.isEditMode,
  updateMediaQuery = update.utils.hooks.useMediaQuery.query,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}) => {
  updateMediaQuery.set(theme.queries.tablet);

  if (currentIndex === undefined) {
    updateCurrentIndex.set(0);

    return null;
  }

  const isNextButtonDisabled = !(
    moduleQuestion &&
    moduleQuestion[name]?.answers[questions[currentIndex].input]?.length
  );

  const save = () => {
    const request: UpdateProfileModuleRequest = {
      name,
      questions: moduleQuestion[name].answers,
    };

    updateData.set({ request });
    updateTrigger.set(Date.now());
  };

  const next = () => {
    if (currentIndex + 1 < questions.length) {
      updateCurrentIndex.set(currentIndex + 1);
    }
  };

  const back = () => {
    if (currentIndex === 0) {
      updateIsEditMode.set(false);
      return;
    }

    if (currentIndex > 0) {
      updateCurrentIndex.set(currentIndex - 1);
    }
  };

  const progress = ((currentIndex + 1) / questions.length) * 100;
  const progressLabel = `${currentIndex + 1} / ${questions.length}`;

  return (
    <StyledModuleWizard lang={language}>
      <StyledModuleWizardHeader>
        <StyledModuleWizardNavigationItem onClick={back}>
          <span className="nav-icon">
            <IoChevronBack />
          </span>
          {!isMobileOrTablet && <span>{translate('global.back')}</span>}
        </StyledModuleWizardNavigationItem>
        <StyledModuleName>
          {translate(`modules.${nameToTranslationKey(name)}.name`)}
        </StyledModuleName>
        <StyledModuleWizardNavigationItem disabled={!moduleQuestion}>
          {isMobileOrTablet ? (
            <span
              className="nav-icon"
              onClick={save}
              role="button"
              tabIndex={0}
              onKeyPress={save}
            >
              <IoSave />
            </span>
          ) : (
            <Button
              className="rounded button button-primary"
              onClick={save}
              disabled={!moduleQuestion}
            >
              {translate('global.save_and_exit')}
            </Button>
          )}
        </StyledModuleWizardNavigationItem>
      </StyledModuleWizardHeader>
      <StyledModuleProgress>
        <ProgressBar percentage={progress} label={progressLabel} />
      </StyledModuleProgress>
      <StyledModuleWizardBody>
        <ModuleQuestion
          question={questions[currentIndex]}
          moduleName={name}
          currentIndex={currentIndex}
          key={currentIndex}
        />
      </StyledModuleWizardBody>
      <StyledModuleWizardFooter>
        {currentIndex + 1 !== questions.length && (
          <StyledModuleWizardNavigationItem>
            <Button
              className="rounded button button-secondary"
              onClick={next}
              disabled={isNextButtonDisabled}
            >
              {translate('global.next')}
            </Button>
          </StyledModuleWizardNavigationItem>
        )}
        {!questions[currentIndex]?.required && (
          <StyledModuleWizardNavigationItem>
            <span>{translate('global.skip')}</span>
          </StyledModuleWizardNavigationItem>
        )}
      </StyledModuleWizardFooter>
    </StyledModuleWizard>
  );
};
