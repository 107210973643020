import { Module } from '../../core/domain';
import { InputType, SelectType } from '../../core/types';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import heroImage from '../../../assets/illustrations/base_profile_hero.svg';

export const baseProfileModule: Module = {
  name: 'Base Profile',
  slug: 'base-profile',
  description:
    'These are the basic questions we need to know about you to give you advices.',
  timeToComplete: '5 min',
  heroImage,
  questions: [
    {
      input: 'Marital Status',
      type: InputType.RADIO,
      required: true,
      description: 'Select your marital status',
      answers: [
        {
          label: 'Married',
        },
        {
          label: 'Divorced',
        },
        {
          label: 'Cohabitant',
        },
        {
          label: 'Single',
        },
        {
          label: 'In a relationship',
        },
      ],
    },
    {
      input: 'Body Shape',
      type: SelectType.MULTISELECT,
      required: true,
      description: 'Choose the options that describe your body shape',
      answers: [
        {
          label: 'Athletic',
        },
        {
          label: 'Not fit',
        },
        {
          label: 'Petite',
        },
        {
          label: 'Slim',
        },
        {
          label: 'Full',
        },
        {
          label: 'Powerful',
        },
        {
          label: 'Hourglass shaped',
        },
        {
          label: 'More square in shape',
        },
        {
          label: 'Pear-shaped',
        },
      ],
    },
    {
      input: 'Eye color',
      type: InputType.RADIO,
      required: true,
      description: 'Choose the color that is more suitable for your eyes',
      answers: [
        'Dark brown',
        'Lighter brown',
        'Brown green',
        'Green',
        'Teal',
        'Blue',
        'Gray',
        'Gray-blue',
        'Gray-green',
        'Black',
      ].map((label) => ({ label })),
    },
    {
      input: 'Eye description',
      type: InputType.RADIO,
      required: true,
      description: 'How would you best describe your eyes?',
      answers: [
        {
          label: 'Big eyes',
        },
        {
          label: 'Narrower eyes',
        },
        {
          label: 'Heavy eyelids',
        },
        {
          label: 'Close together',
        },
        {
          label: 'Far apart',
        },
        {
          label: "No specific characteristics/I don't know",
        },
      ],
    },
    {
      input: 'Height (cm)',
      type: InputType.RANGE,
      minValue: 100,
      maxValue: 250,
      required: true,
      description: 'Select your height',
      answers: [],
    },
    {
      input: 'Bra size (Cup)',
      required: true,
      type: SelectType.SIMPLE,
      description: 'Select your bra cup',
      answers: [
        { label: "I don't wear a bra" },
        {
          label: 'A',
        },
        {
          label: 'B',
        },
        {
          label: 'C',
        },
        {
          label: 'D',
        },
        {
          label: 'E',
        },
        {
          label: 'F',
        },
        {
          label: 'G',
        },
        {
          label: 'H',
        },
        {
          label: 'I',
        },
        {
          label: 'J',
        },
        {
          label: 'K',
        },
        {
          label: 'L',
        },
        {
          label: 'M',
        },
        {
          label: 'N',
        },
      ],
    },
    {
      input: 'Bra size (Chest)',
      required: true,
      type: InputType.RADIO,
      description: 'Select your chest size',
      answers: [
        { label: "I don't wear a bra" },
        {
          label: '60',
        },
        {
          label: '65',
        },
        {
          label: '70',
        },
        {
          label: '75',
        },
        {
          label: '80',
        },
        {
          label: '85',
        },
        {
          label: '90',
        },
        {
          label: '95',
        },
        {
          label: '100',
        },
      ],
    },
    {
      input: 'What best describe your hair?',
      required: true,
      type: SelectType.MULTISELECT,
      description: 'Choose the option that describe your hair',
      answers: [
        {
          label: 'Curly hair',
        },
        {
          label: 'Worn / over-treated hair',
        },
        {
          label: 'Colored hair',
        },
        {
          label: 'Nordic hair',
        },
        {
          label: 'Coarse / frizzy hair',
        },
        {
          label: 'Healthy hair',
        },
        {
          label: 'Undyed hair',
        },
      ],
    },
    {
      input: 'How would you describe your face shape',
      required: true,
      type: InputType.RADIO,
      description: 'Choose the option that is closest to your face shape',
      answers: [
        {
          label: 'Round',
        },
        {
          label: 'Square',
        },
        {
          label: 'Pyramid',
        },
        {
          label: 'Oval',
        },
        {
          label: 'Drop-shaped',
        },
        {
          label: 'Heart-shaped',
        },
        { label: 'Narrow' },
        {
          label: "I'm a little unsure",
        },
      ],
    },
    {
      input: 'Tell us about your skin type',
      required: true,
      type: InputType.RADIO,
      description: 'Which option best describe it?',
      answers: [
        {
          label: 'Oily',
        },
        {
          label: 'Dry',
        },
        {
          label: 'Normal',
        },
        {
          label: 'Combination',
        },
        {
          label: "I'm a little unsure",
        },
      ],
    },
    {
      input: 'Note which other options apply to your skin',
      required: true,
      type: SelectType.MULTISELECT,
      description: 'Choose an option',
      answers: [
        {
          label: 'Smooth',
        },
        {
          label: 'Characterized by scars',
        },
        {
          label: 'Large pores',
        },
        {
          label: 'A little pores',
        },
        {
          label: 'Impurities',
        },
        {
          label: 'Acne',
        },
        {
          label: 'Soft',
        },
        {
          label: 'Wrinkles or lines',
        },
      ],
    },
    {
      input: 'In which areas would you rather have feedback, advice or tips?',
      type: SelectType.MULTISELECT,
      required: true,
      description: 'Select your interests.',
      answers: [
        {
          label: 'Hair',
        },
        {
          label: 'Makeup',
        },
        {
          label: 'Clothes',
        },
      ],
    },
    {
      input: 'What are your expectations for Diva?',
      type: InputType.TEXTAREA,
      required: true,
      description: 'Describe in few words what are your expectations',
      answers: [],
    },
  ],
};
