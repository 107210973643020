import { AwsRum, AwsRumConfig } from 'aws-rum-web';

import { ErrorService } from '../core/services/ErrorLoggerService';

const createService = (): ErrorService => {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn:
      'arn:aws:iam::766125894754:role/RUM-Monitor-eu-north-1-766125894754-3207083940661-Unauth',
    identityPoolId: 'eu-north-1:6f2d3f2a-0f4f-4d9d-8ac9-e5f926fabf37',
    endpoint: 'https://dataplane.rum.eu-north-1.amazonaws.com',
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: false,
    enableXRay: false,
  };

  const APPLICATION_ID = '623f04b0-6340-4801-9b43-441a2c59f64d';
  const APPLICATION_VERSION = '1.0.0';
  const { region } = window.env.auth;

  try {
    const awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      region,
      config
    );

    return {
      logError(error) {
        awsRum.recordError(error);
      },
    };
  } catch (error) {
    console.error('error initializing error service');
    return {
      logError(e) {
        console.error(e);
      },
    };
  }
};

export const ErrorLoggerService = Object.freeze(createService());
