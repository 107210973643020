import { BaseProfileFields } from 'src/types/questionnaire/baseProfile';
import { InformationFields } from 'src/types/questionnaire/information';
import { NeedsFields } from 'src/types/questionnaire/needs';
import { PreferencesFields } from 'src/types/questionnaire/preferences';

export const calculateProgress: producer = ({
  attributesLoaded = observe.ui.dataLoaded.attributes,
  allAttributes = observe.profile.attributes,
  updateProgress = update.ui.profile.questionnaire[param.flow].progress,
}) => {
  if (!attributesLoaded) return;

  const handleModuleProgress = (module: any, moduleAttributes: any) => {
    let completedFields = 0;

    for (const attribute of moduleAttributes) {
      const attributeSelection = allAttributes[attribute];
      if ((!Array.isArray(attributeSelection) && attributeSelection) || (Array.isArray(attributeSelection) && attributeSelection.length))
        completedFields++;
    }

    updateProgress.set(Math.floor((completedFields / moduleAttributes.length) * 100), { flow: module });
  };

  const baseProfileAttributes = Object.values(BaseProfileFields);
  const informationAttributes = Object.values(InformationFields);
  const needsAttributes = Object.values(NeedsFields);
  const preferencesAttributes = Object.values(PreferencesFields);

  handleModuleProgress('baseProfile', baseProfileAttributes);
  handleModuleProgress('information', informationAttributes);
  handleModuleProgress('needs', needsAttributes);
  handleModuleProgress('preferences', preferencesAttributes);
};
