import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledAuthForm = styled.form`
  display: flex;
  width: 100%;
  flex-flow: column;

  & > button {
    margin: 16px 0;
  }
`;

export const StyledAuthFormTitle = styled.div`
  font: 700 34px/48px ${theme.font.secondary};
  text-align: center;
  color: ${theme.color.black};
`;

export const StyledAuthFormCtaWrapper = styled.div`
  display: flex;
  padding: 8px 0;
  justify-content: space-between;

  & > span {
    font: 400 14px/22px ${theme.font.secondary};
    cursor: pointer;
    text-decoration: underline ${theme.color.black};
  }
`;
