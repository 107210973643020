import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledSwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const StyledSwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 58px;
  height: 28px;
  border-radius: 35px;
  background-color: ${theme.color.gray.border};
  border: 2px solid ${theme.color.gray.border};
  position: relative;
  transition: background-color 0.2s;

  ${StyledSwitchInput}:checked + & {
    background-color: ${theme.color.red.somon};
    border: 2px solid ${theme.color.red.somon};
  }
`;

export const StyledSwitchButton = styled.span`
  content: '';
  position: absolute;
  left: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: 0.2s;
  background: ${theme.color.white};
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  ${StyledSwitchInput}:checked + ${StyledSwitchLabel} & {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  ${StyledSwitchLabel}:active & {
    width: 45px;
  }
`;
