import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export const ProgressBar: view = ({ className, progress = observe.ui.progress }) => (
  <LinearProgress
    sx={{
      backgroundColor: '#E1D5CE',
      borderRadius: '100px',
      height: '12px',
      '& .MuiLinearProgress-bar': {
        background: `linear-gradient(91deg,#957689 ${100 - progress}%, #FEBD96 93%)`,
        borderRadius: '30px',
      },
    }}
    variant="determinate"
    value={progress}
    className={className}
  ></LinearProgress>
);
