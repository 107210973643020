import { ApiActionType, resultFormatter } from '../../../core/types';
import { ModulesHttpApiService } from '../../../services';

export const getModulesAction: producer = async ({
  trigger = observe.api[ApiActionType.GET_MODULES].trigger,
  updateTrigger = update.api[ApiActionType.GET_MODULES].trigger,
  updateIsLoading = update.api[ApiActionType.GET_MODULES].isLoading,
  updateResult = update.api[ApiActionType.GET_MODULES].result,
  ModulesApiService = ModulesHttpApiService,
}) => {
  if (!trigger) return;

  updateTrigger.remove();
  updateIsLoading.set(true);

  try {
    const modules = await ModulesApiService.getModules();

    updateResult.set(resultFormatter.ok(modules));
  } catch (e: unknown) {
    updateResult.set(resultFormatter.error((e as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
