import React from 'react';
import ReactModal, { Styles } from 'react-modal';

import {
  modalStyles,
  StyledModalCloseButton,
  StyledModalContent,
} from './styles';

export interface ModalState {
  readonly isOpen?: boolean;
}

export const modalInitialState: ModalState = {
  isOpen: false,
};

type OwnProps = {
  id: string;
  isOpen: boolean;
  styles?: Styles;
  children?: React.ReactChildren;
  onClose?: () => void;
};

type StateProps = {
  updateModalState: Update<ModalState>;
};

type Props = OwnProps & StateProps;

export const Modal: view = ({
  id,
  isOpen,
  styles,
  children,
  onClose,
  updateModalState = update.utils.components.modal.state,
}: Props) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
      return;
    }
    updateModalState.set(modalInitialState);
  };

  return (
    <ReactModal
      id={id}
      isOpen={isOpen}
      style={styles || modalStyles}
      shouldCloseOnEsc
      ariaHideApp={false}
    >
      <StyledModalCloseButton onClick={handleClose}>
        &times;
      </StyledModalCloseButton>
      <StyledModalContent>{children}</StyledModalContent>
    </ReactModal>
  );
};
