export const EditIconCircle = () => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.819092" width="32" height="32" rx="16" fill="#897180" />
    <g clipPath="url(#clip0_358_5883)">
      <path
        d="M17.586 11.7329L21.5866 15.7335L12.8994 24.4207L9.33253 24.8144C8.85503 24.8672 8.45159 24.4635 8.50471 23.986L8.90159 20.4166L17.586 11.7329ZM24.061 11.1372L22.1825 9.25879C21.5966 8.67285 20.6463 8.67285 20.0603 9.25879L18.2932 11.026L22.2938 15.0266L24.061 13.2594C24.6469 12.6732 24.6469 11.7232 24.061 11.1372Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_358_5883">
        <rect width="16" height="16" fill="white" transform="translate(8.5 8.81909)" />
      </clipPath>
    </defs>
  </svg>
);
