import { FaSearch } from 'react-icons/fa';
import {
  StyledSearchBar,
  StyledSearchBarIcon,
  StyledSearchBarInput,
} from './styles';

type Props = {
  placeholder?: string;
  className?: string;
};

export const SearchBar: view<Props> = ({ placeholder, className }: Props) => (
  <StyledSearchBar className={className || ''}>
    <StyledSearchBarInput placeholder={placeholder || ''} />
    <StyledSearchBarIcon>
      <FaSearch />
    </StyledSearchBarIcon>
  </StyledSearchBar>
);
