import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import background from '../../../../assets/theme/background-2.png';
import { theme } from '../../../globalStyle';

export const StyledSubscriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  flex-flow: column;
  background: url(${background}) no-repeat;
  background-size: cover;
  width: 100%;
  color: ${theme.color.white};
  gap: 32px;
`;

export const StyledSubscriptionInfo = styled.div`
  font: 400 18px/24px ${theme.font.secondary};
  color: ${theme.color.white};
  max-width: 800px;
  width: 100%;
  text-align: center;
`;

export const StyledSubscriptionTiers = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  gap: 72px;

  @media ${theme.queries.tablet} {
    gap: 24px;
  }

  .tier {
    display: flex;
    flex-flow: column;
    gap: 24px;
    justify-content: space-between;
    padding: 24px;
    background-color: #76606a;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

    .label {
      font: 500 26px / 1.2 ${theme.font.primary};
      padding-bottom: 8px;
      border-bottom: 2px solid ${theme.color.red.somon};
    }

    .benefits {
      list-style: circle;
      font: 400 18px / 1.2 ${theme.font.primary};
    }

    .prices {
      display: flex;
      gap: 16px;
      font: 400 16px / 1.2 ${theme.font.primary};

      li:nth-child(2n + 1) {
        border-bottom: 2px solid ${theme.color.red.somon};
      }
    }
    .prices::before {
      font: 400 16px / 1.2 ${theme.font.primary};
    }
  }

  .tier:hover {
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }

  @media ${theme.queries.tablet} {
    flex-flow: column;
  }
`;
