import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledProfileAvatarContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
`;

export const StyledProfilePic = styled.div<{ imgSrc?: string }>`
  width: 150px;
  border-radius: 50%;
  padding-top: 150px;
  ${({ imgSrc }) =>
    imgSrc
      ? `
        background: url(${imgSrc}) no-repeat center center;
        background-size: cover;
    `
      : `  background-color: var(--logo-color-secondary)`};
  position: relative;

  @media ${theme.queries.mobile} {
    width: 134px;
    height: 134px;
    padding: 0;
    margin: 0 auto;
  }
`;

export const StyledProfilePicName = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  span {
    color: ${theme.color.black};
    letter-spacing: -1.5px;
    font: 600 96px ${theme.font.secondary};

    @media ${theme.queries.laptop} {
      font-size: 50px;
    }
  }
`;
