import { Video } from '../../../core/domain';
import { ApiActionType, resultFormatter } from '../../../core/types';
import { ContentHttpApiService } from '../../../services';

export const findVideoAction: producer = async ({
  trigger = observe.api[ApiActionType.FIND_VIDEO].trigger,
  id = observe.api[ApiActionType.FIND_VIDEO].data.id,
  updateTrigger = update.api[ApiActionType.FIND_VIDEO].trigger,
  updateIsLoading = update.api[ApiActionType.FIND_VIDEO].isLoading,
  updateVideoState = update.videoState,
  updateResult = update.api[ApiActionType.FIND_VIDEO].result,
  ContentApiService = ContentHttpApiService,
}) => {
  if (!(trigger && id)) return;

  updateTrigger.remove();
  updateIsLoading.set(true);
  updateVideoState.set({
    isLoading: true,
  });

  try {
    const result = await ContentApiService.findVideo(id);
    const { data, error } = result.data.getVideo;

    if (error) {
      updateResult.set(resultFormatter.error(error.message));
      updateIsLoading.set(false);
      return;
    }
    data.video = data?.video?.replace?.(
      'd1w8pkuzsdqeej.cloudfront.net/files',
      'fm-bucket-dba4b3f.s3.eu-north-1.amazonaws.com'
    );
    const video = Video.create(data);

    updateResult.set(resultFormatter.ok(video));
  } catch (e: unknown) {
    updateResult.set(resultFormatter.error((e as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
