import { ChipSelector } from 'src/generic_components/ChipSelector';
import { FieldLabel } from 'src/generic_components/FieldLabel';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { BaseProfile, BaseProfileFields } from 'src/types/questionnaire/baseProfile';

export const FaceShapeSelector: view = ({
  faceShape = observe.profile.attributes.faceShape,
  updateFaceShape = update.ui.profile.attributes.faceShape,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'faceShape';
  const label = translate('profile.questionnaire.base_profile.faceShape.label');
  const choices = BaseProfile[BaseProfileFields.FACE_SHAPE].map((shape) => {
    return {
      label: translate(`profile.questionnaire.base_profile.${BaseProfileFields.FACE_SHAPE}.options.${shape}`),
      value: shape,
    };
  });

  const handleChoice = (event: React.MouseEvent<HTMLElement>) => {
    updateFaceShape.set(event.target.value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      <ChipSelector items={choices} selections={faceShape ? [faceShape] : []} onChange={handleChoice} />
    </div>
  );
};
FaceShapeSelector.displayName = 'FaceShapeSelector';
