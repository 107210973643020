import { MouseEvent } from 'react';

import { InputEventType, InputType } from '../../../core/types';
import { ErrorMessage, HelperMessage } from '../Messages';

import {
  StyledCheckbox,
  StyledCheckboxContainer,
  StyledCheckboxLabel,
  StyledIndicator,
} from './styles';

type Props = {
  name: string;
  isChecked: boolean;
  value?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  helperMessage?: string;
  onChange?: (e: InputEventType) => void;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
};

export const Checkbox = ({
  name,
  label,
  value,
  className,
  disabled,
  errorMessage,
  helperMessage,
  isChecked,
  onChange,
  onClick,
}: Props) => {
  const handleChange = (e: InputEventType) => {
    if (onChange) {
      onChange(e);
    }
  };

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <StyledCheckboxContainer>
      <StyledCheckboxLabel htmlFor={value} disabled={disabled}>
        {label}
      </StyledCheckboxLabel>
      <StyledCheckbox
        value={value}
        id={value}
        type={InputType.CHECKBOX}
        checked={isChecked}
        onChange={handleChange}
        onClick={(e) => handleClick(e)}
        disabled={disabled}
        className={className}
      />
      <StyledIndicator />

      {helperMessage && !errorMessage && (
        <HelperMessage>{helperMessage}</HelperMessage>
      )}

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </StyledCheckboxContainer>
  );
};
