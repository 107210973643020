import React from 'react';

import { GlobalReset } from '../globalStyle';
// import Header from './Header';
import { Loader } from './Loader';
import { Footer } from './Footer';
import { FooterSecondary } from './FooterSecondary';

const { enableNewLandingPage } = window.env.flags;

type OwnProps = {
  children: React.ReactNode;
};

type StateProps = {
  isLoading?: boolean;
};

type Props = OwnProps & StateProps;

export const Layout: view<Props> = ({
  children,
  isLoading = observe.lifeCycle.isLoading,
}: Props) => (
  <>
    <GlobalReset />
    {/* <Header /> */}
    <main id="content">{isLoading ? <Loader /> : children}</main>
  </>
);
