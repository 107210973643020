import { ContentSorter, SearchContentRequest } from '../../../core/domain';
import { ApiActionType, RouteName } from '../../../core/types';

export const feedGetInitialProps: producer = ({
  user = get.user,
  trigger = observe.pages[RouteName.FEED].trigger,
  updateTrigger = update.pages[RouteName.FEED].trigger,
  updateIsLoading = update.pages[RouteName.FEED].isLoading,
  searchVideosTrigger = update.api[ApiActionType.SEARCH_VIDEOS].trigger,
  personalizedVideosTrigger = update.api[ApiActionType.GET_PERSONALIZED_CONTENT]
    .trigger,
  searchVideosData = update.api[ApiActionType.SEARCH_VIDEOS].data,
}) => {
  if (!trigger) return;

  updateTrigger.remove();

  document.title = 'Diva - Feed';
  updateIsLoading.set(true);

  // const subscriptionPlan =
  //   user.value()?.attributes['custom:subscriptionPlan'] ?? '';
  // const premium = !!subscriptionPlan;
  // const where = premium ? {} : { premium_not: true };
  searchVideosData.set({
    searchRequest: SearchContentRequest.create({
      // where,
      sort: [ContentSorter.createdOn_DESC],
    }),
  });
  searchVideosTrigger.set(Date.now());
  personalizedVideosTrigger.set(Date.now());
};
