import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';

import {
  StyledProfileMetaDate,
  StyledProfileMetaDatesWrapper,
  StyledProfileMetaEmail,
  StyledProfileMetaName,
  StyledProfileMetaWrapper,
} from './styles';

type Props = LanguageProps & {
  firstName: string;
  email: string;
  createdAt: number;
};

export const ProfileMeta: view<Props> = ({
  firstName = observe.profile.firstName,
  email = observe.profile.email,
  createdAt = observe.profile.createdAt,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => (
  <StyledProfileMetaWrapper lang={language}>
    <StyledProfileMetaName>{firstName}</StyledProfileMetaName>
    <StyledProfileMetaEmail>{email}</StyledProfileMetaEmail>
    <StyledProfileMetaDatesWrapper>
      <StyledProfileMetaDate>
        <span>{translate('profile_page.profile_created')}:</span>
        <span>{`${new Date(createdAt).toDateString()}`}</span>
      </StyledProfileMetaDate>
    </StyledProfileMetaDatesWrapper>
  </StyledProfileMetaWrapper>
);