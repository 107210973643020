import { CmsError } from '../../../errors';
import { CreateArticlePayload } from '../Article';
import { CmsListMeta } from '../Content';
import { SearchContentRequest } from '../SearchContentRequest';

export const SearchArticlesQuery = (search: SearchContentRequest) => {
  const query = SearchContentRequest.stringify(search);

  return `
     query(${query}) {
       listArticles{
         error {
           code,
           message,
           data,
         },
         data {
           entryId,
           image,
           content,
           name
           tags { name }
           author { name }
           categories { name }
           createdOn   
         },
         meta {
           cursor,
           hasMoreItems,
           totalCount
         }
       }
    }`;
};

export interface SearchArticlesQueryResult {
  listArticles: {
    error: CmsError;
    data: CreateArticlePayload[];
    meta: CmsListMeta;
  };
}
