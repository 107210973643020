import { authModalInitialState } from '../../../components';
import { AuthActionType } from '../../../core/domain';
import { SweetAlertService } from '../../../services';
import { ErrorLoggerService } from '../../../services/ErrorLoggerService';

export const confirmAccountErrorAction: producer = async ({
  result = observe.auth[AuthActionType.CONFIRM_ACCOUNT].result,
  updateResult = update.auth[AuthActionType.CONFIRM_ACCOUNT].result,
  updateAuthModalState = update.utils.components.authModal.state,
  AlertService = SweetAlertService,
}) => {
  if (!(result && result.error)) return;

  await AlertService.error(result.error);

  ErrorLoggerService.logError(result.error);

  updateAuthModalState.set({
    ...authModalInitialState,
    isOpen: true,
    isSignInFormMounted: true,
  });

  updateResult.remove();
};
