import { StyledButton,StyledButtonSecondary } from './styles';
const { enableNewLandingPage } = window.env.flags;

type Props = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onTouchStart?: (event: React.TouchEvent<HTMLButtonElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'button' | 'submit' | 'reset';
};

export const Button: view = ({
  children,
  className,
  disabled,
  onClick,
  onTouchStart,
  type = 'button',
}: Props) =>
  enableNewLandingPage ? (
    <StyledButtonSecondary
      className={className}
      disabled={disabled}
      onClick={onClick}
      type={type}
      onTouchStart={onTouchStart}
    >
      {children}
    </StyledButtonSecondary>
  ) : (
    <StyledButton
      className={className}
      disabled={disabled}
      onClick={onClick}
      type={type}
      onTouchStart={onTouchStart}
    >
      {children}
    </StyledButton>
  );
