import { MouseEvent } from 'react';

import { InputEventType, InputType } from '../../../core/types';
import { ErrorMessage, HelperMessage } from '../../elements';

import {
  StyledAnswerItem,
  StyledAnswerItemContainer,
  StyledAnswerItemLabel,
  StyledIndicator,
} from './styles';

type Props = {
  name: string;
  isChecked: boolean;
  label?: string;
  value?: string | number;
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  helperMessage?: string;
  onChange?: (e: InputEventType) => void;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
};

export const AnswerItem: view<Props> = ({
  name,
  label,
  value,
  className,
  disabled,
  errorMessage,
  helperMessage,
  isChecked,
  onChange,
  onClick,
}: Props) => {
  const handleChange = (e: InputEventType) => {
    if (onChange) {
      onChange(e);
    }
  };

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <StyledAnswerItemContainer className={className} onClick={handleClick}>
      <StyledAnswerItemLabel htmlFor={name} disabled={disabled}>
        {label}
      </StyledAnswerItemLabel>
      <StyledAnswerItem
        name={name}
        type={InputType.RADIO}
        role={InputType.RADIO}
        checked={isChecked}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
      <StyledIndicator />

      {helperMessage && !errorMessage && (
        <HelperMessage>{helperMessage}</HelperMessage>
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </StyledAnswerItemContainer>
  );
};
