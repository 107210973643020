import { FieldLabel } from 'src/generic_components/FieldLabel';
import { Slider } from 'src/generic_components/Slider';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { BaseProfile, BaseProfileFields } from 'src/types/questionnaire/baseProfile';
import styles from './styles.module.css';
import { InputField } from 'src/generic_components/InputField';

export const BraSizeChestSelector: view = ({
  braSizeChest = observe.profile.attributes.braSizeChest,
  updateBraSizeChest = update.ui.profile.attributes.braSizeChest,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'braSizeChest';
  const label = translate('profile.questionnaire.base_profile.braSizeChest');
  const min = 60,
    max = 100;

  // if (braSizeChest ) {
  //   updateBraSizeChest.set(min);
  //   braSizeChest = min;
  // }

  const handleChangeCommitted = (event: any, value: number) => {
    updateBraSizeChest.set(value);
  };

  const valueVerification = (value: string) => {
    const numValue = parseInt(value);
    let newValue = numValue;

    if (numValue < min || Number.isNaN(numValue)) newValue = min;
    else if (numValue > max) newValue = max;
    else if (numValue % 5 !== 0) newValue = numValue - (numValue % 5);

    return newValue;
  };

  const onSubmitInput = (value: any) => {
    updateBraSizeChest.set(value);
  };


  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      {/* <Slider
        attribute={BaseProfileFields.BRA_SIZE_CHEST}
        onChangeCommitted={handleChangeCommitted}
        min={min}
        max={max}
        step={5}
        showValue={true}
        value={braSizeChest}
        valueVerification={valueVerification}
        inputType={"number"}
        extraClasses={styles['height']}
      /> */}
      <InputField value={braSizeChest} onSubmitInput={onSubmitInput} valueVerification={valueVerification} placeholder={min} type={'number'} />
    </div>
  );
};
BraSizeChestSelector.displayName = 'BraSizeChestSelector';
