import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #897180;
  border: 1px solid #897180;
  box-sizing: border-box;
  color: #FAF4EC;
  cursor: pointer;
  text-align: center;
  padding: 12px 32px;
  margin: 0;
  border-radius:60px;
  font: 600 14px/24px ${theme.font.secondary};
  text-transform: uppercase;
  line-height: 1.2;


  &:hover {
    background-color: #4e3846;
    border: 1px solid #4e3846;
    color: #FAF4EC;
  }

  &:disabled,
  &:disabled:hover {
    background-color: #E1D5CE;
    border: 1px solid #E1D5CE;
    color: #897180;
    cursor: not-allowed;
  }

  &.accent {
    background-color: ${theme.color.red.primary};
    border: 1px solid ${theme.color.red.primary};

    &:hover {
      background-color: ${theme.color.red['primary-hover']};
      border: 1px solid ${theme.color.red['primary-hover']};
    }
  }

  &.small {
    padding: 8px 16px;
  }
`;

export const StyledButtonSecondary = styled.button`
    &.button {
      font: 600 14px/24px  ${theme.font.tertiary};
      border: 1px solid;
      padding: ${theme.spacing.s} ${theme.spacing.xl};
      border-radius: 63px;
      line-height: 1;

      &:hover {
       cursor: pointer;
      }

      &:disabled,
      &:disabled:hover {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }

    &.button-primary {
      background-color: ${theme.color.purple["primary-02"]};
      color: ${theme.color.yellow["light-02"]};
      border-color: ${theme.color.purple["primary-02"]};

      &:hover:not(:disabled) {
        background-color: ${theme.color.purple["hover-02"]};
        border-color: ${theme.color.purple["hover-02"]};
      }
    }

    &.button-secondary {
      background-color: transparent;
      color: ${theme.color.purple["primary-02"]};
      border-color: ${theme.color.purple["primary-02"]};

      &:hover:not(:disabled) {
        color: ${theme.color.yellow["light-02"]};
        background-color: ${theme.color.purple["hover-02"]};
        border-color: ${theme.color.purple["hover-02"]};
      }
    }

    &.button-tertiary {
      background-color: transparent;
      color: ${theme.color.purple["primary-02"]};;
      border-color: transparent;

      &:hover:not(:disabled) {
      text-decoration: underline;
      }
    }

`