import { MouseEvent } from 'react';

import { Checkbox } from './Checkbox';

import { StyledCheckboxGroup, StyledCheckboxGroupLabel } from './styles';

interface Option {
  label: string;
  value: string;
}

type OwnProps = {
  name: string;
  isDisabled?: boolean;
  defaultValue?: string;
  label?: string;
  options: Option[];
  onClick?: (e?: MouseEvent<HTMLInputElement>, data?: string) => void;
};

type StateProps = {
  value: string;
  updateValue: Update<string>;
};

type Props = OwnProps & StateProps;

export const CheckboxGroup: view<Props> = ({
  name,
  label,
  options,
  defaultValue,
  isDisabled,
  onClick,
  value = observe.utils.components.checkboxGroup[prop.name].value,
  updateValue = update.utils.components.checkboxGroup[prop.name].value,
}: Props) => {
  const handleClick = (e: MouseEvent<HTMLInputElement>, newValue: string) => {
    updateValue.set(newValue);

    if (onClick) {
      onClick(e);
    }
  };

  const checkboxGroupValue = value || defaultValue;

  return (
    <StyledCheckboxGroup>
      <StyledCheckboxGroupLabel htmlFor={name}>
        {label}
      </StyledCheckboxGroupLabel>
      {options.map((option: Option) => (
        <Checkbox
          disabled={isDisabled}
          name={name}
          key={option.value}
          label={option.label}
          value={option.value}
          onClick={(e) => handleClick(e, option.value)}
          isChecked={checkboxGroupValue === option.value}
        />
      ))}
    </StyledCheckboxGroup>
  );
};
