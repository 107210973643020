import { ApiActionType, resultFormatter } from '../../../core/types';
import { CognitoAuthService, ProfilesHttpApiService } from '../../../services';

export const findProfileAction: producer = async ({
  trigger = observe.api[ApiActionType.FIND_PROFILE].trigger,
  updateTrigger = update.api[ApiActionType.FIND_PROFILE].trigger,
  updateIsLoading = update.api[ApiActionType.FIND_PROFILE].isLoading,
  updateResult = update.api[ApiActionType.FIND_PROFILE].result,
  ProfilesApiService = ProfilesHttpApiService,
  updateUser = update.user,
  AuthService = CognitoAuthService,
}) => {
  if (!trigger) return;

  updateTrigger.remove();
  updateIsLoading.set(true);

  try {
    const profile = await ProfilesApiService.findProfile();
    updateResult.set(resultFormatter.ok(profile));

    console.log('Bypassing user cache');
    const updatedUser = await AuthService.getCurrentUser({ bypassCache: true });
    updateUser.set(updatedUser);
  } catch (e: unknown) {
    updateResult.set(resultFormatter.error((e as Error).message));
  } finally {
    updateIsLoading.set(false);
  }
};
