import { Link } from 'react-router-dom';

import { LanguageProps, Routes } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { nameToSlug, nameToTranslationKey } from '../../../utils';
import { ProfileModule } from '../../../core/domain';
import { CircularProgressBar } from '../../elements';

import {
  StyledProfileModule,
  StyledProfileModuleNotStarted,
  StyledProfileModulesWrapper,
} from './styles';

type Props = LanguageProps & {
  profileModules?: Record<string, ProfileModule>;
};

const noStartedModulesText = "You haven't started any module yet. Go to ";

export const ProfileModules: view<Props> = ({
  profileModules = observe.profile.modules,
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const modules = profileModules && Object.values(profileModules);

  return (
    <StyledProfileModulesWrapper lang={language}>
      {modules && modules.length > 0 ? (
        modules.map(({ name, progress }) => (
          <StyledProfileModule key={name}>
            <span>
              <CircularProgressBar value={progress} />
            </span>
            <div>
              <span>
                {translate(`modules.${nameToTranslationKey(name)}.name`)}
              </span>
              <Link to={`/modules/${nameToSlug(name)}`}>
                {translate('modules.view')}
              </Link>
            </div>
          </StyledProfileModule>
        ))
      ) : (
        <StyledProfileModuleNotStarted>
          {noStartedModulesText}
          <Link to={Routes.DASHBOARD}>Modules</Link>.
        </StyledProfileModuleNotStarted>
      )}
    </StyledProfileModulesWrapper>
  );
};
