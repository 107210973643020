import { ApiActionType } from '../../../core/types';
import { SweetAlertService } from '../../../services';
import { ErrorLoggerService } from '../../../services/ErrorLoggerService';

export const createCustomerPortalErrorAction: producer = async ({
  result = observe.api[ApiActionType.CREATE_CUSTOMER_PORTAL].result,
  updateResult = update.api[ApiActionType.CREATE_CUSTOMER_PORTAL].result,
  updateError = update.lifeCycle.error,
  AlertService = SweetAlertService,
}) => {
  if (!(result && result.error)) return;

  ErrorLoggerService.logError(result.error);

  await AlertService.error(result.error);

  updateError.set(result.error);
  updateResult.remove();
};
