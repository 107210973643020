import { wildcard } from '@c11/engine.runtime';
import { FaUnderline } from 'react-icons/fa';
import { ProfilesHttpApiService } from 'src/services';
import { BasicInformationFields } from 'src/types/profile';

export const saveProfileSelections: producer = async ({
  field = wildcard,
  selection = observe.ui.profile[arg.field],
  updateProfile = update.profile[arg.field],
  updateSaveLoading = update.ui.dataLoading.saveProfileSelection,
}) => {
  const profileFields = Object.values(BasicInformationFields);
  if (!field || selection === undefined || !profileFields.includes(field)) return;

  try {
    updateSaveLoading.set(true);
    const body = {
      [field]: selection,
    };

    updateProfile.set(selection);

    if (field === BasicInformationFields.PROFILE_PICTURE) {
      if (!selection) await ProfilesHttpApiService.deleteProfilePicture();
      if (selection) await ProfilesHttpApiService.updateProfilePicture(JSON.stringify(body));
    }

    if (field !== BasicInformationFields.PROFILE_PICTURE) await ProfilesHttpApiService.updateBasicInformation(JSON.stringify(body));

    console.log('Profile updated');
  } catch (err: any) {
    console.error('Error saving profile selections: ', err);
  }
  
  updateSaveLoading.set(false);
};
