import styled from 'styled-components';
import { theme } from '../../../globalStyle';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import background from '../../../../assets/theme/background-2.png';
import hairstyling from '../../../../assets/jtdiva/hairstyling.png';
import makeup from '../../../../assets/jtdiva/makeup.png';
import clothes from '../../../../assets/jtdiva/clothes.png';
import screens from '../../../../assets/jtdiva/diva-screens.png';

export const StyledExperienceContainer = styled.div`
  background-color: ${theme.color.yellow.background};
  text-align: center;
  padding: 100px 24px;

  @media ${theme.queries.tablet} { 
    padding: 80px 24px;
  }

  @media ${theme.queries.mobile} { 
    padding: 40px 24px;
  }

  .experience-container-inner {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .title {
    font: 700 48px / 1.2 ${theme.font.primary};
    max-width: 600px;
    margin: 0 auto 60px;

    @media ${theme.queries.tablet} {
      font-size: 40px;
      margin-bottom: 40px;
    }

    @media ${theme.queries.mobile} {
      font-size: 32px;
    }
  }

  .experience-box-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .experience-box {
    background-color: ${theme.color.pink.light};
    padding: 40px;
    width: 33.3%;
    
    span {
      width: 80px;
      height: 80px;
      display: block;
      margin: 0 auto 20px;
    }

    &.hair span {
      background: url(${hairstyling}) center / contain no-repeat;
    }

    &.makeup span {
      background: url(${makeup}) center / contain no-repeat;
    }

    &.clothing span {
      background: url(${clothes}) center / contain no-repeat;
    }
  }

  @media ${theme.queries.mobile} {
    .experience-box-container {
      flex-direction: column;
    }

    .experience-box { 
      width: 100%;
      padding: 20px 40px;
    }

  }

  p {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    font-family: ${theme.font.primary};
    line-height: 1.2;
  }
`;

export const StyledFeaturesContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: url(${background}) no-repeat;
  background-size: cover;
  gap: 32px;
  padding: 24px 8px;
  width: 100%;
  min-height: calc(100vh - 96px);
  color: ${theme.color.white};
`;

export const StyledFeaturesWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-flow: row wrap;
  gap: 24px;
  justify-content: center;

  @media ${theme.queries.tablet} {
    flex-flow: column wrap;
    gap: 32px;
    margin-bottom: 48px;
  }

  .feature:nth-child(3) {
    margin-right: 0;
  }

  .feature {
    display: flex;
    width: calc((100% - 96px) / 3);
    flex-flow: column;
    align-items: center;
    text-align: center;
    justify-content: center;

    @media ${theme.queries.tablet} {
      width: 100%;
    }

    .asset {
      font-size: 64px;

      @media ${theme.queries.tablet} {
        font-size: 48px;
      }
    }

    .label {
      font: 500 24px/32px ${theme.font.secondary};
      color: ${theme.color.white};
      padding-bottom: 2px;
      margin-bottom: 18px;
    }

    .description {
      font: 400 16px/18px ${theme.font.secondary};
      line-height: 1.4;
      padding: 0 32px;
      color: ${theme.color.white};
      text-align: center;
    }
  }
`;

export const StyledScreensContainer = styled.div`
  background-color: ${theme.color.white};

  .screens-container-inner {
    max-width: 1300px;
    padding: 100px 24px 0;
    display: flex;
    gap: 80px;
    align-items: center;
    margin: 0px auto;
    width: 100%;
  }

  .screens {
    background-image: url(${screens});
    background-image: url(/assets/diva-screens.png?e896118…);
    width: 100%;
    max-width: 650px;
    height: 640px;
    display: inline-block;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  button {
    padding: 12px 32px;
  }

  h2 {
    font: 700 48px / 1.2 ${theme.font.primary};
    margin-bottom: 12px;
  }

  
  h3 {
    font: 600 32px / 1.2 ${theme.font.primary};
    margin: 0 auto 30px;
  }

  @media ${theme.queries.laptopS} { 
    .screens-container-inner {
      padding: 60px 24px 0;
      flex-direction: column;
      gap: 0;
    }
    .screens {
      order: 2;
      max-width: 560px;
      height: 500px;
    }

    button {
     display: none;
    }

    h2 {
      font-size: 40px;
      text-align: center;
      margin-bottom: 8px;
    }

    h3 {
      font-size: 28px;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  @media ${theme.queries.mobile} { 
    .screens-container-inner {
      // padding: 40px 0 0 24px;
    }
    .screens {
      max-width: 320px;
      height: 310px;
    }

    h2 {
      font-size: 32px;
    }

    h3 {
      font-size: 20px;
    }
  }

`;
