import { RouteName } from '../../../core/types';

export const dashboardDidMount: producer = ({
  isLoading = observe.pages[RouteName.DASHBOARD].isLoading,
  modules = observe.modules,
  profile = observe.profile,
  updateIsLoading = update.pages[RouteName.DASHBOARD].isLoading,
}) => {
  if (!(isLoading && modules && profile)) return;

  updateIsLoading.set(false);
};
