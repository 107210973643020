import { Module } from '../../core/domain';

import { baseProfileModule } from './baseProfile';
import { informationModule } from './information';
import { needsModule } from './needs';
import { preferencesModule } from './preferences';

export const modules: Module[] = [
  baseProfileModule,
  preferencesModule,
  needsModule,
  informationModule,
];
