import { Loader } from '../../components'
const { newOnboarding } = window.env.flags
import { useHistory } from 'react-router-dom'
import { Routes } from 'src/core/types'

//todo move the redirect logic to producer (react router dom issues with non component views)
export const PostSignIn: view = ({
  basicInfoLoaded = observe.ui.dataLoaded.basicInformation,
  onboardingCompleted = get.profile.onboardingCompleted.value(),
}) => {
  const history = useHistory()

  if (!basicInfoLoaded) return <Loader/>

  let redirectTo = Routes.ONBOARDING
  if (!newOnboarding) redirectTo = onboardingCompleted ? Routes.FEED : Routes.ONBOARDING
  history.push(redirectTo)

  return <></>
}
