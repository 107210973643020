import { FilterIcon } from 'src/icons';
import styles from './styles.module.css';
import { FeedFilter } from 'src/state/FeedFilter';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';

export const Filters: view = ({
  videos = observe.videos.data,
  selectedCategory = observe.selectedCategory,
  updateSelectedCategory = update.selectedCategory,
  translate = I18nTranslationsService.translate,
}) => {
  const filters = [
    {
      name: translate(FeedFilter.ALL),
      active: FeedFilter.ALL === selectedCategory ? true : false,
      numVideos: videos ? videos.length : 0,
      handler: () => updateSelectedCategory.set(FeedFilter.ALL),
    },
    {
      name: translate(FeedFilter.HACKS),
      active: FeedFilter.HACKS === selectedCategory ? true : false,
      numVideos: videos ? videos.filter((video: any) => video.categories.includes(FeedFilter.HACKS.toLowerCase())).length : 0,
      handler: () => updateSelectedCategory.set(FeedFilter.HACKS),
    },
    {
      name: translate(FeedFilter.HAIR),
      active: FeedFilter.HAIR === selectedCategory ? true : false,
      numVideos: videos ? videos.filter((video: any) => video.categories.includes(FeedFilter.HAIR.toLowerCase())).length : 0,
      handler: () => updateSelectedCategory.set(FeedFilter.HAIR),
    },
    {
      name: translate(FeedFilter.MAKEUP),
      active: FeedFilter.MAKEUP === selectedCategory ? true : false,
      numVideos: videos ? videos.filter((video: any) => video.categories.includes(FeedFilter.MAKEUP.toLowerCase())).length : 0,
      handler: () => updateSelectedCategory.set(FeedFilter.MAKEUP),
    },
    {
      name: translate(FeedFilter.CONFIDENCE),
      active: FeedFilter.CONFIDENCE === selectedCategory ? true : false,
      numVideos: videos ? videos.filter((video: any) => video.categories.includes(FeedFilter.CONFIDENCE.toLowerCase())).length : 0,
      handler: () => updateSelectedCategory.set(FeedFilter.CONFIDENCE),
    },
  ];

  return (
    <div className={styles['feed-filters-container']}>
      <div className={styles['feed-filters-title']}>
        <FilterIcon />
        <span>{translate('feed_page.filters')}</span>
      </div>
      <div className={styles['feed-filters-buttons']}>
        {filters
          .filter(({ numVideos }) => numVideos)
          .map(({ name, numVideos, active, handler }) => (
            <div key={Math.random()} className={active ? styles.active : ''} onClick={handler}>
              <span className={styles['filter-title']}>{translate(`categories.${name.toLowerCase()}`)}</span>
              <span className={styles['filter-number']}>{numVideos}</span>
            </div>
          ))}
      </div>
    </div>
  );
};
