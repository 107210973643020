export const useMediaQuery: producer = ({
  query = observe.utils.hooks.useMediaQuery.query,
  matches = observe.utils.hooks.useMediaQuery.matches,
  updateMatches = update.utils.hooks.useMediaQuery.matches,
}) => {
  if (!query) return;

  const media = window.matchMedia(query);
  updateMatches.set(media.matches);

  if (media.matches === matches) return;

  const listener = () => {
    updateMatches.set(media.matches);
  };

  media.addEventListener('change', listener);

  // eslint-disable-next-line consistent-return
  return () => media.removeEventListener('change', listener);
};
