import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledSubSectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0px !important;
  padding-bottom: 0px !important;
  & h1 {
    font-size: 22px;
    margin: 0 0;
  }
`;

export const StyledProfileInfo = styled.div`
  display: flex;
  align-items: stretch;

  font: 500 16px ${theme.font.secondary};

  span:first-child {
    font-size: 16px;
    margin-right: 16px;
  }

  span:last-child {
    text-transform: capitalize;
    color: ${theme.color.black};
  }
`;

export const StyledCondensedProfileInfo = styled.div`
  display: flex;
  align-items: stretch;

  font: 500 12x ${theme.font.secondary};

  span:first-child {
    font-size: 12px;
    margin-right: 16px;
  }

  span:last-child {
    text-transform: capitalize;
    color: ${theme.color.black};
  }

  margin-bottom: 0 !important;
`;

export const StyledProfileInfoWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 16px;

  ${StyledProfileInfo}:not(:last-child) {
    margin-bottom: 16px;
  }

  label {
    margin-right: 20px;
  }

  button {
    align-self: flex-end;
  }
`;

export const StyledEditProfileInfoForm = styled.form`
  display: flex;
  flex-flow: column;

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }

  & > button {
    margin-top: 12px;
    text-transform: capitalize;
  }
`;
