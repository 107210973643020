import { SpinnerRing, SpinnerWrapper } from './styles';

export const Spinner = () => (
  <SpinnerWrapper>
    <SpinnerRing />
    <SpinnerRing />
    <SpinnerRing />
    <SpinnerRing />
  </SpinnerWrapper>
);
