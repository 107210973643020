import { Auth } from '@aws-amplify/auth';

import { AuthChallengeName, User } from '../core/domain';
import {
  AuthParams,
  AuthService,
  ConfirmAccountParams,
  ConfirmNewPasswordParams,
  RequestNewPasswordResponse,
  SignInResult,
  SignUpParams,
  SignUpResult,
  SocialSignInOptions,
} from '../core/services';

const createService = (): AuthService => ({
  async signUp({
    username,
    password,
    firstName,
  }: SignUpParams): Promise<SignUpResult> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Auth.signUp({
      username,
      password,
      attributes: {
        given_name: firstName,
      },
    });
  },

  async confirmAccount({
    username,
    verificationCode,
  }: ConfirmAccountParams): Promise<string> {
    return Auth.confirmSignUp(username, verificationCode);
  },

  async signIn({ username, password }: AuthParams): Promise<User> {
    try {
      const response: SignInResult = await Auth.signIn(username, password);
      if (response.challengeName === AuthChallengeName.NEW_PASSWORD_REQUIRED) {
        return await Auth.completeNewPassword(response, password);
      }

      return response as User;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async requestNewPassword(
    username: string
  ): Promise<RequestNewPasswordResponse> {
    return Auth.forgotPassword(username);
  },

  async confirmNewPassword({
    username,
    password,
    verificationCode,
  }: ConfirmNewPasswordParams): Promise<void> {
    return Auth.forgotPasswordSubmit(username, verificationCode, password);
  },

  async requestNewVerificationCode(username: string): Promise<unknown> {
    return Auth.resendSignUp(username);
  },

  async getCurrentUser(params): Promise<User> {
    return Auth.currentAuthenticatedUser(params);
  },

  async signOut(): Promise<void> {
    return Auth.signOut();
  },

  async socialSignIn({ provider }: SocialSignInOptions): Promise<unknown> {
    return Auth.federatedSignIn({
      provider,
    });
  },
});

export const CognitoAuthService = Object.freeze(createService());
