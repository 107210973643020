
import { VipIcon } from 'src/icons';
import styles from "../styles.module.css"

export const VideoPremiumSmall: view = ({
}) => {
  return (
    <div className={styles['premium-small-tag']}>
      <VipIcon />
    </div>
  );
};
