import { TranslationsDeps } from '../../../core/services';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { Heading } from '../../elements';
import {
  StyledStoryContainer,
  StyledStoryPicture,
  StyledStoryText,
} from './styles';

type Props = LanguageProps & TranslationsDeps;

export const Story: view = ({
  language = observe.language.value,
  translationsService = I18nTranslationsService,
}: Props) => (
  <StyledStoryContainer lang={translationsService.toHTMLLang(language)}>
    <StyledStoryText>
      <Heading>
        {translationsService.translate('home_page.story_title')}
      </Heading>
      <p className="text">
        {translationsService.translate('home_page.story_text')}
      </p>
      <div className="signature" />
    </StyledStoryText>
    <StyledStoryPicture>
      <div className="picture" />
    </StyledStoryPicture>
  </StyledStoryContainer>
);
