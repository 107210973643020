import { Content, CreateContentPayload } from './Content';

export interface CreateArticlePayload extends CreateContentPayload {
  readonly content: { data: { text: string } };
}

export class Article implements Content {
  static create(payload: CreateArticlePayload): Article {
    return new Article({ ...payload });
  }

  readonly name: string;

  readonly categories: string[];

  readonly createdOn: string;

  readonly id: string;

  readonly image?: string;

  readonly tags: string[];

  readonly author?: string;

  readonly content: string;

  private constructor({
    entryId,
    author,
    createdOn,
    image,
    tags,
    content,
    categories,
    name,
  }: CreateArticlePayload) {
    this.author = author?.name;
    this.id = entryId;
    this.image = image;
    this.content = content.data.text;
    this.tags = tags.map((tag) => tag.name);
    this.categories = categories.map((category) => category.name);
    this.createdOn = new Date(createdOn).toDateString();
    this.name = name;
  }
}
