import { SearchContentRequest } from '../../../core/domain';
import { ApiActionType, RouteName } from '../../../core/types';

export const videoDetailsGetInitialProps: producer = ({
  user = get.user,
  trigger = observe.pages[RouteName.VIDEO_DETAILS].trigger,
  params = observe.pages[RouteName.VIDEO_DETAILS].params,
  updateTrigger = update.pages[RouteName.VIDEO_DETAILS].trigger,
  updateIsLoading = update.pages[RouteName.VIDEO_DETAILS].isLoading,
  findVideoTrigger = update.api[ApiActionType.FIND_VIDEO].trigger,
  findVideoData = update.api[ApiActionType.FIND_VIDEO].data,
  searchVideosTrigger = update.api[ApiActionType.SEARCH_VIDEOS].trigger,
  searchVideosData = update.api[ApiActionType.SEARCH_VIDEOS].data,
}) => {
  if (!(trigger && params)) return;

  updateTrigger.remove();

  document.title = 'Diva - Video';
  updateIsLoading.set(true);

  findVideoData.set({ ...params });
  findVideoTrigger.set(Date.now());

  // const subscriptionPlan =
  //   user.value()?.attributes['custom:subscriptionPlan'] ?? '';
  // const premium = !!subscriptionPlan;
  // const where = premium ? {} : { premium_not: true };

  searchVideosData.set({
    searchRequest: SearchContentRequest.create({}),
  });
  searchVideosTrigger.set(Date.now());
};
