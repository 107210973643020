import { Slide } from 'src/newDesign/Onboarding/Slide';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import { I18nTranslationsService } from 'src/services';
import bodyIcon from '../../../../assets/jtdiva/q-body.png';
import { HairShowsIdentitySelector } from '../fields/HairShowsIdentitySelector';
import { BodyShapeChallengeSelector } from '../fields/BodyShapeChallengeSelector';
import { HeightChallengeSelector } from '../fields/HeightChallengeSelector';
import { HelpSnuglyGarmentsSelector } from '../fields/HelpSnuglyGarmentsSelector';

export const Identity: view = ({ translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.needs.describe_needs')}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.needs.heading')}
        cardDescription={translate('profile.questionnaire.needs.description')}
      >
        <HairShowsIdentitySelector />
        <BodyShapeChallengeSelector />
        <HeightChallengeSelector />
        <HelpSnuglyGarmentsSelector />
      </QuestionCard>
    </Slide>
  );
};
