import { authModalInitialState } from '../../../components';
import { AuthActionType } from '../../../core/domain';
import { SweetAlertService } from '../../../services';

export const confirmNewPasswordSuccessAction: producer = async ({
  result = observe.auth[AuthActionType.CONFIRM_NEW_PASSWORD].result,
  updateResult = update.auth[AuthActionType.CONFIRM_NEW_PASSWORD].result,
  updateAuthModalState = update.utils.components.authModal.state,
  AlertService = SweetAlertService,
}) => {
  if (!(result && result.isOk)) return;

  await AlertService.success(result.successMessage);

  updateAuthModalState.set({
    ...authModalInitialState,
    isOpen: true,
    isSignInFormMounted: true,
  });

  updateResult.remove();
};
