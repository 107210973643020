import { InputEventType, InputType } from '../../../core/types';
import {
  StyledRangeInput,
  StyledRangeLabel,
  StyledRangeOutput,
  StyledRangeValue,
  StyledRangeWrapper,
} from './styles';

const DEFAULT_MIN = 1;
const DEFAULT_MAX = 100;
const DEFAULT_STEP = 1;

type RangeProps = {
  name: string;
  value: number;
  label?: string;
  groupClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  onChange?: (event: InputEventType) => void;
};

type RangeStateProps = {
  readonly rangeValue?: number;
  updateRange: Update<number>;
};

type Props = RangeProps & RangeStateProps;

export const Range: view<RangeProps> = ({
  name,
  label,
  min,
  max,
  step,
  groupClassName,
  inputClassName,
  disabled,
  value,
  onChange,
  rangeValue = observe.utils.components.range[prop.name].value,
  updateRange = update.utils.components.range[prop.name].value,
}: Props) => {
  if (rangeValue === undefined) {
    updateRange.set(value || min || DEFAULT_MIN);

    return null;
  }

  const handleChange = (e: InputEventType) => {
    updateRange.set(+e.currentTarget.value);

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <StyledRangeWrapper className={`${groupClassName || ''}`}>
      {label && <StyledRangeLabel htmlFor={name}>{label}</StyledRangeLabel>}
      <StyledRangeOutput>
        <StyledRangeValue>{rangeValue}</StyledRangeValue>
      </StyledRangeOutput>
      <StyledRangeInput
        id={name}
        name={name}
        className={`${inputClassName || ''}`}
        value={rangeValue}
        type={InputType.RANGE}
        onChange={handleChange}
        disabled={disabled}
        min={min || DEFAULT_MIN}
        max={max || DEFAULT_MAX}
        step={step || DEFAULT_STEP}
      />
    </StyledRangeWrapper>
  );
};
