import { FieldLabel } from 'src/generic_components/FieldLabel';
import { Slider } from 'src/generic_components/Slider';
import { I18nTranslationsService } from 'src/services/I18nTranslationsService';
import { BasicInformationFields } from 'src/types/profile';
import styles from './styles.module.css';
import { InputField } from 'src/generic_components/InputField';
import { useEffect, useState } from 'react';
import { InputField_test } from 'src/generic_components/InputField_test';

export const AgeSelector: view = ({
  age = observe.profile.age,
  uiAge = observe.ui.profile.age,
  updateAge = update.ui.profile.age,
  translate = I18nTranslationsService.translate,
}) => {
  const name = 'age';
  const label = translate('profile.age');
  const min = 18,
    max = 99;

  // if (!age) {
  //   updateAge.set(min);
  //   age = min;
  // }

  // const handleChangeCommitted = (event: any, value: number) => {
  //   updateAge.set(value);
  // };

  const valueVerification = (value: string) => {
    const numValue = parseInt(value);
    let newValue = numValue;

    if (numValue < min || Number.isNaN(numValue)) newValue = min;
    else if (numValue > max) newValue = max;

    return newValue;
  };

  const onSubmitInput = (value: any) => {
    updateAge.set(value);
  };

  return (
    <div>
      <FieldLabel htmlFor={name} label={label} />
      {/* <Slider
        attribute={BasicInformationFields.AGE}
        onChangeCommitted={handleChangeCommitted}
        min={min}
        max={max}
        step={1}
        showValue={true}
        value={age}
        measureUnit={translate('measure_unit.years')}
        valueVerification={valueVerification}
        extraClasses={styles['age']}
        inputType={"number"}
      /> */}
      <InputField value={age} onSubmitInput={onSubmitInput} valueVerification={valueVerification} placeholder={min} type={"number"} />
    </div>
  );
};
AgeSelector.displayName = 'AgeSelector';
