import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledContentCardLink = styled.div<{ size: 'small' | 'large' }>`
  width: ${({ size }) =>
  size === 'small' ? 'calc(50% - 32px)' : 'calc(100%)'};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media ${theme.queries.tablet} { 
    width: 100%;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 64px;
  width: 100%;
  margin-top: 64px;

  @media ${theme.queries.tablet} { 
    margin-top: 48px;
    gap: 40px;
  }
`;

export const StyledContentText = styled.h3`
  font: 500 28px/ 32px ${theme.font.secondary};
  width: 100%;
  grid-area: title;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  line-height: 1;
  margin-bottom: 190px;
`;
