import styled from 'styled-components';
import { theme } from '../../../globalStyle';

export const StyledErrorMessage = styled.p`
  color: ${theme.color.red['primary-hover']};
  font: 400 14px/16px ${theme.font.secondary};
  padding: 4px;
`;

export const StyledHelperMessage = styled.p`
  font: 400 12px/18px ${theme.font.secondary};
  color: ${theme.color.gray.border};
  opacity: 0.8;
  padding: 4px;
  white-space: pre-wrap;
`;

export const StyledSuccessMessage = styled.p`
  color: ${theme.color.green.light};
  font: 400 14px/16px ${theme.font.secondary};
  padding: 4px;
`;
