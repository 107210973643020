import { Navigator } from 'src/generic_components/Navigator'
import { Clothing } from './slides/Clothing'
import { Makeup } from './slides/Makeup'
import { Hair } from './slides/Hair'
import { useHistory } from 'react-router-dom'
import { onboardingFlows } from 'src/types/onboarding'
import { Loader } from 'src/components'
import { Routes } from 'src/core/types'
import { I18nTranslationsService } from 'src/services'

export const Preferences: view = ({
  attributesLoaded = observe.ui.dataLoaded.attributes,
  updateCurrentSlide = update.ui.navigator.baseProfile.currentSlide,
  language = observe.language.value,
  translate = I18nTranslationsService.translate
}) => {
  const history = useHistory()
  if (!attributesLoaded) return <Loader/>

  const slides = [<Clothing/>, <Makeup/>, <Hair/>]
  return (
    <div className="modules" lang={language}>
      <Navigator
        slides={slides}
        nextStep={() => history.push(Routes.PROFILE)}
        previousStep={() => history.push(Routes.PROFILE)}
        updateCurrentSlide={(currentSlide) => updateCurrentSlide.set(currentSlide)}
        lastSlideNextButtonText={translate('onboarding.base_profile.your_personalized_feed')}
        translate={translate}
      />
    </div>
  )
}
