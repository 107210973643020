import { CommentsHttpApiService } from '../../../components/Comments/service/CommentsHttpApiService';
import { AuthActionType, User } from '../../../core/domain';
import { Routes } from '../../../core/types';
import {
  PaymentHttpApiService,
  ProfilesHttpApiService,
  LikesHttpApiService,
  PersonalizedContentHttpApiService
} from '../../../services';

export const authorizeSuccessAction: producer = ({
  history,
  result = observe.auth[AuthActionType.AUTHORIZE].result,
  updateResult = update.auth[AuthActionType.AUTHORIZE].result,
  updateUser = update.user,
  ProfilesApiService = ProfilesHttpApiService,
  PaymentApiService = PaymentHttpApiService,
  PersonalizedContentApiService = PersonalizedContentHttpApiService
}) => {
  if (!(result && result.isOk)) return;

  const user: User = result.value;

  updateUser.set(user);

  const userSession = user.getSignInUserSession()?.getIdToken();
  if (!userSession) {
    history.replace(Routes.LANDING);
    return;
  }

  const authToken = userSession?.getJwtToken();
  if (!authToken) {
    history.replace(Routes.LANDING);
    return;
  }

  ProfilesApiService.setAuthToken(`Bearer ${authToken}`);
  LikesHttpApiService.setAuthToken(`Bearer ${authToken}`);
  CommentsHttpApiService.setAuthToken(`Bearer ${authToken}`);
  PaymentApiService.setAuthToken(`Bearer ${authToken}`);
  PersonalizedContentApiService.setAuthToken(`Bearer ${authToken}`);
  
  updateResult.remove();
};
