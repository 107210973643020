import { Slide } from 'src/newDesign/Onboarding/Slide';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import { I18nTranslationsService } from 'src/services';
import bodyIcon from '../../../../assets/jtdiva/q-body.png';
import { BraTypeSelector } from '../fields/BraTypeSelector';
import { DressAccordingToAgeSelector } from '../fields/DressAccordingToAgeSelector';
import { DressingSkillLevelSelector } from '../fields/DressingSkillLevelSelector';

export const StylingPreferences: view = ({ translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.needs.describe_needs')}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.needs.heading')}
        cardDescription={translate('profile.questionnaire.needs.description')}
      >
        <BraTypeSelector />
        <DressAccordingToAgeSelector />
        <DressingSkillLevelSelector />
      </QuestionCard>
    </Slide>
  );
};
