import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

function handleErrors(error: AxiosError): void {
  if (error.response) {
    console.error(error.response.data);
    console.error(error.response.status);
    console.error(error.response.headers);
  } else if (error.request) {
    console.error(error.request);
  } else {
    console.error('Api Core Error', error.message);
  }

  console.error(error.config);
}

export const AxiosService = (apiConfig: AxiosRequestConfig) => {
  const AXIOS: AxiosInstance = axios.create(apiConfig);

  return {
    async get<P, R>(
      urlPath: string,
      params?: P,
      config?: AxiosRequestConfig
    ): Promise<R> {
      try {
        const axiosResponse: AxiosResponse<R> = await AXIOS.get<R>(urlPath, {
          ...config,
          params,
        });
        return axiosResponse.data;
      } catch (error: unknown) {
        handleErrors(error as AxiosError);
        throw error;
      }
    },

    async post<B, R>(
      urlPath: string,
      body: B,
      config?: AxiosRequestConfig
    ): Promise<R> {
      try {
        const axiosResponse: AxiosResponse<R> = await AXIOS.post<R>(
          urlPath,
          body,
          config
        );

        return axiosResponse.data;
      } catch (error: unknown) {
        handleErrors(error as AxiosError);
        throw error;
      }
    },

    async patch<B, R>(
      urlPath: string,
      body: B,
      config?: AxiosRequestConfig
    ): Promise<R> {
      try {
        const axiosResponse: AxiosResponse<R> = await AXIOS.patch<R>(
          urlPath,
          body,
          config
        );

        return axiosResponse.data;
      } catch (error: unknown) {
        handleErrors(error as AxiosError);
        throw error;
      }
    },

    async put<B, R>(
      urlPath: string,
      body: B,
      config?: AxiosRequestConfig
    ): Promise<R> {
      try {
        const axiosResponse: AxiosResponse<R> = await AXIOS.put<R>(
          urlPath,
          body,
          config
        );

        return axiosResponse.data;
      } catch (error: unknown) {
        handleErrors(error as AxiosError);
        throw error;
      }
    },

    async delete<P, R>(
      urlPath: string,
      params?: P,
      config?: AxiosRequestConfig
    ): Promise<R> {
      try {
        const axiosResponse: AxiosResponse<R> = await AXIOS.delete<R>(urlPath, {
          ...config,
          params,
        });

        return axiosResponse.data;
      } catch (error: unknown) {
        handleErrors(error as AxiosError);
        throw error;
      }
    },

    setAuthToken(authToken: string) {
      AXIOS.defaults.headers.common.Authorization = authToken;
    },
  };
};
