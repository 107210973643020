import { Content, CreateContentPayload } from './Content';

export interface CreateVideoPayload extends CreateContentPayload {
  readonly shortDescription?: string;
  readonly description?: { data: { text: string } };
  readonly video: string;
}

export class Video implements Content {
  static create(payload: CreateVideoPayload): Video {
    return new Video({ ...payload });
  }

  readonly name: string;

  readonly categories: string[];

  readonly createdOn: string;

  readonly id: string;

  readonly image?: string;

  readonly tags: string[];

  readonly video: string;

  readonly author?: string;

  readonly description?: string;

  readonly shortDescription?: string;

  readonly premium?: boolean;

  private constructor({
    premium,
    entryId,
    author,
    createdOn,
    image,
    tags,
    video,
    description,
    shortDescription,
    categories,
    name,
  }: CreateVideoPayload) {
    this.premium = premium;
    this.author = author?.name;
    this.id = entryId;
    this.image = image;
    this.video = video;
    this.description = description?.data?.text;
    this.shortDescription = shortDescription;
    this.tags = tags.map((tag) => tag.name);
    this.categories = categories.map((category) => category.name);
    this.createdOn = new Date(createdOn).toDateString();
    this.name = name;
  }
}
