import { Slide } from 'src/newDesign/Onboarding/Slide';
import { I18nTranslationsService } from 'src/services';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import bodyIcon from '../../../../assets/jtdiva/q-skin.png';
import { SkinTypeSelector } from 'src/profileQuestionnaire/baseProfile/fields/SkinTypeSelector';
import { SkinCharacteristicsSelector } from 'src/profileQuestionnaire/baseProfile/fields/SkinCharacteristicsSelector';

export const DescribeSkin: view = ({ hideBackButton, translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.base_profile.describe_body')} hideBackButton={hideBackButton}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.base_profile.skin')}
        cardDescription={translate('profile.questionnaire.base_profile.skin_description')}
        img={bodyIcon}
      >
        <SkinTypeSelector />
        <SkinCharacteristicsSelector />
      </QuestionCard>
    </Slide>
  );
};
