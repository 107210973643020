import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  AuthActionType,
  AuthFields,
  PasswordConstraints,
} from '../../../core/domain';
import { LanguageProps } from '../../../core/types';
import { I18nTranslationsService } from '../../../services';
import { AuthStateProps } from '../../../state';
import { Input, Spinner } from '../../elements';

import { StyledAuthForm } from './styles';
import { Button } from 'src/generic_components/Button';

type Props = AuthStateProps &
  LanguageProps & {
    updateEmail: Update<string>;
    updatePassword: Update<string>;
  };

interface SignInFormValues {
  email: string;
  password: string;
}

const initialValues: SignInFormValues = {
  email: '',
  password: '',
};

const validationSchema: Yup.SchemaOf<SignInFormValues> = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(PasswordConstraints.min, 'Password must be at least 6 characters')
    .matches(
      PasswordConstraints.pattern,
      'Password must contain an uppercase, a lowercase and a digit'
    ),
});

export const SignInForm: view<Props> = ({
  isLoading = observe.auth[AuthActionType.SIGN_IN].isLoading,
  updateTrigger = update.auth[AuthActionType.SIGN_IN].trigger,
  updateEmail = update.auth[AuthActionType.SIGN_IN].fields[AuthFields.EMAIL],
  updatePassword = update.auth[AuthActionType.SIGN_IN].fields[
    AuthFields.PASSWORD
  ],
  language = observe.language.value,
  translate = I18nTranslationsService.translate,
}: Props) => {
  const onSubmit = ({ email, password }: SignInFormValues) => {
    updateEmail.set(email);
    updatePassword.set(password);

    updateTrigger.set(Date.now());
  };

  const { handleSubmit, values, handleChange, errors, isValid } =
    useFormik<SignInFormValues>({
      validationSchema,
      initialValues,
      onSubmit,
    });

  return (
    <StyledAuthForm onSubmit={handleSubmit} lang={language}>
      <Input
        name="email"
        type="email"
        label={translate('global.email')}
        value={values.email}
        errorMessage={errors.email}
        onChange={handleChange}
      />
      <Input
        name="password"
        type="password"
        label={translate('login.password')}
        value={values.password}
        errorMessage={errors.password}
        onChange={handleChange}
      />
      <Button type="submit" disabled={!isValid}>
        {isLoading ? <Spinner /> : translate('login.sign_in')}
      </Button>
    </StyledAuthForm>
  );
};
