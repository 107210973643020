import { Slide } from 'src/newDesign/Onboarding/Slide';
import { I18nTranslationsService } from 'src/services';
import { QuestionCard } from 'src/generic_components/QuestionCard';
import bodyIcon from '../../../../assets/jtdiva/q-body.png';
import { AreaOfInterestSelector } from '../fields/AreaOfInterestSelector';

export const AreaOfInterest: view = ({ hideBackButton, translate = I18nTranslationsService.translate }) => {
  return (
    <Slide mainHeading={translate('profile.questionnaire.base_profile.describe_area_of_interest')} hideBackButton={hideBackButton}>
      <QuestionCard
        cardHeading={translate('profile.questionnaire.base_profile.area_of_interest')}
        cardDescription={translate('profile.questionnaire.base_profile.area_of_interest_description')}
      >
        <AreaOfInterestSelector />
      </QuestionCard>
    </Slide>
  );
};
