import styles from 'src/newDesign/Onboarding/styles.module.css';
import { Welcome } from 'src/newDesign/Onboarding/greeting/Welcome';
import { BasicInformation } from 'src/newDesign/Onboarding/greeting/BasicInformation';
import { onboardingFlows } from 'src/types/onboarding';
import { Loader } from 'src/components';

export const GreetingView: view = ({
  active,
  updateProgress = update.ui.progress,
  language = observe.language.value
}) => {
  if (!active) return null;
  updateProgress.set(15)
  return (
    <div className={styles['welcome-page']} lang={language}>
      <Welcome />
      <BasicInformation hideBackButton={true}/>
    </div>
  );
};
