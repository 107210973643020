import { ProfilesHttpApiService } from 'src/services'

export const getSelections: producer = async ({
  user = observe.user,
  attributesLoaded = get.ui.dataLoaded.attributes.value(),
  updateAttributesLoaded = update.ui.dataLoaded.attributes,
  updateAttribute = update.profile.attributes
}) => {
  if (!user || attributesLoaded) return
  updateAttributesLoaded.set(false)

  let selectionsData: any
  try {
    selectionsData = await ProfilesHttpApiService.getAttributes()
  } catch (err) {
    console.error('Error getting selections data', err)
    return
  }

  if (!selectionsData) return
  let attributes: any = {}
  for (const attribute in selectionsData)
    attributes[attribute] = selectionsData[attribute].answer
  updateAttribute.set(attributes)
  updateAttributesLoaded.set(true)
}
