import { nanoid } from 'nanoid';
import React from 'react';
import { Pagination, Lazy, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { theme } from '../../globalStyle';
import { StyledSliderContainer } from './styles';

type StateProps = {
  readonly isMobileOrTablet?: boolean;
  updateMediaQuery: Update<string>;
};

type OwnProps = {
  slides: JSX.Element[];
  className?: string;
};

type Props = StateProps & OwnProps;

export const Slider: view<Props> = ({
  slides,
  className,
  isMobileOrTablet = observe.utils.hooks.useMediaQuery.matches,
  updateMediaQuery = update.utils.hooks.useMediaQuery.query,
}: Props) => {
  updateMediaQuery.set(theme.queries.tablet);

  return (
    <StyledSliderContainer>
      <Swiper
        initialSlide={0}
        spaceBetween={80}
        centeredSlides
        navigation={!isMobileOrTablet}
        slidesPerView={1}
        simulateTouch={false}
        lazy
        pagination={{
          clickable: true,
        }}
        keyboard={{
          enabled: true,
          onlyInViewport: true,
        }}
        modules={[Pagination, Lazy, Navigation]}
        className={`diva-slider ${className || ''}`}
      >
        {slides.map((slide) => (
          <SwiperSlide key={nanoid()}>{React.cloneElement(slide)}</SwiperSlide>
        ))}
      </Swiper>
    </StyledSliderContainer>
  );
};
