import React from 'react';

import { InputEventType } from '../../../core/types';
import { ErrorMessage } from '../../elements';
import { AnswerItemLabel } from './styles';
import { AnswerItem } from './AnswerItem';

interface Option {
  label: string;
  value: string;
}

type OwnProps = {
  name: string;
  disabled?: boolean;
  label?: string;
  groupClassName?: string;
  options: Option[];
  errorMessage?: string;
  defaultValue?: string;
  onClick?: (e?: InputEventType, value?: string) => void;
};

type StateProps = {
  currentValue: string;
  updateCurrentValue: Update<string>;
};

type Props = OwnProps & StateProps;

export const AnswerItemGroup: view<Props> = ({
  name,
  label,
  disabled,
  options,
  onClick,
  defaultValue,
  errorMessage,
  groupClassName,
  currentValue = observe.utils.components.AnswerItemGroup[prop.name].value,
  updateCurrentValue = update.utils.components.AnswerItemGroup[prop.name].value,
}: Props) => {
  const handleClick = (value: string, e: InputEventType) => {
    updateCurrentValue.set(value);
    if (onClick) {
      onClick(e, value);
    }
  };

  const currentOption = currentValue || defaultValue;

  return (
    <div className={groupClassName}>
      {label && <AnswerItemLabel>{label}</AnswerItemLabel>}
      {options.map((option: Option) => (
        <AnswerItem
          disabled={disabled}
          name={name}
          key={option.label}
          label={option.label}
          value={option.value}
          className={option.value === currentOption ? 'selected' : ''}
          onClick={(e: InputEventType) => handleClick(option.value, e)}
          isChecked={option.value === currentOption}
        />
      ))}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};
