import Amplify from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { engine } from '@c11/engine.runtime';
import { render } from '@c11/engine.react';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import { I18nTranslationsService } from './services';

import { state } from './state';
import './global';
const { auth } = window.env;

import { state as CommentsState } from './components/Comments/state/index';

Amplify.configure({ Auth: auth });
Auth.configure(auth);

const app = engine({
  state: { ...state, ...CommentsState },
  use: [
    render(
      <Router>
        <App />
      </Router>,
      '#app',
      {
        debug: false,
      }
    ),
  ],
});

app.start().then(async () => {
  await I18nTranslationsService.initialize();
});
